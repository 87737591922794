import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

function Pset6() {
  const style = {
    color: "#856404",
    backgroundColor: "#fff3cd",
    borderColor: "#ffeeba",
  };

  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  return (
    <React.Fragment>
      <main className="col-md">
        <a data-id="" id="problem-set-6"></a>
        <h1>
          <a data-id="" href="#problem-set-6">
            Problem Set 6
          </a>
        </h1>

        <a data-id="" id="what-to-do"></a>
        <h2>
          <a data-id="" href="#what-to-do">
            What to Do
          </a>
        </h2>

        <ol>
          <li>
            Submit <Link to="python-hello">Hello in Python</Link>{" "}
          </li>
          <li>
            Submit one of:
            <ul className="fa-ul">
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>{" "}
                <Link to="python-mario-less"> this version of Mario </Link> in
                Python, if feeling less comfortable
              </li>
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>{" "}
                <Link to="python-mario-more"> this version of Mario </Link> in
                Python, if feeling more comfortable
              </li>
            </ul>
          </li>
          <li>
            Submit one of:
            <ul className="fa-ul">
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                <Link to="python-cash"> Cash </Link> in Python, if feeling less
                comfortable
              </li>
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                <Link to="python-credit"> Credit </Link> in Python, if feeling
                more comfortable
              </li>
            </ul>
          </li>
          <li>
            Submit <Link to="python-readability"> Readability </Link> in Python
          </li>
          <li>
            Submit <Link to="dna"> DNA </Link> in Python
          </li>
        </ol>

        <p>
          If you submit both versions of Mario, we’ll record the higher of your
          two scores. If you submit both Cash and Credit, we’ll record the
          higher of your two scores.
        </p>

        <a data-id="" id="when-to-do-it"></a>
        <h2>
          <a data-id="" href="#when-to-do-it">
            When to Do It
          </a>
        </h2>

        <p>
          By{" "}
          <span
            data-local="2020-12-31T23:59:00-05:00"
            data-boundary="window"
            data-toggle="tooltip"
            data-trigger="focus"
            title=""
            data-original-title="Arabian Standard Time"
            className="text-nowrap"
            tabindex="0"
          >
            Fri, Jan 1, 2021, 7:59 AM GMT+3
          </span>
          .
        </p>

        <div
          className="alert alert-warning"
          data-alert="warning"
          role="alert"
          style={style}
        >
          <p>
            Though CS50x’s deadline has already been extended to 31 December
            2021, <em>this version</em> of this problem set will only be
            accepted until the date and time above. Thereafter, you may only
            submit work from CS50x 2021, which will be released on 1 January
            2021.{" "}
            <a
              href="https://cs50.harvard.edu/x/2020/faqs/#i-wont-be-able-to-finish-the-course-before-31-december-2020-what-will-happen"
              className="alert-link"
            >
              Not sure you’ll finish the course by then
            </a>
            ?
          </p>
        </div>

        <a data-id="" id="advice"></a>
        <h2>
          <a data-id="" href="#advice">
            Advice
          </a>
        </h2>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Try out any of David’s programs from class via{" "}
            <a href="../../weeks/6/">Week 6</a>’s source code.
          </li>
        </ul>

        <a data-id="" id="academic-honesty"></a>
        <h2>
          <a data-id="" href="#academic-honesty">
            Academic Honesty
          </a>
        </h2>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            For Hello, Mario, Cash, Credit, and Readability, it is{" "}
            <strong>reasonable</strong> to look at your own implementations
            thereof in C.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            It is <strong>not reasonable</strong> to look at others’
            implementations of the same <em>in Python</em>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Insofar as a goal of these problems is to teach you how to teach
            yourself a new language, keep in mind that these acts are not only{" "}
            <strong>reasonable</strong>, per the syllabus, but encouraged toward
            that end:
            <ul className="fa-ul">
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                Incorporating a few lines of code that you find online or
                elsewhere into your own code, provided that those lines are not
                themselves solutions to assigned problems and that you cite the
                lines’ origins.
              </li>
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                Turning to the web or elsewhere for instruction beyond the
                course’s own, for references, and for solutions to technical
                difficulties, but not for outright solutions to problem set’s
                problems or your own final project.
              </li>
            </ul>
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Pset6;
