import React, { useRef } from "react";

import cs50_ide from "./img/cs50_ide.png";
import cs50_ide_file_tree from "./img/cs50_ide_file_tree.png";
import when_green_flag from "./img/when_green_flag.png";

function Note1() {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();
  const ref10 = useRef();
  const ref11 = useRef();
  const ref12 = useRef();
  const ref13 = useRef();
  const ref14 = useRef();
  const ref15 = useRef();
  const handleClick = (e, reference) => {
    e.preventDefault();
    reference.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 className="no_toc" id="lecture-1">
          Lecture 1
        </h1>

        <ul id="markdown-toc">
          <li>
            <a
              href="#c"
              onClick={(e) => handleClick(e, ref1)}
              id="markdown-toc-c"
            >
              C
            </a>
          </li>
          <li>
            <a
              href="#cs50-ide"
              id="markdown-toc-cs50-ide"
              onClick={(e) => handleClick(e, ref2)}
            >
              CS50 IDE
            </a>
          </li>
          <li>
            <a
              href="#compiling"
              id="markdown-toc-compiling"
              onClick={(e) => handleClick(e, ref3)}
            >
              Compiling
            </a>
          </li>
          <li>
            <a
              href="#functions-and-arguments"
              id="markdown-toc-functions-and-arguments"
              onClick={(e) => handleClick(e, ref4)}
            >
              Functions and arguments
            </a>
          </li>
          <li>
            <a
              href="#main-header-files"
              id="markdown-toc-main-header-files"
              onClick={(e) => handleClick(e, ref5)}
            >
              main, header files
            </a>
          </li>
          <li>
            <a
              href="#tools"
              id="markdown-toc-tools"
              onClick={(e) => handleClick(e, ref6)}
            >
              Tools
            </a>
          </li>
          <li>
            <a
              href="#commands"
              id="markdown-toc-commands"
              onClick={(e) => handleClick(e, ref7)}
            >
              Commands
            </a>
          </li>
          <li>
            <a
              href="#types-format-codes"
              id="markdown-toc-types-format-codes"
              onClick={(e) => handleClick(e, ref8)}
            >
              Types, format codes,
            </a>
          </li>
          <li>
            <a
              href="#operators-limitations-truncation"
              id="markdown-toc-operators-limitations-truncation"
              onClick={(e) => handleClick(e, ref9)}
            >
              Operators, limitations, truncation
            </a>
          </li>
          <li>
            <a
              href="#variables-syntactic-sugar"
              id="markdown-toc-variables-syntactic-sugar"
              onClick={(e) => handleClick(e, ref10)}
            >
              Variables, syntactic sugar
            </a>
          </li>
          <li>
            <a
              href="#conditions"
              id="markdown-toc-conditions"
              onClick={(e) => handleClick(e, ref11)}
            >
              Conditions
            </a>
          </li>
          <li>
            <a
              href="#boolean-expressions-loops"
              id="markdown-toc-boolean-expressions-loops"
              onClick={(e) => handleClick(e, ref12)}
            >
              Boolean expressions, loops
            </a>
          </li>
          <li>
            <a
              href="#abstraction"
              id="markdown-toc-abstraction"
              onClick={(e) => handleClick(e, ref13)}
            >
              Abstraction
            </a>
          </li>
          <li>
            <a
              href="#mario"
              id="markdown-toc-mario"
              onClick={(e) => handleClick(e, ref14)}
            >
              Mario
            </a>
          </li>
          <li>
            <a
              href="#memory-imprecision-and-overflow"
              id="markdown-toc-memory-imprecision-and-overflow"
              onClick={(e) => handleClick(e, ref15)}
            >
              Memory, imprecision, and overflow
            </a>
          </li>
        </ul>

        <h2 id="c" ref={ref1}>
          C
        </h2>

        <ul>
          <li data-marker="*">
            Today we’ll learn a new language, <strong>C</strong>: a programming
            language that has all the features of Scratch and more, but perhaps
            a little less friendly since it’s purely in text:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">#include &lt;stdio.h&gt;</span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, world"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Though at first, to borrow a phrase from MIT, trying to absorb all
            these new concepts may feel like drinking from a fire hose, be
            assured that by the end of the semester we’ll be empowered by and
            experienced at learning and applying these concepts.
          </li>
          <li data-marker="*">
            We can compare a lot of the programming features in C to blocks
            we’ve already seen and used in Scratch. The details of the syntax
            are far less important than the ideas, which we’ve already been
            introduced to.
          </li>
          <li data-marker="*">
            In our example, though the words are new, the ideas are exactly as
            same as the “when green flag clicked” and “say (hello, world)”
            blocks in Scratch:
            <br />
            <img
              src={when_green_flag}
              alt="block labeled 'when green flag clicked', block labeled 'say (hello, world)'"
            />
          </li>
          <li data-marker="*">
            When writing code, we might consider the following qualities:
            <ul>
              <li data-marker="*">
                <strong>Correctness</strong>, or whether our code works
                correctly, as intended.
              </li>
              <li data-marker="*">
                <strong>Design</strong>, or a subjective measure of how
                well-written our code is, based on how efficient it is and how
                elegant or logically readable it is, without unnecessary
                repetition.
              </li>
              <li data-marker="*">
                <strong>Style</strong>, or how aesthetically formatted our code
                is, in terms of consistent indentation and other placement of
                symbols. Differences in style don’t affect the correctness or
                meaning of our code, but affect how readable it is visually.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="cs50-ide" ref={ref2}>
          CS50 IDE
        </h2>
        <ul>
          <li data-marker="*">
            To start writing our code quickly, we’ll use a tool for the course,{" "}
            <a href="https://ide.cs50.io/">
              <strong>CS50 IDE</strong>
            </a>
            , an <em>integrated development environment</em> which includes
            programs and features for writing code. CS50 IDE is built atop a
            popular cloud-based IDE used by general programmers, but with
            additional educational features and customization.
          </li>
          <li data-marker="*">
            We’ll open the IDE, and after logging in we’ll see a screen like
            this:
            <br />
            <img
              src={cs50_ide}
              alt="two panels, top blank, bottom with prompt"
            />
            <ul>
              <li data-marker="*">
                The top panel, blank, will contain text files within which we
                can write our code.
              </li>
              <li data-marker="*">
                The bottom panel, a <strong>terminal</strong> window, will allow
                us to type in various commands and run them, including programs
                from our code above.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Our IDE runs in the cloud and comes with a standard set of tools,
            but know that there are many desktop-based IDEs as well, offering
            more customization and control for different programming purposes,
            at the cost of greater setup time and effort.
          </li>
          <li data-marker="*">
            In the IDE, we’ll go to File &gt; New File, and then File &gt; Save
            to save our file as{" "}
            <code className="language-plaintext highlighter-rouge">
              hello.c
            </code>
            , indicating that our file will be code written in C. We’ll see that
            the name of our tab has indeed changed to{" "}
            <code className="language-plaintext highlighter-rouge">
              hello.c
            </code>
            , and now we’ll paste our code from above:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">#include &lt;stdio.h&gt;</span>
                    <br />
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <br />
                    <span className="p">{"{"}</span>
                    <br />
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, world"</span>
                    <span className="p">);</span>
                    <br />
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            To run our program, we’ll use a CLI, or{" "}
            <strong>command-line interface</strong>, a prompt at which we need
            to enter text commands. This is in contrast to a{" "}
            <strong>graphical user interface</strong>, or GUI, like Scratch,
            where we have images, icons, and buttons in addition to text.
          </li>
        </ul>

        <h2 id="compiling" ref={ref3}>
          Compiling
        </h2>

        <ul>
          <li data-marker="*">
            In the terminal in the bottom pane of our IDE, we’ll{" "}
            <strong>compile</strong> our code before we can run it. Computers
            only understand binary, which is also used to represent instructions
            like printing something to the screen. Our{" "}
            <strong>source code</strong> has been written in characters we can
            read, but it needs to be compiled: converted to{" "}
            <strong>machine code</strong>, patterns of zeros and ones that our
            computer can directly understand.
          </li>
          <li data-marker="*">
            A program called a <strong>compiler</strong> will take source code
            as input and produce machine code as output. In the CS50 IDE, we
            have access to a compiler already, through a command called{" "}
            <strong>make</strong>. In our terminal, we’ll type in{" "}
            <code className="language-plaintext highlighter-rouge">
              make hello
            </code>
            , which will automatically find our{" "}
            <code className="language-plaintext highlighter-rouge">
              hello.c
            </code>{" "}
            file with our source code, and compile it into a program called{" "}
            <code className="language-plaintext highlighter-rouge">hello</code>.
            There will be some output, but no error messages in yellow or red,
            so our program compiled successfully.
          </li>
          <li data-marker="*">
            To run our program, we’ll type in another command,{" "}
            <code className="language-plaintext highlighter-rouge">
              ./hello
            </code>
            , which looks in the current folder,{" "}
            <code className="language-plaintext highlighter-rouge">.</code>, for
            a program called{" "}
            <code className="language-plaintext highlighter-rouge">hello</code>,
            and runs it.
          </li>
          <li data-marker="*">
            The <code className="language-plaintext highlighter-rouge">$</code>{" "}
            in the terminal is an indicator of where the prompt is, or where we
            can type in more commands.
          </li>
        </ul>

        <h2 id="functions-and-arguments" ref={ref4}>
          Functions and arguments
        </h2>

        <ul>
          <li data-marker="*">
            We’ll use the same ideas we’ve explored in Scratch.
          </li>
          <li data-marker="*">
            <strong>Functions</strong> are small actions or verbs that we can
            use in our program to do something, and the inputs to functions are
            called <strong>arguments</strong>.
            <ul>
              <li data-marker="*">
                For example, the “say” block in Scratch might have taken
                something like “hello, world” as an argument. In C, the function
                to print something to the screen is called{" "}
                <code className="language-plaintext highlighter-rouge">
                  printf
                </code>{" "}
                (with the{" "}
                <code className="language-plaintext highlighter-rouge">f</code>{" "}
                standing for “formatted” text, which we’ll soon see). And in C,
                we pass in arguments within parentheses, as in{" "}
                <code className="language-plaintext highlighter-rouge">
                  printf("hello, world");
                </code>
                . The double quotes indicate that we want to print out the
                letters{" "}
                <code className="language-plaintext highlighter-rouge">
                  hello, world
                </code>{" "}
                literally, and the semicolon at the end indicates the end of our
                line of code.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Functions can also have two kinds of outputs:
            <ul>
              <li data-marker="*">
                <strong>side effects</strong>, such as something printed to the
                screen,
              </li>
              <li data-marker="*">
                and <strong>return values</strong>, a value that is passed back
                to our program that we can use or store for later.
                <ul>
                  <li data-marker="*">
                    The “ask” block in Scratch, for example, created an “answer”
                    block.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-marker="*">
            To get the same functionality as the “ask” block, we’ll use a{" "}
            <strong>library</strong>, or a set of code already written. The CS50
            Library will include some basic, simple functions that we can use
            right away. For example,{" "}
            <code className="language-plaintext highlighter-rouge">
              get_string
            </code>{" "}
            will ask the user for a <strong>string</strong>, or some sequence of
            text, and return it to our program.{" "}
            <code className="language-plaintext highlighter-rouge">
              get_string
            </code>{" "}
            takes in some input as the prompt for the user, such as{" "}
            <code className="language-plaintext highlighter-rouge">
              What's your name?
            </code>
            , and we’ll have to save it in a variable with:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="n">string</span>{" "}
                    <span className="n">answer</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"What's your name? "</span>
                    <span className="p">);</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                In C, the single{" "}
                <code className="language-plaintext highlighter-rouge">=</code>{" "}
                indicates <strong>assignment</strong>, or setting the value on
                the right to the variable on the left. And C will call the{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_string
                </code>{" "}
                function in order to get its output first.
              </li>
              <li data-marker="*">
                And we also need to indicate that our variable named{" "}
                <code className="language-plaintext highlighter-rouge">
                  answer
                </code>{" "}
                has a <strong>type</strong> of string, so our program knows to
                interpret the zeros and ones as text.
              </li>
              <li data-marker="*">
                Finally, we need to remember to add a semicolon to end our line
                of code.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            In Scratch, we also used the “answer” block within our “join” and
            “say” blocks. In C, we’ll do this:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, %s"</span>
                    <span className="p">,</span>{" "}
                    <span className="n">answer</span>
                    <span className="p">);</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                The{" "}
                <code className="language-plaintext highlighter-rouge">%s</code>{" "}
                is called a <strong>format code</strong>, which just means that
                we want the{" "}
                <code className="language-plaintext highlighter-rouge">
                  printf
                </code>{" "}
                function to substitute a variable where the{" "}
                <code className="language-plaintext highlighter-rouge">%s</code>{" "}
                placeholder is. And the variable we want to use is{" "}
                <code className="language-plaintext highlighter-rouge">
                  answer
                </code>
                , which we give to{" "}
                <code className="language-plaintext highlighter-rouge">
                  printf
                </code>{" "}
                as another argument, separated from the first one with a comma.
                (
                <code className="language-plaintext highlighter-rouge">
                  printf("hello, answer")
                </code>{" "}
                would literally print out{" "}
                <code className="language-plaintext highlighter-rouge">
                  hello, answer
                </code>{" "}
                every time.)
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Back in the CS50 IDE, we’ll add what we’ve discovered:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">string</span>{" "}
                    <span className="n">answer</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"What's your name? "</span>
                    <span className="p">);</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, %s"</span>
                    <span className="p">,</span>{" "}
                    <span className="n">answer</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We need to tell the compiler to include the CS50 Library, with{" "}
                <code className="language-plaintext highlighter-rouge">
                  #include &lt;cs50.h&gt;
                </code>
                , so we can use the{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_string
                </code>{" "}
                function.
              </li>
              <li data-marker="*">
                We also have an opportunity to use better style here, since we
                could name our{" "}
                <code className="language-plaintext highlighter-rouge">
                  answer
                </code>{" "}
                variable anything, but a more descriptive name will help us
                understand its purpose better than a shorter name like{" "}
                <code className="language-plaintext highlighter-rouge">a</code>{" "}
                or{" "}
                <code className="language-plaintext highlighter-rouge">x</code>.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            After we save the file, we’ll need to recompile our program with{" "}
            <code className="language-plaintext highlighter-rouge">
              make hello
            </code>
            , since we’ve only changed the source code but not the compiled
            machine code. Other languages or IDEs may not require us to manually
            recompile our code after we change it, but here we have the
            opportunity for more control and understanding of what’s happening
            under the hood.
          </li>
          <li data-marker="*">
            Now,{" "}
            <code className="language-plaintext highlighter-rouge">
              ./hello
            </code>{" "}
            will run our program, and prompt us for our name as intended. We
            might notice that the next prompt is printed immediately after our
            program’s output, as in{" "}
            <code className="language-plaintext highlighter-rouge">
              hello, Brian~/ $
            </code>
            . We can add a new line after our program’s output, so the next
            prompt is on its own line, with{" "}
            <code className="language-plaintext highlighter-rouge">\n</code>:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, %s</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">,</span>{" "}
                    <span className="n">answer</span>
                    <span className="p">);</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">\n</code>{" "}
                is an example of an <strong>escape sequence</strong>, or some
                text that represents some other text.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="main-header-files" ref={ref5}>
          main, header files
        </h2>

        <ul>
          <li data-marker="*">
            The “when green flag clicked” block in Scratch starts what we would
            consider to be the main program. In C, the first line for the same
            is{" "}
            <code className="language-plaintext highlighter-rouge">
              int main(void)
            </code>
            , which we’ll learn more about over the coming weeks, followed by an
            open curly brace{" "}
            <code className="language-plaintext highlighter-rouge">{"{"}</code>,
            and a closed curly brace{" "}
            <code className="language-plaintext highlighter-rouge">{"}"}</code>,
            wrapping everything that should be in our program.
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We’ll learn more about ways we can modify this line in the
                coming weeks, but for now we’ll simply use this to start our
                program.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            <strong>Header files</strong> that end with{" "}
            <code className="language-plaintext highlighter-rouge">.h</code>{" "}
            refer to some other set of code, like a library, that we can then
            use in our program. We <em>include</em> them with lines like{" "}
            <code className="language-plaintext highlighter-rouge">
              #include &lt;stdio.h&gt;
            </code>
            , for example, for the <em>standard input/output</em> library, which
            contains the{" "}
            <code className="language-plaintext highlighter-rouge">printf</code>{" "}
            function.
          </li>
        </ul>

        <h2 id="tools" ref={ref6}>
          Tools
        </h2>

        <ul>
          <li data-marker="*">
            With all of the new syntax, it’s easy for us to make mistakes or
            forget something. We have a few tools written by the staff to help
            us.
          </li>
          <li data-marker="*">
            We might forget to include a line of code, and when we try to
            compile our program, see a lot of lines of error messages that are
            hard to understand, since the compiler might have been designed for
            a more technical audience.{" "}
            <strong>
              <code className="language-plaintext highlighter-rouge">
                help50
              </code>
            </strong>{" "}
            is a command we can run to explain problems in our code in a more
            user-friendly way. We can run it by adding{" "}
            <code className="language-plaintext highlighter-rouge">help50</code>{" "}
            to the front of a command we’re trying, like{" "}
            <code className="language-plaintext highlighter-rouge">
              help50 make hello
            </code>
            , to get advice that might be more understandable.
          </li>
          <li data-marker="*">
            It turns out that, in C, new lines and indentation generally don’t
            affect how our code runs. For example, we can change our{" "}
            <code className="language-plaintext highlighter-rouge">main</code>{" "}
            function to be one line,{" "}
            <code className="language-plaintext highlighter-rouge">
              int main(void){'{printf("hello, world");}'}
            </code>
            , but it’s much harder to read, so we would consider it to have bad
            style. We can run{" "}
            <strong>
              <code className="language-plaintext highlighter-rouge">
                style50
              </code>
            </strong>
            , as with{" "}
            <code className="language-plaintext highlighter-rouge">
              style50 hello.c
            </code>
            , with the name of the file of our source code, to see suggestions
            for new lines and indentation.
          </li>
          <li data-marker="*">
            Additionally, we can add <strong>comments</strong>, notes in our
            source code for ourselves or other humans that don’t affect how our
            code runs. For example, we might add a line like{" "}
            <code className="language-plaintext highlighter-rouge">
              // Greet user
            </code>
            , with two slashes{" "}
            <code className="language-plaintext highlighter-rouge">//</code> to
            indicate that the line is a comment, and then write the purpose of
            our code or program to help us remember later on.
          </li>
          <li data-marker="*">
            <strong>
              <code className="language-plaintext highlighter-rouge">
                check50
              </code>
            </strong>{" "}
            will check the correctness of our code with some automated tests.
            The staff writes tests specifically for some of the programs we’ll
            be writing in the course, and instructions for using{" "}
            <code className="language-plaintext highlighter-rouge">
              check50
            </code>{" "}
            will be included in each problem set or lab as needed. After we run{" "}
            <code className="language-plaintext highlighter-rouge">
              check50
            </code>
            , we’ll see some output telling us whether our code passed relevant
            tests.
          </li>
          <li data-marker="*">
            The CS50 IDE also gives us the equivalent of our own computer in the
            cloud, somewhere on the internet, with our own files and folders. If
            we click the folder icon in the top left, we’ll see a file tree, a
            GUI of the files in our IDE:
            <br />
            <img src={cs50_ide_file_tree} alt="left panel with file tree" />
            <ul>
              <li data-marker="*">
                To open a file, we can just double-click it.{" "}
                <code className="language-plaintext highlighter-rouge">
                  hello.c
                </code>{" "}
                is the source code that we just wrote, and{" "}
                <code className="language-plaintext highlighter-rouge">
                  hello
                </code>{" "}
                itself will have lots of red dots, each of which are unprintable
                characters since they represent binary instructions for our
                computers.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="commands" ref={ref7}>
          Commands
        </h2>

        <ul>
          <li data-marker="*">
            Since the CS50 IDE is a virtual computer in the cloud, we can also
            run commands available in Linux, an operating system like macOS or
            Windows.
          </li>
          <li data-marker="*">
            In the terminal, we can type in{" "}
            <code className="language-plaintext highlighter-rouge">ls</code>,
            short for list, to see a list of files and folder in the current
            folder:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="o">~/</span> <span className="err">$</span>{" "}
                    <span className="n">ls</span>
                    <span className="n">hello</span>
                    <span className="o">*</span>{" "}
                    <span className="n">hello</span>
                    <span className="p">.</span>
                    <span className="n">c</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  hello
                </code>{" "}
                is in green with an asterisk to indicate that we can run it as a
                program.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can also <em>remove</em> files with{" "}
            <code className="language-plaintext highlighter-rouge">rm</code>,
            with a command like{" "}
            <code className="language-plaintext highlighter-rouge">
              rm hello
            </code>
            . It will prompt us for a confirmation, and we can respond with{" "}
            <code className="language-plaintext highlighter-rouge">y</code> or{" "}
            <code className="language-plaintext highlighter-rouge">n</code> for
            yes or no.
          </li>
          <li data-marker="*">
            With{" "}
            <code className="language-plaintext highlighter-rouge">mv</code>, or{" "}
            <em>move</em>, we can rename files. With{" "}
            <code className="language-plaintext highlighter-rouge">
              mv hello.c goodbye.c
            </code>
            , we’ve renamed our{" "}
            <code className="language-plaintext highlighter-rouge">
              hello.c
            </code>{" "}
            file to be named{" "}
            <code className="language-plaintext highlighter-rouge">
              goodbye.c
            </code>
            .
          </li>
          <li data-marker="*">
            With{" "}
            <code className="language-plaintext highlighter-rouge">mkdir</code>,
            or <em>make directory</em>, we can create folders, or directories.
            If we run{" "}
            <code className="language-plaintext highlighter-rouge">
              mkdir lecture
            </code>
            , we’ll see a folder called{" "}
            <code className="language-plaintext highlighter-rouge">
              lecture
            </code>
            , and we can move files into directories with a command like{" "}
            <code className="language-plaintext highlighter-rouge">
              mv hello.c lecture/
            </code>
            .
          </li>
          <li data-marker="*">
            To <em>change directories</em> in our terminal, we can use{" "}
            <code className="language-plaintext highlighter-rouge">cd</code>, as
            with{" "}
            <code className="language-plaintext highlighter-rouge">
              cd lecture/
            </code>
            . Our prompt will change from{" "}
            <code className="language-plaintext highlighter-rouge">~/</code> to{" "}
            <code className="language-plaintext highlighter-rouge">
              ~/lecture/
            </code>
            , indicating that we’re in the{" "}
            <code className="language-plaintext highlighter-rouge">
              lecture
            </code>{" "}
            directory inside{" "}
            <code className="language-plaintext highlighter-rouge">~</code>.{" "}
            <code className="language-plaintext highlighter-rouge">~</code>{" "}
            stands for our home directory, or our account’s default, top-level
            folder.
          </li>
          <li data-marker="*">
            We can also use{" "}
            <code className="language-plaintext highlighter-rouge">..</code> as
            shorthand for the parent, or containing folder. Within{" "}
            <code className="language-plaintext highlighter-rouge">
              ~/lecture/
            </code>
            , we can run{" "}
            <code className="language-plaintext highlighter-rouge">
              mv hello.c ..
            </code>{" "}
            to move it back up to{" "}
            <code className="language-plaintext highlighter-rouge">~</code>,
            since it’s the parent folder of{" "}
            <code className="language-plaintext highlighter-rouge">
              lecture/
            </code>
            .{" "}
            <code className="language-plaintext highlighter-rouge">cd ..</code>,
            similarly, will change our terminal’s directory to the current one’s
            parent. A single dot,{" "}
            <code className="language-plaintext highlighter-rouge">.</code>,
            refers to the current directory, as in{" "}
            <code className="language-plaintext highlighter-rouge">
              ./hello
            </code>
            .
          </li>
          <li data-marker="*">
            Now that our{" "}
            <code className="language-plaintext highlighter-rouge">
              lecture/
            </code>{" "}
            folder is empty, we can remove it with{" "}
            <code className="language-plaintext highlighter-rouge">
              rmdir lecture/
            </code>{" "}
            as well.
          </li>
        </ul>

        <h2 id="types-format-codes" ref={ref8}>
          Types, format codes,
        </h2>

        <ul>
          <li data-marker="*">
            There are many data <strong>types</strong> we can use for our
            variables, which indicate to the computer what type of data they
            represent:
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  bool
                </code>
                , a Boolean expression of either{" "}
                <code className="language-plaintext highlighter-rouge">
                  true
                </code>{" "}
                or{" "}
                <code className="language-plaintext highlighter-rouge">
                  false
                </code>
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  char
                </code>
                , a single ASCII character like{" "}
                <code className="language-plaintext highlighter-rouge">a</code>{" "}
                or{" "}
                <code className="language-plaintext highlighter-rouge">2</code>
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  double
                </code>
                , a floating-point value with more digits than a{" "}
                <code className="language-plaintext highlighter-rouge">
                  float
                </code>
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  float
                </code>
                , a floating-point value, or real number with a decimal value
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  int
                </code>
                , integers up to a certain size, or number of bits
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  long
                </code>
                , integers with more bits, so they can count higher than an{" "}
                <code className="language-plaintext highlighter-rouge">
                  int
                </code>
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  string
                </code>
                , a string of characters
              </li>
            </ul>
          </li>
          <li data-marker="*">
            And the CS50 library has corresponding functions to get input of
            various types:
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  get_char
                </code>
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  get_double
                </code>
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  get_float
                </code>
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  get_int
                </code>
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  get_long
                </code>
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  get_string
                </code>
              </li>
            </ul>
          </li>
          <li data-marker="*">
            For{" "}
            <code className="language-plaintext highlighter-rouge">printf</code>
            , too, there are different placeholders for each type:
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">%c</code>{" "}
                for chars
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">%f</code>{" "}
                for floats, doubles
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">%i</code>{" "}
                for ints
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  %li
                </code>{" "}
                for longs
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">%s</code>{" "}
                for strings
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="operators-limitations-truncation" ref={ref9}>
          Operators, limitations, truncation
        </h2>

        <ul>
          <li data-marker="*">
            There are several mathematical operators we can use, too:
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">+</code>{" "}
                for addition
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">-</code>{" "}
                for subtraction
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">*</code>{" "}
                for multiplication
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">/</code>{" "}
                for division
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">%</code>{" "}
                for remainder
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We’ll make a new program,{" "}
            <code className="language-plaintext highlighter-rouge">
              addition.c
            </code>
            :
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="kt">int</span> <span className="n">x</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"x: "</span>
                    <span className="p">);</span>
                    <span className="kt">int</span> <span className="n">y</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"y: "</span>
                    <span className="p">);</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"%i</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">,</span> <span className="n">x</span>{" "}
                    <span className="o">+</span> <span className="n">y</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We’ll include header files for libraries we know we want to use,
                and then we’ll call{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_int
                </code>{" "}
                to get integers from the user, storing them in variables named{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">y</code>.
              </li>
              <li data-marker="*">
                Then, in{" "}
                <code className="language-plaintext highlighter-rouge">
                  printf
                </code>
                , we’ll print a placeholder for an integer,{" "}
                <code className="language-plaintext highlighter-rouge">%i</code>
                , followed by a new line. Since we want to print out the sum of{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">y</code>,
                we’ll pass in{" "}
                <code className="language-plaintext highlighter-rouge">
                  x + y
                </code>{" "}
                for{" "}
                <code className="language-plaintext highlighter-rouge">
                  printf
                </code>{" "}
                to substitute in the string.
              </li>
              <li data-marker="*">
                We’ll save, run{" "}
                <code className="language-plaintext highlighter-rouge">
                  make addition
                </code>{" "}
                in the terminal, and then{" "}
                <code className="language-plaintext highlighter-rouge">
                  ./addition
                </code>{" "}
                to see our program working. If we type in something that’s not
                an integer, we’ll see{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_int
                </code>{" "}
                asking us for an integer again. If we type in a really big
                number, like{" "}
                <code className="language-plaintext highlighter-rouge">
                  4000000000
                </code>
                ,{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_int
                </code>{" "}
                will prompt us again too. This is because, like on many computer
                systems, an{" "}
                <code className="language-plaintext highlighter-rouge">
                  int
                </code>{" "}
                in CS50 IDE is 32 bits, which can only contain about four
                billion different values. And since integers can be positive or
                negative, the highest positive value for an{" "}
                <code className="language-plaintext highlighter-rouge">
                  int
                </code>{" "}
                can only be about two billion, with a lowest negative value of
                about negative two billion, for a total of about four billion
                total values.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can change our program to use the{" "}
            <code className="language-plaintext highlighter-rouge">long</code>{" "}
            type:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="kt">long</span>{" "}
                    <span className="n">x</span> <span className="o">=</span>{" "}
                    <span className="n">get_long</span>
                    <span className="p">(</span>
                    <span className="s">"x: "</span>
                    <span className="p">);</span>
                    <span className="kt">long</span>{" "}
                    <span className="n">y</span> <span className="o">=</span>{" "}
                    <span className="n">get_long</span>
                    <span className="p">(</span>
                    <span className="s">"y: "</span>
                    <span className="p">);</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"%li</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">,</span> <span className="n">x</span>{" "}
                    <span className="o">+</span> <span className="n">y</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Now we can type in bigger integers, and see a correct result as
                expected.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Whenever we get an error while compiling, it’s a good idea to scroll
            up to the top to see the first error and fix that first, since
            sometimes a mistake early in the program will lead to the rest of
            the program being interpreted with errors as well.
          </li>
          <li data-marker="*">
            Let’s look at another example,{" "}
            <code className="language-plaintext highlighter-rouge">
              truncation.c
            </code>
            :
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="c1">// Get numbers from user</span>
                    <span className="kt">int</span> <span className="n">x</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"x: "</span>
                    <span className="p">);</span>
                    <span className="kt">int</span> <span className="n">y</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"y: "</span>
                    <span className="p">);</span>
                    <span className="c1">// Divide x by y</span>
                    <span className="kt">float</span>{" "}
                    <span className="n">z</span> <span className="o">=</span>{" "}
                    <span className="n">x</span> <span className="o">/</span>{" "}
                    <span className="n">y</span>
                    <span className="p">;</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"%f</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">,</span> <span className="n">z</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We’ll store the result of{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                divided by{" "}
                <code className="language-plaintext highlighter-rouge">y</code>{" "}
                in{" "}
                <code className="language-plaintext highlighter-rouge">z</code>,
                a floating-point value, or real number, and print it out as a
                float too.
              </li>
              <li data-marker="*">
                But when we compile and run our program, we see{" "}
                <code className="language-plaintext highlighter-rouge">z</code>{" "}
                printed out as whole numbers like{" "}
                <code className="language-plaintext highlighter-rouge">
                  0.000000
                </code>{" "}
                or{" "}
                <code className="language-plaintext highlighter-rouge">
                  1.000000
                </code>
                . It turns out that, in our code,{" "}
                <code className="language-plaintext highlighter-rouge">
                  x / y
                </code>{" "}
                is divided as two integers <em>first</em>, so the result given
                back by the division operation is an integer as well. The result
                is <strong>truncated</strong>, with the value after the decimal
                point lost. Even though{" "}
                <code className="language-plaintext highlighter-rouge">z</code>{" "}
                is a{" "}
                <code className="language-plaintext highlighter-rouge">
                  float
                </code>
                , the value we’re storing in it is already an integer.
              </li>
              <li data-marker="*">
                To fix this, we <strong>cast</strong>, or convert, our integers
                to floats before we divide them:
                <div className="language-c highlighter-rouge">
                  <div className="highlight">
                    <pre className="highlight">
                      <code>
                        <span className="kt">float</span>{" "}
                        <span className="n">z</span>{" "}
                        <span className="o">=</span>{" "}
                        <span className="p">(</span>
                        <span className="kt">float</span>
                        <span className="p">)</span>{" "}
                        <span className="n">x</span>{" "}
                        <span className="o">/</span>{" "}
                        <span className="p">(</span>
                        <span className="kt">float</span>
                        <span className="p">)</span>{" "}
                        <span className="n">y</span>
                        <span className="p">;</span>
                      </code>
                    </pre>
                  </div>{" "}
                </div>
              </li>
              <li data-marker="*">
                The result will be a float as we expect, and in fact we can cast
                only one of{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                or{" "}
                <code className="language-plaintext highlighter-rouge">y</code>{" "}
                and get a float as well.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="variables-syntactic-sugar" ref={ref10}>
          Variables, syntactic sugar
        </h2>

        <ul>
          <li data-marker="*">
            In Scratch, we had blocks like “set [counter] to (0)” that set a{" "}
            <strong>variable</strong> to some value. In C, we would write{" "}
            <code className="language-plaintext highlighter-rouge">
              int counter = 0;
            </code>{" "}
            for the same effect.
          </li>
          <li data-marker="*">
            We can increase the value of a variable with{" "}
            <code className="language-plaintext highlighter-rouge">
              counter = counter + 1;
            </code>
            , where we look at the right side first, taking the original value
            of{" "}
            <code className="language-plaintext highlighter-rouge">
              counter
            </code>
            , adding 1, and then storing it into the left side (back into{" "}
            <code className="language-plaintext highlighter-rouge">
              counter
            </code>{" "}
            in this case).
          </li>
          <li data-marker="*">
            C also supports <strong>syntactic sugar</strong>, or shorthand
            expressions for the same functionality. In this case, we could
            equivalently say{" "}
            <code className="language-plaintext highlighter-rouge">
              counter += 1;
            </code>{" "}
            to add one to{" "}
            <code className="language-plaintext highlighter-rouge">
              counter
            </code>{" "}
            before storing it again. We could also just write{" "}
            <code className="language-plaintext highlighter-rouge">
              counter++;
            </code>
            , and we can learn this (and other examples) through looking at
            documentation or other references online.
          </li>
        </ul>

        <h2 id="conditions" ref={ref11}>
          Conditions
        </h2>

        <ul>
          <li data-marker="*">
            We can translate conditions, or “if” blocks, with:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="n">x</span> <span className="o">&lt;</span>{" "}
                    <span className="n">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"x is less than y</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Notice that in C, we use{" "}
                <code className="language-plaintext highlighter-rouge">
                  {"{"}
                </code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  {"}"}
                </code>{" "}
                (as well as indentation) to indicate how lines of code should be
                nested.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can have “if” and “else” conditions:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="n">x</span> <span className="o">&lt;</span>{" "}
                    <span className="n">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"x is less than y</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"x is not less than y</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            And even “else if”:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="n">x</span> <span className="o">&lt;</span>{" "}
                    <span className="n">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"x is less than y</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>{" "}
                    <span className="nf">if</span> <span className="p">(</span>
                    <span className="n">x</span> <span className="o">&gt;</span>{" "}
                    <span className="n">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"x is greater than y</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>{" "}
                    <span className="nf">if</span> <span className="p">(</span>
                    <span className="n">x</span> <span className="o">==</span>{" "}
                    <span className="n">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"x is equal to y</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Notice that, to compare two values in C, we use{" "}
                <code className="language-plaintext highlighter-rouge">==</code>
                , two equals signs.
              </li>
              <li data-marker="*">
                And, logically, we don’t need the{" "}
                <code className="language-plaintext highlighter-rouge">
                  if (x == y)
                </code>{" "}
                in the final condition, since that’s the only case remaining, so
                we can just say{" "}
                <code className="language-plaintext highlighter-rouge">
                  else
                </code>
                :
                <div className="language-c highlighter-rouge">
                  <div className="highlight">
                    <pre className="highlight">
                      <code>
                        <span className="k">if</span>{" "}
                        <span className="p">(</span>
                        <span className="n">x</span>{" "}
                        <span className="o">&lt;</span>{" "}
                        <span className="n">y</span>
                        <span className="p">)</span>
                        <span className="p">{"{"}</span>
                        <span className="n">printf</span>
                        <span className="p">(</span>
                        <span className="s">"x is less than y</span>
                        <span className="se">\n</span>
                        <span className="s">"</span>
                        <span className="p">);</span>
                        <span className="p">{"}"}</span>
                        <span className="k">else</span>{" "}
                        <span className="nf">if</span>{" "}
                        <span className="p">(</span>
                        <span className="n">x</span>{" "}
                        <span className="o">&gt;</span>{" "}
                        <span className="n">y</span>
                        <span className="p">)</span>
                        <span className="p">{"{"}</span>
                        <span className="n">printf</span>
                        <span className="p">(</span>
                        <span className="s">"x is greater than y</span>
                        <span className="se">\n</span>
                        <span className="s">"</span>
                        <span className="p">);</span>
                        <span className="p">{"}"}</span>
                        <span className="k">else</span>
                        <span className="p">{"{"}</span>
                        <span className="n">printf</span>
                        <span className="p">(</span>
                        <span className="s">"x is equal to y</span>
                        <span className="se">\n</span>
                        <span className="s">"</span>
                        <span className="p">);</span>
                        <span className="p">{"}"}</span>
                      </code>
                    </pre>
                  </div>{" "}
                </div>
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Let’s take a look at another example,{" "}
            <code className="language-plaintext highlighter-rouge">
              conditions.c
            </code>
            :
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="c1">// Prompt user for x</span>
                    <span className="kt">int</span> <span className="n">x</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"x: "</span>
                    <span className="p">);</span>
                    <span className="c1">// Prompt user for y</span>
                    <span className="kt">int</span> <span className="n">y</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"y: "</span>
                    <span className="p">);</span>
                    <span className="c1">// Compare x and y</span>
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="n">x</span> <span className="o">&lt;</span>{" "}
                    <span className="n">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"x is less than y</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>{" "}
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="n">x</span> <span className="o">&gt;</span>{" "}
                    <span className="n">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"x is greater than y</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"x is equal to y</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We’ve included the conditions we just saw, along with two calls,
                or uses, of{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_int
                </code>{" "}
                to get{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">y</code>{" "}
                from the user.
              </li>
              <li data-marker="*">
                We’ll compile and run our program to see that it indeed works as
                intended.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            In{" "}
            <code className="language-plaintext highlighter-rouge">
              agree.c
            </code>
            , we can ask the user to confirm or deny something:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="kt">char</span>{" "}
                    <span className="n">c</span> <span className="o">=</span>{" "}
                    <span className="n">get_char</span>
                    <span className="p">(</span>
                    <span className="s">"Do you agree? "</span>
                    <span className="p">);</span>
                    <span className="c1">// Check whether agreed</span>
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="n">c</span> <span className="o">==</span>{" "}
                    <span className="sc">'Y'</span>{" "}
                    <span className="o">||</span> <span className="n">c</span>{" "}
                    <span className="o">==</span>{" "}
                    <span className="sc">'y'</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"Agreed.</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>{" "}
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="n">c</span> <span className="o">==</span>{" "}
                    <span className="sc">'N'</span>{" "}
                    <span className="o">||</span> <span className="n">c</span>{" "}
                    <span className="o">==</span>{" "}
                    <span className="sc">'n'</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"Not agreed.</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                With{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_char
                </code>
                , we can get a single character, and since we only have a single
                one in our program, it seems reasonable to call it{" "}
                <code className="language-plaintext highlighter-rouge">c</code>.
              </li>
              <li data-marker="*">
                We use two vertical bars,{" "}
                <code className="language-plaintext highlighter-rouge">||</code>
                , to indicate a logical “or”, whether either expression can be
                true for the condition to be followed. (Two ampersands,{" "}
                <code className="language-plaintext highlighter-rouge">
                  &amp;&amp;
                </code>
                , indicate a logical “and”, where both conditions would have to
                be true.) And notice that we use two equals signs,{" "}
                <code className="language-plaintext highlighter-rouge">==</code>
                , to compare two values, as well as single quotes,{" "}
                <code className="language-plaintext highlighter-rouge">'</code>,
                to surround our values of single characters.
              </li>
              <li data-marker="*">
                If neither of the expressions are true, nothing will happen
                since our program doesn’t have a loop.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="boolean-expressions-loops" ref={ref12}>
          Boolean expressions, loops
        </h2>

        <ul>
          <li data-marker="*">
            We can translate a “forever” block in Scratch with:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">while</span>{" "}
                    <span className="p">(</span>
                    <span className="nb">true</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, world</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                The{" "}
                <code className="language-plaintext highlighter-rouge">
                  while
                </code>{" "}
                keyword requires a condition, so we use{" "}
                <code className="language-plaintext highlighter-rouge">
                  true
                </code>{" "}
                as the Boolean expression to ensure that our loop will run
                forever.{" "}
                <code className="language-plaintext highlighter-rouge">
                  while
                </code>{" "}
                will tell the computer to check whether the expression evaluates
                to{" "}
                <code className="language-plaintext highlighter-rouge">
                  true
                </code>
                , and then run the lines inside the curly braces. Then it will
                repeat that until the expression isn’t true anymore. In this
                case,{" "}
                <code className="language-plaintext highlighter-rouge">
                  true
                </code>{" "}
                will always be true, so our loop is an{" "}
                <strong>infinite loop</strong>, or one that will run forever.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We could do something a certain number of times with{" "}
            <code className="language-plaintext highlighter-rouge">while</code>:
            <br />
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span>
                    <span className="k">while</span>{" "}
                    <span className="p">(</span>
                    <span className="n">i</span> <span className="o">&lt;</span>{" "}
                    <span className="mi">50</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, world</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We create a variable,{" "}
                <code className="language-plaintext highlighter-rouge">i</code>,
                and set it to 0. Then, while{" "}
                <code className="language-plaintext highlighter-rouge">i</code>{" "}
                is less than 50, we run some lines of code, including one where
                we add 1 to{" "}
                <code className="language-plaintext highlighter-rouge">i</code>{" "}
                each time. This way, our loop will eventually end when{" "}
                <code className="language-plaintext highlighter-rouge">i</code>{" "}
                reaches a value of 50.
              </li>
              <li data-marker="*">
                In this case, we’re using the variable{" "}
                <code className="language-plaintext highlighter-rouge">i</code>{" "}
                as a counter, but since it doesn’t serve any additional purpose,
                we can simply name it{" "}
                <code className="language-plaintext highlighter-rouge">i</code>.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Even though we <em>could</em> do the following and start counting at
            1, by convention we should start at 0:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">1</span>
                    <span className="p">;</span>
                    <span className="k">while</span>{" "}
                    <span className="p">(</span>
                    <span className="n">i</span>{" "}
                    <span className="o">&lt;=</span>{" "}
                    <span className="mi">50</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, world</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Another correct, but arguably less well-designed solution might be
            starting at 50 and counting backwards:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">50</span>
                    <span className="p">;</span>
                    <span className="k">while</span>{" "}
                    <span className="p">(</span>
                    <span className="n">i</span> <span className="o">&gt;</span>{" "}
                    <span className="mi">0</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, world</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="n">i</span>
                    <span className="o">--</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                In this case, the logic for our loop is harder to reason about
                without serving any additional purpose, and might even confuse
                readers.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Finally, more commonly we can use the{" "}
            <code className="language-plaintext highlighter-rouge">for</code>{" "}
            keyword:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span> <span className="n">i</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="mi">50</span>
                    <span className="p">;</span> <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"hello, world</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Again, first we create a variable named{" "}
                <code className="language-plaintext highlighter-rouge">i</code>{" "}
                and set it to 0. Then, we check that{" "}
                <code className="language-plaintext highlighter-rouge">
                  i &lt; 50
                </code>{" "}
                every time we reach the top of the loop, before we run any of
                the code inside. If that expression is true, then we run the
                code inside. Finally, after we run the code inside, we use{" "}
                <code className="language-plaintext highlighter-rouge">
                  i++
                </code>{" "}
                to add one to{" "}
                <code className="language-plaintext highlighter-rouge">i</code>,
                and the loop repeats.
              </li>
              <li data-marker="*">
                The{" "}
                <code className="language-plaintext highlighter-rouge">
                  for
                </code>{" "}
                loop is more elegant than a{" "}
                <code className="language-plaintext highlighter-rouge">
                  while
                </code>{" "}
                loop in this case, since everything related to the loop is in
                the same line, and only the code we actually want to run
                multiple times is inside the loop.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Notice that for many of these lines of code, like{" "}
            <code className="language-plaintext highlighter-rouge">if</code>{" "}
            conditions and{" "}
            <code className="language-plaintext highlighter-rouge">for</code>{" "}
            loops, we don’t put a semicolon at the end. This is just how the
            language of C was designed, many years ago, and a general rule is
            that only lines for actions or verbs have semicolons at the end.
          </li>
        </ul>

        <h2 id="abstraction" ref={ref13}>
          Abstraction
        </h2>

        <ul>
          <li data-marker="*">
            We can write a program that prints{" "}
            <code className="language-plaintext highlighter-rouge">meow</code>{" "}
            three times:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">#include &lt;stdio.h&gt;</span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"meow</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"meow</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"meow</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We could use a{" "}
            <code className="language-plaintext highlighter-rouge">for</code>{" "}
            loop, so we don’t have to copy and paste so many lines:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">#include &lt;stdio.h&gt;</span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span> <span className="n">i</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="mi">3</span>
                    <span className="p">;</span> <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"meow</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We can move the{" "}
            <code className="language-plaintext highlighter-rouge">printf</code>{" "}
            line to its own function, like our own puzzle piece:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">#include &lt;stdio.h&gt;</span>
                    <span className="kt">void</span>{" "}
                    <span className="nf">meow</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"meow</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span> <span className="n">i</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="mi">3</span>
                    <span className="p">;</span> <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">meow</span>
                    <span className="p">();</span>
                    <span className="p">{"}"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We defined a function,{" "}
                <code className="language-plaintext highlighter-rouge">
                  meow
                </code>
                , above our{" "}
                <code className="language-plaintext highlighter-rouge">
                  main
                </code>{" "}
                function.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            But conventionally, our{" "}
            <code className="language-plaintext highlighter-rouge">main</code>{" "}
            function should be the first function in our program, so we need a
            few more lines:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">#include &lt;stdio.h&gt;</span>
                    <span className="kt">void</span>{" "}
                    <span className="nf">meow</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">);</span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span> <span className="n">i</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="mi">3</span>
                    <span className="p">;</span> <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">meow</span>
                    <span className="p">();</span>
                    <span className="p">{"}"}</span>
                    <span className="p">{"}"}</span>
                    <span className="kt">void</span>{" "}
                    <span className="nf">meow</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"meow</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                It turns out that we need to declare our{" "}
                <code className="language-plaintext highlighter-rouge">
                  meow
                </code>{" "}
                function first with a <strong>prototype</strong>, before we use
                it in{" "}
                <code className="language-plaintext highlighter-rouge">
                  main
                </code>
                , and actually define it after. The compiler reads our source
                code from top to bottom, so it needs to know that{" "}
                <code className="language-plaintext highlighter-rouge">
                  meow
                </code>{" "}
                will exist later in the file.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can even change our{" "}
            <code className="language-plaintext highlighter-rouge">meow</code>{" "}
            function to take in some input,{" "}
            <code className="language-plaintext highlighter-rouge">n</code>, and
            meow <code className="language-plaintext highlighter-rouge">n</code>{" "}
            times:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">#include &lt;stdio.h&gt;</span>
                    <span className="kt">void</span>{" "}
                    <span className="nf">meow</span>
                    <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">n</span>
                    <span className="p">);</span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">meow</span>
                    <span className="p">(</span>
                    <span className="mi">3</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="kt">void</span>{" "}
                    <span className="nf">meow</span>
                    <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">n</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span> <span className="n">i</span>{" "}
                    <span className="o">&lt;</span> <span className="n">n</span>
                    <span className="p">;</span> <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"meow</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                The{" "}
                <code className="language-plaintext highlighter-rouge">
                  void
                </code>{" "}
                before the{" "}
                <code className="language-plaintext highlighter-rouge">
                  meow
                </code>{" "}
                function means that it doesn’t return a value, and likewise in{" "}
                <code className="language-plaintext highlighter-rouge">
                  main
                </code>{" "}
                we can’t do anything with the result of{" "}
                <code className="language-plaintext highlighter-rouge">
                  meow
                </code>
                , so we just call it.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            The abstraction here leads to better design, since we now have the
            flexibility to reuse our{" "}
            <code className="language-plaintext highlighter-rouge">meow</code>{" "}
            function in multiple places in the future.
          </li>
          <li data-marker="*">
            Let’s look at another example of abstraction,{" "}
            <code className="language-plaintext highlighter-rouge">
              get_positive_int.c
            </code>
            :
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">get_positive_int</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">);</span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_positive_int</span>
                    <span className="p">();</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"%i</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">,</span> <span className="n">i</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="c1">
                      // Prompt user for positive integer
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">get_positive_int</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="kt">int</span> <span className="n">n</span>
                    <span className="p">;</span>
                    <span className="k">do</span>
                    <span className="p">{"{"}</span>
                    <span className="n">n</span> <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"Positive Integer: "</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="k">while</span>{" "}
                    <span className="p">(</span>
                    <span className="n">n</span> <span className="o">&lt;</span>{" "}
                    <span className="mi">1</span>
                    <span className="p">);</span>
                    <span className="k">return</span>{" "}
                    <span className="n">n</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We have our own function that calls{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_int
                </code>{" "}
                repeatedly until we have some integer that’s <em>not</em> less
                than 1. With a do-while loop, our program will do something
                first, then check some condition, and repeat while the condition
                is true. A while loop, on the other hand, will check the
                condition first.
              </li>
              <li data-marker="*">
                We need to declare our integer{" "}
                <code className="language-plaintext highlighter-rouge">n</code>{" "}
                outside the do-while loop, since we need to use it after the
                loop ends. The <strong>scope</strong> of a variable in C refers
                to the context, or lines of code, within which it exists. In
                many cases, this will be the curly braces surrounding the
                variable.
              </li>
              <li data-marker="*">
                Notice that the function{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_positive_int
                </code>{" "}
                now starts with{" "}
                <code className="language-plaintext highlighter-rouge">
                  int
                </code>
                , indicating that it has a return value of type{" "}
                <code className="language-plaintext highlighter-rouge">
                  int
                </code>
                , and in{" "}
                <code className="language-plaintext highlighter-rouge">
                  main
                </code>{" "}
                we indeed store it in{" "}
                <code className="language-plaintext highlighter-rouge">i</code>{" "}
                after calling{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_positive_int()
                </code>
                . In{" "}
                <code className="language-plaintext highlighter-rouge">
                  get_positive_int
                </code>
                , we have a new keyword,{" "}
                <code className="language-plaintext highlighter-rouge">
                  return
                </code>
                , to return the value{" "}
                <code className="language-plaintext highlighter-rouge">n</code>{" "}
                to wherever the function was called.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="mario" ref={ref14}>
          Mario
        </h2>

        <ul>
          <li data-marker="*">
            We might want a program that prints part of a screen from a video
            game like Super Mario Bros. In{" "}
            <code className="language-plaintext highlighter-rouge">
              mario.c
            </code>
            , we can print four question marks, simulating blocks:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">#include &lt;stdio.h&gt;</span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"????</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            With a loop, we can print a number of question marks, following them
            with a single new line after the loop:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">#include &lt;stdio.h&gt;</span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span> <span className="n">i</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="mi">4</span>
                    <span className="p">;</span> <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"?"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We can get a positive integer from the user, and print out that
            number of question marks, by using{" "}
            <code className="language-plaintext highlighter-rouge">n</code> for
            our loop:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="c1">
                      // Get positive integer from user
                    </span>
                    <span className="kt">int</span> <span className="n">n</span>
                    <span className="p">;</span>
                    <span className="k">do</span>
                    <span className="p">{"{"}</span>
                    <span className="n">n</span> <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"Width: "</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="k">while</span>{" "}
                    <span className="p">(</span>
                    <span className="n">n</span> <span className="o">&lt;</span>{" "}
                    <span className="mi">1</span>
                    <span className="p">);</span>
                    <span className="c1">
                      // Print out that many question marks
                    </span>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span> <span className="n">i</span>{" "}
                    <span className="o">&lt;</span> <span className="n">n</span>
                    <span className="p">;</span> <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"?"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            And we can print a two-dimensional set of blocks with nested loops,
            one inside the other:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">i</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span> <span className="n">i</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="mi">3</span>
                    <span className="p">;</span> <span className="n">i</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kt">int</span> <span className="n">j</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span> <span className="n">j</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="mi">3</span>
                    <span className="p">;</span> <span className="n">j</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"#"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We have two nested loops, where the outer loop uses{" "}
                <code className="language-plaintext highlighter-rouge">i</code>{" "}
                to do everything inside 3 times, and the inner loop uses{" "}
                <code className="language-plaintext highlighter-rouge">j</code>,
                a different variable, to do something 3 times for each of{" "}
                <em>those</em> times. In other words, the outer loop prints 3
                “rows”, or lines, ending each of them with a new line, and the
                inner loop prints 3 “columns”, or{" "}
                <code className="language-plaintext highlighter-rouge">#</code>{" "}
                characters, <em>without</em> a new line.
              </li>
            </ul>
          </li>
        </ul>

        <h1 id="memory-imprecision-and-overflow" ref={ref15}>
          Memory, imprecision, and overflow
        </h1>

        <ul>
          <li data-marker="*">
            Our computer has memory, in hardware chips called RAM, random-access
            memory. Our programs use that RAM to store data while they’re
            running, but that memory is finite.
          </li>
          <li data-marker="*">
            With{" "}
            <code className="language-plaintext highlighter-rouge">
              imprecision.c
            </code>
            , we can see what happens when we use floats:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">
                      #include &lt;cs50.h&gt; #include &lt;stdio.h&gt;
                    </span>
                    <span className="kt">int</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">(</span>
                    <span className="kt">void</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="kt">float</span>{" "}
                    <span className="n">x</span> <span className="o">=</span>{" "}
                    <span className="n">get_float</span>
                    <span className="p">(</span>
                    <span className="s">"x: "</span>
                    <span className="p">);</span>
                    <span className="kt">float</span>{" "}
                    <span className="n">y</span> <span className="o">=</span>{" "}
                    <span className="n">get_float</span>
                    <span className="p">(</span>
                    <span className="s">"y: "</span>
                    <span className="p">);</span>
                    <span className="n">printf</span>
                    <span className="p">(</span>
                    <span className="s">"%.50f</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">,</span> <span className="n">x</span>{" "}
                    <span className="o">/</span> <span className="n">y</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                With{" "}
                <code className="language-plaintext highlighter-rouge">
                  %.50f
                </code>
                , we can specify the number of decimal places displayed.
              </li>
              <li data-marker="*">
                Hmm, now we get …
                <div className="language-plaintext highlighter-rouge">
                  <div className="highlight">
                    <pre className="highlight">
                      <code>
                        x: 1 y: 10
                        0.10000000149011611938476562500000000000000000000000
                      </code>
                    </pre>
                  </div>{" "}
                </div>
              </li>
              <li data-marker="*">
                It turns out that this is called{" "}
                <strong>floating-point imprecision</strong>, where we don’t have
                enough bits to store all possible values. With a finite number
                of bits for a{" "}
                <code className="language-plaintext highlighter-rouge">
                  float
                </code>
                , we can’t represent all possible real numbers (of which there
                are an <em>infinite</em> number of), so the computer has to
                store the closest value it can. And this can lead to problems
                where even small differences in value add up, unless the
                programmer uses some other way to represent decimal values as
                accurately as needed.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Last week, when we had three bits and needed to count higher than
            seven (or{" "}
            <code className="language-plaintext highlighter-rouge">111</code>),
            we added another bit to get eight,{" "}
            <code className="language-plaintext highlighter-rouge">1000</code>.
            But if we only had three bits available, we wouldn’t have a place
            for the extra{" "}
            <code className="language-plaintext highlighter-rouge">1</code>. It
            would disappear and we would be back at{" "}
            <code className="language-plaintext highlighter-rouge">000</code>.
            This problem is called <strong>integer overflow</strong>, where an
            integer can only be so big before it runs out of bits.
          </li>
          <li data-marker="*">
            The Y2K problem arose because many programs stored the calendar year
            with just two digits, like 98 for 1998, and 99 for 1999. But when
            the year 2000 approached, the programs had to store only 00, leading
            to confusion between the years 1900 and 2000.
          </li>
          <li data-marker="*">
            In 2038, we’ll also run out of bits to track time, since many years
            ago some humans decided to use 32 bits as the standard number of
            bits to count the number of seconds since January 1st, 1970. But
            with 32 bits representing only positive numbers, we can only count
            up to about four billion, and in 2038 we’ll reach that limit unless
            we upgrade the software in all of our computer systems.
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Note1;
