import React from "react";
import set_cookie from "./img/set_cookie.png";
import geolocation from "./img/geolocation.png";
import x301 from "./img/301.png";
import elements from "./img/elements.png";
import html_structure from "./img/html_structure.png";
import request_headers from "./img/request_headers.png";
import response_headers from "./img/response_headers.png";

function Note8() {
  return (
    <React.Fragment>
      <main className="col-md">
        <h1 className="no_toc" id="lecture-8">
          Lecture 8
        </h1>

        <ul id="markdown-toc">
          <li>
            <a href="#the-internet" id="markdown-toc-the-internet">
              The internet
            </a>
          </li>
          <li>
            <a href="#web-development" id="markdown-toc-web-development">
              Web development
            </a>
          </li>
          <li>
            <a href="#html" id="markdown-toc-html">
              HTML
            </a>
          </li>
          <li>
            <a href="#css" id="markdown-toc-css">
              CSS
            </a>
          </li>
          <li>
            <a href="#javascript" id="markdown-toc-javascript">
              JavaScript
            </a>
          </li>
        </ul>

        <h2 id="the-internet">The internet</h2>

        <ul>
          <li>
            Today we’ll take a look at web programming, using a set of new
            languages and technologies to create graphical and visual
            applications for the internet.
          </li>
          <li>
            The <strong>internet</strong> is the network of networks of
            computers communicating with one another, which provides the
            infrastructure to send zeros and ones. On top of that foundation, we
            can build applications that send and receive data.
          </li>
          <li>
            <strong>Routers</strong> are specialized computers, with CPUs and
            memory, whose purpose is to relay data across cables or wireless
            technologies, between other devices on the internet.
          </li>
          <li>
            <strong>Protocols</strong> are a set of standard conventions, like a
            physical handshake, that the world has agreed upon for computers to
            communicate with. For example, there are certain patterns of zeros
            and ones, or messages, a computer has to use to tell a router where
            it wants to send data.
          </li>
          <li>
            <strong>TCP/IP</strong> are two protocols for sending data between
            two computers. In the real world, we might write an address on an
            envelope in order to send a letter to someone, along with our own
            address for a letter in return. The digital version of an envelope,
            or a message with from and to addresses, is called a{" "}
            <strong>packet</strong>.
          </li>
          <li>
            <strong>IP</strong> stands for internet protocol, a protocol
            supported by modern computers’ software, which includes a standard
            way for computers to address each other.{" "}
            <strong>IP addresses</strong> are unique addresses for computers
            connected to the internet, such that a packet sent from one computer
            to another will be passed along routers until it reaches its
            destination.
            <ul>
              <li>
                Routers have, in their memory, a table mapping IP addresses to
                cables each connected to other routers, so they know where to
                forward packets to. It turns out that there are protocols for
                routers to communicate and figure out these paths as well.
              </li>
            </ul>
          </li>
          <li>
            <strong>DNS</strong>, domain name system, is another technology that
            translates domain names like cs50.harvard.edu to IP addresses. DNS
            is generally provided as a service by the nearest internet service
            provider, or ISP.
          </li>
          <li>
            Finally, <strong>TCP</strong>, transmission control protocol, is one
            final protocol that allows a single server, at the same IP address,
            to provide multiple services through the use of a{" "}
            <strong>port number</strong>, a small integer added to the IP
            address. For example, HTTP, HTTPS, email, and even Zoom has their
            own port numbers for those programs to use to communicate over the
            network.
          </li>
          <li>
            TCP also provides a mechanism for resending packets if a packet is
            somehow lost and not received. It turns out that, on the internet,
            there are multiple paths for a packet to be sent since there are
            lots of routers that are interconnected. So a web browser, making a
            request for a cat, might see its packet sent through one path of
            routers, and the responding server might see its response packets
            sent through another.
            <ul>
              <li>
                A large amount of data, such as a picture, will be broken into
                smaller chunks so that the packets are all of a similar size.
                This way, routers along the internet can send everyone’s packets
                along more fairly and easily. <strong>Net neutrality</strong>{" "}
                refers to the idea that these public routers treat packets
                equally, as opposed to allowing packets from certain companies
                or of certain types to be prioritized.
              </li>
              <li>
                When there are multiple packets for a single response, TCP will
                also specify that each of them be labeled, as with “1 of 2” or
                “2 of 2”, so they can be combined or re-sent as needed.
              </li>
            </ul>
          </li>
          <li>
            With all of these technologies and protocols, we’re able to send
            data from one computer to another, and can abstract the internet
            away, to build applications on top.
          </li>
        </ul>

        <h2 id="web-development">Web development</h2>

        <ul>
          <li>
            The web is one application running on top of the internet, allowing
            us to get web pages. Other applications like Zoom provide video
            conferencing, and email is another application as well.
          </li>
          <li>
            <strong>HTTP</strong>, or Hypertext Transfer Protocol, governs how
            web browsers and web servers communicate within TCP/IP packets.
          </li>
          <li>
            Two commands supported by HTTP include <strong>GET</strong> and{" "}
            <strong>POST</strong>. GET allows a browser to ask for a page or
            file, and POST allows a browser to send data <em>to</em> the server.
          </li>
          <li>
            A <strong>URL</strong>, or web address, might look like{" "}
            <code className="language-plaintext highlighter-rouge">
              https://www.example.com/
            </code>
            .
            <ul>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  https
                </code>{" "}
                is the protocol being used, and in this case HTTPS is the secure
                version of HTTP, ensuring that the contents of packets between
                the browser and server are encrypted.
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  example.com
                </code>{" "}
                is the domain name, where{" "}
                <code className="language-plaintext highlighter-rouge">
                  .com
                </code>{" "}
                is the top-level domain, conventionally indicating the “type” of
                website, like a commercial website for{" "}
                <code className="language-plaintext highlighter-rouge">
                  .com
                </code>
                , or an organization for{" "}
                <code className="language-plaintext highlighter-rouge">
                  .org
                </code>
                . Now there are hundreds of top-level domains, and they vary in
                restrictions on who can use them, but many of them allow anyone
                to register for a domain.
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  www
                </code>{" "}
                is the hostname that, by convention, indicates to us that this
                is a “world wide web” service. It’s not required, so today many
                websites aren’t configured to include it.
              </li>
              <li>
                Finally, the{" "}
                <code className="language-plaintext highlighter-rouge">/</code>{" "}
                at the end is a request for the default file, like{" "}
                <code className="language-plaintext highlighter-rouge">
                  index.html
                </code>
                , that the web server will respond with.
              </li>
            </ul>
          </li>
          <li>
            An HTTP request will start with:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>GET / HTTP/1.1 Host: www.example.com ...</code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                The{" "}
                <code className="language-plaintext highlighter-rouge">
                  GET
                </code>{" "}
                indicates that the request is for some file, and{" "}
                <code className="language-plaintext highlighter-rouge">/</code>{" "}
                indicates the default file. A request could be more specific,
                and start with{" "}
                <code className="language-plaintext highlighter-rouge">
                  GET /index.html
                </code>
                .
              </li>
              <li>
                There are different versions of the HTTP protocol, so{" "}
                <code className="language-plaintext highlighter-rouge">
                  HTTP/1.1
                </code>{" "}
                indicates that the browser is using version 1.1.
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  Host: www.example.com
                </code>{" "}
                indicates that the request is for{" "}
                <code className="language-plaintext highlighter-rouge">
                  www.example.com
                </code>
                , since the same web server might be hosting multiple websites
                and domains.
              </li>
            </ul>
          </li>
          <li>
            A response will start with:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>HTTP/1.1 200 OK Content-Type: text/html ...</code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                The web server will respond with the version of HTTP, followed
                by a status code, which is{" "}
                <code className="language-plaintext highlighter-rouge">
                  200 OK
                </code>{" "}
                here, indicating that the request was valid.
              </li>
              <li>
                Then, the web server indicates the type of content in its
                response, which might be text, image, or other format.
              </li>
              <li>
                Finally, the rest of the packet or packets will include the
                content.
              </li>
            </ul>
          </li>
          <li>
            We can see a redirect in a browser by typing in a URL, like{" "}
            <code className="language-plaintext highlighter-rouge">
              http://www.harvard.edu
            </code>
            , and looking at the address bar after the page has loaded, which
            will show{" "}
            <code className="language-plaintext highlighter-rouge">
              https://www.harvard.edu
            </code>
            . Browsers include developer tools, which allow us to see what’s
            happening. In Chrome’s menu, for example, we can go to View &gt;
            Developer &gt; Developer Tools, which will open a panel on the
            screen. In the Network tab, we can see that there were many
            requests, for text, images, and other pieces of data that were
            downloaded separately for the single web pages.
          </li>
          <li>
            The first request actually returned a status code of{" "}
            <code className="language-plaintext highlighter-rouge">
              301 Moved Permanently
            </code>
            , redirecting our browser from{" "}
            <code className="language-plaintext highlighter-rouge">
              http://...
            </code>{" "}
            to{" "}
            <code className="language-plaintext highlighter-rouge">
              https://...
            </code>
            :<br />
            <img
              src={x301}
              alt="Request for www.harvard.edu with 301 Moved Permanently"
            />
          </li>
          <li>
            The request and response also includes a number of headers, or
            additional data:
            <br />
            <img
              src={request_headers}
              alt="Request headers with GET, Host, Connection, and others"
            />
            <img
              src={response_headers}
              alt="Response headers with HTTP/1.1, Content-Type, and others"
            />
            <ul>
              <li>
                Note that the response includes a{" "}
                <code className="language-plaintext highlighter-rouge">
                  Location:
                </code>{" "}
                header for the browser to redirect us to.
              </li>
            </ul>
          </li>
          <li>
            Other HTTP status codes include:
            <ul>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  200 OK
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  301 Moved Permanently
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  304 Not Modified
                </code>
                <ul>
                  <li>
                    This allows the browser to use its cache, or local copy, of
                    some resource like an image, instead of having the server
                    send it back again.
                  </li>
                </ul>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  307 Temporary Redirect
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  401 Unauthorized
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  403 Forbidden
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  404 Not Found
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  418 I'm a Teapot
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  500 Internal Server Error
                </code>
                <ul>
                  <li>
                    Buggy code on a server might result in this status code.
                  </li>
                </ul>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  503 Service Unavailable
                </code>
              </li>
              <li>…</li>
            </ul>
          </li>
          <li>
            We can use a command-line tool,{" "}
            <code className="language-plaintext highlighter-rouge">curl</code>,
            to connect to a URL. We can run:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    curl -I http://safetyschool.org HTTP/1.1 301 Moved
                    Permanently Server: Sun-ONE-Web-Server/6.1 Date: Wed, 26 Oct
                    2020 18:17:05 GMT Content-length: 122 Content-type:
                    text/html Location: http://www.yale.edu Connection: close
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                It turns out that{" "}
                <code className="language-plaintext highlighter-rouge">
                  safetyschool.org
                </code>{" "}
                redirects to{" "}
                <code className="language-plaintext highlighter-rouge">
                  yale.edu
                </code>
                !
              </li>
              <li>
                And{" "}
                <code className="language-plaintext highlighter-rouge">
                  harvardsucks.org
                </code>{" "}
                is a website with another prank on Harvard!
              </li>
            </ul>
          </li>
          <li>
            Finally, an HTTP request can include inputs to servers, like the
            string{" "}
            <code className="language-plaintext highlighter-rouge">q=cats</code>{" "}
            after the{" "}
            <code className="language-plaintext highlighter-rouge">?</code>:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    GET /search?q=cats HTTP/1.1 Host: www.google.com ...
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                This uses a standard format for passing input, like command-line
                arguments, to web servers.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="html">HTML</h2>

        <ul>
          <li>
            Now that we can use the internet and HTTP to send and receive
            messages, it’s time to see what’s in the content for web pages.{" "}
            <strong>HTML</strong>, Hypertext Markup Language, is not a
            programming language, but rather used to format web pages and tell
            the browser how to display pages, using tags and attributes.
          </li>
          <li>
            A simple page in HTML might look like this:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;title&gt;</span>
                    hello, title
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    hello, body
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                The first line is a declaration that the page follows the HTML
                standard.
              </li>
              <li>
                Next is a <strong>tag</strong>, a word in brackets like{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;html&gt;
                </code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;/html&gt;
                </code>
                . The first is a start or open tag, and the second is a close
                tag. In this case, the tags indicate the start and end of the
                HTML page. The start tag here has an <strong>attribute</strong>{" "}
                as well,{" "}
                <code className="language-plaintext highlighter-rouge">
                  lang="en"
                </code>{" "}
                which specifies that the language of the page will be in
                English, to help the browser translate the page if needed.
              </li>
              <li>
                Within the{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;html&gt;
                </code>{" "}
                tag are two more tags,{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;head&gt;
                </code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;body&gt;
                </code>
                , which are both like children nodes in a tree. And within{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;head&gt;
                </code>{" "}
                is the{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;title&gt;
                </code>{" "}
                tag, the contents of which we see in a tab or window’s title in
                a browser. Within{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;body&gt;
                </code>{" "}
                is the contents of the page itself, which we’ll see in the main
                view of a browser as well.
              </li>
            </ul>
          </li>
          <li>
            The page above will be loaded into the browser as a data structure,
            like this tree:
            <br />
            <img
              src={html_structure}
              alt='HTML above mapped to a tree, with document containing html, containing head, title, "hello, title," as well as body, "hello, body"'
            />
            <ul>
              <li>
                Note that there is a hierarchy mapping each tag and its
                children. Rectangular nodes are tags, while oval ones are text.
              </li>
            </ul>
          </li>
          <li>
            We can save the code above as an HTML on our local computers, which
            would work in a browser, but just for us. With the CS50 IDE, we can
            create an HTML file, and actually make it available over the
            internet.
          </li>
          <li>
            We’ll create{" "}
            <code className="language-plaintext highlighter-rouge">
              hello.html
            </code>{" "}
            with the code above, and start a web server installed on the CS50
            IDE with{" "}
            <code className="language-plaintext highlighter-rouge">
              http-server
            </code>
            , a program that will listen for HTTP requests and respond with
            pages or other content.
          </li>
          <li>
            The CS50 IDE itself is already running on some web server, using
            ports 80 and 443, so our own web server within the IDE will have to
            use a different port,{" "}
            <code className="language-plaintext highlighter-rouge">8080</code>{" "}
            by default. We’ll see a long URL, ending in{" "}
            <code className="language-plaintext highlighter-rouge">
              cs50.xyz
            </code>
            , and if we open that URL we’ll see a listing of files, including{" "}
            <code className="language-plaintext highlighter-rouge">
              hello.html
            </code>
            .
          </li>
          <li>
            Back in the terminal of our IDE, we’ll see new rows of text printed
            by our web server, a log of requests that it’s getting.
          </li>
          <li>
            We’ll take a look at{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/paragraphs0.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                paragraphs.html
              </code>
            </a>
            .
            <ul>
              <li>
                With the{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;p&gt;
                </code>{" "}
                tag, we can indicate that each section of text should be a
                paragraph.
              </li>
              <li>
                After we save this file, we’ll need to refresh the index in the
                web browser, and then open{" "}
                <code className="language-plaintext highlighter-rouge">
                  paragraphs.html
                </code>
                .
              </li>
            </ul>
          </li>
          <li>
            We can add headings with tags that start with{" "}
            <code className="language-plaintext highlighter-rouge">h</code>, and
            have levels of{" "}
            <code className="language-plaintext highlighter-rouge">1</code>{" "}
            through{" "}
            <code className="language-plaintext highlighter-rouge">6</code> in{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/headings.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                headings.html
              </code>
            </a>
            .
          </li>
          <li>
            We take a look at{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/list.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                list.html
              </code>
            </a>
            ,{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/table.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                table.html
              </code>
            </a>
            , and{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/image.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                image.html
              </code>
            </a>{" "}
            as well, to add lists, tables, and images.
            <ul>
              <li>
                We can use the{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;ul&gt;
                </code>{" "}
                tag to create an unordered list, like bullet points, and{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;ol&gt;
                </code>{" "}
                for an ordered list with numbers.
              </li>
              <li>
                Tables start with a{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;table&gt;
                </code>{" "}
                tag and have{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;tr&gt;
                </code>{" "}
                tags as rows, and{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;td&gt;
                </code>{" "}
                tags for individual cells.
              </li>
              <li>
                For{" "}
                <code className="language-plaintext highlighter-rouge">
                  image.html
                </code>
                , we can upload an image to the CS50 IDE, to include it in our
                page, as well as use the{" "}
                <code className="language-plaintext highlighter-rouge">
                  alt
                </code>{" "}
                attribute to add alternative text for accessibility.
              </li>
            </ul>
          </li>
          <li>
            By looking for documentation or other online resources, we can learn
            the tags that exist in HTML, and how to use them.
          </li>
          <li>
            We can create links in{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/link0.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                link.html
              </code>
            </a>{" "}
            with the{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;a&gt;
            </code>
            , or anchor, tag. The{" "}
            <code className="language-plaintext highlighter-rouge">href</code>{" "}
            attribute is for a hypertext reference, or simply where the link
            should take us, and within the tag is the text that should appear as
            the link.
            <ul>
              <li>
                We could set the{" "}
                <code className="language-plaintext highlighter-rouge">
                  href
                </code>{" "}
                to{" "}
                <code className="language-plaintext highlighter-rouge">
                  https://www.yale.edu
                </code>
                , but leave{" "}
                <code className="language-plaintext highlighter-rouge">
                  Harvard
                </code>{" "}
                within the tag, which might prank users or even trick them into
                visiting a fake version of some website.{" "}
                <strong>Phishing</strong> is an act of tricking users, a form of
                social engineering that includes misleading links.
              </li>
            </ul>
          </li>
          <li>
            In{" "}
            <code className="language-plaintext highlighter-rouge">
              search.html
            </code>
            , we can create a more complex form that takes user input and sends
            it to Google’s search engine:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;title&gt;</span>search
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    <span className="nt">&lt;form</span>{" "}
                    <span className="na">action=</span>
                    <span className="s">
                      "https://www.google.com/search"
                    </span>{" "}
                    <span className="na">method=</span>
                    <span className="s">"get"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;input</span>{" "}
                    <span className="na">name=</span>
                    <span className="s">"q"</span>{" "}
                    <span className="na">type=</span>
                    <span className="s">"search"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;input</span>{" "}
                    <span className="na">type=</span>
                    <span className="s">"submit"</span>{" "}
                    <span className="na">value=</span>
                    <span className="s">"Search"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;/form&gt;</span>
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                First, we have a{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;form&gt;
                </code>{" "}
                tag that has an{" "}
                <code className="language-plaintext highlighter-rouge">
                  action
                </code>{" "}
                of Google’s search URL, with a method of GET.
              </li>
              <li>
                Inside the form, we have one{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;input&gt;
                </code>
                , with the name{" "}
                <code className="language-plaintext highlighter-rouge">q</code>,
                and another{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;input&gt;
                </code>{" "}
                with the type of{" "}
                <code className="language-plaintext highlighter-rouge">
                  submit
                </code>
                . When the second input, a button, is clicked, the form will
                append the text in the first input to the action URL, ending it
                with{" "}
                <code className="language-plaintext highlighter-rouge">
                  search?q=...
                </code>
                .
              </li>
              <li>
                So when we open{" "}
                <code className="language-plaintext highlighter-rouge">
                  search.html
                </code>{" "}
                in our browser, we can use the form to search via Google.
              </li>
              <li>
                A form can also use a POST method, which doesn’t include the
                form’s data in the URL, but elsewhere in the request.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="css">CSS</h2>

        <ul>
          <li>
            We can improve the aesthetics of our pages with <strong>CSS</strong>
            , Cascading Style Sheets, another language that tells our browser
            how to display tags on a page. CSS uses <strong>properties</strong>,
            or key-value pairs, like{" "}
            <code className="language-plaintext highlighter-rouge">
              color: red;
            </code>{" "}
            to tags with selectors.
          </li>
          <li>
            In HTML, we have some options for including CSS. We can add a{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;style&gt;
            </code>{" "}
            tag within the{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;head&gt;
            </code>{" "}
            tag, with styles direcly inside, or we can link to a{" "}
            <code className="language-plaintext highlighter-rouge">
              styles.css
            </code>{" "}
            file with a{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;link&gt;
            </code>{" "}
            tag within the{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;head&gt;
            </code>{" "}
            tag.
          </li>
          <li>
            We can also include CSS directly in each tag:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;title&gt;</span>css
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    <span className="nt">&lt;header</span>{" "}
                    <span className="na">style=</span>
                    <span className="s">
                      "font-size: large; text-align: center;"
                    </span>
                    <span className="nt">&gt;</span>
                    John Harvard
                    <span className="nt">&lt;/header&gt;</span>
                    <span className="nt">&lt;main</span>{" "}
                    <span className="na">style=</span>
                    <span className="s">
                      "font-size: medium; text-align: center;"
                    </span>
                    <span className="nt">&gt;</span>
                    Welcome to my home page!
                    <span className="nt">&lt;/main&gt;</span>
                    <span className="nt">&lt;footer</span>{" "}
                    <span className="na">style=</span>
                    <span className="s">
                      "font-size: small; text-align: center;"
                    </span>
                    <span className="nt">&gt;</span>
                    Copyright <span className="ni">&amp;#169;</span> John
                    Harvard
                    <span className="nt">&lt;/footer&gt;</span>
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  &lt;header&gt;
                </code>
                ,{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;main&gt;
                </code>
                , and{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;footer&gt;
                </code>{" "}
                tags are like{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;p&gt;
                </code>{" "}
                tags, indicating the sections that the text on our page are in.
              </li>
              <li>
                For each tag, we can add a{" "}
                <code className="language-plaintext highlighter-rouge">
                  style
                </code>{" "}
                attribute, with the value being a list of CSS key-value
                properties, separated by semicolons. Here, we’re setting the{" "}
                <code className="language-plaintext highlighter-rouge">
                  font-size
                </code>{" "}
                for each tag, and aligning the text in the center.
              </li>
              <li>
                Note that we can use{" "}
                <code className="language-plaintext highlighter-rouge">
                  &amp;#169;
                </code>
                , an <strong>HTML entity</strong>, as a code to include some
                symbol in our web page.
              </li>
            </ul>
          </li>
          <li>
            We can align all the text at once:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;title&gt;</span>css
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body</span>{" "}
                    <span className="na">style=</span>
                    <span className="s">"text-align: center;"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;header</span>{" "}
                    <span className="na">style=</span>
                    <span className="s">"font-size: large;"</span>
                    <span className="nt">&gt;</span>
                    John Harvard
                    <span className="nt">&lt;/header&gt;</span>
                    <span className="nt">&lt;main</span>{" "}
                    <span className="na">style=</span>
                    <span className="s">"font-size: medium;"</span>
                    <span className="nt">&gt;</span>
                    Welcome to my home page!
                    <span className="nt">&lt;/main&gt;</span>
                    <span className="nt">&lt;footer</span>{" "}
                    <span className="na">style=</span>
                    <span className="s">"font-size: small;"</span>
                    <span className="nt">&gt;</span>
                    Copyright <span className="ni">&amp;#169;</span> John
                    Harvard
                    <span className="nt">&lt;/footer&gt;</span>
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Here, the style applied to the{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;body&gt;
                </code>{" "}
                tag cascades, or applies, to its children, so all the sections
                inside will have centered text as well.
              </li>
            </ul>
          </li>
          <li>
            To factor out, or separate our CSS from HTML, we can include styles
            in the{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;head&gt;
            </code>{" "}
            tag:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;style&gt;</span>
                    <span className="nt">header</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">font-size</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">large</span>
                    <span className="p">;</span>
                    <span className="nl">text-align</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">center</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nt">main</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">font-size</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">medium</span>
                    <span className="p">;</span>
                    <span className="nl">text-align</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">center</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nt">footer</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">font-size</span>
                    <span className="p">:</span>{" "}
                    <span className="n">small</span>
                    <span className="p">;</span>
                    <span className="nl">text-align</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">center</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nt">&lt;/style&gt;</span>
                    <span className="nt">&lt;title&gt;</span>css
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    <span className="nt">&lt;header&gt;</span>
                    John Harvard
                    <span className="nt">&lt;/header&gt;</span>
                    <span className="nt">&lt;main&gt;</span>
                    Welcome to my home page!
                    <span className="nt">&lt;/main&gt;</span>
                    <span className="nt">&lt;footer&gt;</span>
                    Copyright <span className="ni">&amp;#169;</span> John
                    Harvard
                    <span className="nt">&lt;/footer&gt;</span>
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                For each <em>type</em> of tag, we’ve used a CSS{" "}
                <strong>type selector</strong> to style it.
              </li>
            </ul>
          </li>
          <li>
            We can also use a more specific <strong>class selector</strong>:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;style&gt;</span>
                    <span className="nc">.centered</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">text-align</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">center</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nc">.large</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">font-size</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">large</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nc">.medium</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">font-size</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">medium</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nc">.small</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">font-size</span>
                    <span className="p">:</span>{" "}
                    <span className="n">small</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nt">&lt;/style&gt;</span>
                    <span className="nt">&lt;title&gt;</span>css
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    <span className="nt">&lt;header</span>{" "}
                    <span className="na">class=</span>
                    <span className="s">"centered large"</span>
                    <span className="nt">&gt;</span>
                    John Harvard
                    <span className="nt">&lt;/header&gt;</span>
                    <span className="nt">&lt;main</span>{" "}
                    <span className="na">class=</span>
                    <span className="s">"centered medium"</span>
                    <span className="nt">&gt;</span>
                    Welcome to my home page!
                    <span className="nt">&lt;/main&gt;</span>
                    <span className="nt">&lt;footer</span>{" "}
                    <span className="na">class=</span>
                    <span className="s">"centered small"</span>
                    <span className="nt">&gt;</span>
                    Copyright <span className="ni">&amp;#169;</span> John
                    Harvard
                    <span className="nt">&lt;/footer&gt;</span>
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We can define our own CSS class with a{" "}
                <code className="language-plaintext highlighter-rouge">.</code>{" "}
                followed by a keyword we choose, so here we’ve created{" "}
                <code className="language-plaintext highlighter-rouge">
                  .large
                </code>
                ,{" "}
                <code className="language-plaintext highlighter-rouge">
                  .medium
                </code>
                , and{" "}
                <code className="language-plaintext highlighter-rouge">
                  .small
                </code>
                , each with some property for the font size.
              </li>
              <li>
                Then, on any number of tags in our page’s HTML, we can add one
                or more of these classes with{" "}
                <code className="language-plaintext highlighter-rouge">
                  className="centered large"
                </code>
                , reusing these styles.
              </li>
              <li>
                We can remove the redundancy for{" "}
                <code className="language-plaintext highlighter-rouge">
                  centered
                </code>
                , and apply it to just the{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;body&gt;
                </code>{" "}
                tag as well.
              </li>
            </ul>
          </li>
          <li>
            Finally, we can take all of the CSS for the properties and move them
            to another file with the{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;link&gt;
            </code>{" "}
            tag:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;link</span>{" "}
                    <span className="na">href=</span>
                    <span className="s">"styles.css"</span>{" "}
                    <span className="na">rel=</span>
                    <span className="s">"stylesheet"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;title&gt;</span>css
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    <span className="nt">&lt;header</span>{" "}
                    <span className="na">class=</span>
                    <span className="s">"centered large"</span>
                    <span className="nt">&gt;</span>
                    John Harvard
                    <span className="nt">&lt;/header&gt;</span>
                    <span className="nt">&lt;main</span>{" "}
                    <span className="na">class=</span>
                    <span className="s">"centered medium"</span>
                    <span className="nt">&gt;</span>
                    Welcome to my home page!
                    <span className="nt">&lt;/main&gt;</span>
                    <span className="nt">&lt;footer</span>{" "}
                    <span className="na">class=</span>
                    <span className="s">"centered small"</span>
                    <span className="nt">&gt;</span>
                    Copyright <span className="ni">&amp;#169;</span> John
                    Harvard
                    <span className="nt">&lt;/footer&gt;</span>
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Now, one person can work on the HTML and another can work on the
                CSS, more independently.
              </li>
            </ul>
          </li>
          <li>
            With CSS, we’ll also rely on references and other resources to look
            up how to use properties as we need them.
          </li>
          <li>
            We can use <strong>pseudoselectors</strong>, which selects certain
            states:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;style&gt;</span>
                    <span className="nf">#harvard</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">color</span>
                    <span className="p">:</span>{" "}
                    <span className="m">#ff0000</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nf">#yale</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">color</span>
                    <span className="p">:</span>{" "}
                    <span className="m">#0000ff</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nt">a</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">text-decoration</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">none</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nt">a</span>
                    <span className="nd">:hover</span>
                    <span className="p">{"{"}</span>
                    <span className="nl">text-decoration</span>
                    <span className="p">:</span>{" "}
                    <span className="nb">underline</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="nt">&lt;/style&gt;</span>
                    <span className="nt">&lt;title&gt;</span>link
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    Visit <span className="nt">&lt;a</span>{" "}
                    <span className="na">href=</span>
                    <span className="s">"https://www.harvard.edu/"</span>{" "}
                    <span className="na">id=</span>
                    <span className="s">"harvard"</span>{" "}
                    <span className="nt">&gt;</span>Harvard
                    <span className="nt">&lt;/a&gt;</span> or{" "}
                    <span className="nt">&lt;a</span>{" "}
                    <span className="na">href=</span>
                    <span className="s">"https://www.yale.edu/"</span>{" "}
                    <span className="na">id=</span>
                    <span className="s">"yale"</span>{" "}
                    <span className="nt">&gt;</span>Yale
                    <span className="nt">&lt;/a&gt;</span>.
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Here, we’re using{" "}
                <code className="language-plaintext highlighter-rouge">
                  a:hover
                </code>{" "}
                to set properties on{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;a&gt;
                </code>{" "}
                tags when the user hovers over them.
              </li>
              <li>
                We also have an{" "}
                <code className="language-plaintext highlighter-rouge">id</code>{" "}
                attribute on each{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;a&gt;
                </code>{" "}
                tag, to set different colors on each with{" "}
                <strong>ID selectors</strong> that start with a{" "}
                <code className="language-plaintext highlighter-rouge">#</code>{" "}
                in CSS.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="javascript">JavaScript</h2>

        <ul>
          <li>
            To write code that can run in users’ browsers, or on the client,
            we’ll use a new language, <strong>JavaScript</strong>.
          </li>
          <li>
            The syntax of JavaScript is similar to that of C and Python for
            basic constructs:
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kd">let</span>{" "}
                    <span className="nx">counter</span>{" "}
                    <span className="o">=</span> <span className="mi">0</span>
                    <span className="p">;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="nx">counter</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nx">counter</span>{" "}
                    <span className="o">+</span> <span className="mi">1</span>
                    <span className="p">;</span>
                    <span className="nx">counter</span>{" "}
                    <span className="o">+=</span> <span className="mi">1</span>
                    <span className="p">;</span>
                    <span className="nx">counter</span>
                    <span className="o">++</span>
                    <span className="p">;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="nx">x</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="nx">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="nx">x</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="nx">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>
                    <span className="p">{"{"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="nx">x</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="nx">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>{" "}
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="nx">x</span>{" "}
                    <span className="o">&gt;</span>{" "}
                    <span className="nx">y</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>
                    <span className="p">{"{"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">while</span>{" "}
                    <span className="p">(</span>
                    <span className="kc">true</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">for</span> <span className="p">(</span>
                    <span className="kd">let</span>{" "}
                    <span className="nx">i</span> <span className="o">=</span>{" "}
                    <span className="mi">0</span>
                    <span className="p">;</span> <span className="nx">i</span>{" "}
                    <span className="o">&lt;</span>{" "}
                    <span className="mi">3</span>
                    <span className="p">;</span> <span className="nx">i</span>
                    <span className="o">++</span>
                    <span className="p">)</span>
                    <span className="p">{"{"}</span>
                    <span className="p">{"}"}</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Notice that JavaScript is loosely typed as well, with{" "}
                <code className="language-plaintext highlighter-rouge">
                  let
                </code>{" "}
                being the keyword to declare variables of any type.
              </li>
            </ul>
          </li>
          <li>
            With JavaScript, we can change the HTML in the browser in real-time.
            We can use{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;script&gt;
            </code>{" "}
            tags to include our code directly, or from a{" "}
            <code className="language-plaintext highlighter-rouge">.js</code>{" "}
            file.
          </li>
          <li>
            We’ll create another form:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;script&gt;</span>
                    <span className="kd">function</span>{" "}
                    <span className="nx">greet</span>
                    <span className="p">()</span>
                    <span className="p">{"{"}</span>
                    <span className="nx">alert</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">hello, body</span>
                    <span className="dl">'</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="nt">&lt;/script&gt;</span>
                    <span className="nt">&lt;title&gt;</span>hello
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    <span className="nt">&lt;form</span>{" "}
                    <span className="na">onsubmit=</span>
                    <span className="s">"greet(); return false;"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;input</span>{" "}
                    <span className="na">id=</span>
                    <span className="s">"name"</span>{" "}
                    <span className="na">type=</span>
                    <span className="s">"text"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;input</span>{" "}
                    <span className="na">type=</span>
                    <span className="s">"submit"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;/form&gt;</span>
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Here, we won’t add an{" "}
                <code className="language-plaintext highlighter-rouge">
                  action
                </code>{" "}
                to our form, since this will stay on the same page. Instead,
                we’ll have an{" "}
                <code className="language-plaintext highlighter-rouge">
                  onsubmit
                </code>{" "}
                attribute that will call a function we’ve defined in JavaScript,
                and use{" "}
                <code className="language-plaintext highlighter-rouge">
                  return false;
                </code>{" "}
                to prevent the form from actually being submitted anywhere.
              </li>
              <li>
                Now, if we load that page, we’ll see{" "}
                <code className="language-plaintext highlighter-rouge">
                  hello, body
                </code>{" "}
                being shown when we submit the form.
              </li>
            </ul>
          </li>
          <li>
            Since our input tag, or <strong>element</strong>, has an ID of{" "}
            <code className="language-plaintext highlighter-rouge">name</code>,
            we can use it in our script:
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="o">&lt;</span>
                    <span className="nx">script</span>
                    <span className="o">&gt;</span>
                    <span className="kd">function</span>{" "}
                    <span className="nx">greet</span>
                    <span className="p">()</span>
                    <span className="p">{"{"}</span>
                    <span className="kd">let</span>{" "}
                    <span className="nx">name</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">#name</span>
                    <span className="dl">'</span>
                    <span className="p">).</span>
                    <span className="nx">value</span>
                    <span className="p">;</span>
                    <span className="nx">alert</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">hello, </span>
                    <span className="dl">'</span> <span className="o">+</span>{" "}
                    <span className="nx">name</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="o">&lt;</span>
                    <span className="sr">/script</span>
                    <span className="err">&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  document
                </code>{" "}
                is a global variable that comes with JavaScript in the browser,
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  querySelector
                </code>{" "}
                is another function we can use to select a node in the{" "}
                <strong>DOM</strong>, Document Object Model, or the tree
                structure of the HTML page. After we select the element with the
                ID{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>
                , we get the{" "}
                <code className="language-plaintext highlighter-rouge">
                  value
                </code>{" "}
                inside the input, and add it to our alert.
              </li>
              <li>
                Note that JavaScript uses single quotes for strings by
                convention, though double quotes can be used as well as long as
                they match for each string.
              </li>
            </ul>
          </li>
          <li>
            We can add more attributes to our form, to change placeholder text,
            change the button’s text, disable autocomplete, or autofocus the
            input:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="nt">&lt;form&gt;</span>
                    <span className="nt">&lt;input</span>{" "}
                    <span className="na">autocomplete=</span>
                    <span className="s">"off"</span>{" "}
                    <span className="na">autofocus</span>{" "}
                    <span className="na">id=</span>
                    <span className="s">"name"</span>{" "}
                    <span className="na">placeholder=</span>
                    <span className="s">"Name"</span>{" "}
                    <span className="na">type=</span>
                    <span className="s">"text"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;input</span>{" "}
                    <span className="na">type=</span>
                    <span className="s">"submit"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;/form&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can also listen to <strong>events</strong> in JavaScript, which
            occur when something happens on the page. For example, we can listen
            to the{" "}
            <code className="language-plaintext highlighter-rouge">submit</code>{" "}
            event on our form, and call the{" "}
            <code className="language-plaintext highlighter-rouge">greet</code>{" "}
            function:
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="o">&lt;</span>
                    <span className="nx">script</span>
                    <span className="o">&gt;</span>
                    <span className="kd">function</span>{" "}
                    <span className="nx">greet</span>
                    <span className="p">()</span>
                    <span className="p">{"{"}</span>
                    <span className="kd">let</span>{" "}
                    <span className="nx">name</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">#name</span>
                    <span className="dl">'</span>
                    <span className="p">).</span>
                    <span className="nx">value</span>
                    <span className="p">;</span>
                    <span className="nx">alert</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">hello, </span>
                    <span className="dl">'</span> <span className="o">+</span>{" "}
                    <span className="nx">name</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="kd">function</span>{" "}
                    <span className="nx">listen</span>
                    <span className="p">()</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">form</span>
                    <span className="dl">'</span>
                    <span className="p">).</span>
                    <span className="nx">addEventListener</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">submit</span>
                    <span className="dl">'</span>
                    <span className="p">,</span>{" "}
                    <span className="nx">greet</span>
                    <span className="p">);</span>
                    <span className="p">{"}"}</span>
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">addEventListener</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">DOMContentLoaded</span>
                    <span className="dl">'</span>
                    <span className="p">,</span>{" "}
                    <span className="nx">listen</span>
                    <span className="p">);</span>
                    <span className="o">&lt;</span>
                    <span className="sr">/script</span>
                    <span className="err">&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Here, in{" "}
                <code className="language-plaintext highlighter-rouge">
                  listen
                </code>{" "}
                we pass the function{" "}
                <code className="language-plaintext highlighter-rouge">
                  greet
                </code>{" "}
                by name, and not call it yet. The event listener will call it
                for us when the event happens.
              </li>
              <li>
                We need to first listen to the{" "}
                <code className="language-plaintext highlighter-rouge">
                  DOMContentLoaded
                </code>{" "}
                event, since the browser reads our HTML file from top to bottom,
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  form
                </code>{" "}
                wouldn’t exist until it’s read the entire file and loaded the
                content. So by listening to this event, and calling our{" "}
                <code className="language-plaintext highlighter-rouge">
                  listen
                </code>{" "}
                function, we know{" "}
                <code className="language-plaintext highlighter-rouge">
                  form
                </code>{" "}
                will exist.
              </li>
            </ul>
          </li>
          <li>
            We can also use <strong>anonymous functions</strong> in JavaScript:
            <div className="language-javascript highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="o">&lt;</span>
                    <span className="nx">script</span>
                    <span className="o">&gt;</span>
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">addEventListener</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">DOMContentLoaded</span>
                    <span className="dl">'</span>
                    <span className="p">,</span>{" "}
                    <span className="kd">function</span>
                    <span className="p">()</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">form</span>
                    <span className="dl">'</span>
                    <span className="p">).</span>
                    <span className="nx">addEventListener</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">submit</span>
                    <span className="dl">'</span>
                    <span className="p">,</span>{" "}
                    <span className="kd">function</span>
                    <span className="p">()</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="kd">let</span>{" "}
                    <span className="nx">name</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">#name</span>
                    <span className="dl">'</span>
                    <span className="p">).</span>
                    <span className="nx">value</span>
                    <span className="p">;</span>
                    <span className="nx">alert</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">hello, </span>
                    <span className="dl">'</span> <span className="o">+</span>{" "}
                    <span className="nx">name</span>
                    <span className="p">);</span>
                    <span className="p">{"}"});</span>
                    <span className="p">{"}"});</span>
                    <span className="o">&lt;</span>
                    <span className="sr">/script</span>
                    <span className="err">&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We can pass in a lambda function with the{" "}
                <code className="language-plaintext highlighter-rouge">
                  function()
                </code>{" "}
                syntax, so here we’ve passed in both listeneres directly to{" "}
                <code className="language-plaintext highlighter-rouge">
                  addEventListener
                </code>
                .
              </li>
            </ul>
          </li>
          <li>
            In addition to{" "}
            <code className="language-plaintext highlighter-rouge">submit</code>
            , there are many other events we can listen to:
            <ul>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  blur
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  change
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  click
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  drag
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  focus
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  keyup
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  load
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  mousedown
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  mouseover
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  mouseup
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  submit
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  touchmove
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  unload
                </code>
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  ...
                </code>
              </li>
            </ul>
          </li>
          <li>
            For example, we can listen to the{" "}
            <code className="language-plaintext highlighter-rouge">keyup</code>{" "}
            event, and change the DOM as soon as we release a key:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;script&gt;</span>
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">addEventListener</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">DOMContentLoaded</span>
                    <span className="dl">'</span>
                    <span className="p">,</span>{" "}
                    <span className="kd">function</span>
                    <span className="p">()</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="kd">let</span>{" "}
                    <span className="nx">input</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">input</span>
                    <span className="dl">'</span>
                    <span className="p">);</span>
                    <span className="nx">input</span>
                    <span className="p">.</span>
                    <span className="nx">addEventListener</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">keyup</span>
                    <span className="dl">'</span>
                    <span className="p">,</span>{" "}
                    <span className="kd">function</span>
                    <span className="p">(</span>
                    <span className="nx">event</span>
                    <span className="p">)</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="kd">let</span>{" "}
                    <span className="nx">name</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">#name</span>
                    <span className="dl">'</span>
                    <span className="p">);</span>
                    <span className="k">if</span> <span className="p">(</span>
                    <span className="nx">input</span>
                    <span className="p">.</span>
                    <span className="nx">value</span>
                    <span className="p">)</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="nx">name</span>
                    <span className="p">.</span>
                    <span className="nx">innerHTML</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="s2">`hello, </span>
                    <span className="p">${"{"}</span>
                    <span className="nx">input</span>
                    <span className="p">.</span>
                    <span className="nx">value</span>
                    <span className="p">{"}"}</span>
                    <span className="s2">`</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="k">else</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="nx">name</span>
                    <span className="p">.</span>
                    <span className="nx">innerHTML</span>{" "}
                    <span className="o">=</span> <span className="dl">'</span>
                    <span className="s1">hello, whoever you are</span>
                    <span className="dl">'</span>
                    <span className="p">;</span>
                    <span className="p">{"}"}</span>
                    <span className="p">{"}"});</span>
                    <span className="p">{"}"});</span>
                    <span className="nt">&lt;/script&gt;</span>
                    <span className="nt">&lt;title&gt;</span>hello
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    <span className="nt">&lt;form&gt;</span>
                    <span className="nt">&lt;input</span>{" "}
                    <span className="na">autocomplete=</span>
                    <span className="s">"off"</span>{" "}
                    <span className="na">autofocus</span>{" "}
                    <span className="na">placeholder=</span>
                    <span className="s">"Name"</span>{" "}
                    <span className="na">type=</span>
                    <span className="s">"text"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;/form&gt;</span>
                    <span className="nt">&lt;p</span>{" "}
                    <span className="na">id=</span>
                    <span className="s">"name"</span>
                    <span className="nt">&gt;&lt;/p&gt;</span>
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Notice that we can substitute strings in JavaScript as well,
                with the{" "}
                <code className="language-plaintext highlighter-rouge">
                  ${"{"}input.value{"}"}
                </code>{" "}
                inside a string surrounded by backticks,{" "}
                <code className="language-plaintext highlighter-rouge">`</code>.
              </li>
            </ul>
          </li>
          <li>
            We can programmatically change style, too:
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="cp">&lt;!DOCTYPE html&gt;</span>
                    <span className="nt">&lt;html</span>{" "}
                    <span className="na">lang=</span>
                    <span className="s">"en"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;head&gt;</span>
                    <span className="nt">&lt;title&gt;</span>background
                    <span className="nt">&lt;/title&gt;</span>
                    <span className="nt">&lt;/head&gt;</span>
                    <span className="nt">&lt;body&gt;</span>
                    <span className="nt">&lt;button</span>{" "}
                    <span className="na">id=</span>
                    <span className="s">"red"</span>
                    <span className="nt">&gt;</span>R
                    <span className="nt">&lt;/button&gt;</span>
                    <span className="nt">&lt;button</span>{" "}
                    <span className="na">id=</span>
                    <span className="s">"green"</span>
                    <span className="nt">&gt;</span>G
                    <span className="nt">&lt;/button&gt;</span>
                    <span className="nt">&lt;button</span>{" "}
                    <span className="na">id=</span>
                    <span className="s">"blue"</span>
                    <span className="nt">&gt;</span>B
                    <span className="nt">&lt;/button&gt;</span>
                    <span className="nt">&lt;script&gt;</span>
                    <span className="kd">let</span>{" "}
                    <span className="nx">body</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">body</span>
                    <span className="dl">'</span>
                    <span className="p">);</span>
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">#red</span>
                    <span className="dl">'</span>
                    <span className="p">).</span>
                    <span className="nx">onclick</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="kd">function</span>
                    <span className="p">()</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="nx">body</span>
                    <span className="p">.</span>
                    <span className="nx">style</span>
                    <span className="p">.</span>
                    <span className="nx">backgroundColor</span>{" "}
                    <span className="o">=</span> <span className="dl">'</span>
                    <span className="s1">red</span>
                    <span className="dl">'</span>
                    <span className="p">;</span>
                    <span className="p">{"}"};</span>
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">#green</span>
                    <span className="dl">'</span>
                    <span className="p">).</span>
                    <span className="nx">onclick</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="kd">function</span>
                    <span className="p">()</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="nx">body</span>
                    <span className="p">.</span>
                    <span className="nx">style</span>
                    <span className="p">.</span>
                    <span className="nx">backgroundColor</span>{" "}
                    <span className="o">=</span> <span className="dl">'</span>
                    <span className="s1">green</span>
                    <span className="dl">'</span>
                    <span className="p">;</span>
                    <span className="p">{"}"};</span>
                    <span className="nb">document</span>
                    <span className="p">.</span>
                    <span className="nx">querySelector</span>
                    <span className="p">(</span>
                    <span className="dl">'</span>
                    <span className="s1">#blue</span>
                    <span className="dl">'</span>
                    <span className="p">).</span>
                    <span className="nx">onclick</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="kd">function</span>
                    <span className="p">()</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="nx">body</span>
                    <span className="p">.</span>
                    <span className="nx">style</span>
                    <span className="p">.</span>
                    <span className="nx">backgroundColor</span>{" "}
                    <span className="o">=</span> <span className="dl">'</span>
                    <span className="s1">blue</span>
                    <span className="dl">'</span>
                    <span className="p">;</span>
                    <span className="p">{"}"};</span>
                    <span className="nt">&lt;/script&gt;</span>
                    <span className="nt">&lt;/body&gt;</span>
                    <span className="nt">&lt;/html&gt;</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                After selecting an element, we can use the{" "}
                <code className="language-plaintext highlighter-rouge">
                  style
                </code>{" "}
                property to set values for CSS properties as well. Here, we have
                three buttons, each of which has an{" "}
                <code className="language-plaintext highlighter-rouge">
                  onclick
                </code>{" "}
                listener that changes the background color of the{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;body&gt;
                </code>{" "}
                element.
              </li>
              <li>
                Notice here that our{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;script&gt;
                </code>{" "}
                tag is at the end of our HTML file, so we don’t need to listen
                to the{" "}
                <code className="language-plaintext highlighter-rouge">
                  DOMContentLoaded
                </code>{" "}
                event, since the rest of the DOM will already have been read by
                the browser.
              </li>
            </ul>
          </li>
          <li>
            In a browser’s developer tools, too, we can see the DOM and any
            styles applied via the{" "}
            <code className="language-plaintext highlighter-rouge">
              Elements
            </code>{" "}
            tab:
            <br />
            <img
              src={elements}
              alt="background.html with Elements tab showing DOM"
            />
            <ul>
              <li>
                We can even use this to change a page in our browser after it’s
                loaded, by clicking on some element and editing the HTML. But
                these changes will only be made in our browser, not in our
                original HTML file or on some web page elsewhere.
              </li>
            </ul>
          </li>
          <li>
            In{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/size.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                size.html
              </code>
            </a>
            , we can set the font size with a dropdown via JavaScript, and in{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/blink.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                blink.html
              </code>
            </a>{" "}
            we can make an element “blink”, alternating between visible and
            hidden.
          </li>
          <li>
            With{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/geolocation.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                geolocation.html
              </code>
            </a>
            , we can ask the browser for a user’s GPS coordinates, and with{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/autocomplete.html?highlight">
              <code className="language-plaintext highlighter-rouge">
                autocomplete.html
              </code>
            </a>
            , we can autocomplete something we typed in, with words from a
            dictionary file.
          </li>
          <li>
            Finally, we can use Python to write code that connect to other
            devices on a local network, like a light bulb, via an{" "}
            <strong>API</strong>, application programming interface. Our light
            bulb’s API in particular accepts request at certain URLs:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">os</span>
                    <span className="kn">import</span>{" "}
                    <span className="nn">requests</span>
                    <span className="n">USERNAME</span>{" "}
                    <span className="o">=</span> <span className="n">os</span>
                    <span className="p">.</span>
                    <span className="n">getenv</span>
                    <span className="p">(</span>
                    <span className="s">"USERNAME"</span>
                    <span className="p">)</span>
                    <span className="n">IP</span> <span className="o">=</span>{" "}
                    <span className="n">os</span>
                    <span className="p">.</span>
                    <span className="n">getenv</span>
                    <span className="p">(</span>
                    <span className="s">"IP"</span>
                    <span className="p">)</span>
                    <span className="n">URL</span> <span className="o">=</span>{" "}
                    <span className="s">f"http://</span>
                    <span className="si">{"{"}</span>
                    <span className="n">IP</span>
                    <span className="si">{"}"}</span>
                    <span className="s">/api/</span>
                    <span className="si">{"{"}</span>
                    <span className="n">USERNAME</span>
                    <span className="si">}</span>
                    <span className="s">/lights/1/state"</span>
                    <span className="n">requests</span>
                    <span className="p">.</span>
                    <span className="n">put</span>
                    <span className="p">(</span>
                    <span className="n">URL</span>
                    <span className="p">,</span> <span className="n">json</span>
                    <span className="o">=</span>
                    <span className="p">{"{"}</span>
                    <span className="s">"on"</span>
                    <span className="p">:</span>{" "}
                    <span className="bp">False</span>
                    <span className="p">{"}"})</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                With this code, we can use the PUT method to send a message to
                our light bulb, turning it off.
              </li>
              <li>
                We use environment variables, values stored elsewhere on our
                computer, for our username and IP address.
              </li>
            </ul>
          </li>
          <li>
            Now, with a little more logic, we can make our light bulb blink:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">os</span>
                    <span className="kn">import</span>{" "}
                    <span className="nn">requests</span>
                    <span className="kn">import</span>{" "}
                    <span className="nn">time</span>
                    <span className="n">USERNAME</span>{" "}
                    <span className="o">=</span> <span className="n">os</span>
                    <span className="p">.</span>
                    <span className="n">getenv</span>
                    <span className="p">(</span>
                    <span className="s">"USERNAME"</span>
                    <span className="p">)</span>
                    <span className="n">IP</span> <span className="o">=</span>{" "}
                    <span className="n">os</span>
                    <span className="p">.</span>
                    <span className="n">getenv</span>
                    <span className="p">(</span>
                    <span className="s">"IP"</span>
                    <span className="p">)</span>
                    <span className="n">URL</span> <span className="o">=</span>{" "}
                    <span className="s">f"http://</span>
                    <span className="si">{"{"}</span>
                    <span className="n">IP</span>
                    <span className="si">{"}"}</span>
                    <span className="s">/api/</span>
                    <span className="si">{"{"}</span>
                    <span className="n">USERNAME</span>
                    <span className="si">{"}"}</span>
                    <span className="s">/lights/1/state"</span>
                    <span className="k">while</span>{" "}
                    <span className="bp">True</span>
                    <span className="p">:</span>
                    <span className="n">requests</span>
                    <span className="p">.</span>
                    <span className="n">put</span>
                    <span className="p">(</span>
                    <span className="n">URL</span>
                    <span className="p">,</span> <span className="n">json</span>
                    <span className="o">=</span>
                    <span className="p">{"{"}</span>
                    <span className="s">"bri"</span>
                    <span className="p">:</span> <span className="mi">254</span>
                    <span className="p">,</span> <span className="s">"on"</span>
                    <span className="p">:</span>{" "}
                    <span className="bp">True</span>
                    <span className="p">{"}"})</span>
                    <span className="n">time</span>
                    <span className="p">.</span>
                    <span className="n">sleep</span>
                    <span className="p">(</span>
                    <span className="mi">1</span>
                    <span className="p">)</span>
                    <span className="n">requests</span>
                    <span className="p">.</span>
                    <span className="n">put</span>
                    <span className="p">(</span>
                    <span className="n">URL</span>
                    <span className="p">,</span> <span className="n">json</span>
                    <span className="o">=</span>
                    <span className="p">{"{"}</span>
                    <span className="s">"on"</span>
                    <span className="p">:</span>{" "}
                    <span className="bp">False</span>
                    <span className="p">{"}"})</span>
                    <span className="n">time</span>
                    <span className="p">.</span>
                    <span className="n">sleep</span>
                    <span className="p">(</span>
                    <span className="mi">1</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We’ll put together HTML, CSS, JavaScript, Python, and SQL next time!
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Note8;
