import React, { useEffect, useState } from "react";
import Course from "../../components/Course/Course";
import "./Courses.css";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../urls";

function Courses() {
  const [courses, setCourses] = useState([]);
  const history = useHistory();

  const getCourses = async () => {
    const token = localStorage.getItem("access_token");
    const response = await fetch(`${BASE_URL}/api/academy/auth/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    try {
      if (response.ok) {
        // user is Authenticated
        let result = await response.json();
        setCourses(result.course);
      } else {
        // Please login to see your dashboard
        history.push("/login");
      }
      // localStorage.setItem("access_token", result.token.access_token);
    } catch (error) {
      // console.log(error);
    }
  };
  
  useEffect( () => {
        getCourses();
  }, []);

  return (
    <>
      <div className="container">
        <div className="title">Computiq Academy platfrom</div>
        <a className="platform" href="https://board.computiq.tech/">
          Student Board
        </a>
        <hr />
        <div className="course-list">
          {courses.map((course, i) => {
            return <Course course={course} key={i} />;
          })}
        </div>
      </div>
    </>
  );
}

export default Courses;
