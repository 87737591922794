import React from "react";
import { useHistory } from "react-router";

const alert = {
  padding: "10px",
  color: "#856404",
  backgroundColor: "#fff3cd",
  borderColor: "#ffeeba",
};

function Pset0() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 id="problem-set-0">Problem Set 0</h1>

        <h2 id="what-to-do">What to Do</h2>

        <ol>
          <li>
            Download and install the latest version of{" "}
            <a href="https://www.google.com/chrome/">Chrome</a>, if you don’t
            have it already.
          </li>
          <li>
            Submit <a href="/#/scratch">this problem on Scratch</a>.
          </li>
        </ol>

        <h2 id="when-to-do-it">When to Do It</h2>

        <p>
          By <span data-local="2021-12-31T23:59:00-05:00"></span>.
        </p>

        <h2 id="advice">Advice</h2>

        <p>
          Here are{" "}
          <a href="https://scratch.mit.edu/studios/27430410/">
            David’s examples
          </a>{" "}
          from lecture if you’d like to review! To see the source code of each,
          click <strong>See inside</strong>.
        </p>
      </main>
    </React.Fragment>
  );
}

export default Pset0;
