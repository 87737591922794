import React, { useState } from "react";

import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";

function Week0() {
  const { t } = useTranslation();
  const [video, setVideo] = useState(
    "https://www.youtube.com/embed/ZX2T7slE_9I"
  );

  return (
    <React.Fragment>
      <h1 id="week-7">{t("week")} 7</h1>
      <div
        className="border embed-responsive embed-responsive-16by9"
        data-video=""
        style={{ maxWidth: "720px" }}
      >
        <Iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="embed-responsive-item"
          src={video}
        ></Iframe>
      </div>
      <details className="mt-5">
        <summary>
          How to generate <code>shows.db</code> from{" "}
          <code>Favorite TV Shows - Form Responses 1.csv</code>
        </summary>
        <ol>
          <li>
            <p>
              Log into <a href="https://ide.cs50.io/">ide.cs50.io</a> using your
              GitHub account.
            </p>
          </li>
          <li>
            <p>In your terminal window, type</p>

            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    wget https://cdn.cs50.net/2020/fall/lectures/7/src7.zip
                  </code>
                </pre>
              </div>{" "}
            </div>

            <p>
              followed by Enter in order to download a Zip file of lecture’s
              source code into your IDE.
            </p>
          </li>
          <li>
            <p>In your terminal window, type</p>

            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>unzip src7.zip</code>
                </pre>
              </div>{" "}
            </div>

            <p>followed by Enter to unzip (i.e., decompress) that Zip file.</p>
          </li>
          <li>
            <p>In your terminal window, type</p>

            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>cd src7/favorites/</code>
                </pre>
              </div>{" "}
            </div>

            <p>
              followed by Enter in order to “change directory” into{" "}
              <code className="language-plaintext highlighter-rouge">
                src2/favorites/
              </code>
              .
            </p>
          </li>
          <li>
            <p>And then:</p>

            <ul>
              <li>
                To generate the lecture’s first version of{" "}
                <code className="language-plaintext highlighter-rouge">
                  shows.db
                </code>
                , execute{" "}
                <code className="language-plaintext highlighter-rouge">
                  sqlite3 shows.db
                </code>
                , followed by{" "}
                <code className="language-plaintext highlighter-rouge">
                  .mode csv
                </code>{" "}
                and then{" "}
                <code className="language-plaintext highlighter-rouge">
                  .import "Favorite TV Shows - Form Responses 1.csv"
                </code>
                .
              </li>
              <li>
                To generate the lecture’s second version of{" "}
                <code className="language-plaintext highlighter-rouge">
                  shows.db
                </code>
                , execute{" "}
                <code className="language-plaintext highlighter-rouge">
                  python favorites8.py
                </code>
                .
              </li>
            </ul>
          </li>
        </ol>
      </details>

      <ul>
        <li>
          {t("Lecture")}
          <ul>
            <li>
              <Link to="note7">{t("Notes")}</Link>
            </li>
            <li>
              {t("Slides")}
              <ul>
                <li>
                  <a href="https://docs.google.com/presentation/d/15se_MWukxRsxlZEx_MMZV6hlG-VVX3MF3V1JQpUyZ30/edit?usp=sharing">
                    {t("Google-slides")}
                  </a>
                </li>
                <li>
                  <a href="https://cdn.cs50.net/2020/fall/lectures/7/lecture7.pdf">
                    PDF
                  </a>
                </li>
              </ul>
            </li>
            <li>
              {t("Source-Code")}
              <ul>
                <li>
                  <a href="https://cdn.cs50.net/2020/fall/lectures/7/src7/">
                    Index
                  </a>
                </li>
                <li>
                  <a href="https://cdn.cs50.net/2020/fall/lectures/7/src7.pdf">
                    PDF
                  </a>
                </li>
                <li>
                  <a href="https://cdn.cs50.net/2020/fall/lectures/7/src7.zip">
                    Zip
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Video
              <ul>
                <li>
                  {" "}
                  <span
                    className="video-link"
                    onClick={() => {
                      setVideo(video);
                    }}
                  >
                    {" "}
                    Video
                  </span>{" "}
                </li>
                <li>
                  <a href="https://www.youtube.com/watch?v=ZX2T7slE_9I&amp;start=1077">
                    YouTube
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          {t("Short")}
          <ul>
            <li>
              {" "}
              <span
                className="video-link"
                onClick={() => {
                  setVideo("https://www.youtube.com/embed/AywtnUjQ6X4");
                }}
              >
                {" "}
                SQL
              </span>{" "}
            </li>
          </ul>
        </li>

        <li>
          <Link to="./Pset7">{t("Problem")} 7</Link>
        </li>
      </ul>
    </React.Fragment>
  );
}

export default Week0;
