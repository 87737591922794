import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

function Pset4() {
  const alert = {
    padding: "10px",
    color: "#664D03",
    backgroundColor: "#fff3cd",
    borderColor: "#ffeeba",
    borderRadius: "10px",
  };
  const alert2 = {
    padding: "10px",
    color: "#065260",
    backgroundColor: "#CFF4FC",
    borderColor: "#ffeeba",
    marginTop: "10px",
    borderRadius: "10px",
  };
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  return (
    <React.Fragment>
      <main className="col-md">
        <h1 id="problem-set-5">Problem Set 5</h1>

        <div style={alert} data-alert="warning" role="alert">
          <p>
            Collaboration on problem sets is not permitted except to the extent
            that you may ask classmates and others for help so long as that help
            does not reduce to another doing your work for you, per the course’s
            policy on{" "}
            <a href="https://cs50.harvard.edu/x/2021/syllabus//#academic-honesty">
              academic honesty
            </a>
            .
          </p>

          <p>
            The staff conducts random audits of submissions to CS50x. Students
            found to be in violation of this policy will be removed from the
            course. Students who have already completed CS50x, if found to be in
            violation, will have their CS50 Certificate permanently revoked.
          </p>
        </div>

        <div style={alert2} data-alert="info" role="alert">
          <p>
            GitHub now requires that you use SSH or a personal access token
            instead of a password to log in, but you can still use{" "}
            <code className="language-plaintext highlighter-rouge">
              check50
            </code>{" "}
            and{" "}
            <code className="language-plaintext highlighter-rouge">
              submit50
            </code>
            ! See <a href="https://cs50.ly/github">cs50.ly/github</a> for
            instructions if you haven’t already!
          </p>
        </div>

        <h2 id="what-to-do">What to Do</h2>

        <div style={alert} data-alert="warning" role="alert">
          <p>
            Be sure you have completed{" "}
            <a href="https://cs50.harvard.edu/x/2021/labs/5/">Lab 5</a> before
            beginning this problem set.
          </p>
        </div>

        <ol>
          <li>
            Submit <Link to="speller">Speller</Link>
          </li>
        </ol>

        <h2 id="when-to-do-it">When to Do It</h2>

        <p>
          By{" "}
          <span>{new Date("2021-12-31T23:59:00-05:00").toLocaleString()}</span>.
        </p>

        <h2 id="advice">Advice</h2>

        <ul>
          <li data-marker="*">
            Try out any of David’s programs from <Link to="week5"> Week 5</Link>
            .
          </li>
          <li data-marker="*">
            <p>
              If you see any errors when compiling your code with{" "}
              <code className="language-plaintext highlighter-rouge">make</code>
              , focus first on fixing the very first error you see, scrolling up
              as needed. If unsure what it means, try asking{" "}
              <code className="language-plaintext highlighter-rouge">
                help50
              </code>{" "}
              for help. For instance, if trying to compile{" "}
              <code className="language-plaintext highlighter-rouge">
                hello
              </code>
              , and
            </p>

            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>make speller</code>
                </pre>
              </div>{" "}
            </div>
            <p>is yielding errors, try running</p>
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>help50 make speller</code>
                </pre>
              </div>{" "}
            </div>
            <p>instead!</p>
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Pset4;
