import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Readability() {
  const style = {
    color: "#856404",
    backgroundColor: "#fff3cd",
    borderColor: "#ffeeba",
  };

  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md">
        <h1 id="readability">Readability</h1>

        <p>
          Implement a program that computes the approximate grade level needed
          to comprehend some text, per the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ python readability.py Text: Congratulations! Today is your
                day. You're off to Great Places! You're off and away! Grade 3
              </code>
            </pre>
          </div>
        </div>

        <h2 id="specification">Specification</h2>

        <ul>
          <li data-marker="*">
            Write, in a file called{" "}
            <code className="highlighter-rouge">readability.py</code> in{" "}
            <code className="highlighter-rouge">~/pset6/readability/</code>, a
            program that first asks the user to type in some text, and then
            outputs the grade level for the text, according to the Coleman-Liau
            formula, exactly as you did in <Link to="pset2">Problem Set 2</Link>
            , except that your program this time should be written in Python.
            <ul>
              <li data-marker="*">
                Recall that the Coleman-Liau index is computed as{" "}
                <code className="highlighter-rouge">
                  0.0588 * L - 0.296 * S - 15.8
                </code>
                , where <code className="highlighter-rouge">L</code> is the
                average number of letters per 100 words in the text, and{" "}
                <code className="highlighter-rouge">S</code> is the average
                number of sentences per 100 words in the text.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Use <code className="highlighter-rouge">get_string</code> from the
            CS50 Library to get the user’s input, and{" "}
            <code className="highlighter-rouge">print</code> to output your
            answer.
          </li>
          <li data-marker="*">
            Your program should count the number of letters, words, and
            sentences in the text. You may assume that a letter is any lowercase
            character from <code className="highlighter-rouge">a</code> to{" "}
            <code className="highlighter-rouge">z</code> or any uppercase
            character from <code className="highlighter-rouge">A</code> to{" "}
            <code className="highlighter-rouge">Z</code>, any sequence of
            characters separated by spaces should count as a word, and that any
            occurrence of a period, exclamation point, or question mark
            indicates the end of a sentence.
          </li>
          <li data-marker="*">
            Your program should print as output{" "}
            <code className="highlighter-rouge">"Grade X"</code> where{" "}
            <code className="highlighter-rouge">X</code> is the grade level
            computed by the Coleman-Liau formula, rounded to the nearest
            integer.
          </li>
          <li data-marker="*">
            If the resulting index number is 16 or higher (equivalent to or
            greater than a senior undergraduate reading level), your program
            should output <code className="highlighter-rouge">"Grade 16+"</code>{" "}
            instead of giving the exact index number. If the index number is
            less than 1, your program should output{" "}
            <code className="highlighter-rouge">"Before Grade 1"</code>.
          </li>
        </ul>

        <div className="alert" data-alert="warning" role="alert" style={style}>
          <p>
            Note that the specification here is only a summary of the
            requirements, so if you didn’t do Readability in C, or if you are
            still unsure, we’d recommend that you review the{" "}
            <Link to="readability">C specification and walkthrough</Link> for
            clarification.
          </p>
        </div>

        <h2 id="usage">Usage</h2>

        <p>Your program should behave per the example below.</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ python readability.py Text: Congratulations! Today is your
                day. You're off to Great Places! You're off and away! Grade 3
              </code>
            </pre>
          </div>
        </div>

        <h2 id="testing">Testing</h2>

        <p>
          No <code className="highlighter-rouge">check50</code> for this
          problem, but be sure to test your code for each of the following.
        </p>

        <ul>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              One fish. Two fish. Red fish. Blue fish.
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Before Grade 1</code>.
          </li>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              Would you like them here or there? I would not like them here or
              there. I would not like them anywhere.
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Grade 2</code>.
          </li>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              Congratulations! Today is your day. You're off to Great Places!
              You're off and away!
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Grade 3</code>.
          </li>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              Harry Potter was a highly unusual boy in many ways. For one thing,
              he hated the summer holidays more than any other time of year. For
              another, he really wanted to do his homework, but was forced to do
              it in secret, in the dead of the night. And he also happened to be
              a wizard.
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Grade 5</code>.
          </li>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              In my younger and more vulnerable years my father gave me some
              advice that I've been turning over in my mind ever since.
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Grade 7</code>.
          </li>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              Alice was beginning to get very tired of sitting by her sister on
              the bank, and of having nothing to do: once or twice she had
              peeped into the book her sister was reading, but it had no
              pictures or conversations in it, "and what is the use of a book,"
              thought Alice "without pictures or conversation?"
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Grade 8</code>.
          </li>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              When he was nearly thirteen, my brother Jem got his arm badly
              broken at the elbow. When it healed, and Jem's fears of never
              being able to play football were assuaged, he was seldom
              self-conscious about his injury. His left arm was somewhat shorter
              than his right; when he stood or walked, the back of his hand was
              at right angles to his body, his thumb parallel to his thigh.
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Grade 8</code>.
          </li>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              There are more things in Heaven and Earth, Horatio, than are
              dreamt of in your philosophy.
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Grade 9</code>.
          </li>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              It was a bright cold day in April, and the clocks were striking
              thirteen. Winston Smith, his chin nuzzled into his breast in an
              effort to escape the vile wind, slipped quickly through the glass
              doors of Victory Mansions, though not quickly enough to prevent a
              swirl of gritty dust from entering along with him.
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Grade 10</code>.
          </li>
          <li data-marker="*">
            Run your program as{" "}
            <code className="highlighter-rouge">python readability.py</code>,
            and wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">
              A large class of computational problems involve the determination
              of properties of graphs, digraphs, integers, arrays of integers,
              finite families of finite sets, boolean formulas and elements of
              other countable domains.
            </code>{" "}
            and press enter. Your program should output{" "}
            <code className="highlighter-rouge">Grade 16+</code>.
          </li>
        </ul>

        <h2 id="how-to-submit">How to Submit</h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2020/x/sentimental/readability</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Readability;
