import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

function MarioLess() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md">
        <a data-id="" id="mario"></a>
        <h1>
          <a data-id="" href="#mario">
            Mario
          </a>
        </h1>

        <p>
          <img
            src={require("./pyramid.png")}
            alt="screenshot of Mario jumping up pyramid"
          />
        </p>

        <p>
          Implement a program that prints out a half-pyramid of a specified
          height, per the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                $ ./mario
                <br />
                Height: 4
                <br />
                #
                <br />
                ##
                <br />
                ###
                <br />
                ####
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="specification"></a>
        <h2>
          <a data-id="" href="#specification">
            Specification
          </a>
        </h2>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Write, in a file called{" "}
            <code className="highlighter-rouge">mario.py</code> in{" "}
            <code className="highlighter-rouge">~/pset6/mario/less/</code>, a
            program that recreates the half-pyramid using hashes (
            <code className="highlighter-rouge">#</code>) for blocks, exactly as
            you did in <a href="../../../1/">Problem Set 1</a>, except that your
            program this time should be written (a) in Python and (b) in CS50
            IDE.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            To make things more interesting, first prompt the user with{" "}
            <code className="highlighter-rouge">get_int</code> for the
            half-pyramid’s height, a positive integer between{" "}
            <code className="highlighter-rouge">1</code> and{" "}
            <code className="highlighter-rouge">8</code>, inclusive.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            If the user fails to provide a positive integer no greater than{" "}
            <code className="highlighter-rouge">8</code>, you should re-prompt
            for the same again.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Then, generate (with the help of{" "}
            <code className="highlighter-rouge">print</code> and one or more
            loops) the desired half-pyramid.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Take care to align the bottom-left corner of your half-pyramid with
            the left-hand edge of your terminal window.
          </li>
        </ul>

        <a data-id="" id="usage"></a>
        <h2>
          <a data-id="" href="#usage">
            Usage
          </a>
        </h2>

        <p>Your program should behave per the example below.</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                $ ./mario
                <br />
                Height: 4
                <br />
                #
                <br />
                ##
                <br />
                ###
                <br />
                ####
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="testing"></a>
        <h2>
          <a data-id="" href="#testing">
            Workthrough
          </a>
        </h2>

        <iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="embed-responsive-item"
          src={t("pset6_mario_less")}
          scrolling="no"
          style={{
            width: "100%",
            height: "700px",
          }}
        ></iframe>

        <a data-id="" id="testing"></a>
        <h2>
          <a data-id="" href="#testing">
            Testing
          </a>
        </h2>

        <p>
          No <code className="highlighter-rouge">check50</code> for this
          problem, but be sure to test your code for each of the following.
        </p>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python mario.py</code> and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">-1</code> and press enter. Your
            program should reject this input as invalid, as by re-prompting the
            user to type in another number.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python mario.py</code> and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">0</code> and press enter. Your
            program should reject this input as invalid, as by re-prompting the
            user to type in another number.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python mario.py</code> and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">1</code> and press enter. Your
            program should generate the below output. Be sure that the pyramid
            is aligned to the bottom-left corner of your terminal, and that
            there are no extra spaces at the end of each line.
          </li>
        </ul>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>#</code>
            </pre>
          </div>
        </div>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python mario.py</code> and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">2</code> and press enter. Your
            program should generate the below output. Be sure that the pyramid
            is aligned to the bottom-left corner of your terminal, and that
            there are no extra spaces at the end of each line.
          </li>
        </ul>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                #
                <br />
                ##
                <br />
              </code>
            </pre>
          </div>
        </div>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python mario.py</code> and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">8</code> and press enter. Your
            program should generate the below output. Be sure that the pyramid
            is aligned to the bottom-left corner of your terminal, and that
            there are no extra spaces at the end of each line.
          </li>
        </ul>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                #
                <br />
                ##
                <br />
                ###
                <br />
                ####
                <br />
                #####
                <br />
                ######
                <br />
                #######
                <br />
                ########
                <br />
              </code>
            </pre>
          </div>
        </div>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python mario.py</code> and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">9</code> and press enter. Your
            program should reject this input as invalid, as by re-prompting the
            user to type in another number. Then, type in{" "}
            <code className="highlighter-rouge">2</code> and press enter. Your
            program should generate the below output. Be sure that the pyramid
            is aligned to the bottom-left corner of your terminal, and that
            there are no extra spaces at the end of each line.
          </li>
        </ul>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                #
                <br />
                ##
                <br />
              </code>
            </pre>
          </div>
        </div>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python mario.py</code> and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">foo</code> and press enter. Your
            program should reject this input as invalid, as by re-prompting the
            user to type in another number.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python mario.py</code> and wait
            for a prompt for input. Do not type anything, and press enter. Your
            program should reject this input as invalid, as by re-prompting the
            user to type in another number.
          </li>
        </ul>

        <a data-id="" id="how-to-submit"></a>
        <h2>
          <a data-id="" href="#how-to-submit">
            How to Submit
          </a>
        </h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2020/x/sentimental/mario/less</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default MarioLess;
