import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

function Credit() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md">
        <a data-id="" id="credit"></a>
        <h1>
          <a data-id="" href="#credit">
            Credit
          </a>
        </h1>

        <p>
          Implement a program that determines whether a provided credit card
          number is valid according to Luhn’s algorithm.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                <br />
                $ python credit.py
                <br />
                Number: 378282246310005
                <br />
                AMEX
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="specification"></a>
        <h2>
          <a data-id="" href="#specification">
            Specification
          </a>
        </h2>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            In <code className="highlighter-rouge">credit.py</code> in{" "}
            <code className="highlighter-rouge">~/pset6/credit/</code>, write a
            program that prompts the user for a credit card number and then
            reports (via <code className="highlighter-rouge">print</code>)
            whether it is a valid American Express, MasterCard, or Visa card
            number, exactly as you did in <a href="../../1/">Problem Set 1</a>,
            except that your program this time should be written (a) in Python
            and (b) in CS50 IDE.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            So that we can automate some tests of your code, we ask that your
            program’s last line of output be{" "}
            <code className="highlighter-rouge">AMEX\n</code> or{" "}
            <code className="highlighter-rouge">MASTERCARD\n</code> or{" "}
            <code className="highlighter-rouge">VISA\n</code> or{" "}
            <code className="highlighter-rouge">INVALID\n</code>, nothing more,
            nothing less.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            For simplicity, you may assume that the user’s input will be
            entirely numeric (i.e., devoid of hyphens, as might be printed on an
            actual card).
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Best to use <code className="highlighter-rouge">get_int</code> or{" "}
            <code className="highlighter-rouge">get_string</code> from CS50’s
            library to get users’ input, depending on how you to decide to
            implement this one.
          </li>
        </ul>

        <a data-id="" id="usage"></a>
        <h2>
          <a data-id="" href="#usage">
            Usage
          </a>
        </h2>

        <p>Your program should behave per the example below.</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                $ python credit.py
                <br />
                Number: 378282246310005
                <br />
                AMEX
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="workthrough"></a>
        <h2>
          <a data-id="" href="#workthrough">
            Workthrough
          </a>
        </h2>

        <iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="embed-responsive-item"
          src={t("pset6_credit")}
          scrolling="no"
          style={{
            width: "100%",
            height: "700px",
          }}
        ></iframe>
        <a data-id="" id="testing"></a>
        <h2>
          <a data-id="" href="#testing">
            Testing
          </a>
        </h2>

        <p>
          No <code className="highlighter-rouge">check50</code> for this
          problem, but be sure to test your code for each of the following.
        </p>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python credit.py</code>, and
            wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">378282246310005</code> and press
            enter. Your program should output{" "}
            <code className="highlighter-rouge">AMEX</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python credit.py</code>, and
            wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">371449635398431</code> and press
            enter. Your program should output{" "}
            <code className="highlighter-rouge">AMEX</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python credit.py</code>, and
            wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">5555555555554444</code> and
            press enter. Your program should output{" "}
            <code className="highlighter-rouge">MASTERCARD</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python credit.py</code>, and
            wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">5105105105105100</code> and
            press enter. Your program should output{" "}
            <code className="highlighter-rouge">MASTERCARD</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python credit.py</code>, and
            wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">4111111111111111</code> and
            press enter. Your program should output{" "}
            <code className="highlighter-rouge">VISA</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python credit.py</code>, and
            wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">4012888888881881</code> and
            press enter. Your program should output{" "}
            <code className="highlighter-rouge">VISA</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python credit.py</code>, and
            wait for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">1234567890</code> and press
            enter. Your program should output{" "}
            <code className="highlighter-rouge">INVALID</code>.
          </li>
        </ul>

        <a data-id="" id="how-to-submit"></a>
        <h2>
          <a data-id="" href="#how-to-submit">
            How to Submit
          </a>
        </h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2020/x/sentimental/credit</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Credit;
