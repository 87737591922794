import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "./../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import "./Sidenav.css";

function Sidenav(props) {
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    localStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
  };

  const [collapse, setCollapse] = useState("");
  function handleCollapse() {
    setCollapse(collapse === "active" ? " " : "active");
  }

  const disableLink = (e) => {
    e.preventDefault();
  };
  return (
    <React.Fragment>
      <nav id="sidebar" className={collapse}>
        <div className="custom-menu">
          <button
            type="button"
            onClick={handleCollapse}
            id="sidebarCollapse"
            className="btn active btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>

        <div className="p-4">
          <ul className="list-unstyled components mb-5">
            <li>
              <NavLink
                exact
                activeClassName="active1"
                to={{
                  pathname: "week0",
                  state: {
                    from: "week1",
                  },
                }}
              >
                {t("week")} 0
              </NavLink>
              <span className="week-title">{t("scratch")} 😺</span>
            </li>
            <li>
              <Link to="week1">{t("week")} 1</Link>
              <span className="week-title"> {t("C")}</span>
            </li>
            <li>
              <NavLink exact activeClassName="active1" to="week2">
                {t("week")} 2
              </NavLink>
              <span className="week-title"> {t("array")}</span>
            </li>
            <li>
              <NavLink exact activeClassName="active1" to="week3">
                {t("week")} 3
              </NavLink>
              <span className="week-title"> {t("algorithms")}</span>
            </li>
            <li>
              <NavLink exact activeClassName="active1" to="week4">
                {t("week")} 4
              </NavLink>
              <span className="week-title"> {t("memory")}</span>
            </li>
            <li>
              <NavLink exact activeClassName="active1" to="week5">
                {t("week")} 5
              </NavLink>
              <span className="week-title"> {t("data-structures")} </span>
            </li>
            <li className="disabled">
              <NavLink
                exact
                activeClassName="active1"
                to="week6"
                onClick={(e) => disableLink(e)}
              >
                {t("week")} 6
              </NavLink>
              <span className="week-title"> {t("python")} 🐍</span>
            </li>
            <li className="disabled">
              <NavLink
                exact
                activeClassName="active1"
                to="week7"
                onClick={(e) => disableLink(e)}
              >
                {t("week")} 7
              </NavLink>
              <span className="week-title"> SQL </span>
            </li>
            {/* <li className="disabled"> */}
            <li className="disabled">
              <NavLink
                exact
                activeClassName="active1"
                to="week8"
                onClick={(e) => disableLink(e)}
              >
                {t("week")} 8
              </NavLink>
              <span className="week-title"> HTML, CSS, JS</span>
            </li>

            <li className="disabled">
              <NavLink
                exact
                activeClassName="active1"
                to="week9"
                onClick={(e) => disableLink(e)}
              >
                {t("week")} 9
              </NavLink>
              <span className="week-title"> Flask</span>
            </li>

            <li className="disabled">
              <NavLink
                exact
                activeClassName="active1"
                to="final-project"
                onClick={(e) => disableLink(e)}
              >
                {t("final-project")}
              </NavLink>
              {/* <span className="week-title"> Final project</span> */}
            </li>
            <li>
              <div className="footer" style={{ marginTop: "20px" }}>
                <h6
                  style={{ display: "inline-block", cursor: "pointer" }}
                  onClick={() => changeLanguage("ar")}
                >
                  ar
                </h6>{" "}
                |{" "}
                <h6
                  style={{ display: "inline-block", cursor: "pointer" }}
                  onClick={() => changeLanguage("en")}
                >
                  en
                </h6>
                <div className="contact">info@computiq.com</div>
                <div>
                  <a href={"https://www.instagram.com/computiq"}>
                    <i className="fa fa-instagram contact"></i>{" "}
                  </a>

                  <a href={"https://www.facebook.com/Computiq/"}>
                    <i className="fa fa-facebook contact"></i>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default Sidenav;
