import React, { useState } from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";
import Sidenav from "./../../components/Sidenav";
import "./../weeks.css";

function Week1() {
  const { t } = useTranslation();
  const [video, setVideo] = useState(t("Lec1"));

  return (
    <React.Fragment>
      <Sidenav />
      <div className="center-week container">
        <h1 id="week-1">{t("week")} 1</h1>

        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
          style={{ maxWidth: "720px" }}
        >
          <Iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={t("Lec1")}
          ></Iframe>
        </div>

        <ul className="mt-5">
          <li data-marker="-">
            {t("Lecture")}
            <ul>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/1/lecture1.mp3">
                  Audio
                </a>
              </li>
              <li data-marker="*">
                <Link to="note1">{t("Notes")}</Link>
              </li>
              <li data-marker="+">
                {t("Slides")}
                <ul>
                  <li data-marker="*">
                    <a href="https://docs.google.com/presentation/d/1_BSY-aHIw8Xa__FL0HyAFoevLW7GHcTnx8j52dn0LsI/edit?usp=sharing">
                      {t("Google-slides")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/1/lecture1.pdf">
                      PDF
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="+">
                {t("Source-Code")}
                <ul>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/1/src1/">
                      {t("Index")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/1/src1.pdf">
                      PDF
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/1/src1.zip">
                      Zip
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/1/lang/en/lecture1.srt">
                  Subtitles
                </a>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/1/lang/en/lecture1.txt">
                  Transcript
                </a>
              </li>
              <li data-marker="+">
                {t("Video")}
                <ul>
                  <li data-marker="*">
                    <a href="https://video.cs50.io/zYierUhIFNQ?screen=VmZ-cWfb2QM">
                      CS50 Video Player
                    </a>
                  </li>
                  <li data-marker="+">
                    MP4
                    <ul>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/1/lecture1-360p.mp4">
                          360p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/1/lecture1-720p.mp4">
                          720p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/1/lecture1-1080p.mp4">
                          1080p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/1/lecture1-4k.mp4">
                          4K
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li data-marker="*">
                    <a href="https://youtu.be/zYierUhIFNQ">YouTube</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-marker="*">
            <a href="https://cs50.harvard.edu/x/2021/labs/1/" target="_blank">
              Lab 1
            </a>
          </li>
          <li data-marker="-">
            {t("Short")}
            <ol>
              <li>
                <a href="https://youtu.be/Fc9htmvVZ9U">Data Types</a>
              </li>
              <li>
                <a href="https://youtu.be/f1xZf4iJDWE">Operators</a>
              </li>
              <li>
                <a href="https://youtu.be/1wsaV5nVC7g">
                  Conditional Statements
                </a>
              </li>
              <li>
                <a href="https://youtu.be/WgX8e_O7eG8">Loops</a>
              </li>
              <li>
                <a href="https://youtu.be/BnJ013X02b8">Command Line</a>
              </li>
            </ol>
          </li>
          <li data-marker="*">
            <Link to="pset1">{t("Problem")} 1</Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default Week1;
