import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      Lang: "English",
      slogan: "Tiq It To Success!",
      week: "Week",
      c: "C",
      scratch: "Scratch",
      array: "Array",
      algorithms: "Argorithm",
      memory: "Memory",
      "data-structures": "Data Structures",
      python: "Python",
      SQL: "SQL",
      information: "Information",
      "go-to-shop": "Checkout CS50x Iraq shop",
      Lecture: "Lecture",
      "Source-Code": "Source Code",
      Video: "Video",
      Notes: "Notes",
      Slides: "Slides",
      "Google-slides": "Google Slides",
      Index: "Index",
      Studio: "Studio",
      Problem: "Problem Set",
      Short: "Short",
      "Data-types": "Data Types",
      Operators: "Operators",
      "Conditional-statements": "Conditional Statements",
      Loops: "Loops",
      "Command-line": "Command Line",
      Functions: "Functions",
      Arrays: "Arrays",
      "Linear-search": "Linear Search",
      "Binary-search": "Binary Search",
      "Bubble-sort": "Bubble Sort",
      "Selection-sort": "Selection Sort",
      "Insertion-sort": "Insertion Sort",
      Recursion: "Recursion",
      "Merge-sort": "Merge Sort",
      "Algorithms Summary": "Algorithms Summary",
      Hexadecimal: "Hexadecimal",
      Pointers: "Pointers",
      "Dynamic-memory-allocation": "Dynamic Memory Allocation",
      "Call-stacks": "Call Stacks",
      "File-pointers": "File Pointers",
      "Data-structures": "Data Structures",
      "Singly-Linked Lists": "Singly-Linked Lists",
      "Hash-tables": "Hash Tables",
      Tries: "Tries",
      "final-project": "Final Project",

      // Lectures

      Lec0: "https://www.youtube.com/embed/YoXxevp1WRQ",
      Lec1: "https://www.youtube.com/embed/zYierUhIFNQ",

      pset1_hello: "https://www.youtube.com/embed/wSk1KSDUEYA",
      pset1_mario_less: "https://www.youtube.com/embed/NAs4FIWkJ4s",
      pset1_cash: "https://www.youtube.com/embed/Y3nWGvqt_Cg",
      pset1_credit: "https://www.youtube.com/embed/dF7wNjsRBjI",
      pset1_mario_more: "https://www.youtube.com/embed/FzN9RAjYG_Q",

      //week2
      Lec2: "https://www.youtube.com/embed/tI_tIZFyKBw",
      pset2_readability: "https://www.youtube.com/embed/AOVyZEh9zgE",
      pset2_caesar: "https://www.youtube.com/embed/V2uusmv2wxI",
      pset2_substitution: "https://www.youtube.com/embed/cXAoZAsgxJ4",

      //Week3
      Lec3: "https://www.youtube.com/embed/gR6nycuZKlM",
      pset3_plurality: "https://www.youtube.com/embed/ftOapzDjEb8",
      pset3_runoff: "https://www.youtube.com/embed/-Vc5aGywKxo",
      pset3_tideman: "https://www.youtube.com/embed/kb83NwyYI68",

      //Week4
      Lec4: "https://www.youtube.com/embed/NKTfNv2T0FE",
      pset4_filter_less:
        "https://www.youtube.com/embed/K0v9byp9jd0?list=PLhQjrBD2T3837jmUt0ep7Tpmnxdv9NVut",
      pset4_recover: "https://www.youtube.com/embed/ooL0r_8N9ms",
      pset4_filter_more:
        "https://www.youtube.com/embed/vsOsctDernw?list=PLhQjrBD2T382OwvMbZuaMGtD9wZkhnhYj",

      // Week5
      Lec5: "https://www.youtube.com/embed/2T-A_GFuoTo",
      pset5_speller:
        "https://www.youtube.com/embed/_z57x5PGF4w?list=PLhQjrBD2T382T4b6jjwX_qbU23E_Unwcz",

      // Week6
      pset6_hello: "https://www.youtube.com/embed/ocfLv6GyKhM",
      pset6_mario_less: "https://www.youtube.com/embed/qaEmkq4UUdQ",
      pset6_cash: "https://www.youtube.com/embed/NtiomrRMvXQ",
      pset6_credit: "https://www.youtube.com/embed/Tc23LSXf-80",
      pset6_dna: "https://www.youtube.com/embed/j84b_EgntcQ",

      // Week7
      pset7_movies: "https://www.youtube.com/embed/v5_A3giDlQs",
      pset7_houses: "https://www.youtube.com/embed/3w-EvTiw3p8",
    },
  },
  ar: {
    translation: {
      Lang: "عربي",
      slogan: "!إبدئها صح",
      week: "اسبوع",
      c: "سي",
      scratch: "سكراج",
      array: "المصفوفة",
      algorithms: "الخوارزمية",
      memory: "الذاكرة",
      "data-structures": "هيكلية البيانات",
      python: "بايثون",
      SQL: "إس كيو إل",
      information: "المعلومات",
      "go-to-shop": " CS50x Iraq  متجر",

      Lecture: "المحاضرة",
      "Source-Code": "الشفرة المصدرية",
      Video: "فيديو",
      Notes: "ملاحظات",
      Slides: "سلايد",
      "Google-slides": "كوكل سلايد",
      Index: "اندكس",
      Studio: "استوديو",
      Problem: "بروبلم سيت",
      Short: "فيديو قصير",

      "Data-types": "انواع البيانات",
      Operators: "العمليات",
      "Conditional-statements": "العبارات الشرطية",
      Loops: "الحلقات",
      "Command-line": "سطر الاوامر",
      Functions: "الدوال",
      Arrays: "المصفوفات",
      "Linear-search": "البحث الخطي",
      "Binary-search": "البحث الثنائي",
      "Bubble-sort": "فرز الفقاعة",
      "Selection-sort": "فرز الاختياري",
      "Insertion-sort": "الفرز بالادراج",
      Recursion: "العودوية",
      "Merge-sort": "الفرز بالدمج",
      "Algorithms-summary": "ملخص الخوارزميات",
      Hexadecimal: "السداسي عشري",
      Pointers: "المؤشرات",
      "Dynamic-memory-allocation": "تخصيص الذاكرة الديناميكية",
      "Call-stacks": "استدعاء الاكوام",
      "File-pointers": "مؤشرات الملقات",
      "Data-structures": "هياكل البيانات",
      "Singly-Linked-lists": "Singly-Linked Lists",
      "Hash-tables": "تجزئة الجداول",
      Tries: "ترايز",
      "final-project": "المشروع النهائي",

      // Lectures
      Lec0: "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRsC4Hk9tysvNV_cca5bDfDp",
      "pset-scratch": "https://www.youtube.com/embed/r8AeYbuMVgU",
      Lec1: "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRuwS34ium69wHQpJu5inoOX",
      pset1_hello: "https://www.youtube.com/embed/wSk1KSDUEYA",
      pset1_mario_less: "https://www.youtube.com/embed/f7q4rESfQYg",
      pset1_mario_more: "https://www.youtube.com/embed/0duLTYJTMNs",
      pset1_cash: "https://www.youtube.com/embed/YpiEHHRcaVY",
      pset1_credit: "https://www.youtube.com/embed/RHV67AZp4sY",

      // Week1
      Lec1: "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRuwS34ium69wHQpJu5inoOX",

      //week2
      Lec2: "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRs7HFFX5iLXBRxyQtrKX9uJ",
      pset2_readability: "https://www.youtube.com/embed/kXjbLFuo7KQ",
      pset2_caesar: "https://www.youtube.com/embed/pVL_k4E-sxo",
      pset2_substitution: "https://www.youtube.com/embed/cXAoZAsgxJ4",

      //Week3
      Lec3: "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRtDJhv-vivzSwkpNenoQmlO",
      pset3_plurality: "https://www.youtube.com/embed/-QLQHj062yQ",
      pset3_runoff: "https://www.youtube.com/embed/Oi8Yise5KBE",
      pset3_tideman: "https://www.youtube.com/embed/kb83NwyYI68",

      //Week4
      Lec4: "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRu-X5RUAjOjOMFgsY6Mqibb",
      pset4_filter_less:
        "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRvLfI3l1byyuiWFkQe3gHUO",
      pset4_recover:
        "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRvFr6ZfVTIrnxrjw00G3F8y",
      pset4_filter_more:
        "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRujGJSS_hmxb1TTBdvggccD",

      // Week5
      Lec5: "https://www.youtube.com/embed/videoseries?list=PL02WP1i83HRtShcNBYWn2Enj_H5u3tFPm",
      pset5_speller: "https://www.youtube.com/embed/kwFiPBuoZaA",
      // Week6
      //Lec6:
      pset6_hello: "https://www.youtube.com/embed/ocfLv6GyKhM",
      pset6_mario_less: "https://www.youtube.com/embed/qaEmkq4UUdQ",
      pset6_cash: "https://www.youtube.com/embed/NtiomrRMvXQ",
      pset6_credit: "https://www.youtube.com/embed/Tc23LSXf-80",
      pset6_dna: "https://www.youtube.com/embed/1O6mfrr8TNQ",

      // Week7
      pset7_movies: "https://www.youtube.com/embed/Krj0a7M_5aw",
      pset7_houses: "https://www.youtube.com/embed/RcBiSUbgmaU",
    },
  },
};

let lang = null;
switch (localStorage.getItem("lang")) {
  case "ar":
    lang = "ar";
    break;
  case "en":
    lang = "en";
    break;
  default:
    lang = "en";
    break;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
