import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { BASE_URL } from "../../urls";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const submit = async (e) => {
    e.preventDefault();

    const response = await fetch(`${BASE_URL}/api/academy/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //   credentials: 'include',
      body: JSON.stringify({
        email,
        password,
      }),
    });

    try {
      let result = await response.json();
      localStorage.setItem("access_token", result.token.access_token);
      window.location.reload();
    } catch (error) {
      setMessage("The email address or password is incorrect.");
      console.log(error);
    }
  };

  return (
    <div className="login">
      <div className="control">
        <div className="welcome">
          <span>Welcome</span>
          <span className="title">To CS50x Iraq Course</span>
        </div>
        <form className="login-card" onSubmit={submit}>
          <h3>Sign In</h3>
          <span
            style={{
              color: "var(--s-red)",
              marginBottom: "5px",
              display: "inline-block",
            }}
          >
            {message}
          </span>
          <div>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <input type="submit" value="LOGIN" className="login-btn" />
        </form>
      </div>
      <div></div>
      <img className="pattrens" src="/src/assets/images/pattrens.png" alt="" />
    </div>
  );
}

export default Login;
