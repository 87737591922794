import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Logo from "./../../assets/images/logo.png"
function Header({ data }) {
  const [open, setOpen] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };

  return (
    <header>
      <div
        className="control"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <span className="image">{data.photo}</span>
        {data.user.first_name}
        {/* <span className="profile">
          <FontAwesomeIcon icon={faUserCircle} /> Profile
        </span> */}
      </div>
      <div className="middle">
        <span>
          <Link to="/courses">Home</Link>
        </span>
        <span onClick={handleLogout}>Logout</span>
      </div>
      <div className="logo">
        <img width="100px" src={Logo} alt="" />
      </div>
    </header>
  );
}

export default Header;
