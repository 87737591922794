import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

function Cash() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md">
        <a data-id="" id="cash"></a>
        <h1>
          <a data-id="" href="#cash">
            Cash
          </a>
        </h1>

        <p>
          Implement a program that calculates the minimum number of coins
          required to give a user change.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                $ python cash.py
                <br />
                Change owed: 0.41
                <br />
                4
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="specification"></a>
        <h2>
          <a data-id="" href="#specification">
            Specification
          </a>
        </h2>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Write, in a file called{" "}
            <code className="highlighter-rouge">cash.py</code> in{" "}
            <code className="highlighter-rouge">~/pset6/cash/</code>, a program
            that first asks the user how much change is owed and then spits out
            the minimum number of coins with which said change can be made,
            exactly as you did in <a href="../../1/">Problem Set 1</a>, except
            that your program this time should be written (a) in Python and (b)
            in CS50 IDE.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Use <code className="highlighter-rouge">get_float</code> from the
            CS50 Library to get the user’s input and{" "}
            <code className="highlighter-rouge">print</code> to output your
            answer. Assume that the only coins available are quarters (25¢),
            dimes (10¢), nickels (5¢), and pennies (1¢).
            <ul className="fa-ul">
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                We ask that you use{" "}
                <code className="highlighter-rouge">get_float</code> so that you
                can handle dollars and cents, albeit sans dollar sign. In other
                words, if some customer is owed $9.75 (as in the case where a
                newspaper costs 25¢ but the customer pays with a $10 bill),
                assume that your program’s input will be{" "}
                <code className="highlighter-rouge">9.75</code> and not{" "}
                <code className="highlighter-rouge">$9.75</code> or{" "}
                <code className="highlighter-rouge">975</code>. However, if some
                customer is owed $9 exactly, assume that your program’s input
                will be <code className="highlighter-rouge">9.00</code> or just{" "}
                <code className="highlighter-rouge">9</code> but, again, not{" "}
                <code className="highlighter-rouge">$9</code> or{" "}
                <code className="highlighter-rouge">900</code>. Of course, by
                nature of floating-point values, your program will likely work
                with inputs like <code className="highlighter-rouge">9.0</code>{" "}
                and <code className="highlighter-rouge">9.000</code> as well;
                you need not worry about checking whether the user’s input is
                “formatted” like money should be.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            If the user fails to provide a non-negative value, your program
            should re-prompt the user for a valid amount again and again until
            the user complies.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Incidentally, so that we can automate some tests of your code, we
            ask that your program’s last line of output be only the minimum
            number of coins possible: an integer followed by a newline.
          </li>
        </ul>

        <a data-id="" id="usage"></a>
        <h2>
          <a data-id="" href="#usage">
            Usage
          </a>
        </h2>

        <p>Your program should behave per the example below.</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                $ python cash.py
                <br />
                Change owed: 0.41
                <br />
                4
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="testing"></a>
        <h2>
          <a data-id="" href="#testing">
            Workthrough
          </a>
        </h2>

        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
        >
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={t("pset6_cash")}
            scrolling="no"
            style={{
              width: "100%",
              height: "700px",
            }}
          ></iframe>
        </div>
        <a data-id="" id="testing"></a>
        <h2>
          <a data-id="" href="#testing">
            Testing
          </a>
        </h2>

        <p>
          No <code className="highlighter-rouge">check50</code> for this
          problem, but be sure to test your code for each of the following.
        </p>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python cash.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">0.41</code> and press enter.
            Your program should output{" "}
            <code className="highlighter-rouge">4</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python cash.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">0.01</code> and press enter.
            Your program should output{" "}
            <code className="highlighter-rouge">1</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python cash.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">0.15</code> and press enter.
            Your program should output{" "}
            <code className="highlighter-rouge">2</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python cash.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">1.60</code> and press enter.
            Your program should output{" "}
            <code className="highlighter-rouge">7</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python cash.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">23</code> and press enter. Your
            program should output <code className="highlighter-rouge">92</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python cash.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">4.2</code> and press enter. Your
            program should output <code className="highlighter-rouge">18</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python cash.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">-1</code> and press enter. Your
            program should reject this input as invalid, as by re-prompting the
            user to type in another number.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python cash.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">foo</code> and press enter. Your
            program should reject this input as invalid, as by re-prompting the
            user to type in another number.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python cash.py</code>, and wait
            for a prompt for input. Do not type anything, and press enter. Your
            program should reject this input as invalid, as by re-prompting the
            user to type in another number.
          </li>
        </ul>

        <a data-id="" id="how-to-submit"></a>
        <h2>
          <a data-id="" href="#how-to-submit">
            How to Submit
          </a>
        </h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2020/x/sentimental/cash</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Cash;
