import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function Final() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md">
        <h1 id="final-project"> Final Project </h1>

        <p>
          The climax of this course is its final project. The final project is
          your opportunity to take your newfound savvy with programming out for
          a spin and develop your very own piece of software. So long as your
          project draws upon this course’s lessons, the nature of your project
          is entirely up to you. You may implement your project in any
          language(s). You are welcome to utilize infrastructure other than the
          CS50 IDE. All that we ask is that you build something of interest to
          you, that you solve an actual problem, that you impact your community,
          or that you change the world. Strive to create something that outlives
          this course.
        </p>

        <p>
          Inasmuch as software development is rarely a one-person effort, you
          are allowed an opportunity to collaborate with one or two classmates
          for this final project. Needless to say, it is expected that every
          student in any such group contribute equally to the design and
          implementation of that group’s project. Moreover, it is expected that
          the scope of a two- or three-person group’s project be, respectively,
          twice or thrice that of a typical one-person project. A one-person
          project, mind you, should entail more time and effort than is required
          by each of the course’s problem sets.
        </p>

        <h2 id="when-to-do-it">When to Do It</h2>

        <p>By 31 December 2020 at 23:59 Eastern Standard Time.</p>

        <div className="alert" data-alert="warning" role="alert">
          <p>
            Though CS50x’s deadline has already been extended to 31 December
            2021, <em>this version</em> of this project will only be accepted
            until the date and time above. Thereafter, you may only submit work
            from CS50x 2021, which will be released on 1 January 2021.{" "}
            <a href="https://cs50.harvard.edu/x/2020/faqs/#i-wont-be-able-to-finish-the-course-before-31-december-2020-what-will-happen">
              Not sure you’ll finish the course by then
            </a>
            ?
          </p>
        </div>

        <h2 id="ideas">Ideas</h2>

        <ul>
          <li data-marker="*">
            a web-based application using JavaScript, Python, and SQL, based in
            part on the web track’s distribution code
          </li>
          <li data-marker="*">an iOS app using Swift</li>
          <li data-marker="*">a game using Lua with LÖVE</li>
          <li data-marker="*">an Android app using Java</li>
          <li data-marker="*">a Chrome extension using JavaScript</li>
          <li data-marker="*">a command-line program using C</li>
          <li data-marker="*">
            a hardware-based application for which you program some device
          </li>
          <li data-marker="*">…</li>
        </ul>

        <h3 id="how-to-submit">How to Submit</h3>

        <h4 id="step-1-of-2">Step 1 of 2</h4>

        <p>
          Create a{" "}
          <code className="language-plaintext highlighter-rouge">
            README.md
          </code>{" "}
          text file that explains your project and save it in a new folder
          called{" "}
          <code className="language-plaintext highlighter-rouge">project</code>{" "}
          in your{" "}
          <code className="language-plaintext highlighter-rouge">~/</code>{" "}
          directory. Note that your project source code itself does not need to
          be submitted, but this{" "}
          <code className="language-plaintext highlighter-rouge">
            README.md
          </code>{" "}
          file must.
        </p>

        <p>
          Execute the below from within your{" "}
          <code className="language-plaintext highlighter-rouge">
            ~/project
          </code>{" "}
          directory, logging in with your GitHub username and password when
          prompted. For security, you’ll see asterisks instead of the actual
          characters in your password.
        </p>

        <div className="language-plaintext highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2020/x/project</code>
            </pre>
          </div>
        </div>

        <h4 id="step-2-of-2">Step 2 of 2</h4>

        <p>
          Submit a short video (that’s no more than 2 minutes in length) in
          which you present your project to the world, as with slides,
          screenshots, voiceover, and/or live action. Your video should somehow
          include your project’s title, your name, your city and country, and
          any other details that you’d like to convey to viewers. See{" "}
          <a href="https://www.howtogeek.com/205742/how-to-record-your-windows-mac-linux-android-or-ios-screen/">
            howtogeek.com/205742/how-to-record-your-windows-mac-linux-android-or-ios-screen
          </a>{" "}
          for tips on how to make a “screencast,” though you’re welcome to use
          an actual camera. Upload your video to YouTube (or, if blocked in your
          country, a similar site) and take note of its URL; it’s fine to flag
          it as “unlisted,” but don’t flag it as “private.”
        </p>

        <p>
          When ready to submit your video, submit{" "}
          <a href="https://forms.cs50.io/9f20d498-c446-4d76-ab3c-8737d479016a">
            this form
          </a>
          !
        </p>

        <p>
          That’s it! Your project should be graded within a few minutes. If you
          don’t see any results in your gradebook, best to resubmit (running the
          above{" "}
          <code className="language-plaintext highlighter-rouge">submit50</code>{" "}
          command) with only your README.md file this time. No need to resubmit
          your form.
        </p>

        <p>This was CS50x.</p>
      </main>
    </React.Fragment>
  );
}

export default Final;
