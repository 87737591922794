import React, { useState } from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";

function Week0() {
  const { t } = useTranslation();
  const [video, setVideo] = useState(
    "https://www.youtube.com/embed/EaOhKg5pKV8"
  );

  return (
    <React.Fragment>
      <h1 id="week-9">{t("week")} 9</h1>

      <main className="col-md">
        <div
          className="border embed-responsive embed-responsive-16by9"
          style={{ maxWidth: "720px" }}
        >
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={video}
          ></iframe>
        </div>
        <ul className="mt-5">
          <li>
            {t("Lecture")}
            <ul>
              <li>
                <Link to="note9">Notes</Link>
              </li>
              <li>
                {t("Slides")}
                <ul>
                  <li>
                    <a href="https://docs.google.com/presentation/d/1E3N-8puMqS2545GR4E7pfo4nAKPx36dwDDvOVv6oGAc/edit?usp=sharing">
                      Google Slides
                    </a>
                  </li>
                  <li>
                    <a href="https://cdn.cs50.net/2020/fall/lectures/9/lecture9.pdf">
                      PDF
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                {t("Source-Code")}
                <ul>
                  <li>
                    <a href="https://cdn.cs50.net/2020/fall/lectures/9/src9/">
                      Index
                    </a>
                  </li>
                  <li>
                    <a href="https://cdn.cs50.net/2020/fall/lectures/9/src9.pdf">
                      PDF
                    </a>
                  </li>
                  <li>
                    <a href="https://cdn.cs50.net/2020/fall/lectures/9/src9.zip">
                      Zip
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Video
                <ul>
                  <li>
                    <a href="https://youtube.com/watch?v=EaOhKg5pKV8&amp;start=868">
                      YouTube
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Shorts
            <ol>
              <li>
                <a href="https://video.cs50.io/dQcBs4S-wEQ">AJAX</a>
              </li>
              <li>
                <a href="https://youtu.be/GhB6Q7KC-SM">Flask</a>
              </li>
              <li>
                <a href="https://www.youtube.com/watch?v=zdH1PnWxSpA&feature=emb_logo">
                  Database
                </a>
              </li>
            </ol>
          </li>
          <li>
            <Link to="pset9">Problem Set 9</Link>
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Week0;
