import React, { useState } from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";
import Sidenav from "./../../components/Sidenav";
import "./../weeks.css";

function Week3() {
  const { t } = useTranslation();
  const [video, setVideo] = useState(t("Lec3"));

  return (
    <React.Fragment>
      <Sidenav />
      <div className="center-week container">
        <h1 id="week-3">{t("week")} 3</h1>

        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
          style={{ maxWidth: "720px" }}
        >
          <Iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={video}
          ></Iframe>
        </div>

        <ul className="mt-5">
          <li data-marker="-">
            {t("Lecture")}
            <ul>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/3/lecture3.mp3">
                  Audio
                </a>
              </li>
              <li data-marker="*">
                <Link to="note3">{t("Notes")}</Link>
              </li>
              <li data-marker="+">
                {t("Slides")}
                <ul>
                  <li data-marker="*">
                    <a href="https://docs.google.com/presentation/d/1pHf8lId1tvO5tSHs9NJYN2H_YhvGdoLakyOFq4XQq7s/edit?usp=sharing">
                      {t("Google-slides")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/3/lecture3.pdf">
                      PDF
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="+">
                {t("Source-Code")}
                <ul>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/3/src3/">
                      {t("Index")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/3/src3.pdf">
                      PDF
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/3/src3.zip">
                      Zip
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/3/lang/en/lecture3.srt">
                  Subtitles
                </a>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/3/lang/en/lecture3.txt">
                  Transcript
                </a>
              </li>
              <li data-marker="+">
                {t("Video")}
                <ul>
                  <li data-marker="*">
                    <a href="https://video.cs50.io/gR6nycuZKlM?screen=CRl1MoW0F-E">
                      CS50 Video Player
                    </a>
                  </li>
                  <li data-marker="+">
                    MP4
                    <ul>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/3/lecture3-360p.mp4">
                          360p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/3/lecture3-720p.mp4">
                          720p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/3/lecture3-1080p.mp4">
                          1080p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/3/lecture3-4k.mp4">
                          4K
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li data-marker="*">
                    <a href="https://youtu.be/gR6nycuZKlM">YouTube</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-marker="-">
            {t("Short")}
            <ol>
              <li>
                <a href="https://youtu.be/TwsgCHYmbbA">Linear Search</a>
              </li>
              <li>
                <a href="https://youtu.be/T98PIp4omUA">Binary Search</a>
              </li>
              <li>
                <a href="https://youtu.be/RT-hUXUWQ2I">Bubble Sort</a>
              </li>
              <li>
                <a href="https://youtu.be/3hH8kTHFw2A">Selection Sort</a>
              </li>
              <li>
                <a href="https://youtu.be/mz6tAJMVmfM">Recursion</a>
              </li>
              <li>
                <a href="https://youtu.be/Ns7tGNbtvV4">Merge Sort</a>
              </li>
            </ol>
          </li>
          <li data-marker="*">
            <a href="https://cs50.harvard.edu/x/2021/labs/3/">Lab 3</a>
          </li>
          <li data-marker="*">
            <Link to="pset3">{t("Problem")} 3</Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default Week3;
