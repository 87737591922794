import React from "react";
import spreadsheet from "./img/spreadsheet.png";
import shows_table_genres_table from "./img/shows_table_genres_table.png";
import imdb_tables from "./img/imdb_tables.png";

function Note7() {
  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 className="no_toc" id="lecture-7">
          Lecture 7
        </h1>

        <ul id="markdown-toc">
          <li>
            <a href="#spreadsheets" id="markdown-toc-spreadsheets">
              Spreadsheets
            </a>
          </li>
          <li>
            <a href="#sql" id="markdown-toc-sql">
              SQL
            </a>
          </li>
          <li>
            <a href="#imdb" id="markdown-toc-imdb">
              IMDb
            </a>
          </li>
          <li>
            <a href="#multiple-tables" id="markdown-toc-multiple-tables">
              Multiple tables
            </a>
          </li>
          <li>
            <a href="#problems" id="markdown-toc-problems">
              Problems
            </a>
          </li>
        </ul>

        <h2 id="spreadsheets">Spreadsheets</h2>

        <ul>
          <li>
            Most of us are familiar with spreadsheets, rows of data, with each
            column in a row having a different piece of data that relate to each
            other somehow.
          </li>
          <li>
            A database is an application that can store data, and we can think
            of Google Sheets as one such application.
          </li>
          <li>
            For example, we created a Google Form to ask students their favorite
            TV show and genre of it. We look thorugh the responses, and see that
            the spreadsheet has three columns: “Timestamp”, “title”, and
            “genres”:
            <br />
            <img
              src={spreadsheet}
              alt='image of Google Sheets spreadsheet with row 1 having cells "Timestamp", "title", and "genres", with row 2 having cells "10/28/2019 15:03:45", "Dynasty", "Drama, Family", and so on'
            />
          </li>
          <li>
            We can download a CSV file from the spreadsheet with “File &gt;
            Download”, upload it to our IDE, and see that it’s a text file with
            comma-separated values matching the spreadsheet’s data.
          </li>
          <li>
            We’ll write <code className="highlighter-rouge">favorites.py</code>:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">csv</span>
                    <span className="k">with</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">
                      "CS50 2019 - Lecture 7 - Favorite TV Shows (Responses) -
                      Form Responses 1.csv"
                    </span>
                    <span className="p">,</span> <span className="s">"r"</span>
                    <span className="p">)</span> <span className="k">as</span>{" "}
                    <span className="nb">file</span>
                    <span className="p">:</span>
                    <span className="n">reader</span>{" "}
                    <span className="o">=</span> <span className="n">csv</span>
                    <span className="o">.</span>
                    <span className="n">DictReader</span>
                    <span className="p">(</span>
                    <span className="nb">file</span>
                    <span className="p">)</span>
                    <span className="k">for</span>{" "}
                    <span className="n">row</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">reader</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"title"</span>
                    <span className="p">])</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We’re just going to open the file and make sure we can get the
                title of each row.
              </li>
            </ul>
          </li>
          <li>
            Now we can use a dictionary to count the number of times we’ve seen
            each title, with the keys being the titles and the values for each
            key an integer, tracking how many times we’ve seen that title:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">csv</span>
                    <span className="n">counts</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="p">{"{}"}</span>
                    <span className="k">with</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">
                      "CS50 2019 - Lecture 7 - Favorite TV Shows (Responses) -
                      Form Responses 1.csv"
                    </span>
                    <span className="p">,</span> <span className="s">"r"</span>
                    <span className="p">)</span> <span className="k">as</span>{" "}
                    <span className="nb">file</span>
                    <span className="p">:</span>
                    <span className="n">reader</span>{" "}
                    <span className="o">=</span> <span className="n">csv</span>
                    <span className="o">.</span>
                    <span className="n">DictReader</span>
                    <span className="p">(</span>
                    <span className="nb">file</span>
                    <span className="p">)</span>
                    <span className="k">for</span>{" "}
                    <span className="n">row</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">reader</span>
                    <span className="p">:</span>
                    <span className="n">title</span>{" "}
                    <span className="o">=</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"title"</span>
                    <span className="p">]</span>
                    <span className="k">if</span>{" "}
                    <span className="n">title</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">counts</span>
                    <span className="p">:</span>
                    <span className="n">counts</span>
                    <span className="p">[</span>
                    <span className="n">title</span>
                    <span className="p">]</span> <span className="o">+=</span>{" "}
                    <span className="mi">1</span>
                    <span className="k">else</span>
                    <span className="p">:</span>
                    <span className="n">counts</span>
                    <span className="p">[</span>
                    <span className="n">title</span>
                    <span className="p">]</span> <span className="o">=</span>{" "}
                    <span className="mi">1</span>
                    <span className="k">for</span>{" "}
                    <span className="n">title</span>
                    <span className="p">,</span>{" "}
                    <span className="n">count</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">counts</span>
                    <span className="o">.</span>
                    <span className="n">items</span>
                    <span className="p">():</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">title</span>
                    <span className="p">,</span>{" "}
                    <span className="n">count</span>
                    <span className="p">,</span> <span className="n">sep</span>
                    <span className="o">=</span>
                    <span className="s">" | "</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                In each row, we can get the{" "}
                <code className="highlighter-rouge">title</code> with{" "}
                <code className="highlighter-rouge">row["title"]</code>.
              </li>
              <li>
                Here, if we’ve seen the title before (it’s in{" "}
                <code className="highlighter-rouge">counts</code>), we can just
                add 1 to the value. Otherwise, we need to set the initial value
                to 1.
              </li>
              <li>
                Finally, we can print out our dictionary’s keys and values with
                a separator so it’s a bit easier to read.
              </li>
            </ul>
          </li>
          <li>
            We can change the way we iterate to{" "}
            <code className="highlighter-rouge">
              for title, count in sorted(counts.items()):
            </code>
            , and we’ll see our dictionary sorted by the keys, alphabetically.
          </li>
          <li>
            But we can sort by the key-value pairs in the dictionary with:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">def</span> <span className="nf">f</span>
                    <span className="p">(</span>
                    <span className="n">item</span>
                    <span className="p">):</span>
                    <span className="k">return</span>{" "}
                    <span className="n">item</span>
                    <span className="p">[</span>
                    <span className="mi">1</span>
                    <span className="p">]</span>
                    <span className="k">for</span>{" "}
                    <span className="n">title</span>
                    <span className="p">,</span>{" "}
                    <span className="n">count</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">sorted</span>
                    <span className="p">(</span>
                    <span className="n">counts</span>
                    <span className="o">.</span>
                    <span className="n">items</span>
                    <span className="p">(),</span>{" "}
                    <span className="n">key</span>
                    <span className="o">=</span>
                    <span className="n">f</span>
                    <span className="p">,</span>{" "}
                    <span className="n">reverse</span>
                    <span className="o">=</span>
                    <span className="bp">True</span>
                    <span className="p">):</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We define a function,{" "}
                <code className="highlighter-rouge">f</code>, which just returns
                the value from the{" "}
                <code className="highlighter-rouge">item</code> in the
                dictionary with{" "}
                <code className="highlighter-rouge">item[1]</code>. The{" "}
                <code className="highlighter-rouge">sorted</code> function, in
                turn, can use that as the key to sort the dictionary’s items.
                And we’ll also pass in{" "}
                <code className="highlighter-rouge">reverse=True</code> to sort
                from largest to smallest, instead of smallest to largest.
              </li>
            </ul>
          </li>
          <li>
            We can actually define our function in the same line, with this
            syntax:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">for</span>{" "}
                    <span className="n">title</span>
                    <span className="p">,</span>{" "}
                    <span className="n">count</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">sorted</span>
                    <span className="p">(</span>
                    <span className="n">counts</span>
                    <span className="o">.</span>
                    <span className="n">items</span>
                    <span className="p">(),</span>{" "}
                    <span className="n">key</span>
                    <span className="o">=</span>
                    <span className="k">lambda</span>{" "}
                    <span className="n">item</span>
                    <span className="p">:</span> <span className="n">item</span>
                    <span className="p">[</span>
                    <span className="mi">1</span>
                    <span className="p">],</span>{" "}
                    <span className="n">reverse</span>
                    <span className="o">=</span>
                    <span className="bp">True</span>
                    <span className="p">):</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We pass in a lambda, or anonymous function, as the key, which
                takes in the <code className="highlighter-rouge">item</code> and
                returns <code className="highlighter-rouge">item[1]</code>.
              </li>
            </ul>
          </li>
          <li>
            Finally, we can make all the titles lowercase with{" "}
            <code className="highlighter-rouge">
              title = row["title"].lower()
            </code>
            , so our counts can be a little more accurate even if the names
            weren’t typed in the exact same way.
          </li>
        </ul>

        <h2 id="sql">SQL</h2>

        <ul>
          <li>
            We’ll look at a new program in our terminal window,{" "}
            <code className="highlighter-rouge">sqlite3</code>, a command-line
            program that lets us use another language, SQL (pronounced like
            “sequel”).
          </li>
          <li>
            We’ll run some commands to create a new database called{" "}
            <code className="highlighter-rouge">favorites.db</code> and import
            our CSV file into a table called “favorites”:
            <div className="highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    ~/ $ sqlite3 favorites.db SQLite version 3.22.0 2018-01-22
                    18:45:57 Enter ".help" for usage hints. sqlite&gt; .mode csv
                    sqlite&gt; .import "CS50 2019 - Lecture 7 - Favorite TV
                    Shows (Responses) - Form Responses 1.csv" favorites
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We see a <code className="highlighter-rouge">favorites.db</code> in
            our IDE after we run this, and now we can use SQL to interact with
            our data:
            <div className="highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    sqlite&gt; SELECT title FROM favorites; title Dynasty The
                    Office Blindspot 24 Friends psych Veep Survivor ...
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can even sort our results:
            <div className="highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    sqlite&gt; SELECT title FROM favorites ORDER BY title; title
                    / 24 9009 Adventure Time Airplane Repo Always Sunny Ancient
                    Aliens ...
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            And get a count of the number of times each title appears:
            <div className="highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    sqlite&gt; SELECT title, COUNT(title) FROM favorites GROUP
                    BY title; title | COUNT(title) / | 1 24 | 1 9009 | 1
                    Adventure Time | 1 Airplane Repo | 1 Always Sunny | 1
                    Ancient Aliens | 1 ...
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can even set the count of each title to a new variable,{" "}
            <code className="highlighter-rouge">n</code>, and order our results
            by that, in descending order. Then we can see the top 10 results
            with <code className="highlighter-rouge">LIMIT 10</code>:
            <div className="highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    sqlite&gt; SELECT title, COUNT(title) AS n FROM favorites
                    GROUP BY title ORDER BY n DESC LIMIT 10; title | n The
                    Office | 30 Friends | 20 Game of Thrones | 20 Breaking Bad |
                    14 Black Mirror | 9 Rick and Morty | 9 Brooklyn Nine-Nine |
                    5 Game of thrones | 5 No | 5 Prison Break | 5
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            SQL is a language that lets us work with a relational database, an
            application lets us store data and work with them more quickly than
            with a CSV.
          </li>
          <li>
            With <code className="highlighter-rouge">.schema</code>, we can see
            how the format for the table for our data is created:
            <div className="highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    sqlite&gt; .schema CREATE TABLE favorites( "Timestamp" TEXT,
                    "title" TEXT, "genres" TEXT );
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            It turns out that, when working with data, we only need four
            operations:
            <ul>
              <li>
                <code className="highlighter-rouge">CREATE</code>
              </li>
              <li>
                <code className="highlighter-rouge">READ</code>
              </li>
              <li>
                <code className="highlighter-rouge">UPDATE</code>
              </li>
              <li>
                <code className="highlighter-rouge">DELETE</code>
              </li>
            </ul>
          </li>
          <li>
            In SQL, the commands to perform each of these operations are:
            <ul>
              <li>
                <code className="highlighter-rouge">INSERT</code>
              </li>
              <li>
                <code className="highlighter-rouge">SELECT</code>
              </li>
              <li>
                <code className="highlighter-rouge">UPDATE</code>
              </li>
              <li>
                <code className="highlighter-rouge">DELETE</code>
              </li>
            </ul>
          </li>
          <li>
            First, we’ll need to insert a table with the{" "}
            <code className="highlighter-rouge">
              CREATE TABLE table (column type, ...);
            </code>{" "}
            command.
          </li>
          <li>
            SQL, too, has its own data types to optimize the amount of space
            used for storing data:
            <ul>
              <li>
                <code className="highlighter-rouge">BLOB</code>, for “binary
                large object”, raw binary data that might represent files
              </li>
              <li>
                <code className="highlighter-rouge">INTEGER</code>
                <ul>
                  <li>
                    <code className="highlighter-rouge">smallint</code>
                  </li>
                  <li>
                    <code className="highlighter-rouge">integer</code>
                  </li>
                  <li>
                    <code className="highlighter-rouge">bigint</code>
                  </li>
                </ul>
              </li>
              <li>
                <code className="highlighter-rouge">NUMERIC</code>
                <ul>
                  <li>
                    <code className="highlighter-rouge">boolean</code>
                  </li>
                  <li>
                    <code className="highlighter-rouge">date</code>
                  </li>
                  <li>
                    <code className="highlighter-rouge">datetime</code>
                  </li>
                  <li>
                    <code className="highlighter-rouge">
                      numeric(scale,precision)
                    </code>
                    , which solves floating-point imprecision by using as many
                    bits as needed, for each digit before and after the decimal
                    point
                  </li>
                  <li>
                    <code className="highlighter-rouge">time</code>
                  </li>
                  <li>
                    <code className="highlighter-rouge">timestamp</code>
                  </li>
                </ul>
              </li>
              <li>
                <code className="highlighter-rouge">REAL</code>
                <ul>
                  <li>
                    <code className="highlighter-rouge">real</code>, for
                    floating-point values
                  </li>
                  <li>
                    <code className="highlighter-rouge">double precision</code>,
                    with more bits
                  </li>
                </ul>
              </li>
              <li>
                <code className="highlighter-rouge">TEXT</code>
                <ul>
                  <li>
                    <code className="highlighter-rouge">char(n)</code>, for an
                    exact number of characters
                  </li>
                  <li>
                    <code className="highlighter-rouge">varchar(n)</code>, for a
                    variable number of characters, up to a certain limit
                  </li>
                  <li>
                    <code className="highlighter-rouge">text</code>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            SQLite is one database application that supports SQL, and there are
            many companies with server applications that support SQL, includes
            Oracle Database, MySQL, PostgreSQL, MariaDB, and Microsoft Access.
          </li>
          <li>
            After inserting values, we can use functions to perform
            calculations, too:
            <ul>
              <li>
                <code className="highlighter-rouge">AVG</code>
              </li>
              <li>
                <code className="highlighter-rouge">COUNT</code>
              </li>
              <li>
                <code className="highlighter-rouge">DISTINCT</code>, for getting
                distinct values without duplicates
              </li>
              <li>
                <code className="highlighter-rouge">MAX</code>
              </li>
              <li>
                <code className="highlighter-rouge">MIN</code>
              </li>
              <li>…</li>
            </ul>
          </li>
          <li>
            There are also other operations we can combine as needed:
            <ul>
              <li>
                <code className="highlighter-rouge">WHERE</code>, matching on
                some strict condition
              </li>
              <li>
                <code className="highlighter-rouge">LIKE</code>, matching on
                substrings for text
              </li>
              <li>
                <code className="highlighter-rouge">LIMIT</code>
              </li>
              <li>
                <code className="highlighter-rouge">GROUP BY</code>
              </li>
              <li>
                <code className="highlighter-rouge">ORDER BY</code>
              </li>
              <li>
                <code className="highlighter-rouge">JOIN</code>, combining data
                from multiple tables
              </li>
            </ul>
          </li>
          <li>
            We can update data with{" "}
            <code className="highlighter-rouge">
              UPDATE table SET column=value WHERE condition;
            </code>
            , which could include 0, 1, or more rows depending on our condition.
            For example, we might say{" "}
            <code className="highlighter-rouge">
              UPDATE favorites SET title = "The Office" WHERE title LIKE
              "%office"
            </code>
            , and that will set all the rows with the title containing “office”
            to be “The Office” so we can make them consistent.
          </li>
          <li>
            And we can remove matching rows with{" "}
            <code className="highlighter-rouge">
              DELETE FROM table WHERE condition;
            </code>
            , as in{" "}
            <code className="highlighter-rouge">
              DELETE FROM favorites WHERE title = "Friends";
            </code>
            .
          </li>
          <li>
            We can even delete an entire table altogether with another command,{" "}
            <code className="highlighter-rouge">DROP</code>.
          </li>
        </ul>

        <h2 id="imdb">IMDb</h2>

        <ul>
          <li>
            IMDb, or “Internet Movie Database”, has datasets{" "}
            <a href="https://www.imdb.com/interfaces/">available to download</a>{" "}
            as TSV, or tab-separate values, files.
          </li>
          <li>
            For example, we can download{" "}
            <code className="highlighter-rouge">title.basics.tsv.gz</code>,
            which will contain basic data about titles:
            <ul>
              <li>
                <code className="highlighter-rouge">tconst</code>, a unique
                identifier for each title, like{" "}
                <code className="highlighter-rouge">tt4786824</code>
              </li>
              <li>
                <code className="highlighter-rouge">titleType</code>, the type
                of the title, like{" "}
                <code className="highlighter-rouge">tvSeries</code>
              </li>
              <li>
                <code className="highlighter-rouge">primaryTitle</code>, the
                main title used, like{" "}
                <code className="highlighter-rouge">The Crown</code>
              </li>
              <li>
                <code className="highlighter-rouge">startYear</code>, the year a
                title was released, like{" "}
                <code className="highlighter-rouge">2016</code>
              </li>
              <li>
                <code className="highlighter-rouge">genres</code>, a
                comma-separated list of genres, like{" "}
                <code className="highlighter-rouge">Drama,History</code>
              </li>
            </ul>
          </li>
          <li>
            We take a look at{" "}
            <code className="highlighter-rouge">title.basics.tsv</code> after
            we’ve unzipped it, and we see that the first rows are indeed the
            headers we expected and each row has values separated by tabs. But
            the file has more than 6 million rows, so even searching for one
            value takes a moment.
          </li>
          <li>
            We’ll download the file into our IDE with{" "}
            <code className="highlighter-rouge">wget</code>, and then{" "}
            <code className="highlighter-rouge">gunzip</code> to unzip it. But
            our IDE doesn’t have enough space, so we’ll use our Mac’s terminal
            instead.
          </li>
          <li>
            We’ll write <code className="highlighter-rouge">import.py</code> to
            read the file in:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">csv</span>
                    <span className="c1"># Open TSV file for reading</span>
                    <span className="k">with</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">"title.basics.tsv"</span>
                    <span className="p">,</span> <span className="s">"r"</span>
                    <span className="p">)</span> <span className="k">as</span>{" "}
                    <span className="n">titles</span>
                    <span className="p">:</span>
                    <span className="c1">
                      # Since the file is a TSV file, we can use the CSV reader
                      and change
                    </span>{" "}
                    <span className="c1"># the separator to a tab.</span>{" "}
                    <span className="n">reader</span>{" "}
                    <span className="o">=</span> <span className="n">csv</span>
                    <span className="o">.</span>
                    <span className="n">DictReader</span>
                    <span className="p">(</span>
                    <span className="n">titles</span>
                    <span className="p">,</span>{" "}
                    <span className="n">delimiter</span>
                    <span className="o">=</span>
                    <span className="s">"</span>
                    <span className="se">\t</span>
                    <span className="s">"</span>
                    <span className="p">)</span>
                    <span className="c1">
                      # Open new CSV file for writing
                    </span>{" "}
                    <span className="k">with</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">"shows0.csv"</span>
                    <span className="p">,</span> <span className="s">"w"</span>
                    <span className="p">)</span> <span className="k">as</span>{" "}
                    <span className="n">shows</span>
                    <span className="p">:</span>
                    <span className="c1"># Create writer</span>{" "}
                    <span className="n">writer</span>{" "}
                    <span className="o">=</span> <span className="n">csv</span>
                    <span className="o">.</span>
                    <span className="n">writer</span>
                    <span className="p">(</span>
                    <span className="n">shows</span>
                    <span className="p">)</span>
                    <span className="c1">
                      # Write header of the columns we want
                    </span>{" "}
                    <span className="n">writer</span>
                    <span className="o">.</span>
                    <span className="n">writerow</span>
                    <span className="p">([</span>
                    <span className="s">"tconst"</span>
                    <span className="p">,</span>{" "}
                    <span className="s">"primaryTitle"</span>
                    <span className="p">,</span>{" "}
                    <span className="s">"startYear"</span>
                    <span className="p">,</span>{" "}
                    <span className="s">"genres"</span>
                    <span className="p">])</span>
                    <span className="c1"># Iterate over TSV file</span>{" "}
                    <span className="k">for</span>{" "}
                    <span className="n">row</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">reader</span>
                    <span className="p">:</span>
                    <span className="c1"># If non-adult TV show</span>{" "}
                    <span className="k">if</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"titleType"</span>
                    <span className="p">]</span> <span className="o">==</span>{" "}
                    <span className="s">"tvSeries"</span>{" "}
                    <span className="ow">and</span>{" "}
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"isAdult"</span>
                    <span className="p">]</span> <span className="o">==</span>{" "}
                    <span className="s">"0"</span>
                    <span className="p">:</span>
                    <span className="c1"># Write row</span>{" "}
                    <span className="n">writer</span>
                    <span className="o">.</span>
                    <span className="n">writerow</span>
                    <span className="p">([</span>
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"tconst"</span>
                    <span className="p">],</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"primaryTitle"</span>
                    <span className="p">],</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"startYear"</span>
                    <span className="p">],</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"genres"</span>
                    <span className="p">]])</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            Now, we can open{" "}
            <code className="highlighter-rouge">shows0.csv</code> and see a
            smaller set of data. But it turns out, for some of the rows,{" "}
            <code className="highlighter-rouge">startYear</code> has a value of{" "}
            <code className="highlighter-rouge">\N</code>, and that’s a special
            value from IMDb when they want to represent values that are missing.
            So we can filter out those values and convert the{" "}
            <code className="highlighter-rouge">startYear</code> to an integer
            to filter for shows after 1970:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="o">...</span>
                    <span className="c1">
                      # If year not missing (We need to escape the backslash
                      too)
                    </span>
                    <span className="k">if</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"startYear"</span>
                    <span className="p">]</span> <span className="o">!=</span>{" "}
                    <span className="s">"</span>
                    <span className="se">\\</span>
                    <span className="s">N"</span>
                    <span className="p">:</span>
                    <span className="c1"># If since 1970</span>{" "}
                    <span className="k">if</span>{" "}
                    <span className="nb">int</span>
                    <span className="p">(</span>
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"startYear"</span>
                    <span className="p">])</span>{" "}
                    <span className="o">&gt;=</span>{" "}
                    <span className="mi">1970</span>
                    <span className="p">:</span>
                    <span className="c1"># Write row</span>{" "}
                    <span className="n">writer</span>
                    <span className="o">.</span>
                    <span className="n">writerow</span>
                    <span className="p">([</span>
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"tconst"</span>
                    <span className="p">],</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"primaryTitle"</span>
                    <span className="p">],</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"startYear"</span>
                    <span className="p">],</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"genres"</span>
                    <span className="p">]])</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can write a program to search for a particular title:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">csv</span>
                    <span className="c1"># Prompt user for title</span>
                    <span className="n">title</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nb">input</span>
                    <span className="p">(</span>
                    <span className="s">"Title: "</span>
                    <span className="p">)</span>
                    <span className="c1"># Open CSV file</span>
                    <span className="k">with</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">"shows2.csv"</span>
                    <span className="p">,</span> <span className="s">"r"</span>
                    <span className="p">)</span> <span className="k">as</span>{" "}
                    <span className="nb">input</span>
                    <span className="p">:</span>
                    <span className="c1"># Create DictReader</span>{" "}
                    <span className="n">reader</span>{" "}
                    <span className="o">=</span> <span className="n">csv</span>
                    <span className="o">.</span>
                    <span className="n">DictReader</span>
                    <span className="p">(</span>
                    <span className="nb">input</span>
                    <span className="p">)</span>
                    <span className="c1"># Iterate over CSV file</span>{" "}
                    <span className="k">for</span>{" "}
                    <span className="n">row</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">reader</span>
                    <span className="p">:</span>
                    <span className="c1"># Search for title</span>{" "}
                    <span className="k">if</span>{" "}
                    <span className="n">title</span>
                    <span className="o">.</span>
                    <span className="n">lower</span>
                    <span className="p">()</span> <span className="o">==</span>{" "}
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"primaryTitle"</span>
                    <span className="p">]</span>
                    <span className="o">.</span>
                    <span className="n">lower</span>
                    <span className="p">():</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"primaryTitle"</span>
                    <span className="p">],</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"startYear"</span>
                    <span className="p">],</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"genres"</span>
                    <span className="p">],</span> <span className="n">sep</span>
                    <span className="o">=</span>
                    <span className="s">" | "</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We can run this program and see our results, but we can see how
                SQL can do a better job.
              </li>
            </ul>
          </li>
          <li>
            In Python, we can connect to a SQL database and read our file into
            it once, so we can make lots of queries without writing new programs
            and without having to read the entire file each time.
          </li>
          <li>
            Let’s do this more easily with the CS50 library:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">cs50</span>
                    <span className="kn">import</span>{" "}
                    <span className="nn">csv</span>
                    <span className="c1">
                      # Create database by opening and closing an empty file
                      first
                    </span>
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">"shows3.db"</span>
                    <span className="p">,</span> <span className="s">"w"</span>
                    <span className="p">)</span>
                    <span className="o">.</span>
                    <span className="n">close</span>
                    <span className="p">()</span>
                    <span className="n">db</span> <span className="o">=</span>{" "}
                    <span className="n">cs50</span>
                    <span className="o">.</span>
                    <span className="n">SQL</span>
                    <span className="p">(</span>
                    <span className="s">"sqlite:///shows3.db"</span>
                    <span className="p">)</span>
                    <span className="c1">
                      # Create table called `shows`, and specify the columns we
                      want, # all of which will be text except `startYear`
                    </span>
                    <span className="n">db</span>
                    <span className="o">.</span>
                    <span className="n">execute</span>
                    <span className="p">(</span>
                    <span className="s">
                      "CREATE TABLE shows (tconst TEXT, primaryTitle TEXT,
                      startYear NUMERIC, genres TEXT)"
                    </span>
                    <span className="p">)</span>
                    <span className="c1">
                      # Open TSV file #
                      https://datasets.imdbws.com/title.basics.tsv.gz
                    </span>
                    <span className="k">with</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">"title.basics.tsv"</span>
                    <span className="p">,</span> <span className="s">"r"</span>
                    <span className="p">)</span> <span className="k">as</span>{" "}
                    <span className="n">titles</span>
                    <span className="p">:</span>
                    <span className="c1"># Create DictReader</span>{" "}
                    <span className="n">reader</span>{" "}
                    <span className="o">=</span> <span className="n">csv</span>
                    <span className="o">.</span>
                    <span className="n">DictReader</span>
                    <span className="p">(</span>
                    <span className="n">titles</span>
                    <span className="p">,</span>{" "}
                    <span className="n">delimiter</span>
                    <span className="o">=</span>
                    <span className="s">"</span>
                    <span className="se">\t</span>
                    <span className="s">"</span>
                    <span className="p">)</span>
                    <span className="c1"># Iterate over TSV file</span>{" "}
                    <span className="k">for</span>{" "}
                    <span className="n">row</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">reader</span>
                    <span className="p">:</span>
                    <span className="c1"># If non-adult TV show</span>{" "}
                    <span className="k">if</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"titleType"</span>
                    <span className="p">]</span> <span className="o">==</span>{" "}
                    <span className="s">"tvSeries"</span>{" "}
                    <span className="ow">and</span>{" "}
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"isAdult"</span>
                    <span className="p">]</span> <span className="o">==</span>{" "}
                    <span className="s">"0"</span>
                    <span className="p">:</span>
                    <span className="c1"># If year not missing</span>{" "}
                    <span className="k">if</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"startYear"</span>
                    <span className="p">]</span> <span className="o">!=</span>{" "}
                    <span className="s">"</span>
                    <span className="se">\\</span>
                    <span className="s">N"</span>
                    <span className="p">:</span>
                    <span className="c1"># If since 1970</span>{" "}
                    <span className="n">startYear</span>{" "}
                    <span className="o">=</span> <span className="nb">int</span>
                    <span className="p">(</span>
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"startYear"</span>
                    <span className="p">])</span>
                    <span className="k">if</span>{" "}
                    <span className="n">startYear</span>{" "}
                    <span className="o">&gt;=</span>{" "}
                    <span className="mi">1970</span>
                    <span className="p">:</span>
                    <span className="c1">
                      # Insert show by substituting values into each ?
                      placeholder
                    </span>{" "}
                    <span className="n">db</span>
                    <span className="o">.</span>
                    <span className="n">execute</span>
                    <span className="p">(</span>
                    <span className="s">
                      "INSERT INTO shows (tconst, primaryTitle, startYear,
                      genres) VALUES(?, ?, ?, ?)"
                    </span>
                    <span className="p">,</span>
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"tconst"</span>
                    <span className="p">],</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"primaryTitle"</span>
                    <span className="p">],</span>{" "}
                    <span className="n">startYear</span>
                    <span className="p">,</span>{" "}
                    <span className="n">genres</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            Now we can run{" "}
            <code className="highlighter-rouge">sqlite3 shows3.db</code> and run
            commands like before, such as{" "}
            <code className="highlighter-rouge">
              SELECT * FROM shows LIMIT 10;
            </code>
            .
          </li>
          <li>
            With{" "}
            <code className="highlighter-rouge">
              SELECT COUNT(*) FROM shows;
            </code>{" "}
            we can see that there are more than 150,000 shows in our table, and
            with{" "}
            <code className="highlighter-rouge">
              SELECT COUNT(*) FROM shows WHERE startYear = 2019;
            </code>
            , we see that there were more than 6000 this year.
          </li>
        </ul>

        <h2 id="multiple-tables">Multiple tables</h2>

        <ul>
          <li>
            But each of the rows will only have one column for genres, and the
            values are multiple genres put together. So we can go back to our
            import program, and add another table:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">cs50</span>
                    <span className="kn">import</span>{" "}
                    <span className="nn">csv</span>
                    <span className="c1"># Create database</span>
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">"shows4.db"</span>
                    <span className="p">,</span> <span className="s">"w"</span>
                    <span className="p">)</span>
                    <span className="o">.</span>
                    <span className="n">close</span>
                    <span className="p">()</span>
                    <span className="n">db</span> <span className="o">=</span>{" "}
                    <span className="n">cs50</span>
                    <span className="o">.</span>
                    <span className="n">SQL</span>
                    <span className="p">(</span>
                    <span className="s">"sqlite:///shows4.db"</span>
                    <span className="p">)</span>
                    <span className="c1"># Create tables</span>
                    <span className="n">db</span>
                    <span className="o">.</span>
                    <span className="n">execute</span>
                    <span className="p">(</span>
                    <span className="s">
                      "CREATE TABLE shows (id INT, title TEXT, year NUMERIC,
                      PRIMARY KEY(id))"
                    </span>
                    <span className="p">)</span>
                    <span className="c1">
                      # The `genres` table will have a column called `show_id`
                      that references # the `shows` table above
                    </span>
                    <span className="n">db</span>
                    <span className="o">.</span>
                    <span className="n">execute</span>
                    <span className="p">(</span>
                    <span className="s">
                      "CREATE TABLE genres (show_id INT, genre TEXT, FOREIGN
                      KEY(show_id) REFERENCES shows(id))"
                    </span>
                    <span className="p">)</span>
                    <span className="c1">
                      # Open TSV file #
                      https://datasets.imdbws.com/title.basics.tsv.gz
                    </span>
                    <span className="k">with</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">"title.basics.tsv"</span>
                    <span className="p">,</span> <span className="s">"r"</span>
                    <span className="p">)</span> <span className="k">as</span>{" "}
                    <span className="n">titles</span>
                    <span className="p">:</span>
                    <span className="c1"># Create DictReader</span>{" "}
                    <span className="n">reader</span>{" "}
                    <span className="o">=</span> <span className="n">csv</span>
                    <span className="o">.</span>
                    <span className="n">DictReader</span>
                    <span className="p">(</span>
                    <span className="n">titles</span>
                    <span className="p">,</span>{" "}
                    <span className="n">delimiter</span>
                    <span className="o">=</span>
                    <span className="s">"</span>
                    <span className="se">\t</span>
                    <span className="s">"</span>
                    <span className="p">)</span>
                    <span className="c1"># Iterate over TSV file</span>{" "}
                    <span className="k">for</span>{" "}
                    <span className="n">row</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">reader</span>
                    <span className="p">:</span>
                    <span className="c1"># If non-adult TV show</span>{" "}
                    <span className="k">if</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"titleType"</span>
                    <span className="p">]</span> <span className="o">==</span>{" "}
                    <span className="s">"tvSeries"</span>{" "}
                    <span className="ow">and</span>{" "}
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"isAdult"</span>
                    <span className="p">]</span> <span className="o">==</span>{" "}
                    <span className="s">"0"</span>
                    <span className="p">:</span>
                    <span className="c1"># If year not missing</span>{" "}
                    <span className="k">if</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"startYear"</span>
                    <span className="p">]</span> <span className="o">!=</span>{" "}
                    <span className="s">"</span>
                    <span className="se">\\</span>
                    <span className="s">N"</span>
                    <span className="p">:</span>
                    <span className="c1"># If since 1970</span>{" "}
                    <span className="n">startYear</span>{" "}
                    <span className="o">=</span> <span className="nb">int</span>
                    <span className="p">(</span>
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"startYear"</span>
                    <span className="p">])</span>
                    <span className="k">if</span>{" "}
                    <span className="n">startYear</span>{" "}
                    <span className="o">&gt;=</span>{" "}
                    <span className="mi">1970</span>
                    <span className="p">:</span>
                    <span className="c1"># Trim prefix from tconst</span>{" "}
                    <span className="nb">id</span> <span className="o">=</span>{" "}
                    <span className="nb">int</span>
                    <span className="p">(</span>
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"tconst"</span>
                    <span className="p">][</span>
                    <span className="mi">2</span>
                    <span className="p">:])</span>
                    <span className="c1"># Insert show</span>{" "}
                    <span className="n">db</span>
                    <span className="o">.</span>
                    <span className="n">execute</span>
                    <span className="p">(</span>
                    <span className="s">
                      "INSERT INTO shows (id, title, year) VALUES(?, ?, ?)"
                    </span>
                    <span className="p">,</span> <span className="nb">id</span>
                    <span className="p">,</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"primaryTitle"</span>
                    <span className="p">],</span>{" "}
                    <span className="n">startYear</span>
                    <span className="p">)</span>
                    <span className="c1"># Insert genres</span>{" "}
                    <span className="k">if</span> <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"genres"</span>
                    <span className="p">]</span> <span className="o">!=</span>{" "}
                    <span className="s">"</span>
                    <span className="se">\\</span>
                    <span className="s">N"</span>
                    <span className="p">:</span>
                    <span className="k">for</span>{" "}
                    <span className="n">genre</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">row</span>
                    <span className="p">[</span>
                    <span className="s">"genres"</span>
                    <span className="p">]</span>
                    <span className="o">.</span>
                    <span className="n">split</span>
                    <span className="p">(</span>
                    <span className="s">","</span>
                    <span className="p">):</span>
                    <span className="n">db</span>
                    <span className="o">.</span>
                    <span className="n">execute</span>
                    <span className="p">(</span>
                    <span className="s">
                      "INSERT INTO genres (show_id, genre) VALUES(?, ?)"
                    </span>
                    <span className="p">,</span> <span className="nb">id</span>
                    <span className="p">,</span>{" "}
                    <span className="n">genre</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                So now our <code className="highlighter-rouge">shows</code>{" "}
                table no longer has a{" "}
                <code className="highlighter-rouge">genres</code> column, but
                instead we have a{" "}
                <code className="highlighter-rouge">genres</code> table with
                each row representing a show and an associated genre. Now, a
                particular show can have multiple genres we can search for, and
                we can get other data about the show from the{" "}
                <code className="highlighter-rouge">shows</code> table given its
                ID.
              </li>
            </ul>
          </li>
          <li>
            In fact, we can combine both tables with{" "}
            <code className="highlighter-rouge">
              SELECT * FROM shows WHERE id IN (SELECT show_id FROM genres WHERE
              genre = "Comedy") AND year = 2019;
            </code>
            . We’re filtering our{" "}
            <code className="highlighter-rouge">shows</code> table by IDs where
            the ID in the <code className="highlighter-rouge">genres</code>{" "}
            table has a value of “Comedy” for the{" "}
            <code className="highlighter-rouge">genre</code> column, and has the
            value of 2019 for the{" "}
            <code className="highlighter-rouge">year</code> column.
          </li>
          <li>
            Our tables look like this:
            <br />
            <img
              src={shows_table_genres_table}
              alt="table labeled shows with entries id, title, and year, and table labeled genres with show_id and genre and arrow from show_id to id"
            />
            <ul>
              <li>
                Since the ID in the{" "}
                <code className="highlighter-rouge">genre</code> table come from
                the <code className="highlighter-rouge">shows</code> table, we
                call it <code className="highlighter-rouge">show_id</code>. And
                the arrow indicates that a single show ID might have many
                matching rows in the{" "}
                <code className="highlighter-rouge">genres</code> table.
              </li>
            </ul>
          </li>
          <li>
            We see that some datasets from IMDb, like{" "}
            <code className="highlighter-rouge">title.principals.tsv</code>,
            have only IDs for certain columns that we’ll have to look up in
            other tables.
          </li>
          <li>
            By reading the descriptions for each table, we can see that all of
            the data can be used to construct these tables:
            <br />
            <img
              src={imdb_tables}
              alt="table labeled people, shows, genres, ratings, stars, writers with arrows indicating IDs between tables"
            />
            <ul>
              <li>
                Notice that, for example, a person’s name could also be copied
                to the <code className="highlighter-rouge">stars</code> or{" "}
                <code className="highlighter-rouge">writers</code> tables, but
                instead only the{" "}
                <code className="highlighter-rouge">person_id</code> is used to
                link to the data in the{" "}
                <code className="highlighter-rouge">people</code> table. This
                way, we only need to update the name in one place if we need to
                make a change.
              </li>
            </ul>
          </li>
          <li>
            We’ll open a database,{" "}
            <code className="highlighter-rouge">shows.db</code>, with these
            tables to look at some more examples.
          </li>
          <li>
            We’ll download a program called{" "}
            <a href="https://sqlitebrowser.org/dl/">DB Browser for SQLite</a>,
            which will have a graphical user interface to browse our tables and
            data. We can use the “Execute SQL” tab to run SQL directly in the
            program, too.
          </li>
          <li>
            We can run{" "}
            <code className="highlighter-rouge">
              SELECT * FROM shows JOIN genres ON show.id = genres.show_id;
            </code>{" "}
            to join two tables by matching IDs in columns we specify. Then we’ll
            get back a wider table, with columns from each of those two tables.
          </li>
          <li>
            We can take a person’s ID and find them in shows with{" "}
            <code className="highlighter-rouge">
              SELECT * FROM stars WHERE person_id = 1122;
            </code>
            , but we can do a query inside our query with{" "}
            <code className="highlighter-rouge">
              SELECT show_id FROM stars WHERE person_id = (SELECT id FROM people
              WHERE name = "Ellen DeGeneres");
            </code>
            .
          </li>
          <li>
            This gives us back the{" "}
            <code className="highlighter-rouge">show_id</code>, so to get the
            show data we can run:{" "}
            <code className="highlighter-rouge">
              SELECT * FROM shows WHERE id IN (...);
            </code>{" "}
            with <code className="highlighter-rouge">...</code> being the query
            above.
          </li>
          <li>
            We can get the same results with:
            <div className="highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    SELECT title FROM people JOIN stars ON people.id =
                    stars.person_id JOIN shows ON stars.show_id = shows.id WHERE
                    name = "Ellen DeGeneres"
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We join the <code className="highlighter-rouge">people</code>{" "}
                table with the <code className="highlighter-rouge">stars</code>{" "}
                table, and then with the{" "}
                <code className="highlighter-rouge">shows</code> table by
                specifying columns that should match between the tables, and
                then selecting just the{" "}
                <code className="highlighter-rouge">title</code> with a filter
                on the name.
              </li>
              <li>
                But now we can select other fields from our combined tables,
                too.
              </li>
            </ul>
          </li>
          <li>
            It turns out that we can specify columns of our tables to be special
            types, such as:
            <ul>
              <li>
                <code className="highlighter-rouge">PRIMARY KEY</code>, used as
                the primary identifier for a row
              </li>
              <li>
                <code className="highlighter-rouge">FOREIGN KEY</code>, which
                points to a row in another table
              </li>
              <li>
                <code className="highlighter-rouge">UNIQUE</code>, which means
                it has to be unique in this table
              </li>
              <li>
                <code className="highlighter-rouge">INDEX</code>, which asks our
                database to create a index to more quickly query based on this
                column. An index is a data structure like a tree, which helps us
                search for values.
              </li>
            </ul>
          </li>
          <li>
            We can create an index with{" "}
            <code className="highlighter-rouge">
              CREATE INDEX person_index ON stars (person_id);
            </code>
            . Then the <code className="highlighter-rouge">person_id</code>{" "}
            column will have an index called{" "}
            <code className="highlighter-rouge">person_index</code>. With the
            right indexes, our join query is several hundred times faster.
          </li>
        </ul>

        <h2 id="problems">Problems</h2>

        <ul>
          <li>
            One problem with databases is <strong>race conditions</strong>,
            where the timing of two actions or events cause unexpected behavior.
          </li>
          <li>
            For example, consider two roommates and a shared fridge in their
            dorm. The first roommate comes home, and sees that there is no milk
            in the fridge. So the first roommate leaves to the store to buy
            milk, and while they are at the store, the second roommate comes
            home, sees that there is no milk, and leaves for another store to
            get milk. Later, there will be two jugs of milk in the fridge. By
            leaving a note, we can solve this problem. We can even lock the
            fridge so that our roommate can’t check whether there is milk, until
            we’ve gotten back.
          </li>
          <li>
            This can happen in our database if we have something like this:
            <div className="highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    rows = db.execute("SELECT likes FROM posts WHERE id=?", id);
                    likes = rows[0]["likes"] db.execute("UPDATE posts SET likes
                    = ?", likes + 1);
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                First, we’re getting the number of likes on a post with a given
                ID. Then, we set the number of likes to that number plus one.
              </li>
              <li>
                But now if we have two different web servers both trying to add
                a like, they might both set it to the same value instead of
                actually adding one each time. For example, if there are 2
                likes, both servers will check the number of likes, see that
                there are 2, and set the value to 3. One of the likes will then
                be lost.
              </li>
            </ul>
          </li>
          <li>
            To solve this, we can use transactions, where a set of actions is
            guaranteed to happen together.
          </li>
          <li>
            Another problem in SQL is called a{" "}
            <strong>SQL injection attack</strong>, where an adversary can
            execute their own commands on our database.
          </li>
          <li>
            For example, someone might try type in{" "}
            <code className="highlighter-rouge">malan@harvard.edu'--</code> as
            their email. If we have a SQL query that’s a formatted string
            (without escaping, or substituting dangerous characters from, the
            input), such as{" "}
            <code className="highlighter-rouge">
              f"SELECT * FROM users WHERE username = '{"{username}"}' AND
              password = '{"{password}"}'"
            </code>
            , then the query will end up being{" "}
            <code className="highlighter-rouge">
              f"SELECT * FROM users WHERE username = 'malan@harvard.edu'--' AND
              password = '{"{password}"}'"
            </code>
            , which will actually select the row where{" "}
            <code className="highlighter-rouge">
              username = 'malan@harvard.edu'
            </code>{" "}
            and turn the rest of the line into a comment. To prevent this, we
            should use <code className="highlighter-rouge">?</code> placeholders
            for our SQL library to automatically escape inputs from the user.
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Note7;
