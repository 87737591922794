import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

function Pset7() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  return (
    <React.Fragment>
      <main className="col-md">
        <a data-id="" id="problem-set-7"></a>
        <h1>
          <a data-id="" href="#problem-set-7">
            Problem Set 7
          </a>
        </h1>

        <a data-id="" id="what-to-do"></a>
        <h2>
          <a data-id="" href="#what-to-do">
            What to Do
          </a>
        </h2>

        <ol>
          <li>
            Submit <Link to="movies">Movies</Link>{" "}
          </li>
          <li>
            Submit <Link to="houses">Houses</Link>{" "}
          </li>
        </ol>

        <a data-id="" id="when-to-do-it"></a>
        <h2>
          <a data-id="" href="#when-to-do-it">
            When to Do It
          </a>
        </h2>

        <p>
          By{" "}
          <span
            data-local="2020-12-31T23:59:00-05:00"
            data-boundary="window"
            data-toggle="tooltip"
            data-trigger="focus"
            title=""
            data-original-title="Arabian Standard Time"
            className="text-nowrap"
            tabindex="0"
          >
            Fri, Jan 1, 2021, 7:59 AM GMT+3
          </span>
          .
        </p>

        <div className="alert alert-warning" data-alert="warning" role="alert">
          <p>
            Though CS50x’s deadline has already been extended to 31 December
            2021, <em>this version</em> of this problem set will only be
            accepted until the date and time above. Thereafter, you may only
            submit work from CS50x 2021, which will be released on 1 January
            2021.{" "}
            <a
              href="https://cs50.harvard.edu/x/2020/faqs/#i-wont-be-able-to-finish-the-course-before-31-december-2020-what-will-happen"
              className="alert-link"
            >
              Not sure you’ll finish the course by then
            </a>
            ?
          </p>
        </div>

        <a data-id="" id="advice"></a>
        <h2>
          <a data-id="" href="#advice">
            Advice
          </a>
        </h2>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Head to{" "}
            <a href="https://www.w3schools.com/sql/">w3schools.com/sql</a> for a
            handy reference!
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Pset7;
