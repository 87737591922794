import React from "react";
import { useHistory } from "react-router";
import pyramid from "./img/pyramid.png";
import { useTranslation } from "react-i18next";

const alert = {
  padding: "10px",
  color: "#856404",
  backgroundColor: "#fff3cd",
  borderColor: "#ffeeba",
};

function MarioMore() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 id="mario">Mario</h1>

        <h2 id="world-1-1">World 1-1</h2>

        <p>
          Toward the beginning of World 1-1 in Nintendo’s Super Mario Brothers,
          Mario must hop over adjacent pyramids of blocks, per the below.
        </p>

        <p>
          <img
            src={pyramid}
            alt="screenshot of Mario jumping over adjacent pyramids"
          />
        </p>

        <p>
          Let’s recreate those pyramids in C, albeit in text, using hashes (
          <code>#</code>) for bricks, a la the below. Each hash is a bit taller
          than it is wide, so the pyramids themselves are also be taller than
          they are wide.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                {`
   #  #
  ##  ##
 ###  ###
####  ####
`}
              </code>
            </pre>
          </div>
        </div>

        <p>
          The program we’ll write will be called{" "}
          <code className="highlighter-rouge">mario</code>. And let’s allow the
          user to decide just how tall the pyramids should be by first prompting
          them for a positive integer between, say, 1 and 8, inclusive.
        </p>

        <p>
          Here’s how the program might work if the user inputs{" "}
          <code className="highlighter-rouge">8</code> when prompted:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario <br />
                Height: 8 <br />
                {`
       #  #
      ##  ##
     ###  ###
    ####  ####
   #####  #####
  ######  ######
 #######  #######
########  ########
`}
              </code>
            </pre>
          </div>
        </div>

        <p>
          Here’s how the program might work if the user inputs{" "}
          <code className="highlighter-rouge">4</code> when prompted:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario <br />
                Height: 4<br />
                {`  
   #  #
  ##  ##
 ###  ###
####  ####
`}
              </code>
            </pre>
          </div>
        </div>

        <p>
          Here’s how the program might work if the user inputs{" "}
          <code className="highlighter-rouge">2</code> when prompted:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario Height: 2<br />
                {`
 #  #
##  ##
`}
              </code>
            </pre>
          </div>
        </div>

        <p>
          And here’s how the program might work if the user inputs{" "}
          <code className="highlighter-rouge">1</code> when prompted:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario <br />
                Height: 1<br />
                <br /># #
              </code>
            </pre>
          </div>
        </div>

        <p>
          If the user doesn’t, in fact, input a positive integer between 1 and
          8, inclusive, when prompted, the program should re-prompt the user
          until they cooperate:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario <br />
                Height: -1 <br />
                Height: 0 <br />
                Height: 42 <br />
                Height: 50 <br />
                Height: 4 <br />
                {`  
   #  #
  ##  ##
 ###  ###
####  ####
`}
              </code>
            </pre>
          </div>
        </div>

        <p>
          Notice that width of the “gap” between adjacent pyramids is equal to
          the width of two hashes, irrespective of the pyramids’ heights.
        </p>

        <p>
          Create a new directory called{" "}
          <code className="highlighter-rouge">mario</code> inside of your{" "}
          <code className="highlighter-rouge">pset1</code> directory by
          executing
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>~/ $ mkdir ~/pset1/mario</code>
            </pre>
          </div>
        </div>

        <p>
          Create a new file called{" "}
          <code className="highlighter-rouge">mario.c</code> inside your{" "}
          <code className="highlighter-rouge">mario</code> directory. Modify{" "}
          <code className="highlighter-rouge">mario.c</code> in such a way that
          it implements this program as described!
        </p>

        <h3 id="walkthrough">Walkthrough</h3>

        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
        >
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={t("pset1_mario_more")}
          ></iframe>
        </div>

        <h3 id="how-to-test-your-code">How to Test Your Code</h3>

        <p>Does your code work as prescribed when you input</p>

        <ul>
          <li>
            <code className="highlighter-rouge">-1</code> (or other negative
            numbers)?
          </li>
          <li>
            <code className="highlighter-rouge">0</code>?
          </li>
          <li>
            <code className="highlighter-rouge">1</code> through{" "}
            <code className="highlighter-rouge">8</code>?
          </li>
          <li>
            <code className="highlighter-rouge">9</code> or other positive
            numbers?
          </li>
          <li>letters or words?</li>
          <li>no input at all, when you only hit Enter?</li>
        </ul>

        <p>
          You can also execute the below to evaluate the correctness of your
          code using <code className="highlighter-rouge">check50</code>. But be
          sure to compile and test it yourself as well!
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>check50 cs50/problems/2021/x/mario/more</code>
            </pre>
          </div>
        </div>

        <p>
          Execute the below to evaluate the style of your code using{" "}
          <code className="highlighter-rouge">style50</code>.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>style50 mario.c</code>
            </pre>
          </div>
        </div>

        <h2 id="how-to-submit">How to Submit</h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2021/x/mario/more</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default MarioMore;
