import React, { useRef } from "react";
import { useHistory } from "react-router";
// Images
import InputOutput from "./img/input_output.png";
import bulb0 from "./img/lightbulb_0.png";
import bulb1 from "./img/lightbulb_1.png";
import bulb2 from "./img/lightbulb_2.png";
import bulb3 from "./img/lightbulb_3.png";
import bulb4 from "./img/lightbulb_4.png";
import bulb5 from "./img/lightbulb_5.png";
import bulb6 from "./img/lightbulb_6.png";
import bulb7 from "./img/lightbulb_7.png";
import face_with_tears_of_joy from "./img/face_with_tears_of_joy.png";
import darkYellow from "./img/darkYellow.png";
import emojiZoomed from "./img/emoji_zoomed.png";
import rgb from "./img/rgb.png";
import rgbCombined from "./img/rgb_combined.png";
import algorithms from "./img/algorithms.png";
import runningTime from "./img/running_time.png";
import answer from "./img/scratch/answer.png";
import askAlgorithm from "./img/scratch/ask_algorithm.png";
import bark from "./img/scratch/bark.png";
import bark1 from "./img/scratch/bark1.png";
import bounce from "./img/scratch/bounce.png";
import coughFunction from "./img/scratch/cough_function.png";
import coughFunction2 from "./img/scratch/cough_function_2.png";
import cough0 from "./img/scratch/cough0.png";
import cough1 from "./img/scratch/cough1.png";
import count from "./img/scratch/count.png";
import foreverMeow from "./img/scratch/forever_meow.png";
import helloWorld from "./img/scratch/hello_world.png";
import joinAlgorithm from "./img/scratch/join_algorithm.png";
import join from "./img/scratch/join.png";
import ouch from "./img/scratch/ouch.png";
import marco from "./img/scratch/marco.png";
import pet0 from "./img/scratch/pet0.png";
import pet1 from "./img/scratch/pet1.png";
import pointTowards from "./img/scratch/point_towards.png";
import polo from "./img/scratch/polo.png";
import sayAgain from "./img/scratch/say_again.png";
import sayAlgorithm from "./img/scratch/say_algorithm.png";
import scratch from "./img/scratch/scratch.png";
import speakJoin from "./img/scratch/speak_join.png";
import meow_wait from "./img/scratch/meow_wait.png";
import repeatMeow from "./img/scratch/repeat_meow.png";
import draw from "./img/scratch/draw.png";
import translateImage from "./img/scratch/translate.png";
import wait from "./img/scratch/wait.png";

function Note() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const handleClick = (e, reference) => {
    e.preventDefault();
    reference.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <React.Fragment>
      <h1 className="no_toc" id="lecture-0">
        Lecture 0
      </h1>

      <ul id="markdown-toc">
        <li>
          <a
            href="#welcome"
            id="markdown-toc-welcome"
            onClick={(e) => handleClick(e, ref1)}
          >
            Welcome
          </a>
        </li>
        <li>
          <a
            href="#what-is-computer-science"
            id="markdown-toc-what-is-computer-science"
            onClick={(e) => handleClick(e, ref2)}
          >
            What is computer science?
          </a>
        </li>
        <li>
          <a
            href="#representing-numbers"
            id="markdown-toc-representing-numbers"
            onClick={(e) => handleClick(e, ref3)}
          >
            Representing numbers
          </a>
        </li>
        <li>
          <a
            href="#text"
            id="markdown-toc-text"
            onClick={(e) => handleClick(e, ref4)}
          >
            Text
          </a>
        </li>
        <li>
          <a
            href="#images-video-sounds"
            id="markdown-toc-images-video-sounds"
            onClick={(e) => handleClick(e, ref5)}
          >
            Images, video, sounds
          </a>
        </li>
        <li>
          <a
            href="#algorithms"
            id="markdown-toc-algorithms"
            onClick={(e) => handleClick(e, ref6)}
          >
            Algorithms
          </a>
        </li>
        <li>
          <a
            href="#pseudocode"
            id="markdown-toc-pseudocode"
            onClick={(e) => handleClick(e, ref7)}
          >
            Pseudocode
          </a>
        </li>
        <li>
          <a
            href="#scratch"
            id="markdown-toc-scratch"
            onClick={(e) => handleClick(e, ref8)}
          >
            Scratch
          </a>
        </li>
      </ul>

      <h2 id="welcome" ref={ref1}>
        Welcome
      </h2>

      <ul>
        <li data-marker="*">
          This year, we’ll be in the{" "}
          <a href="https://americanrepertorytheater.org/venue/loeb-drama-center-3/">
            Loeb Drama Center
          </a>{" "}
          at Harvard University, where, thanks to our close collaboration with
          the{" "}
          <a href="https://americanrepertorytheater.org/">
            American Repertory Theater
          </a>
          , we have an amazing stage and even props for demonstrations.
        </li>
        <li data-marker="*">
          We turned an 18th century{" "}
          <a href="https://images.hollis.harvard.edu/permalink/f/100kie6/HVD_VIAolvwork671391">
            watercolor painting of Harvard’s campus
          </a>{" "}
          by a student, Jonathan Fisher, into the backdrop for the stage.
        </li>
        <li data-marker="*">
          Twenty years ago as an undergraduate, David overcame his own
          trepidation, stepping outside his comfort zone and took CS50 himself,
          finding that the course was less about programming than about problem
          solving.
        </li>
        <li data-marker="*">
          In fact, two-thirds of CS50 students have never taken a computer
          science course before.
        </li>
        <li data-marker="*">
          And importantly, too:
          <blockquote>
            <p>
              what ultimately matters in this course is not so much where you
              end up relative to your classmates but where you end up relative
              to yourself when you began
            </p>
          </blockquote>
        </li>
        <li data-marker="*">
          We’ll start off the course recreating a component of a{" "}
          <a href="https://en.wikipedia.org/wiki/Super_Mario_Bros.">
            Super Mario game
          </a>
          , later building a web application called CS50 Finance that will allow
          users to buy and sell stocks virtually, and ending the course with the
          creation of your very own final project.
        </li>
      </ul>

      <h2 id="what-is-computer-science" ref={ref2}>
        What is computer science?
      </h2>

      <ul>
        <li data-marker="*">
          Computer science is fundamentally problem solving.
        </li>
        <li data-marker="*">
          We can think of <strong>problem solving</strong> as the process of
          taking some input (details about our problem) and generate some output
          (the solution to our problem). The “black box” in the middle is
          computer science, or the code we’ll learn to write.
          <br />
          <img
            src={InputOutput}
            alt='word "input", arrow into box, arrow out of box, word "output"'
          />
        </li>
        <li data-marker="*">
          To begin doing that, we’ll need a way to represent inputs and outputs,
          so we can store and work with information in a standardized way.
        </li>
      </ul>

      <h2 id="representing-numbers" ref={ref3}>
        Representing numbers
      </h2>

      <ul>
        <li data-marker="*">
          We might start with the task of taking attendance by counting the
          number of people in a room. With our hand, we might raise one finger
          at a time to represent each person, but we won’t be able to count very
          high. This system is called <strong>unary</strong>, where each digit
          represents a single value of one.
        </li>
        <li data-marker="*">
          We’ve probably learned a more efficient system to represent numbers,
          where we have ten digits, 0 through 9:
          <pre>0 1 2 3 4 5 6 7 8 9</pre>
          <ul>
            <li data-marker="*">
              This system is called decimal, or <strong>base 10</strong>, since
              there are ten different values that a digit can represent.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          Computers use a simpler system called <strong>binary</strong>, or base
          two, with only two possible digits, 0 and 1.
          <ul>
            <li data-marker="*">
              Each <em>binary digit</em> is also called a <strong>bit</strong>.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          Since computers run on electricity, which can be turned on or off, we
          can conveniently represent a bit by turning some switch on or off to
          represent a 0 or 1.
          <ul>
            <li data-marker="*">
              With one light bulb, for example, we can turn it on to count to 1.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          With three light bulbs, we can turn them on in different patterns, and
          count from 0 (with all three off) to 7 (with all three on):
          <br />
          <img src={bulb0} alt="off off off representing 0" />
          <br />
          <img src={bulb1} alt="off off on representing 1" />
          <br />
          <img src={bulb2} alt="off on off representing 2" />
          <br />
          <img src={bulb3} alt="off on on representing 3" />
          <br />
          <img src={bulb4} alt="on off off representing 4" />
          <br />
          <img src={bulb5} alt="on off on representing 5" />
          <br />
          <img src={bulb6} alt="on on off representing 6" />
          <br />
          <img src={bulb7} alt="on on on representing 7" />
        </li>
        <li data-marker="*">
          Inside modern computers, there are not light bulbs but million of tiny
          switches called <strong>transistors</strong> that can be turned on and
          off to represent different values.
        </li>
        <li data-marker="*">
          For example, we know the following number in decimal represents one
          hundred and twenty-three.
          <pre>1 2 3</pre>
          <ul>
            <li data-marker="*">
              The{" "}
              <code className="language-plaintext highlighter-rouge">3</code> is
              in the ones column, the{" "}
              <code className="language-plaintext highlighter-rouge">2</code> is
              in the tens column, and the{" "}
              <code className="language-plaintext highlighter-rouge">1</code> is
              in the hundreds column.
            </li>
            <li data-marker="*">
              So{" "}
              <code className="language-plaintext highlighter-rouge">123</code>{" "}
              is{" "}
              <code className="language-plaintext highlighter-rouge">
                100×1 + 10×2 + 1×3 = 100 + 20 + 3 = 123
              </code>
              .
            </li>
            <li data-marker="*">
              Each place for a digit represents a power of ten, since there are
              ten possible digits for each place. The rightmost place is for 10
              <sup>0</sup>, the middle one 10<sup>1</sup>, and the leftmost
              place 10<sup>2</sup>:
              <pre>
                10<sup>2</sup> 10<sup>1</sup> 10<sup>0</sup>
                {"\n"}
                {"1   2   3"}
              </pre>
            </li>
          </ul>
        </li>
        <li data-marker="*">
          In binary, with just two digits, we have powers of two for each place
          value:
          <pre>
            2<sup>2</sup> 2<sup>1</sup> 2<sup>0</sup>
            {"\n#  #  #"}
          </pre>
          <ul>
            <li data-marker="*">
              This is equivalent to:
              <pre>4 2 1 {"\n"}# # #</pre>
            </li>
          </ul>
        </li>
        <li data-marker="*">
          With all the light bulbs or switches off, we would still have a value
          of 0:
          <pre>
            {"4 2 1"}
            <b>{"\n0  0  0"}</b>
          </pre>
        </li>
        <li data-marker="*">
          Now if we change the binary value to, say,{" "}
          <code className="language-plaintext highlighter-rouge">
            {"0 1 1"}
          </code>
          , the decimal value would be 3, since we add the 2 and the 1:
          <pre>
            {"4 2 1"}
            <b>{"\n0  1  1"}</b>
          </pre>
        </li>
        <li data-marker="*">
          If we had several more light bulbs, we might have a binary value of{" "}
          <code className="language-plaintext highlighter-rouge">110010</code>,
          which would have the equivalent decimal value of{" "}
          <code className="language-plaintext highlighter-rouge">50</code>:
          <pre>
            {"32 16 8 4 2 1"}
            <b> {"\n1      1     0   0   1   0"}</b>
          </pre>
          <ul>
            <li data-marker="*">
              Notice that{" "}
              <code className="language-plaintext highlighter-rouge">
                32 + 16 + 2 = 50
              </code>
              .
            </li>
          </ul>
        </li>
        <li data-marker="*">
          With more bits, we can count up to even higher numbers.
        </li>
      </ul>

      <h2 id="text" ref={ref4}>
        Text
      </h2>

      <ul>
        <li data-marker="*">
          To represent letters, all we need to do is decide how numbers map to
          letters. Some humans, many years ago, collectively decided on a
          standard mapping of numbers to letters. The letter “A”, for example,
          is the number 65, and “B” is 66, and so on. By using context, like
          whether we’re looking at a spreadsheet or an email, different programs
          can interpret and display the same bits as numbers or text.
        </li>
        <li data-marker="*">
          The standard mapping,{" "}
          <a href="https://en.wikipedia.org/wiki/ASCII">
            <strong>ASCII</strong>
          </a>
          , also includes lowercase letters and punctuation.
        </li>
        <li data-marker="*">
          If we received a text message with a pattern of bits that had the
          decimal values{" "}
          <code className="language-plaintext highlighter-rouge">72</code>,{" "}
          <code className="language-plaintext highlighter-rouge">73</code>, and{" "}
          <code className="language-plaintext highlighter-rouge">33</code>,
          those bits would map to the letters{" "}
          <code className="language-plaintext highlighter-rouge">HI!</code>.
          Each letter is typically represented with a pattern of eight bits, or
          a <strong>byte</strong>, so the sequences of bits we would receive are{" "}
          <code className="language-plaintext highlighter-rouge">01001000</code>
          ,{" "}
          <code className="language-plaintext highlighter-rouge">01001001</code>
          , and{" "}
          <code className="language-plaintext highlighter-rouge">00100001</code>
          .
          <ul>
            <li data-marker="*">
              We might already be familiar with using bytes as a unit of
              measurement for data, as in megabytes or gigabytes, for millions
              or billions of bytes.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          With eight bits, or one byte, we can have 2<sup>8</sup>, or 256
          different values (including zero). (The <em>highest value</em> we can
          count up to would be 255.)
        </li>
        <li data-marker="*">
          Other characters, such as letters with accent marks and symbols in
          other languages, are part of a standard called{" "}
          <a href="https://en.wikipedia.org/wiki/Unicode">
            <strong>Unicode</strong>
          </a>
          , which uses more bits than ASCII to accommodate all these characters.
          <ul>
            <li data-marker="*">
              When we receive an emoji, our computer is actually just receiving
              a number in binary that it then maps to the image of the emoji
              based on the Unicode standard.
              <ul>
                <li data-marker="*">
                  For example, the “face with tears of joy” emoji is just the
                  bits{" "}
                  <code className="language-plaintext highlighter-rouge">
                    000000011111011000000010
                  </code>
                  :<br />
                  <img
                    src={face_with_tears_of_joy}
                    alt="face with tears of joy emoji"
                  />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <h2 id="images-video-sounds" ref={ref5}>
        Images, video, sounds
      </h2>

      <ul>
        <li data-marker="*">
          An image, like the picture of the emoji, are made up of colors.
        </li>
        <li data-marker="*">
          With only bits, we can map numbers to colors as well. There are many
          different systems to represent colors, but a common one is{" "}
          <strong>RGB</strong>, which represents different colors by indicating
          the amount of red, green, and blue within each color.
        </li>
        <li data-marker="*">
          For example, our pattern of bits earlier,{" "}
          <code className="language-plaintext highlighter-rouge">72</code>,{" "}
          <code className="language-plaintext highlighter-rouge">73</code>, and{" "}
          <code className="language-plaintext highlighter-rouge">33</code> might
          indicate the amount of red, green, and blue in a color. (And our
          programs would know those bits map to a color if we opened an image
          file, as opposed to receiving them in a text message.)
          <ul>
            <li data-marker="*">
              Each number might be a byte, with 256 possible values, so with
              three bytes, we can represent millions of colors. Our three bytes
              from above would represent a dark shade of yellow:
              <br />
              <img src={darkYellow} alt="dark yellow" />
            </li>
          </ul>
        </li>
        <li data-marker="*">
          The dots, or squares, on our screens are called{" "}
          <strong>pixels</strong>, and images are made up of many thousands or
          millions of those pixels as well. So by using three bytes to represent
          the color for each pixel, we can create images. We can see pixels in
          an emoji if we zoom in, for example:
          <br />
          <img
            src={emojiZoomed}
            alt="zoomed-in emoji of laughing tears of joy with squares of pixels distinguishable"
          />
        </li>
        <li data-marker="*">
          The <strong>resolution</strong> of an image is the number of pixels
          there are, horizontally and vertically, so a high-resolution image
          will have more pixels and require more bytes to be stored.
        </li>
        <li data-marker="*">
          Videos are made up of many images, changing multiple times a second to
          give us the appearance of motion, as an old-fashioned{" "}
          <a href="https://youtu.be/p3q9MM__h-M">flipbook</a> might do.
        </li>
        <li data-marker="*">
          Music can be represented with bits, too, with mappings of numbers to
          notes and durations, or more complex mappings of bits to sound
          frequencies at each moment of time.
        </li>
        <li data-marker="*">
          File formats, like JPEG and PNG, or Word or Excel documents, are also
          based on some standard that some humans have agreed on, for
          representing information with bits.
        </li>
      </ul>

      <h2 id="algorithms" ref={ref6}>
        Algorithms
      </h2>

      <ul>
        <li data-marker="*">
          Now that we can represent inputs and outputs, we can work on problem
          solving. The black box earlier will contain{" "}
          <strong>algorithms</strong>, step-by-step instructions for solving
          problems:
          <br />
          <img src={algorithms} alt='box with word "algorithms"' />
        </li>
        <li data-marker="*">
          Humans can follow algorithms too, such as recipes for cooking. When
          programming a computer, we need to be more precise with our algorithms
          so our instructions aren’t ambiguous or misinterpreted.
        </li>
        <li data-marker="*">
          We might have an application on our phones that store our contacts,
          with their names and phone numbers sorted alphabetically. The
          old-school equivalent might be a phone book, a printed copy of names
          and phone numbers.
        </li>
        <li data-marker="*">
          Our input to the problem of finding someone’s number would be the
          phone book and a name to look for. We might open the book and start
          from the first page, looking for a name one page at a time. This
          algorithm would be <strong>correct</strong>, since we will eventually
          find the name if it’s in the book.
        </li>
        <li data-marker="*">
          We might flip through the book two pages at a time, but this algorithm
          will not be correct since we might skip the page with our name on it.
          We can fix this <strong>bug</strong>, or mistake, by going back one
          page if we flipped too far, since we know the phone book is sorted
          alphabetically.
        </li>
        <li data-marker="*">
          Another algorithm would be opening the phone book to the middle,
          decide whether our name will be in the left half or right half of the
          book (because the book is alphabetized), and reduce the size of our
          problem by half. We can repeat this until we find our name, dividing
          the problem in half each time. With 1024 pages to start, we would only
          need 10 steps of dividing in half before we have just one page
          remaining to check. We can see this visualized in an{" "}
          <a href="https://youtu.be/F5LZhsekEBc">
            animation of dividing a phone book in half repeatedly
          </a>
          , compared to the{" "}
          <a href="https://youtu.be/-yTRajiUi5s">
            animation of searching one page at a time
          </a>
          .
        </li>
        <li data-marker="*">
          In fact, we can represent the efficiency of each of those algorithms
          with a chart:
          <br />
          <img
            src={runningTime}
            alt='chart with: "size of problem" as x-axis; "time to solve" as y-axis; red, steep straight line from origin to top of graph labeled "n"; yellow, less steep straight line from origin to top of graph labeled "n/2"; green, curved line that gets less and less steep from origin to right of graph labeled "log_2  n"'
          />
          <ul>
            <li data-marker="*">
              Our first solution, searching one page at a time, can be
              represented by the red line: our time to solve increases linearly
              as the size of the problem increases. <em>n</em> is a some number
              representing the size of the problem, so with <em>n</em> pages in
              our phone books, we have to take up to <em>n</em> steps to find a
              name.
            </li>
            <li data-marker="*">
              The second solution, searching two pages at a time, can be
              represented by the yellow line: our slope is less steep, but still
              linear. Now, we only need (roughly) <em>n</em> / 2 steps, since we
              flip two pages at a time.
            </li>
            <li data-marker="*">
              Our final solution, dividing the phone book in half each time, can
              be represented by the green line, with a fundamentally different
              relationship between the size of the problem and the time to solve
              it:{" "}
              <a href="https://en.wikipedia.org/wiki/Logarithm">
                <strong>logarithmic</strong>
              </a>
              , since our time to solve rises more and more slowly as the size
              of the problem increases. In other words, if the phone book went
              from 1000 to 2000 pages, we would only need one more step to find
              our name. If the size doubled again from 2000 to 4000 pages, we
              would still only need one more step. The green line is labeled log
              <sub>2</sub> <em>n</em>, or log base 2 of <em>n</em>, since we’re
              dividing the problem by two with each step.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          When we write programs using algorithms, we generally care not just
          how correct they are, but how <strong>well-designed</strong> they are,
          considering factors such as efficiency.
        </li>
      </ul>

      <h2 id="pseudocode" ref={ref7}>
        Pseudocode
      </h2>

      <ul>
        <li data-marker="*">
          We can write <strong>pseudocode</strong>, which is a representation of
          our algorithm in precise English (or some other human language):
          <pre>
            1 Pick up phone book 2 Open to middle of phone book 3 Look at page 4
            If person is on page 5 Call person 6 Else if person is earlier in
            book 7 Open to middle of left half of book 8 Go back to line 3 9
            Else if person is later in book 10 Open to middle of right half of
            book 11 Go back to line 3 12 Else 13 Quit
          </pre>
          <ul>
            <li data-marker="*">
              With these steps, we check the middle page, decide what to do, and
              repeat. If the person isn’t on the page, and there’s no more pages
              in the book left, then we stop. And that final case is
              particularly important to remember. When other programs on our
              computers forgot that final case, they might appear to freeze or
              stop responding, since they’ve encountered a case that wasn’t
              accounted for, or continue to repeat the same work over and over
              behind the scenes without making any progress.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          Some of these lines start with verbs, or actions. We’ll start calling
          these <em>functions</em>:
          <pre>
            1 <b>Pick up</b> phone book 2 <b>Open to</b> middle of phone book 3{" "}
            <b>Look at</b> page 4 If person is on page 5 <b>Call</b> person 6
            Else if person is earlier in book 7 <b>Open to</b> middle of left
            half of book 8 Go back to line 3 9 Else if person is later in book
            10 <b>Open to</b> middle of right half of book 11 Go back to line 3
            12 Else 13 <b>Quit</b>
          </pre>
        </li>
        <li data-marker="*">
          We also have branches that lead to different paths, like forks in the
          road, which we’ll call <em>conditions</em>:
          <pre>
            1 Pick up phone book 2 Open to middle of phone book 3 Look at page 4{" "}
            <b>If</b> person is on page 5 Call person 6 <b>Else if</b> person is
            earlier in book 7 Open to middle of left half of book 8 Go back to
            line 3 9 <b>Else if</b> person is later in book 10 Open to middle of
            right half of book 11 Go back to line 3 12 <b>Else</b>
            13 Quit
          </pre>
        </li>
        <li data-marker="*">
          And the questions that decide where we go are called{" "}
          <em>Boolean expressions</em>, which eventually result in a value of
          yes or no, or true or false:
          <pre>
            1 Pick up phone book 2 Open to middle of phone book 3 Look at page 4
            If <b>person is on page</b>5 Call person 6 Else if{" "}
            <b>person is earlier in book</b>7 Open to middle of left half of
            book 8 Go back to line 3 9 Else if <b>person is later in book</b>
            10 Open to middle of right half of book 11 Go back to line 3 12 Else
            13 Quit
          </pre>
        </li>
        <li data-marker="*">
          Lastly, we have words that create cycles, where we can repeat parts of
          our program, called <em>loops</em>:
          <pre>
            1 Pick up phone book 2 Open to middle of phone book 3 Look at page 4
            If person is on page 5 Call person 6 Else if person is earlier in
            book 7 Open to middle of left half of book 8{" "}
            <b>Go back to line 3</b>9 Else if person is later in book 10 Open to
            middle of right half of book 11 <b>Go back to line 3</b>
            12 Else 13 Quit
          </pre>
        </li>
      </ul>

      <h2 id="scratch" ref={ref8}>
        Scratch
      </h2>

      <ul>
        <li data-marker="*">
          We can write programs with the building blocks we just discovered:
          <ul>
            <li data-marker="*">functions</li>
            <li data-marker="*">conditions</li>
            <li data-marker="*">Boolean expressions</li>
            <li data-marker="*">loops</li>
          </ul>
        </li>
        <li data-marker="*">
          And we’ll discover additional features including:
          <ul>
            <li data-marker="*">variables</li>
            <li data-marker="*">threads</li>
            <li data-marker="*">events</li>
            <li data-marker="*">…</li>
          </ul>
        </li>
        <li data-marker="*">
          Before we learn to use a text-based programming language called C,
          we’ll use a graphical programming language called{" "}
          <a href="https://scratch.mit.edu/">Scratch</a>, where we’ll drag and
          drop blocks that contain instructions.
        </li>
        <li data-marker="*">
          A simple program in C that prints out “hello, world”, would look like
          this:
          <div className="language-c highlighter-rouge">
            <div className="highlight">
              <pre className="highlight">
                <code>
                  <span className="cp">#include &lt;stdio.h&gt;</span>
                  <span className="kt">int</span>{" "}
                  <span className="nf">main</span>
                  <span className="p">(</span>
                  <span className="kt">void</span>
                  <span className="p">)</span>
                  <span className="p">{"{"}</span>
                  <span className="n">printf</span>
                  <span className="p">(</span>
                  <span className="s">"hello, world</span>
                  <span className="se">\n</span>
                  <span className="s">"</span>
                  <span className="p">);</span>
                  <span className="p">{"}"}</span>
                </code>
              </pre>
            </div>{" "}
          </div>
          <ul>
            <li data-marker="*">
              There’s a lot of symbols and syntax, or arrangement of these
              symbols, that we would have to figure out.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          The programming environment for Scratch is a little more friendly:
          <br />
          <img src={scratch} alt="screenshot of Scratch's interface" />
          <ul>
            <li data-marker="*">
              On the top right, we have a stage that will be shown by our
              program, where we can add or change backgrounds, characters
              (called sprites in Scratch), and more.
            </li>
            <li data-marker="*">
              On the left, we have puzzle pieces that represent functions or
              variables, or other concepts, that we can drag and drop into our
              instruction area in the center.
            </li>
            <li data-marker="*">
              On the bottom right, we can add more characters for our program to
              use.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          We can drag a few blocks to make Scratch say “hello, world”:
          <br />
          <img src={helloWorld} alt="screenshot of hello, world" />
          <ul>
            <li data-marker="*">
              The “when green flag clicked” block refers to the start of our
              program (since there is a green flag above the stage that we can
              use to start it), and below it we’ve snapped in a “say” block and
              typed in “hello, world”. And we can figure out what these blocks
              do by exploring the interface and experimenting.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          We can also drag in the “ask and wait” block, with a question like
          “What’s your name?”, and combine it with a “say” block for the answer:
          <br />
          <img src={answer} alt="screenshot of question and answer" />
          <ul>
            <li data-marker="*">
              The “answer” block is a variable, or value, that stores what the
              program’s user types in, and we can place it in a “say” block by
              draggind and dropping as well.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          But we didn’t wait after we said “Hello” with the first block, so we
          can use the “join” block to combine two phrases so our cat can say
          “hello, David”:
          <br />
          <img src={join} alt="screenshot of join" />
          <ul>
            <li data-marker="*">
              When we try to nest blocks, or place them one inside the other,
              Scratch will help us by expanding places where they can be used.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          In fact, the “say” block itself is like an algorithm, where we
          provided an input of “hello, world” and it produced the output of
          Scratch (the cat) “saying” that phrase:
          <br />
          <img
            src={sayAlgorithm}
            alt='say as algorithm with "hello, world" as input and cat as output'
          />
        </li>
        <li data-marker="*">
          The “ask” block, too, takes in an input (the question we want to ask),
          and produces the output of the “answer” block:
          <br />
          <img
            src={askAlgorithm}
            alt='ask as algorithm with "What&apos;s your name?" as input and answer block as output'
          />
        </li>
        <li data-marker="*">
          We can then use the “answer” block along with our own text, “hello, “,
          as two inputs to the join algorithm …<br />
          <img
            src={joinAlgorithm}
            alt='join as algorithm with "hello, " and "answer" as input and "hello, David!" as output'
          />
        </li>
        <li data-marker="*">
          … the output of which we pass can as input to the “say” block:
          <br />
          <img
            src={sayAgain}
            alt='say as algorithm with "hello, David!" as input and cat as output'
          />
        </li>
        <li data-marker="*">
          At the bottom left of the screen, we see an icon for extensions, and
          one of them is called Text to Speech. After we add it, we can use the
          “speak” block to hear our cat speak:
          <br />
          <img src={speakJoin} alt="speak block with join hello, answer" />
        </li>
        <li data-marker="*">
          The Text to Speech extension, thanks to the cloud, or computer servers
          on the internet, is converting our text to audio.
        </li>
        <li data-marker="*">
          We can try to make the cat say meow:
          <br />
          <img
            src={meow_wait}
            alt='blocks labeled "play sound Meow until done" and "wait 1 seconds", repeated three times'
          />
          <ul>
            <li data-marker="*">
              We can have it say meow three times, but now we’re repeating
              blocks over and over.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          Let’s use a loop, or a “repeat” block:
          <br />
          <img
            src={repeatMeow}
            alt='blocks labeled "repeat 3" with "play sound Meow until done" and "wait 1 seconds" nested inside'
          />
          <ul>
            <li data-marker="*">
              Now our program achieves the same results, but with fewer blocks.
              We can consider it to have a better design: if there’s something
              we wanted to change, we would only need to change it in one place
              instead of three.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          We can have the cat point towards the mouse and move towards it:
          <br />
          <img
            src={pointTowards}
            alt='blocks labeled "forever" with "point towards mouse-pointer" and "move 1 steps" nested inside'
          />
        </li>
        <li data-marker="*">
          We try out the Pen extension, by using the “pen down” block with a
          condition:
          <br />
          <img
            src={draw}
            alt='blocks labeled "forever" with "go to mouse pointer", "if mouse down? then (pen down) else (pen up) nested inside"'
          />
          <ul>
            <li data-marker="*">
              Here, we move the cat to the mouse pointer, and if the mouse is
              clicked, or down, we put the “pen down”, which draws. Otherwise,
              we put the pen up. We repeat this very quickly, over and over
              again, so we end up with the effect of drawing whenever we have
              the mouse held down.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          Scratch also has different costumes, or images, that we can use for
          our characters.
        </li>
        <li data-marker="*">
          We’ll make a program that can count:
          <br />
          <img
            src={count}
            alt='blocks labeled "set counter to 1" and "forever" with "say counter for 1 seconds" and "change counter by 1" nested inside'
          />
          <ul>
            <li data-marker="*">
              Here,{" "}
              <code className="language-plaintext highlighter-rouge">
                counter
              </code>{" "}
              is a variable, the value of which we can set, use, and change.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          We look at some more programs, like{" "}
          <a href="https://scratch.mit.edu/projects/277536611/editor/">
            bounce
          </a>
          , where the cat moves back and forth on the screen forever, by turning
          around whenever we’re at the edge of the screen.
          <ul>
            <li data-marker="*">
              We can improve the animation by having the cat change to a
              different costumes after every 10 steps in{" "}
              <a href="https://scratch.mit.edu/projects/277536630/editor/">
                bounce1
              </a>
              . Now when we click the green flag to run our program, we see the
              cat alternate the movement of its legs.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          We can even record our own sounds with our computer’s microphone, and
          play them in our program.
        </li>
        <li data-marker="*">
          To build more and more complex programs, we start with each of these
          simpler features, and layer them atop one another.
        </li>
        <li data-marker="*">
          We can also have Scratch meow if we touch it with the mouse pointer,
          in{" "}
          <a href="https://scratch.mit.edu/projects/277537223/editor/">pet0</a>.
        </li>
        <li data-marker="*">
          In{" "}
          <a href="https://scratch.mit.edu/projects/326130490/editor/">bark</a>,
          we have not one but two programs in the same Scratch project. Both of
          these programs will run at the same time after the green flag is
          clicked. One of these will play a sea lion sound if the{" "}
          <code className="language-plaintext highlighter-rouge">muted</code>{" "}
          variable is set to{" "}
          <code className="language-plaintext highlighter-rouge">false</code>,
          and the other will set the{" "}
          <code className="language-plaintext highlighter-rouge">muted</code>{" "}
          variable from either{" "}
          <code className="language-plaintext highlighter-rouge">true</code> to{" "}
          <code className="language-plaintext highlighter-rouge">false</code>,
          or <code className="language-plaintext highlighter-rouge">false</code>{" "}
          to <code className="language-plaintext highlighter-rouge">true</code>,
          if the space key is pressed.
        </li>
        <li data-marker="*">
          Another extension looks at the video as captured by our computer’s
          webcam, and plays the meow sound if the video has motion above some
          threshold.
        </li>
        <li data-marker="*">
          With multiple sprites, or characters, we can have different sets of
          blocks for each of them:
          <br />
          <img
            src={marco}
            alt='blocks labeled "forever" with if key space pressed? then" with "say Marco! for 2 seconds" and "broadcast event" nested inside'
          />
          <ul>
            <li data-marker="*">
              For one puppet, we have these blocks that say “Marco!”, and then a
              “broadcast event” block. This “event” is used for our two sprites
              to communicate with each other, like sending a message behind the
              scenes. So our other puppet can just wait for this “event” to say
              “Polo!”:
              <br />
              <img
                src={polo}
                alt='blocks labeled "when I receive event", "say Polo! for 2 seconds"'
              />
            </li>
          </ul>
        </li>
        <li data-marker="*">
          We can use the Translate extension to say something in other
          languages, too:
          <br />
          <img
            src={translateImage}
            alt='blocks labeled "when green flag clicked", "ask (What&apos;s your name?) and wait", "say (translate (join (hello) (answer) to Arabic))"'
          />
          <ul>
            <li data-marker="*">
              Here, the output of the “join” block is use as the input to the
              “translate” block, the output of which is passed as input to the
              “say” block.
            </li>
          </ul>
        </li>
        <li data-marker="*">
          Now that we know some basics, we can think about the design, or
          quality of our programs. For example, we might want to have the cat
          meow three times with the “repeat” block:
          <br />
          <img
            src={repeatMeow}
            alt='blocks labeled "repeat 3" with "play sound Meow until done" and "wait 1 seconds" nested inside'
          />
        </li>
        <li data-marker="*">
          We can use <strong>abstraction</strong>, which simplifies a more
          complex concept. In this case, we can define our own “meow” block in
          Scratch, and reuse it elsewhere in our program, as seen in{" "}
          <a href="https://scratch.mit.edu/projects/421542702/editor/">meow3</a>
          . The advantage is that we don’t need to know how meowing is
          implemented, or written in code, but rather just use it in our
          program, making it more readable.
        </li>
        <li data-marker="*">
          We can even define a block with an input in{" "}
          <a href="https://scratch.mit.edu/projects/421543064/editor/">meow4</a>
          , where we have a block that make the cat meow a certain number of
          times. Now we can reuse that block in our program to meow any number
          of times, much like how we can use the “translate” or “speak” blocks,
          without knowing the <strong>implementation details</strong>, or how
          the block actually works.
        </li>
        <li data-marker="*">
          We take a look at a few more demos, including{" "}
          <a href="https://scratch.mit.edu/projects/277536784/">
            Gingerbread tales remix
          </a>{" "}
          and{" "}
          <a href="https://scratch.mit.edu/projects/277537196/">Oscartime</a>,
          both of which combine loops, conditions, and movement to make an
          interactive game.
        </li>
        <li data-marker="*">
          Oscartime was actually made by David many years ago, and he started by
          adding one sprite, then one feature at a time, and so on, until they
          added up to the more complicated program.
        </li>
        <li data-marker="*">
          A former student, Andrew, created{" "}
          <a href="https://scratch.mit.edu/projects/37412/">Raining Men</a>.
          Even though Andrew ultimately ended up not pursuing computer science
          as a profession, the problem-solving skills, algorithms, and ideas
          we’ll learn in the course are applicable everywhere.
        </li>
        <li data-marker="*">Until next time!</li>
      </ul>
    </React.Fragment>
  );
}

export default Note;
