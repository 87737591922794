import React, { useState, useEffect } from "react";
import "./App.css";
import { BASE_URL } from "./urls";
import Login from "./components/Login/Login";

import Week0 from "./pages/Week0/Week0";
import Week1 from "./pages/Week1/Week1";
import Week2 from "./pages/Week2/Week2";
import Week3 from "./pages/Week3/Week3";
import Week4 from "./pages/Week4/Week4";
import Week5 from "./pages/Week5/Week5";
import Week6 from "./pages/Week6/Week6";
import Week7 from "./pages/Week7/Week7";
import Week8 from "./pages/Week8/Week8";
import Week9 from "./pages/Week9/Week9";

import Note from "./pages/Week0/Note";
import Note1 from "./pages/Week1/Note1";
import Note2 from "./pages/Week2/Note2";
import Note3 from "./pages/Week3/Note3";
import Note4 from "./pages/Week4/Note4";
import Note5 from "./pages/Week5/Note5";
import Note6 from "./pages/Week6/Note6";
import Note7 from "./pages/Week7/Note7";
import Note8 from "./pages/Week8/Note8";
import Pset0 from "./pages/Week0/Pset0";
import Pset1 from "./pages/Week1/Pset1";
import Pset2 from "./pages/Week2/Pset2";
import Pset3 from "./pages/Week3/Pset3";
import Pset4 from "./pages/Week4/Pset4";
import Pset5 from "./pages/Week5/Pset5";
import Pset6 from "./pages/Week6/Pset6";
import Pset7 from "./pages/Week7/Pset7";
import Finance from "./pages/Week9/Pset9";

import Scratch from "./pages/Week0/Scratch";
import Hello from "./pages/Week1/Hello";
import { Route } from "react-router-dom";
import MarioLess from "./pages/Week1/MarioLess";
import MarioMore from "./pages/Week1/MarioMore";
import Cash from "./pages/Week1/Cash";
import Credit from "./pages/Week1/Credit";
import Readability from "./pages/Week2/Readability";
import Caesar from "./pages/Week2/Caesar";
import Substitution from "./pages/Week2/Substitution";

import Plurality from "./pages/Week3/Plurality";
import Runoff from "./pages/Week3/Runoff";
import Tideman from "./pages/Week3/Tideman";

import FilterLess from "./pages/Week4/FilterLess";
import FilterMore from "./pages/Week4/FilterMore";
import Recover from "./pages/Week4/Recover";

import Speller from "./pages/Week5/Speller";

// python psets
import PythonHello from "./pages/Week6/Hello";
import PythonMarioLess from "./pages/Week6/MarioLess";
import PythonMarioMore from "./pages/Week6/MarioMore";
import PythonCash from "./pages/Week6/Cash";
import PythonCredit from "./pages/Week6/Credit";
import PythonDna from "./pages/Week6/Dna";
import PythonReadability from "./pages/Week6/Readability";

import Movies from "./pages/Week7/Movies";
import Houses from "./pages/Week7/Houses";
import Homepage from "./pages/Week8/Pset8";
import Note9 from "./pages/Week9/Note9";

import Final from "./pages/Final";
import Courses from "./pages/Courses/Courses";
import Header from "./components/Header/Header";
import CoursesDetails from "./pages/CourseDetails/CourseDetails";

function App() {
  const [data, setData] = useState("");
  const [isAuth, setIsAuth] = useState(false);

  useEffect(async () => {
    const token = localStorage.getItem("access_token");
    const response = await fetch(`${BASE_URL}/api/academy/auth/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    try {
      if (response.ok) {
        // user is Authenticated
        let result = await response.json();
        localStorage.setItem("user", JSON.stringify(result.user));
        setData(result);
        console.log(result);
        setIsAuth(true);
      } else {
        // Please login to see your dashboard
        setIsAuth(false);
      }
      // localStorage.setItem("access_token", result.token.access_token);
    } catch (error) {
      // console.log(error);
    }
  }, []);

  if (isAuth) {
    // * Here is your content
    return (
      <div className="App">
        <Header data={data} />
        <div className="wrapper">
          {/* Side */}
          {/* <Sidenav /> */}

          {/* Pages */}

          <Route path="/" exact component={() => <Courses data={data} />} />
          <Route
            path="/courses"
            exact
            component={() => <Courses data={data} />}
          />
          <Route
            path="/courses/:id"
            exact
            component={() => <CoursesDetails />}
          />

          {/* CS50x Course */}
          <Route path="/week0" exact component={() => <Week0 />} />
          <Route path="/week1" exact component={() => <Week1 />} />
          <Route path="/week2" exact component={() => <Week2 />} />
          <Route path="/week3" exact component={() => <Week3 />} />
          <Route path="/week4" exact component={() => <Week4 />} />
          <Route path="/week5" exact component={() => <Week5 />} />
          <Route path="/week6" exact component={() => <Week6 />} />
          <Route path="/week7" exact component={() => <Week7 />} />
          <Route path="/week8" exact component={() => <Week8 />} />
          <Route path="/week9" exact component={() => <Week9 />} />

          <Route path="/note0" exact component={() => <Note />} />
          <Route path="/note1" exact component={() => <Note1 />} />
          <Route path="/note2" exact component={() => <Note2 />} />
          <Route path="/note3" exact component={() => <Note3 />} />
          <Route path="/note4" exact component={() => <Note4 />} />
          <Route path="/note5" exact component={() => <Note5 />} />
          <Route path="/note6" exact component={() => <Note6 />} />
          <Route path="/note7" exact component={() => <Note7 />} />
          <Route path="/note8" exact component={() => <Note8 />} />
          <Route path="/note9" exact component={() => <Note9 />} />

          <Route path="/psets" exact component={() => <Pset0 />} />
          <Route path="/scratch" exact component={() => <Scratch />} />

          <Route path="/pset1" exact component={() => <Pset1 />} />
          <Route path="/hello" exact component={() => <Hello />} />
          <Route path="/mario-less" exact component={() => <MarioLess />} />
          <Route path="/mario-more" exact component={() => <MarioMore />} />
          <Route path="/cash" exact component={() => <Cash />} />
          <Route path="/credit" exact component={() => <Credit />} />

          <Route path="/pset2" exact component={() => <Pset2 />} />
          <Route path="/readability" exact component={() => <Readability />} />
          <Route path="/caesar" exact component={() => <Caesar />} />
          <Route
            path="/substitution"
            exact
            component={() => <Substitution />}
          />

          <Route path="/pset3" exact component={() => <Pset3 />} />
          <Route path="/plurality" exact component={() => <Plurality />} />
          <Route path="/runoff" exact component={() => <Runoff />} />
          <Route path="/tideman" exact component={() => <Tideman />} />

          <Route path="/pset4" exact component={() => <Pset4 />} />
          <Route path="/filter-less" exact component={() => <FilterLess />} />
          <Route path="/filter-more" exact component={() => <FilterMore />} />
          <Route path="/recover" exact component={() => <Recover />} />

          <Route path="/pset5" exact component={() => <Pset5 />} />
          <Route path="/speller" exact component={() => <Speller />} />

          <Route path="/pset6" exact component={() => <Pset6 />} />
          <Route path="/python-hello" exact component={() => <PythonHello />} />
          <Route
            path="/python-mario-less"
            exact
            component={() => <PythonMarioLess />}
          />
          <Route
            path="/python-mario-more"
            exact
            component={() => <PythonMarioMore />}
          />
          <Route path="/python-cash" exact component={() => <PythonCash />} />
          <Route
            path="/python-credit"
            exact
            component={() => <PythonCredit />}
          />
          <Route
            path="/python-readability"
            exact
            component={() => <PythonReadability />}
          />
          <Route path="/dna" exact component={() => <PythonDna />} />

          <Route path="/pset7" exact component={() => <Pset7 />} />
          <Route path="/movies" exact component={() => <Movies />} />
          <Route path="/houses" exact component={() => <Houses />} />

          <Route path="/homepage" exact component={() => <Homepage />} />
          <Route path="/pset9" exact component={() => <Finance />} />
          <Route path="/final-project" exact component={() => <Final />} />
        </div>
      </div>
    );
  } else {
    return <Login />;
  }
}

export default App;
