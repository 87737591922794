import React from "react";
import "./style.css";
import cookie_header from "./img/cookie_header.png";
import json from "./img/json.png";
import mvc from "./img/mvc.png";
import ajax from "./img/ajax.png";

function Note9() {
  const style = {
    code: {
      backgroundColor: "grey",
    },
  };
  return (
    <React.Fragment>
      <main className="col-md" style={style}>
        <h1 className="no_toc" id="lecture-9">
          Lecture 9
        </h1>
        <ul id="markdown-toc">
          <li>
            <a href="#web-programming">Web programming</a>
          </li>
          <li>
            <a href="#flask" id="markdown-toc-flask">
              Flask
            </a>
          </li>
          <li>
            <a href="#forms" id="markdown-toc-forms">
              Forms
            </a>
          </li>
          <li>
            <a href="#post" id="markdown-toc-post">
              POST
            </a>
          </li>
          <li>
            <a href="#layouts" id="markdown-toc-layouts">
              Layouts
            </a>
          </li>
          <li>
            <a href="#frosh-ims" id="markdown-toc-frosh-ims">
              Frosh IMs
            </a>
          </li>
          <li>
            <a href="#storing-data" id="markdown-toc-storing-data">
              Storing data
            </a>
          </li>
          <li>
            <a href="#sessions" id="markdown-toc-sessions">
              Sessions
            </a>
          </li>
          <li>
            <a href="#store-shows" id="markdown-toc-store-shows">
              store, shows
            </a>
          </li>
        </ul>

        <h2 id="web-programming">Web programming</h2>

        <ul>
          <li>
            Today we’ll create more advanced web applications by writing code
            that runs on the server.
          </li>
          <li>
            Last week, we used{" "}
            <code className="language-plaintext highlighter-rouge">
              http-server
            </code>{" "}
            in the CS50 IDE as a <strong>web server</strong>, a program that
            listens for connections and requests, and responds with web pages or
            other resources.
          </li>
          <li>
            An HTTP request has headers, like:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>GET / HTTP/1.1 ...</code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                These headers can ask for some file or page, or send data from
                the browser back to the server.
              </li>
            </ul>
          </li>
          <li>
            While{" "}
            <code className="language-plaintext highlighter-rouge">
              http-server
            </code>{" "}
            only responds with static pages, we can use other web servers that
            parses, or analyzes request headers, like{" "}
            <code className="language-plaintext highlighter-rouge">
              GET /search?q=cats HTTP/1.1
            </code>
            , to return pages dynamically.
          </li>
        </ul>

        {/* #breakpoint ------------------------- */}

        <h2 id="flask">Flask</h2>

        <ul>
          <li>
            We’ll use Python and a library called <strong>Flask</strong> to
            write our own web server, implementing additional features. Flask is
            also a <strong>framework</strong>, where the library of code also
            comes with a set of conventions for how it should be used. For
            example, like other libraries, Flask includes functions we can use
            to parse requests individually, but as a framework, also requires
            our program’s code to be organized in a certain way:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`
application.py
requirements.txt
static/
templates/`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                <code>application.py</code> will have the Python code for our
                web server.
              </li>
              <li>
                <code>requirements.py</code> includes a list of required
                libraries for our application.
              </li>
              <li>
                <code>static/</code> is a directory of static files, like CSS
                and JavaScript files.
              </li>
              <li>
                <code>templates/</code> is a directory for files that will be
                used to create our final HTML.
              </li>
            </ul>
          </li>
          <li>
            There are many web server frameworks for each of the popular
            languages, and Flask will be a representative one that we use today.
          </li>
          <li>
            Flask also implements a particular <strong>design pattern</strong>,
            or way that our program and code is organized. For Flask, the design
            pattern is generally <strong>MVC</strong>, or Model–view–controller:
            <br />
            <img
              src={mvc}
              alt="Model with arrow to View labeled Updates; View with arrow to User labeled Sees; User with arrow to Controlled labeled Uses; Controller with arrow to Model labeled Manipulates"
            />
            <ul>
              <li>
                The controller is our logic and code that manages our
                application overall, given user input. In Flask, this will be
                our Python code.
              </li>
              <li>
                The view is the user interface, like the HTML and CSS that the
                user will see and interact with.
              </li>
              <li>
                The model is our application’s data, such as a SQL database or
                CSV file.
              </li>
            </ul>
          </li>
          <li>
            The simplest Flask application might look like this:
            <pre>{`
from flask import Flask

app = Flask(__name__)

@app.route("/")
def index():
    return "hello, world"

`}</pre>
            <ul>
              <li>
                First, we’ll import{" "}
                <code className="language-plaintext highlighter-rouge">
                  Flask
                </code>{" "}
                from the{" "}
                <code className="language-plaintext highlighter-rouge">
                  flask
                </code>{" "}
                library, which happens to use a capital letter for its main
                name.
              </li>
              <li>
                Then, we’ll create an{" "}
                <code className="language-plaintext highlighter-rouge">
                  app
                </code>{" "}
                variable by giving our file’s name to the{" "}
                <code className="language-plaintext highlighter-rouge">
                  Flask
                </code>{" "}
                variable.
              </li>
              <li>
                Next, we’ll label a function for the{" "}
                <code className="language-plaintext highlighter-rouge">/</code>{" "}
                route, or URL with{" "}
                <code className="language-plaintext highlighter-rouge">
                  @app.route
                </code>
                . The{" "}
                <code className="language-plaintext highlighter-rouge">@</code>{" "}
                symbol in Python is called a decorator, which applies one
                function to another.
              </li>
              <li>
                We’ll call the function{" "}
                <code className="language-plaintext highlighter-rouge">
                  index
                </code>
                , since it should respond to a request for{" "}
                <code className="language-plaintext highlighter-rouge">/</code>,
                the default page. And our function will just respond with a
                string for now.
              </li>
            </ul>
          </li>
          <li>
            In the CS50 IDE, we can go to the directory with our application
            code, and type{" "}
            <code className="language-plaintext highlighter-rouge">
              flask run
            </code>{" "}
            to start it. We’ll see a URL, and we can open it to see{" "}
            <code className="language-plaintext highlighter-rouge">
              hello, world
            </code>
            .
          </li>
          <li>
            We’ll update our code to actually return HTML with the{" "}
            <code className="language-plaintext highlighter-rouge">
              render_template
            </code>{" "}
            function, which finds a file given and returns its contents:
            <pre>
              {`
from flask import Flask, render_template

app = Flask(__name__)


@app.route("/")
def index():
    return render_template("index.html")
       `}
            </pre>
            <ul>
              <li>
                We’ll need to create a{" "}
                <code className="language-plaintext highlighter-rouge">
                  templates/
                </code>{" "}
                directory, and create an{" "}
                <code className="language-plaintext highlighter-rouge">
                  index.html
                </code>{" "}
                file with some content inside it.
              </li>
              <li>
                Now, typing{" "}
                <code className="language-plaintext highlighter-rouge">
                  flask run
                </code>{" "}
                will return that HTML file when we visit our server’s URL.
              </li>
            </ul>
          </li>
          <li>
            We’ll pass in an argument to{" "}
            <code className="language-plaintext highlighter-rouge">
              render_template
            </code>{" "}
            in our controller code:
            <pre>
              {`
from flask import Flask, render_template, request

app = Flask(__name__)

@app.route("/")
def index():
    return render_template("index.html", name=request.args.get("name", "world"))
        `}
            </pre>
            <ul>
              <li>
                It turns out that we can give{" "}
                <code className="language-plaintext highlighter-rouge">
                  render_template
                </code>{" "}
                any named argument, like{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>
                , and it will substitute that in our template, or our HTML file
                with placeholders.
                <ul>
                  <li>
                    In{" "}
                    <code className="language-plaintext highlighter-rouge">
                      index.html
                    </code>
                    , we’ll replace{" "}
                    <code className="language-plaintext highlighter-rouge">
                      hello, world
                    </code>{" "}
                    with{" "}
                    <code className="language-plaintext highlighter-rouge">
                      hello,{" "}
                    </code>{" "}
                    to tell Flask where to substitute the{" "}
                    <code className="language-plaintext highlighter-rouge">
                      name
                    </code>{" "}
                    variable:
                    <pre>
                      {" "}
                      {`
<!DOCTYPE html>

<html lang="en">
    <head>
        <title>hello</title>
    </head>
    <body>
        hello, {{ name }}
    </body>
</html>
            `}
                    </pre>
                  </li>
                </ul>
              </li>
              <li>
                We can use the{" "}
                <code className="language-plaintext highlighter-rouge">
                  request
                </code>{" "}
                variable from the Flask library to get a parameter from the HTTP
                request, in this case also{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>
                , and fall back to a default of{" "}
                <code className="language-plaintext highlighter-rouge">
                  world
                </code>{" "}
                if one wasn’t provided.
              </li>
              <li>
                Now, when we restart our server after making these changes, and
                visit the default page with a URL like{" "}
                <code className="language-plaintext highlighter-rouge">
                  /?name=David
                </code>
                , we’ll see that same input returned back to us in the HTML
                generated by our server.
              </li>
            </ul>
          </li>
          <li>
            We can presume that Google’s search query, at{" "}
            <code className="language-plaintext highlighter-rouge">
              /search?q=cats
            </code>
            , is also parsed by some code for the{" "}
            <code className="language-plaintext highlighter-rouge">q</code>{" "}
            parameter and passed along to some database to get all the results
            that are relevant. Those results are then used to generate the final
            HTML page.
          </li>
        </ul>

        {/* #breakpoint ------------------------- */}

        <h2 id="forms">Forms</h2>

        <ul>
          <li>
            We’ll move our original template into{" "}
            <code className="language-plaintext highlighter-rouge">
              greet.html
            </code>
            , so it will greet the user with their name. In{" "}
            <code className="language-plaintext highlighter-rouge">
              index.html
            </code>
            , we’ll create a form:
            <pre>
              {`
<!DOCTYPE html>

<html lang="en">
    <head>
        <title>hello</title>
    </head>
    <body>
        <form action="/greet" method="get">
            <input name="name" type="text">
            <input type="submit">
        </form>
    </body>
</html>
        `}
            </pre>
            <ul>
              <li>
                We’ll send the form to the{" "}
                <code className="language-plaintext highlighter-rouge">
                  /greet
                </code>{" "}
                route, and have an input for the{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>{" "}
                parameter and one for the submit button.
              </li>
              <li>
                In our{" "}
                <code className="language-plaintext highlighter-rouge">
                  applications.py
                </code>{" "}
                controller, we’ll also need to add a function for the{" "}
                <code className="language-plaintext highlighter-rouge">
                  /greet
                </code>{" "}
                route, which is almost exactly what we had for{" "}
                <code className="language-plaintext highlighter-rouge">/</code>{" "}
                before:
                <pre>
                  {`
@app.route("/")
def index():
    return render_template("index.html")


@app.route("/greet")
def greet():
    return render_template("greet.html", name=request.args.get("name", "world"))
           `}
                </pre>
                <ul>
                  <li>
                    Our form at{" "}
                    <code className="language-plaintext highlighter-rouge">
                      index.html
                    </code>{" "}
                    will be static since it can be the same every time.
                  </li>
                </ul>
              </li>
              <li>
                Now, we can run our server, see our form at the default page,
                and use it to generate another page.
              </li>
            </ul>
          </li>
        </ul>
        {/* #breakpoint ------------------------- */}

        <h2 id="post">POST</h2>

        <ul>
          <li>
            Our form above used the GET method, which includes our form’s data
            in the URL.
          </li>
          <li>
            We’ll change the method in our HTML:{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;form action="/greet" method="form"&gt;
            </code>
            . Our controller will also need to be changed to accept the POST
            method, and look for the parameter somewhere else:
            <pre>
              {`
@app.route("/greet", methods=["POST"])
def greet():
    return render_template("greet.html", name=request.form.get("name", "world"))
       `}
            </pre>
            <ul>
              <li>
                While{" "}
                <code className="language-plaintext highlighter-rouge">
                  request.args
                </code>{" "}
                is for parameters in a GET request, we have to use{" "}
                <code className="language-plaintext highlighter-rouge">
                  request.form
                </code>{" "}
                in Flask for parameters in a POST request.
              </li>
            </ul>
          </li>
          <li>
            Now, when we restart our application after making these changes, we
            can see that the form takes us to{" "}
            <code className="language-plaintext highlighter-rouge">/greet</code>
            , but the contents aren’t included in the URL anymore.
          </li>
        </ul>

        {/* #breakpoint ------------------------- */}

        <h2 id="layouts">Layouts</h2>

        <ul>
          <li>
            In{" "}
            <code className="language-plaintext highlighter-rouge">
              index.html
            </code>{" "}
            and{" "}
            <code className="language-plaintext highlighter-rouge">
              greet.html
            </code>
            , we have some repeated HTML code. With just HTML, we aren’t able to
            share code between files, but with Flask templates (and other web
            frameworks), we can factor out such common content.
          </li>
          <li>
            We’ll create another template,{" "}
            <code className="language-plaintext highlighter-rouge">
              layout.html
            </code>
            :
            <pre>
              {`
<!DOCTYPE html>

<html lang="en">
    <head>
        <title>hello</title>
    </head>
    <body>
        {% block body %}{% endblock %}
    </body>
</html>
        `}
            </pre>
            <ul>
              <li>
                Flask supports Jinja, a templating language, which uses the{" "}
                <code className="language-plaintext highlighter-rouge">{`{% %}`}</code>{" "}
                syntax to include placeholder blocks, or other chunks of code.
                Here we’ve named our block{" "}
                <code className="language-plaintext highlighter-rouge">
                  body
                </code>{" "}
                since it contains the HTML that should go in the{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;body&gt;
                </code>{" "}
                element.
              </li>
            </ul>
          </li>
          <li>
            In{" "}
            <code className="language-plaintext highlighter-rouge">
              index.html
            </code>
            , we’ll use the{" "}
            <code className="language-plaintext highlighter-rouge">
              layout.html
            </code>{" "}
            blueprint and only define the{" "}
            <code className="language-plaintext highlighter-rouge">body</code>{" "}
            block with:
            <pre>
              {`
{% extends "layout.html" %}

{% block body %}

    <form action="/greet" method="post">
        <input autocomplete="off" autofocus name="name" placeholder="Name" type="text">
        <input type="submit">
    </form>

{% endblock %}
        `}
            </pre>
          </li>
          <li>
            Similarly, in{" "}
            <code className="language-plaintext highlighter-rouge">
              greet.html
            </code>
            , we define the{" "}
            <code className="language-plaintext highlighter-rouge">body</code>{" "}
            block with just the greeting:
            <pre>
              {`
{% extends "layout.html" %}

{% block body %}

    hello, {{ name }}

{% endblock %}
    `}
            </pre>
          </li>
          <li>
            Now, if we restart our server, and view the source of our HTML after
            opening our server’s URL, we see a complete page with our form
            inside our HTML file, generated by Flask.
          </li>
          <li>
            We can even reuse the same route to support both GET and POST
            methods:
            <pre>
              {`
@app.route("/", methods=["GET", "POST"])
def index():
    if request.method == "POST":
        return render_template("greet.html", name=request.form.get("name", "world"))
    return render_template("index.html")
    `}
            </pre>
            <ul>
              <li>
                First, we check if the{" "}
                <code className="language-plaintext highlighter-rouge">
                  method
                </code>{" "}
                of the{" "}
                <code className="language-plaintext highlighter-rouge">
                  request
                </code>{" "}
                is a POST request. If so, we’ll look for the{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>{" "}
                parameter and return HTML from the{" "}
                <code className="language-plaintext highlighter-rouge">
                  greet.html
                </code>{" "}
                template. Otherwise, we’ll return HTML from the{" "}
                <code className="language-plaintext highlighter-rouge">
                  index.html
                </code>
                , which has our form.
              </li>
              <li>
                We’ll also need to change the form’s{" "}
                <code className="language-plaintext highlighter-rouge">
                  action
                </code>{" "}
                to the default{" "}
                <code className="language-plaintext highlighter-rouge">/</code>{" "}
                route.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="frosh-ims">Frosh IMs</h2>

        <ul>
          <li>
            One of David’s first web applications was for students on campus to
            register for “frosh IMs”, intramural sports.
          </li>
          <li>
            We’ll use a{" "}
            <code className="language-plaintext highlighter-rouge">
              layout.html
            </code>{" "}
            similar to what we had before:
            <pre>
              {`
<!DOCTYPE html>

<html lang="en">
    <head>
        <meta name="viewport" content="initial-scale=1, width=device-width">
        <title>froshims</title>
    </head>
    <body>
        {% block body %}{% endblock %}
    </body>
</html>
    `}
            </pre>
            <ul>
              <li>
                A{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;meta&gt;
                </code>{" "}
                tag in{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;head&gt;
                </code>{" "}
                allows us to add more metadata to our page. In this case, we’re
                adding a{" "}
                <code className="language-plaintext highlighter-rouge">
                  content
                </code>{" "}
                attribute for the{" "}
                <code className="language-plaintext highlighter-rouge">
                  viewport
                </code>{" "}
                metadata, in order to tell the browser to automamtically scale
                our page’s size and fonts to the device.
              </li>
            </ul>
          </li>
          <li>
            In our{" "}
            <code className="language-plaintext highlighter-rouge">
              application.py
            </code>
            , we’ll return our{" "}
            <code className="language-plaintext highlighter-rouge">
              index.html
            </code>{" "}
            template for the default{" "}
            <code className="language-plaintext highlighter-rouge">/</code>{" "}
            route:
            <pre>
              {`
from flask import Flask, render_template, request

app = Flask(__name__)

SPORTS = [
    "Dodgeball",
    "Flag Football",
    "Soccer",
    "Volleyball",
    "Ultimate Frisbee"
]

@app.route("/")
def index():
    return render_template("index.html")
      `}
            </pre>
          </li>
          <li>
            Our{" "}
            <code className="language-plaintext highlighter-rouge">
              index.html
            </code>{" "}
            template will look like this:
            <pre>
              {`
{% extends "layout.html" %}

{% block body %}
    <h1>Register</h1>

    <form action="/register" method="post">

        <input autocomplete="off" autofocus name="name" placeholder="Name" type="text">
        <select name="sport">
            <option disabled selected value="">Sport</option>
            <option value="Dodgeball">Dodgeball</option>
            <option value="Flag Football">Flag Football</option>
            <option value="Soccer">Soccer</option>
            <option value="Volleyball">Volleyball</option>
            <option value="Ultimate Frisbee">Ultimate Frisbee</option>
        </select>
        <input type="submit" value="Register">

    </form>
{% endblock %}
    `}
            </pre>
            <ul>
              <li>
                We’ll have a form like before, and have a{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;select&gt;
                </code>{" "}
                menu with options for each sport.
              </li>
            </ul>
          </li>
          <li>
            In our{" "}
            <code className="language-plaintext highlighter-rouge">
              application.py
            </code>
            , we’ll allow POST for our{" "}
            <code className="language-plaintext highlighter-rouge">
              /register
            </code>{" "}
            route:
            <pre>
              {`
@app.route("/register", methods=["POST"])
def register():

  if not request.form.get("name") or not request.form.get("sport"):
      return render_template("failure.html")

  return render_template("success.html")
    `}
            </pre>
            <ul>
              <li>
                We’ll check that our form’s values are valid, and then return a
                template depending on the results, even though we aren’t
                actually doing anything with the data yet.
              </li>
            </ul>
          </li>
          <li>
            But a user can change the form’s HTML in their browser, and send a
            request that contains some other sport as the selected option!
          </li>
          <li>
            We’ll check that the value for{" "}
            <code className="language-plaintext highlighter-rouge">sport</code>{" "}
            is valid by creating a list in{" "}
            <code className="language-plaintext highlighter-rouge">
              application.py
            </code>
            :
            <pre>
              {`
from flask import Flask, render_template, request

app = Flask(__name__)

SPORTS = [
    "Dodgeball",
    "Flag Football",
    "Soccer",
    "Volleyball",
    "Ultimate Frisbee"
]

@app.route("/")
def index():
    return render_template("index.html", sports=SPORTS)

...
    `}
            </pre>
            <ul>
              <li>
                Then, we’ll pass that list into the{" "}
                <code className="language-plaintext highlighter-rouge">
                  index.html
                </code>{" "}
                template.
              </li>
            </ul>
          </li>
          <li>
            In our template, we can even use loops to generate a list of options
            from the list of strings passed in as{" "}
            <code className="language-plaintext highlighter-rouge">sports</code>
            :
            <pre>
              {`
...
<select name="sport">
    <option disabled selected value="">Sport</option>
    {% for sport in sports %}
        <option value="{{ sport }}">{{ sport }}</option>
    {% endfor %}
</select>
...
    `}
            </pre>
          </li>
          <li>
            Finally, we can check that the{" "}
            <code className="language-plaintext highlighter-rouge">sport</code>{" "}
            sent in the POST request is in the list{" "}
            <code className="language-plaintext highlighter-rouge">SPORTS</code>{" "}
            in{" "}
            <code className="language-plaintext highlighter-rouge">
              application.py
            </code>
            :
            <pre>
              {`
...
@app.route("/register", methods=["POST"])
def register():

    if not request.form.get("name") or request.form.get("sport") not in SPORTS:
        return render_template("failure.html")

    return render_template("success.html")
     `}
            </pre>
          </li>
          <li>
            We can change the select menu in our form to be checkboxes, to allow
            for multiple sports:
            <pre>
              {`
{% extends "layout.html" %}

{% block body %}
    <h1>Register</h1>

    <form action="/register" method="post">

        <input autocomplete="off" autofocus name="name" placeholder="Name" type="text">
        {% for sport in sports %}
            <input name="sport" type="checkbox" value="{{ sport }}"> {{ sport }}
        {% endfor %}
        <input type="submit" value="Register">

    </form>
{% endblock %}
    `}
            </pre>
            <ul>
              <li>
                In our{" "}
                <code className="language-plaintext highlighter-rouge">
                  register
                </code>{" "}
                function, we can call{" "}
                <code className="language-plaintext highlighter-rouge">
                  request.form.getlist
                </code>{" "}
                to get the list of checked options.
              </li>
            </ul>
          </li>
          <li>
            We can also use radio buttons, which will allow only one option to
            be chosen at a time.
          </li>
        </ul>

        <h2 id="storing-data">Storing data</h2>

        <ul>
          <li>
            Let’s store our registered students, or registrants, in a dictionary
            in the memory of our web server:
            <pre>
              {`
from flask import Flask, redirect, render_template, request

app = Flask(__name__)

REGISTRANTS = {}

...

@app.route("/register", methods=["POST"])
def register():

    name = request.form.get("name")
    if not name:
        return render_template("error.html", message="Missing name")

    sport = request.form.get("sport")
    if not sport:
        return render_template("error.html", message="Missing sport")
    if sport not in SPORTS:
        return render_template("error.html", message="Invalid sport")

    REGISTRANTS[name] = sport

    return redirect("/registrants")
    `}
            </pre>
            <ul>
              <li>
                We’ll create a dictionary called{" "}
                <code className="language-plaintext highlighter-rouge">
                  REGISTRANTS
                </code>
                , and in{" "}
                <code className="language-plaintext highlighter-rouge">
                  register
                </code>{" "}
                we’ll first check the{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  sport
                </code>
                , returning a different error message in each case. Then, we can
                safely store the name and sport in our{" "}
                <code className="language-plaintext highlighter-rouge">
                  REGISTRANTS
                </code>{" "}
                dictionary, and redirect to another route that will display
                registered students.
              </li>
              <li>
                The error message template, meanwhile, will just display the
                message:
                <pre>
                  {`
{% extends "layout.html" %}

{% block body %}
    {{ message }}
{% endblock %}
    `}
                </pre>
              </li>
            </ul>
          </li>
          <li>
            Let’s add the{" "}
            <code className="language-plaintext highlighter-rouge">
              /registrants
            </code>{" "}
            route and template to show the registered students:
            <pre>
              {`
@app.route("/registrants")
def registrants():
    return render_template("registrants.html", registrants=REGISTRANTS)
    `}
            </pre>
            <ul>
              <li>
                In our route, we’ll pass in the{" "}
                <code className="language-plaintext highlighter-rouge">
                  REGISTRANTS
                </code>{" "}
                dictionary to the template as a parameter called{" "}
                <code className="language-plaintext highlighter-rouge">
                  registrants
                </code>
                :
                <pre>
                  {`
{% extends "layout.html" %}

{% block body %}
    <h1>Registrants</h1>
    <table>
        <thead>
            <tr>
                <th>Name</th>
                <th>Sport</th>
            </tr>
        </thead>
        <tbody>
            {% for name in registrants %}
                <tr>
                    <td>{{ name }}</td>
                    <td>{{ registrants[name] }}</td>
                </tr>
            {% endfor %}
        </tbody>
    </table>
{% endblock %}
    `}
                </pre>
              </li>
              <li>
                Our template will have a table, with a heading row and row for
                each key and value stored in{" "}
                <code className="language-plaintext highlighter-rouge">
                  registrants
                </code>
                .
              </li>
            </ul>
          </li>
          <li>
            If our web server stops running, we’ll lose the data stored, so
            we’ll use a SQLite database with the SQL library from{" "}
            <code className="language-plaintext highlighter-rouge">cs50</code>:
            <pre>
              {`
from cs50 import SQL
from flask import Flask, redirect, render_template, request

app = Flask(__name__)

db = SQL("sqlite:///froshims.db")

...
    `}
            </pre>
            <ul>
              <li>
                In the IDE’s terminal, we can run{" "}
                <code className="language-plaintext highlighter-rouge">
                  sqlite3 froshims.db
                </code>{" "}
                to open the database, and use the{" "}
                <code className="language-plaintext highlighter-rouge">
                  .schema
                </code>{" "}
                command to see the table with columns of{" "}
                <code className="language-plaintext highlighter-rouge">id</code>
                ,{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>
                , and{" "}
                <code className="language-plaintext highlighter-rouge">
                  sport
                </code>
                , which was created in advance.
              </li>
            </ul>
          </li>
          <li>
            Now, in our routes, we can insert and select rows with SQL:
            <pre>
              {`
@app.route("/register", methods=["POST"])
def register():

    name = request.form.get("name")
    if not name:
        return render_template("error.html", message="Missing name")
    sport = request.form.get("sport")
    if not sport:
        return render_template("error.html", message="Missing sport")
    if sport not in SPORTS:
        return render_template("error.html", message="Invalid sport")

    db.execute("INSERT INTO registrants (name, sport) VALUES(?, ?)", name, sport)

    return redirect("/registrants")


@app.route("/registrants")
def registrants():
    registrants = db.execute("SELECT * FROM registrants")
    return render_template("registrants.html", registrants=registrants)
    `}
            </pre>
            <ul>
              <li>
                Once we’ve validated the request, we can use{" "}
                <code className="language-plaintext highlighter-rouge">
                  INSERT INTO
                </code>{" "}
                to add a row, and similarly, in{" "}
                <code className="language-plaintext highlighter-rouge">
                  registrants()
                </code>
                , we can{" "}
                <code className="language-plaintext highlighter-rouge">
                  SELECT
                </code>{" "}
                all rows and pass them to the template as a list of rows.
              </li>
            </ul>
          </li>
          <li>
            Our{" "}
            <code className="language-plaintext highlighter-rouge">
              registrants.html
            </code>{" "}
            template will also need to be adjusted, since each row returned from{" "}
            <code className="language-plaintext highlighter-rouge">
              db.execute
            </code>{" "}
            is a dictionary. So we can use{" "}
            <code className="language-plaintext highlighter-rouge">
              registrant.name
            </code>{" "}
            and{" "}
            <code className="language-plaintext highlighter-rouge">
              registrant.sport
            </code>{" "}
            to access the value of each key in each row:
            <pre>
              {`
<tbody>
{% for registrant in registrants %}
    <tr>
        <td>{{ registrant.name }}</td>
        <td>{{ registrant.sport }}</td>
        <td>
            <form action="/deregister" method="post">
                <input name="id" type="hidden" value="{{ registrant.id }}">
                <input type="submit" value="Deregister">
            </form>
        </td>
    </tr>
{% endfor %}
</tbody>
    `}
            </pre>
          </li>
          <li>
            We can even email users with another library,{" "}
            <code className="language-plaintext highlighter-rouge">
              flask_mail
            </code>
            :
            <pre>
              {`
import os
import re

from flask import Flask, render_template, request
from flask_mail import Mail, Message

app = Flask(__name__)
app.config["MAIL_DEFAULT_SENDER"] = os.getenv("MAIL_DEFAULT_SENDER")
app.config["MAIL_PASSWORD"] = os.getenv("MAIL_PASSWORD")
app.config["MAIL_PORT"] = 587
app.config["MAIL_SERVER"] = "smtp.gmail.com"
app.config["MAIL_USE_TLS"] = True
app.config["MAIL_USERNAME"] = os.getenv("MAIL_USERNAME")
mail = Mail(app)
    `}
            </pre>
            <ul>
              <li>
                We’ve set some sensitive variables outside of our code, in the
                IDE’s environment, so we can avoid including them in our code.
              </li>
              <li>
                It turns out that we can provide configuration details like a
                username and password and mail server, in this case Gmail’s, to
                the{" "}
                <code className="language-plaintext highlighter-rouge">
                  Mail
                </code>{" "}
                variable, which will send mail for us.
              </li>
            </ul>
          </li>
          <li>
            Finally, in our{" "}
            <code className="language-plaintext highlighter-rouge">
              register
            </code>{" "}
            route, we can send an email to the user:
            <pre>
              {`
@app.route("/register", methods=["POST"])
def register():

    email = request.form.get("email")
    if not email:
        return render_template("error.html", message="Missing email")
    sport = request.form.get("sport")
    if not sport:
        return render_template("error.html", message="Missing sport")
    if sport not in SPORTS:
        return render_template("error.html", message="Invalid sport")

    message = Message("You are registered!", recipients=[email])
    mail.send(message)

    return render_template("success.html")
    `}
            </pre>
            <ul>
              <li>
                In our form, we’ll also need to ask for an email instead of a
                name:
                <pre>
                  {`
<input autocomplete="off" name="email" placeholder="Email" type="email">
    `}
                </pre>
              </li>
            </ul>
          </li>
          <li>
            Now, if we restart our server and use the form to provide an email,
            we’ll see that we indeed get one sent to us!
          </li>
        </ul>

        <h2 id="sessions">Sessions</h2>
        <ul>
          <li>
            <strong>Sessions</strong> are how web servers remembers information
            about each user, which enables features like allowing users to stay
            logged in.
          </li>
          <li>
            It turns out that servers can send another header in a response,
            called{" "}
            <code className="language-plaintext highlighter-rouge">
              Set-Cookie
            </code>
            :
            <pre>
              {`
HTTP/1.1 200 OK
Content-Type: text/html
Set-Cookie: session=value
...
    `}
            </pre>
            <ul>
              <li>
                <strong>Cookies</strong> are small pieces of data from a web
                server that the browser saves for us. In many cases, they are
                large random numbers or strings used to uniquely identify and
                track a user between visits.
              </li>
              <li>
                In this case, the server is asking our browser to set a cookie
                for that server, called{" "}
                <code className="language-plaintext highlighter-rouge">
                  session
                </code>{" "}
                to a value of{" "}
                <code className="language-plaintext highlighter-rouge">
                  value
                </code>
                .
              </li>
            </ul>
          </li>
          <li>
            Then, when the browser makes another request to the same server,
            it’ll send back cookies that the same server has set before:
            <pre>
              {`
GET / HTTP/1.1
Host: gmail.com
Cookie: session=value
    `}
            </pre>
          </li>
          <li>
            In the real world, amusement parks might give you a hand stamp so
            you can return after leaving. Similarly, our browser is presenting
            our cookies back to the web server, so it can remember who we are.
          </li>
          <li>
            Advertising companies might set cookies from a number of websites,
            in order to track users across all of them. In Incognito mode, by
            contrast, the browser doesn’t send any cookies set from before.
          </li>
          <li>
            In Flask, we can use the{" "}
            <code className="language-plaintext highlighter-rouge">
              flask_session
            </code>{" "}
            library to manage this for us:
            <pre>
              {`
from flask import Flask, redirect, render_template, request, session
from flask_session import Session

app = Flask(__name__)
app.config["SESSION_PERMANENT"] = False
app.config["SESSION_TYPE"] = "filesystem"
Session(app)


@app.route("/")
def index():
    if not session.get("name"):
        return redirect("/login")
    return render_template("index.html")


@app.route("/login", methods=["GET", "POST"])
def login():
    if request.method == "POST":
        session["name"] = request.form.get("name")
        return redirect("/")
    return render_template("login.html")


@app.route("/logout")
def logout():
    session["name"] = None
    return redirect("/")
    `}
            </pre>
            <ul>
              <li>
                We’ll configure the session library to use the IDE’s filesystem,
                and use{" "}
                <code className="language-plaintext highlighter-rouge">
                  session
                </code>{" "}
                like a dictionary to store a user’s name. It turns out that
                Flask will use HTTP cookies for us, to maintain this{" "}
                <code className="language-plaintext highlighter-rouge">
                  session
                </code>{" "}
                variable for each user visiting our web server. Each visitor
                will get their own{" "}
                <code className="language-plaintext highlighter-rouge">
                  session
                </code>{" "}
                variable, even though it appears to be global in our code.
              </li>
              <li>
                For our default{" "}
                <code className="language-plaintext highlighter-rouge">/</code>{" "}
                route, we’ll redirect to{" "}
                <code className="language-plaintext highlighter-rouge">
                  /login
                </code>{" "}
                if there’s no name set in{" "}
                <code className="language-plaintext highlighter-rouge">
                  session
                </code>{" "}
                for the user yet, and otherwise show a default{" "}
                <code className="language-plaintext highlighter-rouge">
                  index.html
                </code>{" "}
                template.
              </li>
              <li>
                For our{" "}
                <code className="language-plaintext highlighter-rouge">
                  /login
                </code>{" "}
                route, we’ll set{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>{" "}
                in{" "}
                <code className="language-plaintext highlighter-rouge">
                  session
                </code>{" "}
                to the form’s value sent via POST, and then redirect to the
                default route. If we visited the route via GET, we’ll render the
                login form at{" "}
                <code className="language-plaintext highlighter-rouge">
                  login.html
                </code>
                .
              </li>
              <li>
                For the{" "}
                <code className="language-plaintext highlighter-rouge">
                  /logout
                </code>{" "}
                route, we can clear the value for{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>{" "}
                in{" "}
                <code className="language-plaintext highlighter-rouge">
                  session
                </code>{" "}
                by setting it to{" "}
                <code className="language-plaintext highlighter-rouge">
                  None
                </code>
                , and redirect to{" "}
                <code className="language-plaintext highlighter-rouge">/</code>{" "}
                again.
              </li>
              <li>
                We’ll also generally need a{" "}
                <code className="language-plaintext highlighter-rouge">
                  requirements.txt
                </code>{" "}
                that includes the names of libraries we want to use, so they can
                be installed for our application, but the ones we use here have
                been preinstalled in the IDE.
              </li>
            </ul>
          </li>
          <li>
            In our{" "}
            <code className="language-plaintext highlighter-rouge">
              login.html
            </code>
            , we’ll have a form with just a name:
            <pre>
              {`
{% extends "layout.html" %}

{% block body %}

    <form action="/login" method="post">
        <input autocomplete="off" autofocus name="name" placeholder="Name" type="text">
        <input type="submit" value="Log In">
    </form>

{% endblock %}
    `}
            </pre>
          </li>
          <li>
            And in our{" "}
            <code className="language-plaintext highlighter-rouge">
              index.html
            </code>
            , we can check if{" "}
            <code className="language-plaintext highlighter-rouge">
              session.name
            </code>{" "}
            exists, and show different content:
            <pre>
              {`
{% extends "layout.html" %}

{% block body %}

    {% if session.name %}
        You are logged in as {{ session.name }}. <a href="/logout">Log out</a>.
    {% else %}
        You are not logged in. <a href="/login">Log in</a>.
    {% endif %}

{% endblock %}
    `}
            </pre>
          </li>
          <li>
            When we restart our server, go to its URL, and log in, we can see in
            the Network tab that our browser is indeed sending a{" "}
            <code className="language-plaintext highlighter-rouge">
              Cookie:
            </code>{" "}
            header in the request:
            <br />
            <img
              src={cookie_header}
              alt="Headers tab for a request with Cookie: session=... among others"
            />
          </li>
        </ul>

        <h2 id="store-shows">store, shows</h2>

        <ul>
          <li>
            We’ll look through an example,{" "}
            <a href="https://cdn.cs50.net/2020/fall/lectures/9/src9/store/">
              <code className="language-plaintext highlighter-rouge">
                store
              </code>
            </a>
            :
            <ul>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  application.py
                </code>{" "}
                initializes and configures our application to use a database and
                sessions. In{" "}
                <code className="language-plaintext highlighter-rouge">
                  index()
                </code>
                , the default route renders a list of books stored in the
                database.
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  templates/books.html
                </code>{" "}
                shows the list of{" "}
                <code className="language-plaintext highlighter-rouge">
                  books
                </code>
                , as well as a form that allows us to click “Add to Cart” for
                each of them.
              </li>
              <li>
                The{" "}
                <code className="language-plaintext highlighter-rouge">
                  /cart
                </code>{" "}
                route, in turn, stores an{" "}
                <code className="language-plaintext highlighter-rouge">id</code>{" "}
                from a POST request in the{" "}
                <code className="language-plaintext highlighter-rouge">
                  session
                </code>{" "}
                variable in a list. If the request used a GET method, however,{" "}
                <code className="language-plaintext highlighter-rouge">
                  /cart
                </code>{" "}
                would show a list of books with{" "}
                <code className="language-plaintext highlighter-rouge">id</code>
                s matching the list of{" "}
                <code className="language-plaintext highlighter-rouge">id</code>
                s stored in{" "}
                <code className="language-plaintext highlighter-rouge">
                  session
                </code>
                .
              </li>
            </ul>
          </li>
          <li>
            So, “shopping carts” on websites can be implemented with cookies and
            session variables stored on the server.
          </li>
          <li>
            When we view the source generated by our default route, we see that
            each book has its own{" "}
            <code className="language-plaintext highlighter-rouge">
              &lt;form&gt;
            </code>{" "}
            element, each with a different{" "}
            <code className="language-plaintext highlighter-rouge">id</code>{" "}
            input that’s hidden and generated. This{" "}
            <code className="language-plaintext highlighter-rouge">id</code>{" "}
            comes from the SQLite database on our server, and is sent back to
            the{" "}
            <code className="language-plaintext highlighter-rouge">/cart</code>{" "}
            route.
          </li>
          <li>
            We’ll look at another example,{" "}
            <code className="language-plaintext highlighter-rouge">shows</code>,
            where we can use both JavaScript on the <strong>front-end</strong>,
            or side that the user sees, and Python on the{" "}
            <strong>back-end</strong>, or server side.
          </li>
          <li>
            In{" "}
            <code className="language-plaintext highlighter-rouge">
              application.py
            </code>{" "}
            here, we’ll open a database,{" "}
            <code className="language-plaintext highlighter-rouge">
              shows.db
            </code>
            :
            <pre>
              {`
from cs50 import SQL
from flask import Flask, render_template, request

app = Flask(__name__)

db = SQL("sqlite:///shows.db")


@app.route("/")
def index():
    return render_template("index.html")


@app.route("/search")
def search():
    shows = db.execute("SELECT * FROM shows WHERE title LIKE ?", "%" + request.args.get("q") + "%")
    return render_template("search.html", shows=shows)
    `}
            </pre>
            <ul>
              <li>
                The default{" "}
                <code className="language-plaintext highlighter-rouge">/</code>{" "}
                route will show a form, where we can type in some search term.
              </li>
              <li>
                The form will use the GET method to send the search query to{" "}
                <code className="language-plaintext highlighter-rouge">
                  /search
                </code>
                , which in turn will use the database to find a list of shows
                that match. Finally, a{" "}
                <code className="language-plaintext highlighter-rouge">
                  search.html
                </code>{" "}
                template will show the list of shows.
              </li>
            </ul>
          </li>
          <li>
            With JavaScript, we can show a partial list of results as we type.
            First, we’ll use a function called{" "}
            <code className="language-plaintext highlighter-rouge">
              jsonify
            </code>{" "}
            to return our shows in the JSON format, a standard format that
            JavaScript can use.
            <pre>
              {`
@app.route("/search")
def search():
    shows = db.execute("SELECT * FROM shows WHERE title LIKE ?", "%" + request.args.get("q") + "%")
    return jsonify(shows)
    `}
            </pre>
            <ul>
              <li>
                Now we can submit a search query, and see that we get back a
                list of dictionaries:
                <br />
                <img
                  src={json}
                  alt="Response for search?q=office with list in JSON"
                />
              </li>
            </ul>
          </li>
          <li>
            Then, our{" "}
            <code className="language-plaintext highlighter-rouge">
              index.html
            </code>{" "}
            template can convert this list to elements in the DOM:
            <pre>
              {`
<!DOCTYPE html>

<html lang="en">
    <head>
        <meta name="viewport" content="initial-scale=1, width=device-width">
        <title>shows</title>
    </head>
    <body>

        <input autocomplete="off" autofocus placeholder="Query" type="search">

        <ul></ul>

        <script crossorigin="anonymous" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
        <script>

            let input = document.querySelector('input');
            input.addEventListener('keyup', function() {
                $.get('/search?q=' + input.value, function(shows) {
                  let html = '';
                  for (let id in shows)
                  {
                      let title = shows[id].title;
                      html += '<li>' + title + '</li>';
                  }

                  document.querySelector('ul').innerHTML = html;
                });
            });

        </script>

    </body>
</html>
    `}
            </pre>
            <ul>
              <li>
                We’ll use another library, JQuery, to make requests more easily.
              </li>
              <li>
                We’ll listen to changes in the{" "}
                <code className="language-plaintext highlighter-rouge">
                  input
                </code>{" "}
                element, and use{" "}
                <code className="language-plaintext highlighter-rouge">
                  $.get
                </code>
                , which calls a JQuery library function to make a GET request
                with the input’s value. Then, the response will be passed to an
                anonymous function as the variable{" "}
                <code className="language-plaintext highlighter-rouge">
                  shows
                </code>
                , which will set the DOM with generated{" "}
                <code className="language-plaintext highlighter-rouge">
                  &lt;li&gt;
                </code>{" "}
                elements based on the response’s data.
              </li>
              <li>
                <code className="language-plaintext highlighter-rouge">
                  $.get
                </code>{" "}
                is an <strong>AJAX</strong> call, which allows for JavaScript to
                make additional HTTP requests after the page has loaded, to get
                more data. If we open the Network tab again, we can indeed see
                that each key we pressed made another request, with a response:
                <br />
                <img
                  src={ajax}
                  alt="Network tab with requests for search?q=of, search?q=off, etc. and Response with JSON"
                />
              </li>
              <li>
                Since the network request might be slow, the anonymous function
                we pass to{" "}
                <code className="language-plaintext highlighter-rouge">
                  $.get
                </code>{" "}
                is a <strong>callback</strong> function, which is only called
                after we get a response from the server. In the meantime, the
                browser can run other JavaScript code.
              </li>
            </ul>
          </li>
          <li>That’s it for today!</li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Note9;
