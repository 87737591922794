import React from "react";
import "./Course.css";
import { BASE_URL } from "./../../urls";
import { Link } from "react-router-dom";

function Course({ course }) {
  return (
    <>
      <Link
        className="course-card"
        to={course.title === "CS50x Iraq" ? `/week0` : `/courses/` + course.id}
      >
        <div>
          <div
            className="card-img"
            style={{
              backgroundImage: `linear-gradient(rgb(0 0 0 / 52%), rgb(0 0 0 / 76%)), url(${
                BASE_URL + course.image
              })`,
            }}
          >
            <span className="title" style={{ color: "var(--s-blue)" }}>
              {course.title}
            </span>
          </div>
          <div className="details">
            <span>
              {" "}
              <b> DURATION</b>{" "}
            </span>
            <div className="duration-level">
              <span className="date-time">{course.duration} Weeks </span>
              <div>
                <span className="level">{course.level}</span>
                <img src="" alt="" />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default Course;
