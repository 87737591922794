import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

function Hello() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main>
        <a data-id="" id="hello"></a>
        <h1>
          <a data-id="" href="#hello">
            Hello
          </a>
        </h1>

        <p>
          Implement a program that prints out a simple greeting to the user, per
          the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ python hello.py
                <br />
                What is your name?
                <br />
                David
                <br />
                hello, David
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="specification"></a>
        <h2>
          <a data-id="" href="#specification">
            Specification
          </a>
        </h2>

        <p>
          Write, in a file called{" "}
          <code className="highlighter-rouge">hello.py</code> in{" "}
          <code className="highlighter-rouge">~/pset6/hello</code>, a program
          that prompts a user for their name, and then prints{" "}
          <code className="highlighter-rouge">hello, so-and-so</code>, where{" "}
          <code className="highlighter-rouge">so-and-so</code> is their provided
          name, exactly as you did in <a href="../../1/">Problem Set 1</a>,
          except that your program this time should be written (a) in Python and
          (b) in CS50 IDE.
        </p>

        <a data-id="" id="usage"></a>
        <h2>
          <a data-id="" href="#usage">
            Usage
          </a>
        </h2>

        <p>Your program should behave per the example below.</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                $ python hello.py
                <br />
                What is your name?
                <br />
                Emma
                <br />
                hello, Emma
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="testing"></a>
        <h2>
          <a data-id="" href="#testing">
            Workthrough
          </a>
        </h2>

        <iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="embed-responsive-item"
          src={t("pset6_hello")}
          scrolling="no"
          style={{
            width: "100%",
            height: "700px",
          }}
        ></iframe>

        <a data-id="" id="testing"></a>
        <h2>
          <a data-id="" href="#testing">
            Testing
          </a>
        </h2>

        <p>
          No <code className="highlighter-rouge">check50</code> for this
          problem, but be sure to test your code for each of the following.
        </p>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python hello.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">Emma</code> and press enter.
            Your program should output{" "}
            <code className="highlighter-rouge">hello, Emma</code>.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Run your program as{" "}
            <code className="highlighter-rouge">python hello.py</code>, and wait
            for a prompt for input. Type in{" "}
            <code className="highlighter-rouge">Rodrigo</code> and press enter.
            Your program should output{" "}
            <code className="highlighter-rouge">hello, Rodrigo</code>.
          </li>
        </ul>

        <a data-id="" id="how-to-submit"></a>
        <h2>
          <a data-id="" href="#how-to-submit">
            How to Submit
          </a>
        </h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2020/x/sentimental/hello</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Hello;
