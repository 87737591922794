import React, { useState } from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";
import Sidenav from "./../../components/Sidenav";
import "./../weeks.css";

function Week5() {
  const { t } = useTranslation();
  const [video, setVideo] = useState(
    "https://www.youtube.com/embed/4IrUAqYKjIA"
  );
  return (
    <React.Fragment>
      <Sidenav />
      <div className="center-week container">
        <h1 id="week-5">{t("week")} 5</h1>
        <h2 id="data-structures">{t("data-structures")}</h2>
        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
          style={{ maxWidth: "720px" }}
        >
          <Iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={t("Lec5")}
          ></Iframe>
        </div>

        <ul className="mt-5">
          <li data-marker="-">
            {t("Lecture")}
            <ul>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/5/lecture5.mp3">
                  Audio
                </a>
              </li>
              <li data-marker="*">
                <Link to="note5">{t("Notes")}</Link>
              </li>
              <li data-marker="+">
                {t("Slides")}
                <ul>
                  <li data-marker="*">
                    <a href="https://docs.google.com/presentation/d/1kFKPHpbjTJGfoveZmHHydVEb3dSi3E7s5V1xSxeli-0/edit?usp=sharing">
                      {t("Google-slides")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/5/lecture5.pdf">
                      PDF
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="+">
                {t("Source-Code")}
                <ul>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/5/src5/">
                      {t("Index")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/5/src5.pdf">
                      PDF
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/5/src5.zip">
                      Zip
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/5/lang/en/lecture5.srt">
                  Subtitles
                </a>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/5/lang/en/lecture5.txt">
                  Transcript
                </a>
              </li>
              <li data-marker="+">
                {t("Video")}
                <ul>
                  <li data-marker="*">
                    <a href="https://video.cs50.io/2T-A_GFuoTo?screen=QdhPqzoTyUE">
                      CS50 Video Player
                    </a>
                  </li>
                  <li data-marker="+">
                    MP4
                    <ul>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/5/lecture5-360p.mp4">
                          360p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/5/lecture5-720p.mp4">
                          720p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/5/lecture5-1080p.mp4">
                          1080p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/5/lecture5-4k.mp4">
                          4K
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li data-marker="*">
                    <a href="https://youtu.be/2T-A_GFuoTo">YouTube</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-marker="-">
            {t("Short")}
            <ol>
              <li>
                <a href="https://youtu.be/3uGchQbk7g8">Data Structures</a>
              </li>
              <li>
                <a href="https://youtu.be/zQI3FyWm144">Singly-Linked Lists</a>
              </li>
              <li>
                <a href="https://youtu.be/nvzVHwrrub0">Hash Tables</a>
              </li>
              <li>
                <a href="https://youtu.be/MC-iQHFdEDI">Tries</a>
              </li>
            </ol>
          </li>
          <li data-marker="*">
            <a href="https://cs50.harvard.edu/x/2021/labs/5/">Lab 5</a>
          </li>
          <li data-marker="*">
            <Link to="pset5">{t("Problem")} 5 </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default Week5;
