import React, { useState } from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";
import Sidenav from "./../../components/Sidenav";
import "./../weeks.css";
function Week2() {
  const { t } = useTranslation();
  const [video, setVideo] = useState(t("Lec2"));

  return (
    <React.Fragment>
      <Sidenav />
      <div className="center-week container">
        <h1 id="week-2">{t("week")} 2</h1>

        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
          style={{ maxWidth: "720px" }}
        >
          <Iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={video}
          ></Iframe>
        </div>

        <ul className="mt-5">
          <li data-marker="-">
            {t("Lecture")}
            <ul>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/2/lecture2.mp3">
                  Audio
                </a>
              </li>
              <li data-marker="*">
                <Link to="note2">{t("Notes")}</Link>
              </li>
              <li data-marker="+">
                {t("Slides")}
                <ul>
                  <li data-marker="*">
                    <a href="https://docs.google.com/presentation/d/1oebR4o4bfHe-4j1jRhv3VS9uk1NqJ___VUhKygI6_JU/edit?usp=sharing">
                      {t("Google-slides")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/2/lecture2.pdf">
                      PDF
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="+">
                {t("Source-Code")}
                <ul>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/2/src2/">
                      {t("Index")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/2/src2.pdf">
                      PDF
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/2/src2.zip">
                      Zip
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/2/lang/en/lecture2.srt">
                  Subtitles
                </a>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/2/lang/en/lecture2.txt">
                  Transcript
                </a>
              </li>
              <li data-marker="+">
                {t("Video")}
                <ul>
                  <li data-marker="*">
                    <a href="https://video.cs50.io/tI_tIZFyKBw?screen=SOJ4zTf_nkg">
                      CS50 Video Player
                    </a>
                  </li>
                  <li data-marker="+">
                    MP4
                    <ul>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/2/lecture2-360p.mp4">
                          360p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/2/lecture2-720p.mp4">
                          720p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/2/lecture2-1080p.mp4">
                          1080p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/2/lecture2-4k.mp4">
                          4K
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li data-marker="*">
                    <a href="https://youtu.be/tI_tIZFyKBw">YouTube</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-marker="-">
            {t("Short")}
            <ol>
              <li>
                <a href="https://youtu.be/n1glFqt3g38">Functions</a>
              </li>
              <li>
                <a href="https://youtu.be/GiFbdVGjF9I">Variables and Scope</a>
              </li>
              <li>
                <a href="https://youtu.be/K1yC1xshF40">Arrays</a>
              </li>
              <li>
                <a href="https://youtu.be/AI6Ccfno6Pk">
                  Command Line Arguments
                </a>
              </li>
            </ol>
          </li>
          <li data-marker="*">
            <a href="https://cs50.harvard.edu/x/2021/labs/2/">Lab 2</a>
          </li>
          <li data-marker="*">
            <Link to="pset2">Problem Set 2</Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default Week2;
