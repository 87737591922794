import React, { useState } from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";

function Week6() {
  const { t } = useTranslation();
  const [video, setVideo] = useState(
    "https://www.youtube.com/embed/kM4oZTJaO8k"
  );
  return (
    <React.Fragment>
      <h1 id="week-6">{t("week")} 6</h1>
      <div
        className="border embed-responsive embed-responsive-16by9"
        data-video=""
        style={{ maxWidth: "720px" }}
      >
        <Iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="embed-responsive-item"
          src={video}
        ></Iframe>
      </div>
      <ul>
        <li>
          {t("Lecture")}
          <ul>
            <li>
              Demos
              <ul>
                <li>
                  <Link to="https://github.com/alievk/avatarify">
                    Avatarify
                  </Link>
                </li>
                <li>
                  <Link to="https://github.com/ageitgey/face_recognition">
                    Face Recognition
                  </Link>
                </li>
                <li>
                  <Link to="https://github.com/lincolnloop/python-qrcode">
                    Pure python QR Code generator
                  </Link>
                </li>
                <li>
                  <Link to="https://github.com/Uberi/speech_recognition">
                    SpeechRecognition
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="note6">{t("Notes")}</Link>
            </li>
            <li>
              {t("Slides")}
              <ul>
                <li>
                  <Link to="https://docs.google.com/presentation/d/16hi8SlpyO7qS0QRKUrpm_sbwK-YfG0QTyGfEjVh9fJs/edit?usp=sharing">
                    Google Slides
                  </Link>
                </li>
                <li>
                  <Link to="https://cdn.cs50.net/2020/fall/lectures/6/lecture6.pdf">
                    PDF
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              {t("Source-Code")}
              <ul>
                <li>
                  <Link to="https://cdn.cs50.net/2020/fall/lectures/6/src6/">
                    Index
                  </Link>
                </li>
                <li>
                  <Link to="https://cdn.cs50.net/2020/fall/lectures/6/src6.pdf">
                    PDF
                  </Link>
                </li>
                <li>
                  <Link to="https://cdn.cs50.net/2020/fall/lectures/6/src6.zip">
                    Zip
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              Video
              <ul>
                <li>
                  {" "}
                  <span
                    className="video-link"
                    onClick={() => {
                      setVideo("https://www.youtube.com/embed/kM4oZTJaO8k");
                    }}
                  >
                    {" "}
                    CS50 Video{" "}
                  </span>{" "}
                </li>
                <li>
                  <a href="https://youtube.com/watch?v=kM4oZTJaO8k&amp;start=624">
                    YouTube
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          {t("Short")}
          <ul>
            <li>
              {" "}
              <span
                className="video-link"
                onClick={() => {
                  setVideo("https://www.youtube.com/embed/mgBpcQRDtl0");
                }}
              >
                {" "}
                {t("Python")}
              </span>{" "}
            </li>
          </ul>
        </li>
        <li>
          <Link to="./Pset6">{t("Problem")} 6</Link>
        </li>
      </ul>
    </React.Fragment>
  );
}

export default Week6;
