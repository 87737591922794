import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { BASE_URL } from "./../../urls";
import { useHistory } from "react-router-dom";
import CustomSidenav from "../../components/CustomSidenav/CustomSidenav";
import "./CourseDetails.css";
function CoursesDetails({ data }) {
  const history = useHistory();
  const { id } = useParams();
  const [weeks, setWeeks] = useState([]);
  const [title, setTitle] = useState("");
  const [currentVideo, setCurrentVideo] = useState(0);
  const [current, setCurrent] = useState({
    videos: [{ video_id: "" }],
  });

  // window.location.reload();

  const setCurrentWeek = (data) => {
    setCurrent(data);
    setCurrentVideo(data.videos[0].video_id);
  };

  const getCourseDetails = async (id) => {
    const token = localStorage.getItem("access_token");
    const response = await fetch(`${BASE_URL}/api/academy/data/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    try {
      if (response.ok) {
        // user is Authenticated
        let result = await response.json();
        setTitle(result.title);
        setWeeks(result.weeks);
        setCurrent(result.weeks[0]);
        setCurrentVideo(result.weeks[0].videos[0].video_id);
        return result;
      } else {
        // Please login to see your dashboard
        history.push("/login");
      }
      // localStorage.setItem("access_token", result.token.access_token);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getCourseDetails(id);
  }, [id]);

  return (
    <>
      <CustomSidenav setCurrentWeek={setCurrentWeek} weeks={weeks} />
      <div className="course-player">
        <h1>{current.title}</h1>
        <iframe
          className="video-player"
          width="60%"
          height="400px"
          src={"https://www.youtube.com/embed/" + currentVideo}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        <div className="navigation-list">
          {current.videos.map((video, index) => {
            return (
              <div
                className={video.video_id === currentVideo ? "current" : ""}
                onClick={() => setCurrentVideo(video.video_id)}
                key={index}
              >
                {index + 1}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default CoursesDetails;
