import React, { useRef } from "react";

import running_time from "./img/running_time.png";

function Note3() {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();
  const ref10 = useRef();
  const ref11 = useRef();

  const handleClick = (e, reference) => {
    e.preventDefault();
    reference.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 className="no_toc" id="lecture-3">
          Lecture 3
        </h1>

        <ul id="markdown-toc">
          <li>
            <a
              href="#last-week"
              id="markdown-toc-last-week"
              onClick={(e) => handleClick(e, ref1)}
            >
              Last week
            </a>
          </li>
          <li>
            <a
              href="#searching"
              id="markdown-toc-searching"
              onClick={(e) => handleClick(e, ref2)}
            >
              Searching
            </a>{" "}
            <ul>
              <li>
                <a
                  href="#big-o"
                  id="markdown-toc-big-o"
                  onClick={(e) => handleClick(e, ref3)}
                >
                  Big O
                </a>
              </li>
              <li>
                <a
                  href="#linear-search-binary-search"
                  id="markdown-toc-linear-search-binary-search"
                  onClick={(e) => handleClick(e, ref4)}
                >
                  Linear search, binary search
                </a>
              </li>
              <li>
                <a
                  href="#searching-with-code"
                  id="markdown-toc-searching-with-code"
                  onClick={(e) => handleClick(e, ref5)}
                >
                  Searching with code
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#structs"
              id="markdown-toc-structs"
              onClick={(e) => handleClick(e, ref6)}
            >
              Structs
            </a>
          </li>
          <li>
            <a
              href="#sorting"
              id="markdown-toc-sorting"
              onClick={(e) => handleClick(e, ref7)}
            >
              Sorting
            </a>{" "}
            <ul>
              <li>
                <a
                  href="#selection-sort"
                  id="markdown-toc-selection-sort"
                  onClick={(e) => handleClick(e, ref8)}
                >
                  Selection sort
                </a>
              </li>
              <li>
                <a
                  href="#bubble-sort"
                  id="markdown-toc-bubble-sort"
                  onClick={(e) => handleClick(e, ref9)}
                >
                  Bubble sort
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#recursion"
              id="markdown-toc-recursion"
              onClick={(e) => handleClick(e, ref10)}
            >
              Recursion
            </a>
          </li>
          <li>
            <a
              href="#merge-sort"
              id="markdown-toc-merge-sort"
              onClick={(e) => handleClick(e, ref11)}
            >
              Merge sort
            </a>
          </li>
        </ul>

        <h2 id="last-week" ref={ref1}>
          Last week
        </h2>

        <ul>
          <li data-marker="*">
            We learned about tools to solve problems, or bugs, in our code. In
            particular, we discovered how to use a debugger, a tool that allows
            us to step slowly through our code and look at values in memory
            while our program is running.
          </li>
          <li data-marker="*">
            Another powerful, if less technical, tool is rubber duck debugging,
            where we try to explain what we’re trying to do to a rubber duck (or
            some other object), and in the process realize the problem (and
            hopefully solution!) on our own.
          </li>
          <li data-marker="*">
            We looked at memory, visualizing bytes in a grid and storing values
            in each box, or byte, with variables and arrays.
          </li>
        </ul>

        <h2 id="searching" ref={ref2}>
          Searching
        </h2>

        <ul>
          <li data-marker="*">
            It turns out that, with arrays, a computer can’t look at all of the
            elements at once. Instead, a computer can only to look at them one
            at a time, though the order can be arbitrary. (Recall in Week 0,
            David could only look at one page at a time in a phone book, whether
            he flipped through in order or in a more sophisticated way.)
          </li>
          <li data-marker="*">
            <strong>Searching</strong> is how we solve the problem of finding a
            particular value. A simple case might have an input of some array of
            values, and the output might simply be a{" "}
            <code className="language-plaintext highlighter-rouge">bool</code>,
            whether or not a particular value is in the array.
          </li>
          <li data-marker="*">
            Today we’ll look at algorithms for searching. To discuss them, we’ll
            consider <strong>running time</strong>, or how long an algorithm
            takes to run given some size of input.
          </li>
        </ul>

        <h3 id="big-o" ref={ref3}>
          Big O
        </h3>

        <ul>
          <li data-marker="*">
            In week 0, we saw different types of algorithms and their running
            times:
            <br />
            <img
              src={running_time}
              alt='chart with: "size of problem" as x–axis; "time to solve" as y–axis; red, steep straight line from origin to top of graph labeled "n"; yellow, less steep straight line from origin to top of graph labeled "n/2"; green, curved line that gets less and less steep from origin to right of graph labeled "log_2 n"'
            />
            <br />
            <br />
            <ul>
              <li data-marker="*">
                Recall that the red line is searching linearly, one page at a
                time; the yellow line is searching two pages at a time; and the
                green line is searching logarithmically, dividing the problem in
                half each time.
              </li>
              <li data-marker="*">
                And these running times are for the worst case, or the case
                where the value takes the longest to find (on the last page, as
                opposed to the first page).
              </li>
            </ul>
          </li>
          <li data-marker="*">
            The more formal way to describe each of these running times is with{" "}
            <strong>big O notation</strong>, which we can think of as “on the
            order of”. For example, if our algorithm is linear search, it will
            take approximately O(n) steps, read as “big O of n” or “on the order
            of n”. In fact, even an algorithm that looks at two items at a time
            and takes (n<sup>2</sup>) steps has O(n). This is because, as n gets
            bigger and bigger, only the dominant factor, or largest term, n,
            matters. In the chart above, if we zoomed out and changed the units
            on our axes, we would see the red and yellow lines end up very close
            together.
          </li>
          <li data-marker="*">
            A logarithmic running time is O(log n), no matter what the base is,
            since this is just an approximation of what fundamentally happens to
            running time if n is very large.
          </li>
          <li data-marker="*">
            There are some common running times:
            <ul>
              <li data-marker="*">
                O(n<sup>2</sup>)
              </li>
              <li data-marker="*">O(n log n)</li>
              <li data-marker="*">
                O(n)
                <ul>
                  <li data-marker="*">
                    (searching one page at a time, in order)
                  </li>
                </ul>
              </li>
              <li data-marker="*">
                O(log n)
                <ul>
                  <li data-marker="*">
                    (dividing the phone book in half each time)
                  </li>
                </ul>
              </li>
              <li data-marker="*">
                O(1)
                <ul>
                  <li data-marker="*">
                    An algorithm that takes a <strong>constant</strong> number
                    of steps, regardless of how big the problem is.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Computer scientists might also use big Ω, big Omega notation, which
            is the lower bound of number of steps for our algorithm. Big O is
            the upper bound of number of steps, or the worst case.
          </li>
          <li data-marker="*">
            And we have a similar set of the most common big Ω running times:
            <ul>
              <li data-marker="*">
                Ω(n<sup>2</sup>)
              </li>
              <li data-marker="*">Ω(n log n)</li>
              <li data-marker="*">Ω(n)</li>
              <li data-marker="*">Ω(log n)</li>
              <li data-marker="*">
                Ω(1)
                <ul>
                  <li data-marker="*">
                    (searching in a phone book, since we might find our name on
                    the first page we check)
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>

        <h3 id="linear-search-binary-search" ref={ref4}>
          Linear search, binary search
        </h3>

        <ul>
          <li data-marker="*">
            On stage, we have a few prop doors, with numbers hidden behind them.
            Since a computer can only look at one element in an array at a time,
            we can only open one door at a time as well.
          </li>
          <li data-marker="*">
            If we want to look for the number zero, for example, we would have
            to open one door at a time, and if we didn’t know anything about the
            numbers behind the doors, the simplest algorithm would be going from
            left to right.
          </li>
          <li data-marker="*">
            So, we might write pseudocode for <strong>linear search</strong>{" "}
            with:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`For i from 0 to n–1\n   If number behind i'th door\n   Return true\nReturn false`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We label each of{" "}
                <code className="language-plaintext highlighter-rouge">n</code>{" "}
                doors from{" "}
                <code className="language-plaintext highlighter-rouge">0</code>{" "}
                to{" "}
                <code className="language-plaintext highlighter-rouge">
                  n–1
                </code>
                , and check each of them in order.
              </li>
              <li data-marker="*">
                “Return false” is <em>outside</em> the for loop, since we only
                want to do that after we’ve looked behind <em>all</em> the
                doors.
              </li>
              <li data-marker="*">
                The big O running time for this algorithm would be O(n), and the
                lower bound, big Omega, would be Ω(1).
              </li>
            </ul>
          </li>
          <li data-marker="*">
            If we know that the numbers behind the doors are sorted, then we can
            start in the middle, and find our value more efficiently.
          </li>
          <li data-marker="*">
            For binary search, our algorithm might look like:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`If no doors
    Return false
If number behind middle door
    Return true
Else if number < middle door
    Search left half
Else if number > middle door
    Search right half`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                The upper bound for binary search is O(log n), and the lower
                bound also Ω(1), if the number we’re looking for is in the
                middle, where we happen to start.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            With 64 light bulbs, we notice that linear search takes much longer
            than binary search, which only takes a few steps.
          </li>
          <li data-marker="*">
            We turned off the light bulbs at a frequency of one{" "}
            <strong>hertz</strong>, or cycle per second, and a processor’s speed
            might be measured in gigahertz, or billions of operations per
            second.
          </li>
        </ul>

        <h3 id="searching-with-code" ref={ref5}>
          Searching with code
        </h3>

        <ul>
          <li data-marker="*">
            Let’s take a look at{" "}
            <code className="language-plaintext highlighter-rouge">
              numbers.c
            </code>
            :
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <stdio.h>

int main(void)
{
    int numbers[] = {4, 6, 8, 2, 7, 5, 0};

    for (int i = 0; i < 7; i++)
    {
        if (numbers[i] == 0)
        {
            printf("Found\\n");
            return 0;
        }
    }
    printf("Not found\\n");
    return 1;
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Here we initialize an array with some values in curly braces,
                and we check the items in the array one at a time, in order, to
                see if they’re equal to zero (what we were originally looking
                for behind the doors on stage).
              </li>
              <li data-marker="*">
                If we find the value of zero, we return an exit code of 0 (to
                indicate success). Otherwise, <em>after</em> our for loop, we
                return 1 (to indicate failure).
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can do the same for names:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <stdio.h>
#include <string.h>

int main(void)
{
    string names[] = {"Bill", "Charlie", "Fred", "George", "Ginny", "Percy", "Ron"};

    for (int i = 0; i < 7; i++)
    {
        if (strcmp(names[i], "Ron") == 0)
        {
            printf("Found\\n");
            return 0;
        }
    }
    printf("Not found\\n");
    return 1;
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Note that{" "}
                <code className="language-plaintext highlighter-rouge">
                  names
                </code>{" "}
                is a sorted array of strings.
              </li>
              <li data-marker="*">
                We can’t compare strings directly in C, since they’re not a
                simple data type but rather an array of many characters.
                Luckily, the{" "}
                <code className="language-plaintext highlighter-rouge">
                  string
                </code>{" "}
                library has a{" "}
                <code className="language-plaintext highlighter-rouge">
                  strcmp
                </code>{" "}
                (“string compare”) function which compares strings for us, one
                character at a time, and returns{" "}
                <code className="language-plaintext highlighter-rouge">0</code>{" "}
                if they’re the same.
              </li>
              <li data-marker="*">
                If we only check for{" "}
                <code className="language-plaintext highlighter-rouge">
                  strcmp(names[i], "Ron")
                </code>{" "}
                and not{" "}
                <code className="language-plaintext highlighter-rouge">
                  strcmp(names[i], "Ron") == 0
                </code>
                , then we’ll print{" "}
                <code className="language-plaintext highlighter-rouge">
                  Found
                </code>{" "}
                even if the name isn’t found. This is because{" "}
                <code className="language-plaintext highlighter-rouge">
                  strcmp
                </code>{" "}
                returns a value that isn’t{" "}
                <code className="language-plaintext highlighter-rouge">0</code>{" "}
                if two strings <em>don’t</em> match, and any nonzero value is
                equivalent to true in a condition.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="structs" ref={ref6}>
          Structs
        </h2>

        <ul>
          <li data-marker="*">
            If we wanted to implement a program that searches a phone book, we
            might want a data type for a “person”, with their name and phone
            number.
          </li>
          <li data-marker="*">
            It turns out in C that we can define our own data type, or data{" "}
            <em>structure</em>, with a <strong>struct</strong> in the following
            syntax:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`typedef struct
{
    string name;
    string number;
}
person;`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We use{" "}
                <code className="language-plaintext highlighter-rouge">
                  string
                </code>{" "}
                for the{" "}
                <code className="language-plaintext highlighter-rouge">
                  number
                </code>
                , since we want to include symbols and formatting, like plus
                signs or hyphens.
              </li>
              <li data-marker="*">
                Our struct contains other data types inside it.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Let’s try to implement our phone book without structs first:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <stdio.h>
#include <string.h>

int main(void)
{
    string names[] = {"Brian", "David"};
    string numbers[] = {"+1-617-495-1000", "+1-949-468-2750"};

    for (int i = 0; i < 2; i++)
    {
        if (strcmp(names[i], "David") == 0)
        {
            printf("Found %s\\n", numbers[i]);
            return 0;
        }
    }
    printf("Not found\\n");
    return 1;
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We’ll need to be careful to make sure that the firstname in{" "}
                <code className="language-plaintext highlighter-rouge">
                  names
                </code>{" "}
                matches the first number in{" "}
                <code className="language-plaintext highlighter-rouge">
                  numbers
                </code>
                , and so on.
              </li>
              <li data-marker="*">
                If the name at a certain index{" "}
                <code className="language-plaintext highlighter-rouge">i</code>{" "}
                in the{" "}
                <code className="language-plaintext highlighter-rouge">
                  names
                </code>{" "}
                array matches who we’re looking for, we can return the phone
                number in the{" "}
                <code className="language-plaintext highlighter-rouge">
                  numbers
                </code>{" "}
                array at the same index.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            With structs, we can be a little more confident that we won’t have
            human errors in our program:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <stdio.h>
#include <string.h>

typedef struct
{
    string name;
    string number;
}
person;

int main(void)
{
    person people[2];

    people[0].name = "Brian";
    people[0].number = "+1-617-495-1000";

    people[1].name = "David";
    people[1].number = "+1-949-468-2750";

    for (int i = 0; i < 2; i++)
    {
        if (strcmp(people[i].name, "David") == 0)
        {
            printf("Found %s\\n", people[i].number);
            return 0;
        }
    }
    printf("Not found\\n");
    return 1;
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We create an array of the{" "}
                <code className="language-plaintext highlighter-rouge">
                  person
                </code>{" "}
                struct type, and name it{" "}
                <code className="language-plaintext highlighter-rouge">
                  people
                </code>{" "}
                (as in{" "}
                <code className="language-plaintext highlighter-rouge">
                  int numbers[]
                </code>
                , though we could name it arbitrarily, like any other variable).
                We set the values for each field, or variable, inside each{" "}
                <code className="language-plaintext highlighter-rouge">
                  person
                </code>{" "}
                struct, using the dot operator,{" "}
                <code className="language-plaintext highlighter-rouge">.</code>.
              </li>
              <li data-marker="*">
                In our loop, we can now be more certain that the{" "}
                <code className="language-plaintext highlighter-rouge">
                  number
                </code>{" "}
                corresponds to the{" "}
                <code className="language-plaintext highlighter-rouge">
                  name
                </code>{" "}
                since they are from the same{" "}
                <code className="language-plaintext highlighter-rouge">
                  person
                </code>{" "}
                struct.
              </li>
              <li data-marker="*">
                We can also improve the design of our program with a constant,
                like{" "}
                <code className="language-plaintext highlighter-rouge">
                  const int NUMBER = 10;
                </code>
                , and store our values not in our code but in a separate file or
                even a database, which we’ll soon see.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Soon too, we’ll write our own header files with definitions for
            structs, so they can be shared across different files for our
            program.
          </li>
        </ul>

        <h2 id="sorting" ref={ref7}>
          Sorting
        </h2>

        <ul>
          <li data-marker="*">
            If our input is an unsorted list of numbers, there are many
            algorithms we could use to produce an output of a sorted list, where
            all the elements are in order.
          </li>
          <li data-marker="*">
            With a sorted list, we can use binary search for efficiency, but it
            might take more time to write a sorting algorithm for that
            efficiency, so sometimes we’ll encounter the tradeoff of time it
            takes a human to write a program compared to the time it takes a
            computer to run some algorithm. Other tradeoffs we’ll see might be
            time and complexity, or time and memory usage.
          </li>
        </ul>

        <h3 id="selection-sort" ref={ref8}>
          Selection sort
        </h3>

        <ul>
          <li data-marker="*">
            Brian is backstage with a set of numbers on a shelf, in unsorted
            order:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>6 3 8 5 2 7 4 1</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Taking some numbers and moving them to their right place, Brian
            sorts the numbers pretty quickly.
          </li>
          <li data-marker="*">
            Going step-by-step, Brian looks at each number in the list,
            remembering the smallest one we’ve seen so far. He gets to the end,
            and sees that 1 is the smallest, and he knows that must go at the
            beginning, so he’ll just swap it with the number at the beginning,
            6:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`6 3 8 5 2 7 4 1
–             –
1 3 8 5 2 7 4 6`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Now Brian knows at least the first number is in the right place, so
            he can look for the smallest number among the rest, and swap it with
            the next unsorted number (now the second number):
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`1 3 8 5 2 7 4 6
  –     –
1 2 8 5 3 7 4 6`}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            And he repeats this again, swapping the next smallest, 3, with the
            8:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`1 2 8 5 3 7 4 6
    -   -
1 2 3 5 8 7 4 6`}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            After a few more swaps, we end up with a sorted list.
          </li>
          <li data-marker="*">
            This algorithm is called <strong>selection sort</strong>, and we can
            be a bit more specific with some pseudocode:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`For i from 0 to n–1
    Find smallest item between i'th item and last item
    Swap smallest item with i'th item`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                The first step in the loop is to look for the smallest item in
                the unsorted part of the list, which will be between the i’th
                item and last item, since we know we’ve sorted up to the
                “i-1’th” item.
              </li>
              <li data-marker="*">
                Then, we swap the smallest item with the i’th item, which makes
                everything up to the item at i sorted.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We look at a{" "}
            <a href="https://www.cs.usfca.edu/~galles/visualization/ComparisonSort.html">
              visualization online
            </a>{" "}
            with animations for how the elements move for selection sort.
          </li>
          <li data-marker="*">
            For this algorithm, we were looking at roughly all n elements to
            find the smallest, and making n passes to sort all the elements.
          </li>
          <li data-marker="*">
            More formally, we can use some math formulas to show that the
            biggest factor is indeed (n<sup>2</sup>). We started with having to
            look at all n elements, then only (n - 1), then (n - 2):
            <br />
            (n + (n – 1) + (n – 2) + ... + 1)
            <br />
            (n(n + 1)/2)
            <br />
            ((n^2 + n)/2)
            <br />
            (n^2/2 + n/2)
            <br />
            O(n<sup>2</sup>)
            <br />
            <ul>
              <li data-marker="*">
                Since (n<sup>2</sup>) is the biggest, or dominant, factor, we
                can say that the algorithm has running time of O(n<sup>2</sup>).
              </li>
            </ul>
          </li>
        </ul>

        <h3 id="bubble-sort" ref={ref9}>
          Bubble sort
        </h3>

        <ul>
          <li data-marker="*">
            We can try a different algorithm, one where we swap pairs of numbers
            repeatedly, called <strong>bubble sort</strong>.
          </li>
          <li data-marker="*">
            Brian will look at the first two numbers, and swap them so they are
            in order:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`6 3 8 5 2 7 4 1
– –
3 6 8 5 2 7 4 1`}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            The next pair,{" "}
            <code className="language-plaintext highlighter-rouge">6</code> and{" "}
            <code className="language-plaintext highlighter-rouge">8</code>, are
            in order, so we don’t need to swap them.
          </li>
          <li data-marker="*">
            The next pair,{" "}
            <code className="language-plaintext highlighter-rouge">8</code> and{" "}
            <code className="language-plaintext highlighter-rouge">5</code>,
            need to be swapped:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`3 6 8 5 2 7 4 1
    – –
3 6 5 8 2 7 4 `}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Brian continues until he reaches the end of the list:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`3 6 5 2 8 7 4 1
        – –
3 6 5 2 7 8 4 1
          – –
3 6 5 2 7 4 8 1
            – –
3 6 5 2 7 4 1 8
              -`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Our list isn’t sorted yet, but we’re slightly closer to the solution
            because the biggest value,{" "}
            <code className="language-plaintext highlighter-rouge">8</code>, has
            been shifted all the way to the right. And other bigger numbers have
            also moved to the right, or “bubbled up”.
          </li>
          <li data-marker="*">
            Brian will make another pass through the list:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`3 6 5 2 7 4 1 8
– –
3 6 5 2 7 4 1 8
  – –
3 5 6 2 7 4 1 8
    – –
3 5 2 6 7 4 1 8
      – –
3 5 2 6 7 4 1 8
        – –
3 5 2 6 4 7 1 8
          - –
3 5 2 6 4 1 7 8
            - -`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Note that we didn’t need to swap the 3 and 6, or the 6 and 7.
              </li>
              <li data-marker="*">
                But now, the next biggest value,{" "}
                <code className="language-plaintext highlighter-rouge">7</code>,
                moved all the way to the right.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Brian will repeat this process a few more times, and more and more
            of the list becomes sorted, until we have a fully sorted list.
          </li>
          <li data-marker="*">
            With selection sort, the best case with a sorted list would still
            take just as many steps as the worst case, since we only check for
            the smallest number with each pass.
          </li>
          <li data-marker="*">
            The pseudocode for bubble sort might look like:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`Repeat until sorted
    For i from 0 to n–2
        If i'th and i+1'th elements out of order
            Swap them`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Since we are comparing the{" "}
                <code className="language-plaintext highlighter-rouge">
                  i'th
                </code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  i+1'th
                </code>{" "}
                element, we only need to go up to (n – 2) for{" "}
                <code className="language-plaintext highlighter-rouge">i</code>.
                Then, we swap the two elements if they’re out of order.
              </li>
              <li data-marker="*">
                And we can stop as soon as the list is sorted, since we can just
                remember whether we made any swaps. If not, the list must be
                sorted already.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            To determine the running time for bubble sort, we have (n – 1)
            comparisons in the loop, and at most (n – 1) loops, so we get (n
            <sup>2</sup> – 2n + 2) steps total. But the largest factor, or
            dominant term, is again (n<sup>2</sup>) as{" "}
            <code className="language-plaintext highlighter-rouge">n</code> gets
            larger and larger, so we can say that bubble sort has O(n
            <sup>2</sup>). So it turns out that fundamentally, selection sort
            and bubble sort have the same upper bound for running time.
          </li>
          <li data-marker="*">
            The lower bound for running time here would be Ω(n), once we look at
            all the elements once.
          </li>
          <li data-marker="*">
            So our upper bounds for running time that we’ve seen are:
            <ul>
              <li data-marker="*">
                O(n<sup>2</sup>)
                <ul>
                  <li data-marker="*">selection sort, bubble sort</li>
                </ul>
              </li>
              <li data-marker="*">O(n log n)</li>
              <li data-marker="*">
                O(n)
                <ul>
                  <li data-marker="*">linear search</li>
                </ul>
              </li>
              <li data-marker="*">
                O(log n)
                <ul>
                  <li data-marker="*">binary search</li>
                </ul>
              </li>
              <li data-marker="*">O(1)</li>
            </ul>
          </li>
          <li data-marker="*">
            And for lower bounds:
            <ul>
              <li data-marker="*">
                Ω(n<sup>2</sup>)
                <ul>
                  <li data-marker="*">selection sort</li>
                </ul>
              </li>
              <li data-marker="*">Ω(n log n)</li>
              <li data-marker="*">
                Ω(n)
                <ul>
                  <li data-marker="*">bubble sort</li>
                </ul>
              </li>
              <li data-marker="*">Ω(log n)</li>
              <li data-marker="*">
                Ω(1)
                <ul>
                  <li data-marker="*">linear search, binary search</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="recursion" ref={ref10}>
          Recursion
        </h2>

        <ul>
          <li data-marker="*">
            <strong>Recursion</strong> is the ability for a function to call
            itself. We haven’t seen this in code yet, but we’ve seen something
            in pseudocode in week 0 that we might be able to convert:
            <pre>
              {`1  Pick up phone book
2  Open to middle of phone book
3  Look at page
4  If Smith is on page
5      Call Mike
6  Else if Smith is earlier in book
7      Open to middle of left half of book
8      Go back to line 3
9  Else if Smith is later in book
10     Open to middle of right half of book
11     Go back to line 3
12 Else
13     Quit`}
            </pre>
            <ul>
              <li data-marker="*">
                Here, we’re using a loop-like instruction to go back to a
                particular line.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We could instead just repeat our entire algorithm on the half of the
            book we have left:
            <pre>
              {`1  Pick up phone book
2  Open to middle of phone book
3  Look at page
4  If Smith is on page
5      Call Mike
6  Else if Smith is earlier in book
7      Search left half of book
8
9  Else if Smith is later in book
10     Search right half of book
11
12 Else
13     Quit`}
            </pre>
            <ul>
              <li data-marker="*">
                This seems like a cyclical process that will never end, but
                we’re actually changing the input to the function and dividing
                the problem in half each time, stopping once there’s no more
                book left.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            In week 1, too, we implemented a “pyramid” of blocks in the
            following shape:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`#
##
###
####`}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            But notice that a pyramid of height 4 is actually a pyramid of
            height 3, with an extra row of 4 blocks added on. And a pyramid of
            height 3 is a pyramid of height 2, with an extra row of 3 blocks. A
            pyramid of height 2 is a pyramid of height 1, with an extra row of 2
            blocks. And finally, a pyramid of height 1 is just a single block.
          </li>
          <li data-marker="*">
            With this idea in mind, we can write a recursive function to draw a
            pyramid, a function that calls itself to draw a smaller pyramid
            before adding another row.
          </li>
        </ul>

        <h2 id="merge-sort" ref={ref11}>
          Merge sort
        </h2>

        <ul>
          <li data-marker="*">
            We can take the idea of recusion to sorting, with another algorithm
            called <strong>merge sort</strong>. The pseudocode might look like:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`If only one number
  Return
Else
    Sort left half of number
    Sort right half of number
    Merge sorted halves`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We’ll best see this in practice with two sorted lists:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>3 5 6 8 | 1 2 4 7</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We’ll <em>merge</em> the two lists for a final sorted list by taking
            the smallest element at the front of each list, one at a time:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`3 5 6 8 | _ 2 4 7

1`}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            The 1 on the right side is the smallest between 1 and 3, so we can
            start our sorted list with it.
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`3 5 6 8 | _ _ 4 7

1 2`}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            The next smallest number, between 2 and 3, is 2, so we use the 2.
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ 5 6 8 | _ _ 4 7

1 2 3`}</code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ 5 6 8 | _ _ _ 7

1 2 3 4`}</code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ _ 6 8 | _ _ _ 7

1 2 3 4 5`}</code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ _ _ 8 | _ _ _ 7

1 2 3 4 5 6`}</code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ _ _ 8 | _ _ _ _

1 2 3 4 5 6 7`}</code>
                </pre>
              </div>{" "}
            </div>
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ _ _ _ | _ _ _ _

1 2 3 4 5 6 7 8`}</code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">Now we have a completely sorted list.</li>
            </ul>
          </li>
          <li data-marker="*">
            We’ve seen how the final line in our pseudocode can be implemented,
            and now we’ll see how the entire algorithm works:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`If only one number
  Return
Else
    Sort left half of number
    Sort right half of number
    Merge sorted halves`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We start with another unsorted list:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>6 3 8 5 2 7 4 1</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            To start, we need to sort the left half first:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>6 3 8 5</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Well, to sort that, we need to sort the left half of the left half
            first:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>6 3</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Now both of these halves just have one item each, so they’re both
            sorted. We merge these two lists together, for a sorted list:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ _ 8 5 2 7 4 1
3 6`}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We’re back to sorting the right half of the left half, merging them
            together:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ _ _ _ 2 7 4 1
3 6 5 8`}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Both halves of the <em>left half</em> have been sorted individually,
            so now we need to merge them together:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ _ _ _ 2 7 4 1
_ _ _ _
3 5 6 8`}</code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We’ll do what we just did, with the right half:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`_ _ _ _ _ _ _ _
_ _ _ _ 2 7 1 4
3 5 6 8`}</code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                First, we sort both halves of the right half.
                <div className="language-plaintext highlighter-rouge">
                  <div className="highlight">
                    <pre className="highlight">
                      <code>
                        {`_ _ _ _ _ _ _ _
_ _ _ _ _ _ _ _
3 5 6 8 1 2 4 7`}
                      </code>
                    </pre>
                  </div>{" "}
                </div>
              </li>
              <li data-marker="*">
                Then, we merge them together for a sorted right half.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Finally, we have two sorted halves again, and we can merge them for
            a fully sorted list:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`_ _ _ _ _ _ _ _
_ _ _ _ _ _ _ _
_ _ _ _ _ _ _ _
1 2 3 4 5 6 7 8`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            Each number was moved from one shelf to another three times (since
            the list was divided from 8, to 4, to 2, and to 1 before merged back
            together into sorted lists of 2, 4, and finally 8 again). And each
            shelf required all 8 numbers to be merged together, one at a time.
          </li>
          <li data-marker="*">
            Each shelf required n steps, and there were only log n shelves
            needed, so we multiply those factors together. Our total running
            time for binary search is O(log n):
            <ul>
              <li data-marker="*">
                O(n<sup>2</sup>)
                <ul>
                  <li data-marker="*">selection sort, bubble sort</li>
                </ul>
              </li>
              <li data-marker="*">
                O(n log n)
                <ul>
                  <li data-marker="*">merge sort</li>
                </ul>
              </li>
              <li data-marker="*">
                O(n)
                <ul>
                  <li data-marker="*">linear search</li>
                </ul>
              </li>
              <li data-marker="*">
                O(log n)
                <ul>
                  <li data-marker="*">binary search</li>
                </ul>
              </li>
              <li data-marker="*">O(1)</li>
            </ul>
          </li>
          <li data-marker="*">
            (Since log n is greater than 1 but less than n, (n log n) is in
            between n (times 1) and (n<sup>2</sup>).)
          </li>
          <li data-marker="*">
            The best case, Ω, is still n log n, since we still have to sort each
            half first and then merge them together:
            <ul>
              <li data-marker="*">
                Ω(n<sup>2</sup>)
                <ul>
                  <li data-marker="*">selection sort</li>
                </ul>
              </li>
              <li data-marker="*">
                Ω(n log n)
                <ul>
                  <li data-marker="*">merge sort</li>
                </ul>
              </li>
              <li data-marker="*">
                Ω(n)
                <ul>
                  <li data-marker="*">bubble sort</li>
                </ul>
              </li>
              <li data-marker="*">Ω(log n)</li>
              <li data-marker="*">
                Ω(1)
                <ul>
                  <li data-marker="*">linear search, binary search</li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Even though merge sort is likely to be faster than selection sort or
            bubble sort, we did need another shelf, or more memory, to
            temporarily store our merged lists at each stage. We face the
            tradeoff of incurring a higher cost, another array in memory, for
            the benefit of faster sorting.
          </li>
          <li data-marker="*">
            Finally, there is another notation, θ, Theta, which we use to
            describe running times of algorithms if the upper bound and lower
            bound is the same. For example, merge sort has θ(n log n) since the
            best and worst case both require the same number of steps. And
            selection sort has θ(n<sup>2</sup>):
            <ul>
              <li data-marker="*">
                θ(n<sup>2</sup>)
                <ul>
                  <li data-marker="*">selection sort</li>
                </ul>
              </li>
              <li data-marker="*">
                θ(n log n)
                <ul>
                  <li data-marker="*">merge sort</li>
                </ul>
              </li>
              <li data-marker="*">θ(n)</li>
              <li data-marker="*">θ(log n)</li>
              <li data-marker="*">θ(1)</li>
            </ul>
          </li>
          <li data-marker="*">
            We look at a{" "}
            <a href="https://www.youtube.com/watch?v=ZZuD6iUe3Pc">
              final visualization
            </a>{" "}
            of sorting algorithms with a larger number of inputs, running at the
            same time.
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Note3;
