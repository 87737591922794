import React, { useState } from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";

function Week0() {
  const { t } = useTranslation();
  const [video, setVideo] = useState(
    "https://www.youtube.com/embed/xvkHFSNfzyQ"
  );

  return (
    <React.Fragment>
      <h1 id="week-8">{t("week")} 8</h1>

      <div
        className="border embed-responsive embed-responsive-16by9"
        data-video=""
        style={{ maxWidth: "720px" }}
      >
        <Iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="embed-responsive-item"
          src={video}
        ></Iframe>
      </div>

      <ul className="mt-5">
        <li>
          Lecture
          <ul>
            <li>
              Demos
              <ol>
                <li>
                  <a href="https://youtu.be/DcjCUdiddm4">
                    Passing TCP/IP Packet 1 of 3
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/J7jWw5340-U">
                    Passing TCP/IP Packet 2 of 3
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/cUJlRNRguAM">
                    Passing TCP/IP Packet 3 of 3
                  </a>
                </li>
                <li>
                  <a href="http://safetyschool.org/">safetyschool.org</a>
                </li>
                <li>
                  <a href="http://harvardsucks.org/">harvardsucks.org</a>
                </li>
              </ol>
            </li>
            <li>
              <Link to="note8">Notes</Link>
            </li>
            <li>
              Slides
              <ul>
                <li>
                  <a href="https://docs.google.com/presentation/d/1I4J3nuca8unFlPT_6ilP9Xz1E1jyA-TkUZ2RIbiUbnk/edit?usp=sharing">
                    Google Slides
                  </a>
                </li>
                <li>
                  <a href="https://cdn.cs50.net/2020/fall/lectures/8/lecture8.pdf">
                    PDF
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Source Code
              <ul>
                <li>
                  <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8/">
                    Index
                  </a>
                </li>
                <li>
                  <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8.pdf">
                    PDF
                  </a>
                </li>
                <li>
                  <a href="https://cdn.cs50.net/2020/fall/lectures/8/src8.zip">
                    Zip
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Video
              <ul>
                <li>
                  <a href="https://video.cs50.io/xvkHFSNfzyQ?screen=yGwFfnTcIkk&amp;start=865&amp;end=10677&amp;offset=-29833">
                    CS50 Video Player
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/watch?v=xvkHFSNfzyQ&amp;start=865">
                    YouTube
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Shorts
          <ol>
            <li>
              <a href="https://video.cs50.io/04GztBlVo_s">Internet Primer</a>
            </li>
            <li>
              <a href="https://video.cs50.io/A1g9SokDJSU">IP</a>
            </li>
            <li>
              <a href="https://video.cs50.io/GP7uvI_6uas">TCP</a>
            </li>
            <li>
              <a href="https://video.cs50.io/4axL8Gfw2nI">HTTP</a>
            </li>
            <li>
              <a href="https://video.cs50.io/YK78KhMf7bs">HTML</a>
            </li>
            <li>
              <a href="https://video.cs50.io/Ub3FKU21ubk">CSS</a>
            </li>
            <li>
              <a href="https://video.cs50.io/Z93IaNfavZw">JavaScript</a>
            </li>
            <li>
              <a href="https://video.cs50.io/LKWJpgvfH3U">DOM</a>
            </li>
          </ol>
        </li>

        <li>
          <Link to="homepage">Problem Set 8</Link>
        </li>
      </ul>
    </React.Fragment>
  );
}

export default Week0;
