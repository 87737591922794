import React, { useState } from "react";
import "./CustomSidenav.css";

function CustomSidenav({ weeks, setCurrentWeek }) {

  const [collapse, setCollapse] = useState("");
  function handleCollapse() {
    setCollapse(collapse === "active" ? " " : "active");
  }

  return (
    <>
      <nav id="sidebar" className={collapse}>
        <div className="custom-menu">
          <button
            type="button"
            onClick={handleCollapse}
            id="sidebarCollapse"
            className="btn active btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>

        <div className="p-4">
          <ul className="list-unstyled components mb-5">
            {weeks.map((week, index) => {
              return (
                <li key={index}>
                 <span
                  className="week-list"
                    onClick={() => {
                      setCurrentWeek(week);
                    }}
                  >
                    {week.title}{" "}
                  </span>
                </li>
              );
            })}

            <li>
              <div className="footer" style={{ marginTop: "20px" }}>
                <div className="contact">info@computiq.tech</div>
                <div>
                  <a href={"https://www.instagram.com/computiq/"}>
                    <i className="fa fa-instagram contact"></i>{" "}
                  </a>

                  <a href={"https://www.facebook.com/Computiq/"}>
                    <i className="fa fa-facebook contact"></i>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default CustomSidenav;
