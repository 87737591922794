import React from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "./../weeks.css";
import Sidenav from "./../../components/Sidenav";

function Week0() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Sidenav/>
      <div className="center-week container">
        <h1 id="week-0">{t("week")} 0</h1>
        <h2 id="scratch">Scratch</h2>
        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
          style={{ maxWidth: "720px" }}
        >
          <Iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="border"
            data-video=""
            src={t("Lec0")}
          ></Iframe>
        </div>
        <ul>
          <li data-marker="-">
            {t("Lecture")}
            <ul>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/0/lecture0.mp3">
                  Audio
                </a>
              </li>
              <li data-marker="+">
                Demos
                <ul>
                  <li data-marker="*">
                    <a href="https://youtu.be/p3q9MM__h-M">
                      Grumpy Cloud FLIPBOOK
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://youtu.be/F5LZhsekEBc">
                      Searching a Phone Book Efficiently
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://youtu.be/-yTRajiUi5s">
                      Searching a Phone Book Inefficiently
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="*">
                <Link
                  to={{
                    pathname: "/note0",
                    state: {
                      from: "week0",
                    },
                  }}
                >
                  {t("Notes")}
                </Link>
              </li>
              <li data-marker="+">
                {t("Slides")}
                <ul>
                  <li data-marker="*">
                    <a href="https://docs.google.com/presentation/d/11nbVogBHrI5XWZ5yqg6Lhn7WxvRPTntUDGDzLbPbzC4/edit?usp=sharing">
                      {t("Google-slides")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/0/lecture0.pdf">
                      PDF
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="+">
                {t("Source-Code")}
                <ul>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/0/src0/">
                      {t("Index")}
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://scratch.mit.edu/studios/27430410/">
                      Studio
                    </a>
                  </li>
                  <li data-marker="*">
                    <a href="https://cdn.cs50.net/2020/fall/lectures/0/src0.zip">
                      Zip
                    </a>
                  </li>
                </ul>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/0/lang/en/lecture0.srt">
                  Subtitles
                </a>
              </li>
              <li data-marker="*">
                <a href="http://cdn.cs50.net/2020/fall/lectures/0/lang/en/lecture0.txt">
                  Transcript
                </a>
              </li>
              <li data-marker="+">
                {t("Video")}
                <ul>
                  <li data-marker="*">
                    <a href="https://video.cs50.io/YoXxevp1WRQ?screen=mu28ADhzRQE">
                      CS50 Video Player
                    </a>
                  </li>
                  <li data-marker="+">
                    MP4
                    <ul>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/0/lecture0-360p.mp4">
                          360p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/0/lecture0-720p.mp4">
                          720p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/0/lecture0-1080p.mp4">
                          1080p
                        </a>
                      </li>
                      <li data-marker="*">
                        <a href="http://cdn.cs50.net/2020/fall/lectures/0/lecture0-4k.mp4">
                          4K
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li data-marker="*">
                    <a href="https://youtu.be/YoXxevp1WRQ">YouTube</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-marker="*">
            <Link to="psets">{t("Problem")} 0</Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default Week0;
