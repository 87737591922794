import React from "react";

function Note6() {
  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 className="no_toc" id="lecture-6">
          Lecture 6
        </h1>

        <ul id="markdown-toc">
          <li>
            <a href="#python-basics" id="markdown-toc-python-basics">
              Python Basics
            </a>
          </li>
          <li>
            <a href="#examples" id="markdown-toc-examples">
              Examples
            </a>
          </li>
          <li>
            <a href="#more-features" id="markdown-toc-more-features">
              More features
            </a>
          </li>
          <li>
            <a href="#files" id="markdown-toc-files">
              Files
            </a>
          </li>
          <li>
            <a href="#new-features" id="markdown-toc-new-features">
              New features
            </a>
          </li>
        </ul>

        <h2 id="python-basics">Python Basics</h2>

        <ul>
          <li>
            Today we’ll learn a new programming language called Python, and
            remember that one of the overall goals of the course is not learning
            any particular languages, but how to program in general.
          </li>
          <li>
            Source code in Python looks a lot simpler than C, but is capable of
            solving problems in fields like data science. In fact, to print
            “hello, world”, all we need to write is:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"hello, world"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Notice that, unlike in C, we don’t need to import a standard
                library, declare a{" "}
                <code className="highlighter-rouge">main</code> function,
                specify a newline in the{" "}
                <code className="highlighter-rouge">print</code> function, or
                use semicolons.
              </li>
            </ul>
          </li>
          <li>
            Python is an interpreted language, which means that we actually run
            another program (an interpreter) that reads our source code and runs
            it top to bottom. For example, we can save the above as{" "}
            <code className="highlighter-rouge">hello.py</code>, and run the
            command <code className="highlighter-rouge">python hello.py</code>{" "}
            to run our code, without having to compile it.
          </li>
          <li>
            We can get strings from a user:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="n">answer</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"What's your name?</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"hello, "</span>{" "}
                    <span className="o">+</span>{" "}
                    <span className="n">answer</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We create a variable called{" "}
                <code className="highlighter-rouge">answer</code>, without
                specifying the type (the interpreter determins that from context
                for us), and we can easily combine two strings with the{" "}
                <code className="highlighter-rouge">+</code> operator before we
                pass it into <code className="highlighter-rouge">print</code>.
              </li>
              <li>
                We can also pass in multiple arguments to{" "}
                <code className="highlighter-rouge">print</code>, with{" "}
                <code className="highlighter-rouge">
                  print("hello,", answer)
                </code>
                , and it will automatically join them with spaces for us too.
              </li>
              <li>
                <code className="highlighter-rouge">print</code> also accepts
                format strings like{" "}
                <code className="highlighter-rouge">
                  f"hello, {"{answer}"}"
                </code>
                , which substitutes variables inside curly braces into a string.
              </li>
            </ul>
          </li>
          <li>
            We can create variables with just{" "}
            <code className="highlighter-rouge">counter = 0</code>. To increment
            a variable, we can use{" "}
            <code className="highlighter-rouge">counter = counter + 1</code> or{" "}
            <code className="highlighter-rouge">counter += 1</code>.
          </li>
          <li>
            Conditions look like:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">if</span> <span className="n">x</span>{" "}
                    <span className="o">&lt;</span> <span className="n">y</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"x is less than y"</span>
                    <span className="p">)</span>
                    <span className="k">elif</span> <span className="n">x</span>{" "}
                    <span className="o">&gt;</span> <span className="n">y</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"x is greater than y"</span>
                    <span className="p">)</span>
                    <span className="k">else</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"x is equal to y"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Unlike in C and JavaScript (whereby braces{" "}
                <code className="highlighter-rouge">{"{"}</code>{" "}
                <code className="highlighter-rouge">{"}"}</code> are used to
                indicate blocks of code), the exact indentation of each line is
                what determines the level of nesting in Python.
              </li>
              <li>
                And instead of{" "}
                <code className="highlighter-rouge">else if</code>, we just say{" "}
                <code className="highlighter-rouge">elif</code>.
              </li>
            </ul>
          </li>
          <li>
            Boolean expressions are slightly different, too:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">while</span>{" "}
                    <span className="bp">True</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"hello, world"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can write a loop with a variable:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="n">i</span> <span className="o">=</span>{" "}
                    <span className="mi">3</span>
                    <span className="k">while</span>{" "}
                    <span className="n">i</span> <span className="o">&gt;</span>{" "}
                    <span className="mi">0</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"cough"</span>
                    <span className="p">)</span>
                    <span className="n">i</span> <span className="o">-=</span>{" "}
                    <span className="mi">1</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can also use a <code className="highlighter-rouge">for</code>{" "}
            loop, where we can do something for each element in a list:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">for</span> <span className="n">i</span>{" "}
                    <span className="ow">in</span> <span className="p">[</span>
                    <span className="mi">0</span>
                    <span className="p">,</span> <span className="mi">1</span>
                    <span className="p">,</span> <span className="mi">2</span>
                    <span className="p">]:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"cough"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Lists in Python are like arrays in C, but they can grow and
                shrink easily with the interpreter managing the implementation
                and memory for us.
              </li>
              <li>
                This <code className="highlighter-rouge">for</code> loop will
                set the variable <code className="highlighter-rouge">i</code> to
                the first element, <code className="highlighter-rouge">0</code>,
                run, then to the second element,{" "}
                <code className="highlighter-rouge">1</code>, run, and so on.
              </li>
              <li>
                And we can use a special function,{" "}
                <code className="highlighter-rouge">range</code>, to get some
                number of values, as in{" "}
                <code className="highlighter-rouge">for i in range(3)</code>.
                This will give us <code className="highlighter-rouge">0</code>,{" "}
                <code className="highlighter-rouge">1</code>, and{" "}
                <code className="highlighter-rouge">2</code>, for a total of
                thee values.
              </li>
            </ul>
          </li>
          <li>
            In Python, there are many data types:
            <ul>
              <li>
                <code className="highlighter-rouge">bool</code>,{" "}
                <code className="highlighter-rouge">True</code> or{" "}
                <code className="highlighter-rouge">False</code>
              </li>
              <li>
                <code className="highlighter-rouge">float</code>, real numbers
              </li>
              <li>
                <code className="highlighter-rouge">int</code>, integers
              </li>
              <li>
                <code className="highlighter-rouge">str</code>, strings
              </li>
              <li>
                <code className="highlighter-rouge">range</code>, sequence of
                numbers
              </li>
              <li>
                <code className="highlighter-rouge">list</code>, sequence of
                mutable values, that we can change or add or remove
              </li>
              <li>
                <code className="highlighter-rouge">tuple</code>, sequence of
                immutable values, that we can’t change
              </li>
              <li>
                <code className="highlighter-rouge">dict</code>, collection of
                key/value pairs, like a hash table
              </li>
              <li>
                <code className="highlighter-rouge">set</code>, collection of
                unique values
              </li>
            </ul>
          </li>
          <li>
            <a href="https://docs.python.org">docs.python.org</a> is the
            official source of documentation, but Google and StackOverflow will
            also have helpful resources when we need to figure out how to do
            something in Python. In fact, programmers in the real world rarely
            know everything in the documentation, but rather how to find what
            they need when they need it.
          </li>
        </ul>

        <h2 id="examples">Examples</h2>

        <ul>
          <li>
            We can blur an image with:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">PIL</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">Image</span>
                    <span className="p">,</span>{" "}
                    <span className="n">ImageFilter</span>
                    <span className="n">before</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">Image</span>
                    <span className="o">.</span>
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">"bridge.bmp"</span>
                    <span className="p">)</span>
                    <span className="n">after</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">before</span>
                    <span className="o">.</span>
                    <span className="nb">filter</span>
                    <span className="p">(</span>
                    <span className="n">ImageFilter</span>
                    <span className="o">.</span>
                    <span className="n">BLUR</span>
                    <span className="p">)</span>
                    <span className="n">after</span>
                    <span className="o">.</span>
                    <span className="n">save</span>
                    <span className="p">(</span>
                    <span className="s">"out.bmp"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                In Python, we include other libraries with{" "}
                <code className="highlighter-rouge">import</code>, and here
                we’ll <code className="highlighter-rouge">import</code> the{" "}
                <code className="highlighter-rouge">Image</code> and{" "}
                <code className="highlighter-rouge">ImageFilter</code> names
                from the <code className="highlighter-rouge">PIL</code> library.
              </li>
              <li>
                It turns out, if we look for documention for the{" "}
                <code className="highlighter-rouge">PIL</code> library, we can
                use the next three lines of code to open an image called{" "}
                <code className="highlighter-rouge">bridge.bmp</code>, run a
                blur filter on it, and save it to a file called{" "}
                <code className="highlighter-rouge">out.bmp</code>.
              </li>
              <li>
                And we can run this with{" "}
                <code className="highlighter-rouge">python blur.py</code> after
                saving to a file called{" "}
                <code className="highlighter-rouge">blur.py</code>.
              </li>
            </ul>
          </li>
          <li>
            We can implement a dictionary with:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="n">words</span>{" "}
                    <span className="o">=</span> <span className="nb">set</span>
                    <span className="p">()</span>
                    <span className="k">def</span>{" "}
                    <span className="nf">check</span>
                    <span className="p">(</span>
                    <span className="n">word</span>
                    <span className="p">):</span>
                    <span className="k">if</span>{" "}
                    <span className="n">word</span>
                    <span className="o">.</span>
                    <span className="n">lower</span>
                    <span className="p">()</span> <span className="ow">in</span>{" "}
                    <span className="n">words</span>
                    <span className="p">:</span>
                    <span className="k">return</span>{" "}
                    <span className="bp">True</span>
                    <span className="k">else</span>
                    <span className="p">:</span>
                    <span className="k">return</span>{" "}
                    <span className="bp">False</span>
                    <span className="k">def</span>{" "}
                    <span className="nf">load</span>
                    <span className="p">(</span>
                    <span className="n">dictionary</span>
                    <span className="p">):</span>
                    <span className="nb">file</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="n">dictionary</span>
                    <span className="p">,</span> <span className="s">"r"</span>
                    <span className="p">)</span>
                    <span className="k">for</span>{" "}
                    <span className="n">line</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">file</span>
                    <span className="p">:</span>
                    <span className="n">words</span>
                    <span className="o">.</span>
                    <span className="n">add</span>
                    <span className="p">(</span>
                    <span className="n">line</span>
                    <span className="o">.</span>
                    <span className="n">rstrip</span>
                    <span className="p">(</span>
                    <span className="s">"</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">))</span>
                    <span className="nb">file</span>
                    <span className="o">.</span>
                    <span className="n">close</span>
                    <span className="p">()</span>
                    <span className="k">return</span>{" "}
                    <span className="bp">True</span>
                    <span className="k">def</span>{" "}
                    <span className="nf">size</span>
                    <span className="p">():</span>
                    <span className="k">return</span>{" "}
                    <span className="nb">len</span>
                    <span className="p">(</span>
                    <span className="n">words</span>
                    <span className="p">)</span>
                    <span className="k">def</span>{" "}
                    <span className="nf">unload</span>
                    <span className="p">():</span>
                    <span className="k">return</span>{" "}
                    <span className="bp">True</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                First, we create a new set called{" "}
                <code className="highlighter-rouge">words</code>. Then, for{" "}
                <code className="highlighter-rouge">check</code>, we can just
                ask `{" "}
                <code className="highlighter-rouge">
                  if word.lower() in words
                </code>
                . For <code className="highlighter-rouge">load</code>, we open
                the file and use{" "}
                <code className="highlighter-rouge">words.add</code> to add each
                line to our set. For{" "}
                <code className="highlighter-rouge">size</code>, we can use{" "}
                <code className="highlighter-rouge">len</code> to count the
                number of elements in our set, and finally, for{" "}
                <code className="highlighter-rouge">unload</code>, we don’t have
                to do anything!
              </li>
            </ul>
          </li>
          <li>
            It turns out, even though implementing a program in Python is
            simpler for us, the running time of our program in Python is slower
            than our program in C since our interpreter has to do more work for
            us. So, depending on our goals, we’ll also have to consider the
            tradeoff of human time of writing a program that’s more efficient,
            versus the running time of the program.
          </li>
          <li>
            In Python, we can too include the CS50 library, but our syntax will
            be:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_string</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>Notice that we specify the functions we want to use.</li>
            </ul>
          </li>
          <li>
            Now we can get strings from a user:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_string</span>
                    <span className="n">s</span> <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"What's your name?:</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"hello, "</span>{" "}
                    <span className="o">+</span> <span className="n">s</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can substitute expressions into our format strings, too:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_int</span>
                    <span className="n">age</span> <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"What's your age?</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">
                      "You are at least {"{age * 365}"} days old."
                    </span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            And we can demonstrate conditions:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_int</span>
                    <span className="n">x</span> <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"x: "</span>
                    <span className="p">)</span>
                    <span className="n">y</span> <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"y: "</span>
                    <span className="p">)</span>
                    <span className="k">if</span> <span className="n">x</span>{" "}
                    <span className="o">&lt;</span> <span className="n">y</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"x is less than y"</span>
                    <span className="p">)</span>
                    <span className="k">elif</span> <span className="n">x</span>{" "}
                    <span className="o">&gt;</span> <span className="n">y</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"x is greater than y"</span>
                    <span className="p">)</span>
                    <span className="k">else</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"x is equal to y"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            To check conditions, we can say:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_string</span>
                    <span className="n">s</span> <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"Do you agree?</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">)</span>
                    <span className="k">if</span> <span className="n">s</span>{" "}
                    <span className="o">==</span> <span className="s">"Y"</span>{" "}
                    <span className="ow">or</span> <span className="n">s</span>{" "}
                    <span className="o">==</span> <span className="s">"y"</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Agreed."</span>
                    <span className="p">)</span>
                    <span className="k">elif</span> <span className="n">s</span>{" "}
                    <span className="o">==</span> <span className="s">"N"</span>{" "}
                    <span className="ow">or</span> <span className="n">s</span>{" "}
                    <span className="o">==</span> <span className="s">"n"</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Not agreed."</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Python doesn’t have chars, so we can check them as strings
                directly.
              </li>
              <li>
                We can also say{" "}
                <code className="highlighter-rouge">if s in ["Y", "y"]:</code>,
                or{" "}
                <code className="highlighter-rouge">
                  if s.lower() in ["y"]:
                </code>
                . It turns out that strings in Python are like structs in C,
                where we have not only variables but functions that we can call.
                For example, given a string{" "}
                <code className="highlighter-rouge">s</code>, we can call its{" "}
                <code className="highlighter-rouge">lower</code> function with{" "}
                <code className="highlighter-rouge">s.lower()</code> to get the
                lowercase version of the string.
              </li>
            </ul>
          </li>
          <li>
            We can improve versions of{" "}
            <code className="highlighter-rouge">cough</code>, too:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"cough"</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"cough"</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"cough"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We don’t need to declare a{" "}
                <code className="highlighter-rouge">main</code> function, so we
                just write the same line of code three times.
              </li>
            </ul>
          </li>
          <li>
            But we can do better:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">for</span> <span className="n">i</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">range</span>
                    <span className="p">(</span>
                    <span className="mi">3</span>
                    <span className="p">):</span>
                    <span className="n">cough</span>
                    <span className="p">()</span>
                    <span className="k">def</span>{" "}
                    <span className="nf">cough</span>
                    <span className="p">():</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"cough"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Notice that we don’t need to specify the return type of a new
                function, which we can define with{" "}
                <code className="highlighter-rouge">def</code>.
              </li>
              <li>
                But this causes an error when we try to run it:{" "}
                <code className="highlighter-rouge">
                  NameError: name 'cough' is not defined
                </code>
                . It turns out that we need to define our function before we use
                it, so we can either move our definition of{" "}
                <code className="highlighter-rouge">cough</code> to the top, or
                create a main function:
                <div className="language-python highlighter-rouge">
                  <div className="highlight">
                    <pre className="highlight">
                      <code>
                        <span className="k">def</span>{" "}
                        <span className="nf">main</span>
                        <span className="p">():</span>
                        <span className="k">for</span>{" "}
                        <span className="n">i</span>{" "}
                        <span className="ow">in</span>{" "}
                        <span className="nb">range</span>
                        <span className="p">(</span>
                        <span className="mi">3</span>
                        <span className="p">):</span>
                        <span className="n">cough</span>
                        <span className="p">()</span>
                        <span className="k">def</span>{" "}
                        <span className="nf">cough</span>
                        <span className="p">():</span>
                        <span className="k">print</span>
                        <span className="p">(</span>
                        <span className="s">"cough"</span>
                        <span className="p">)</span>
                        <span className="n">main</span>
                        <span className="p">()</span>
                      </code>
                    </pre>
                  </div>{" "}
                </div>
              </li>
              <li>
                Now, by the time we actually call our{" "}
                <code className="highlighter-rouge">main</code> function, the{" "}
                <code className="highlighter-rouge">cough</code> function will
                have been read by our interpreter.
              </li>
            </ul>
          </li>
          <li>
            Our functions can take inputs, too:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">def</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">():</span>
                    <span className="n">cough</span>
                    <span className="p">(</span>
                    <span className="mi">3</span>
                    <span className="p">)</span>
                    <span className="k">def</span>{" "}
                    <span className="nf">cough</span>
                    <span className="p">(</span>
                    <span className="n">n</span>
                    <span className="p">):</span>
                    <span className="k">for</span> <span className="n">i</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">range</span>
                    <span className="p">(</span>
                    <span className="n">n</span>
                    <span className="p">):</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"cough"</span>
                    <span className="p">)</span>
                    <span className="n">main</span>
                    <span className="p">()</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can define a function to get a positive integer:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_int</span>
                    <span className="k">def</span>{" "}
                    <span className="nf">main</span>
                    <span className="p">():</span>
                    <span className="n">i</span> <span className="o">=</span>{" "}
                    <span className="n">get_positive_int</span>
                    <span className="p">()</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">i</span>
                    <span className="p">)</span>
                    <span className="k">def</span>{" "}
                    <span className="nf">get_positive_int</span>
                    <span className="p">():</span>
                    <span className="k">while</span>{" "}
                    <span className="bp">True</span>
                    <span className="p">:</span>
                    <span className="n">n</span> <span className="o">=</span>{" "}
                    <span className="n">get_int</span>
                    <span className="p">(</span>
                    <span className="s">"Positive Integer: "</span>
                    <span className="p">)</span>
                    <span className="k">if</span> <span className="n">n</span>{" "}
                    <span className="o">&gt;</span>{" "}
                    <span className="mi">0</span>
                    <span className="p">:</span>
                    <span className="k">break</span>
                    <span className="k">return</span>{" "}
                    <span className="n">n</span>
                    <span className="n">main</span>
                    <span className="p">()</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Since there is no do-while loop in Python as there is in C, we
                have a <code className="highlighter-rouge">while</code> loop
                that will go on infinitely, but we use{" "}
                <code className="highlighter-rouge">break</code> to end the loop
                as soon as <code className="highlighter-rouge">n &gt; 0</code>.
                Then, our function will just{" "}
                <code className="highlighter-rouge">return n</code>.
              </li>
              <li>
                Notice that variables in Python have function scope by default,
                meaning that <code className="highlighter-rouge">n</code> can be
                initialized within a loop, but still be accessible later in the
                function.
              </li>
            </ul>
          </li>
          <li>
            We can print out a row of question marks on the screen:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">for</span> <span className="n">i</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">range</span>
                    <span className="p">(</span>
                    <span className="mi">4</span>
                    <span className="p">):</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"?"</span>
                    <span className="p">,</span> <span className="n">end</span>
                    <span className="o">=</span>
                    <span className="s">""</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">()</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                When we print each block, we don’t want the automatic new line,
                so we can pass a parameter, or named argument, to the{" "}
                <code className="highlighter-rouge">print</code> function. Here,
                we say <code className="highlighter-rouge">end=""</code> to
                specify that nothing should be printed at the end of our string.
                Then, after we print our row, we can call{" "}
                <code className="highlighter-rouge">print</code> to get a new
                line.
              </li>
            </ul>
          </li>
          <li>
            We can also “multiply” a string and print that directly with:{" "}
            <code className="highlighter-rouge">print("?" * 4)</code>.
          </li>
          <li>
            We can print a column with a loop:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">for</span> <span className="n">i</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">range</span>
                    <span className="p">(</span>
                    <span className="mi">3</span>
                    <span className="p">):</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"#"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            And without a loop:{" "}
            <code className="highlighter-rouge">print("#\n" * 3, end="")</code>.
          </li>
          <li>
            We can implement nested loops:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="k">for</span> <span className="n">i</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">range</span>
                    <span className="p">(</span>
                    <span className="mi">3</span>
                    <span className="p">):</span>
                    <span className="k">for</span> <span className="n">j</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">range</span>
                    <span className="p">(</span>
                    <span className="mi">3</span>
                    <span className="p">):</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"#"</span>
                    <span className="p">,</span> <span className="n">end</span>
                    <span className="o">=</span>
                    <span className="s">""</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">()</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We don’t need to use the{" "}
            <code className="highlighter-rouge">get_string</code> function from
            the CS50 library, since we can use the{" "}
            <code className="highlighter-rouge">input</code> function built into
            Python to get a string from the user. But if we want another type of
            data, like an integer, from the user, we’ll need to cast it with{" "}
            <code className="highlighter-rouge">int()</code>.
          </li>
          <li>
            But our program will crash if the string isn’t convertable to an
            integer, so we can use{" "}
            <code className="highlighter-rouge">get_string</code> which will
            just ask again.
          </li>
          <li>
            In Python, trying to get an integer overflow actually won’t work:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">time</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">sleep</span>
                    <span className="n">i</span> <span className="o">=</span>{" "}
                    <span className="mi">1</span>
                    <span className="k">while</span>{" "}
                    <span className="bp">True</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">i</span>
                    <span className="p">)</span>
                    <span className="n">sleep</span>
                    <span className="p">(</span>
                    <span className="mi">1</span>
                    <span className="p">)</span>
                    <span className="n">i</span> <span className="o">*=</span>{" "}
                    <span className="mi">2</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We call the <code className="highlighter-rouge">sleep</code>{" "}
                function to pause our program for a second between each
                iteration.
              </li>
              <li>
                This will continue until the integer can no longer fit in your
                computer’s memory.
              </li>
            </ul>
          </li>
          <li>
            Floating-point imprecision, too, can be prevented by libraries that
            can represent decimal numbers with as many bits as are needed.
          </li>
          <li>
            We can make a list:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="n">scores</span>{" "}
                    <span className="o">=</span> <span className="p">[]</span>
                    <span className="n">scores</span>
                    <span className="o">.</span>
                    <span className="n">append</span>
                    <span className="p">(</span>
                    <span className="mi">72</span>
                    <span className="p">)</span>
                    <span className="n">scores</span>
                    <span className="o">.</span>
                    <span className="n">append</span>
                    <span className="p">(</span>
                    <span className="mi">73</span>
                    <span className="p">)</span>
                    <span className="n">scores</span>
                    <span className="o">.</span>
                    <span className="n">append</span>
                    <span className="p">(</span>
                    <span className="mi">33</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">
                      "Average: {"{sum(scores) / len(scores)}"}"
                    </span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                With <code className="highlighter-rouge">append</code>, we can
                add items to our list, using it like a linked list.
              </li>
              <li>
                We can also declare a list with some values like{" "}
                <code className="highlighter-rouge">scores = [72, 73, 33]</code>
                .
              </li>
            </ul>
          </li>
          <li>
            We can iterate over each character in a string:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_string</span>
                    <span className="n">s</span> <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"Input: "</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Output: "</span>
                    <span className="p">,</span> <span className="n">end</span>
                    <span className="o">=</span>
                    <span className="s">""</span>
                    <span className="p">)</span>
                    <span className="k">for</span> <span className="n">c</span>{" "}
                    <span className="ow">in</span> <span className="n">s</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">c</span>
                    <span className="p">,</span> <span className="n">end</span>
                    <span className="o">=</span>
                    <span className="s">""</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">()</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>Python will get each character in the string for us.</li>
            </ul>
          </li>
          <li>
            To make a string uppercase, too, we can just call{" "}
            <code className="highlighter-rouge">s.upper()</code> to get the
            uppercase version of the entire string, without having to iterate
            over each character ourselves.
          </li>
        </ul>

        <h2 id="more-features">More features</h2>

        <ul>
          <li>
            We can take command-line arguments with:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">sys</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">argv</span>
                    <span className="k">for</span> <span className="n">i</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="nb">range</span>
                    <span className="p">(</span>
                    <span className="nb">len</span>
                    <span className="p">(</span>
                    <span className="n">argv</span>
                    <span className="p">)):</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">argv</span>
                    <span className="p">[</span>
                    <span className="n">i</span>
                    <span className="p">])</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Since <code className="highlighter-rouge">argv</code> is a list
                of strings, we can use{" "}
                <code className="highlighter-rouge">len()</code> to get its
                length, and <code className="highlighter-rouge">range()</code>{" "}
                for a range of values that we can use as an index for each
                element in the list.
              </li>
            </ul>
          </li>
          <li>
            But we can also let Python iterate over the list for us:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">sys</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">argv</span>
                    <span className="k">for</span>{" "}
                    <span className="n">arg</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">argv</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">arg</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can return exit codes when our program exits, too:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">sys</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">argv</span>
                    <span className="p">,</span>{" "}
                    <span className="nb">exit</span>
                    <span className="k">if</span>{" "}
                    <span className="nb">len</span>
                    <span className="p">(</span>
                    <span className="n">argv</span>
                    <span className="p">)</span> <span className="o">!=</span>{" "}
                    <span className="mi">2</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"missing command-line argument"</span>
                    <span className="p">)</span>
                    <span className="nb">exit</span>
                    <span className="p">(</span>
                    <span className="mi">1</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">"hello, {"{argv[1]}"}"</span>
                    <span className="p">)</span>
                    <span className="nb">exit</span>
                    <span className="p">(</span>
                    <span className="mi">0</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                We import the <code className="highlighter-rouge">exit</code>{" "}
                function, and call it with the code we want our program to exit
                with.
              </li>
            </ul>
          </li>
          <li>
            We can implement linear search by just checking each element in a
            list:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">sys</span>
                    <span className="n">names</span>{" "}
                    <span className="o">=</span> <span className="p">[</span>
                    <span className="s">"EMMA"</span>
                    <span className="p">,</span>{" "}
                    <span className="s">"RODRIGO"</span>
                    <span className="p">,</span>{" "}
                    <span className="s">"BRIAN"</span>
                    <span className="p">,</span>{" "}
                    <span className="s">"DAVID"</span>
                    <span className="p">]</span>
                    <span className="k">if</span>{" "}
                    <span className="s">"EMMA"</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">names</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Found"</span>
                    <span className="p">)</span>
                    <span className="n">sys</span>
                    <span className="o">.</span>
                    <span className="nb">exit</span>
                    <span className="p">(</span>
                    <span className="mi">0</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Not found"</span>
                    <span className="p">)</span>
                    <span className="n">sys</span>
                    <span className="o">.</span>
                    <span className="nb">exit</span>
                    <span className="p">(</span>
                    <span className="mi">1</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            If we have a dictionary, a set of key:value pairs, we can also check
            each key:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">sys</span>
                    <span className="n">people</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="p">{"{"}</span>
                    <span className="s">"EMMA"</span>
                    <span className="p">:</span>{" "}
                    <span className="s">"617-555-0100"</span>
                    <span className="p">,</span>
                    <span className="s">"RODRIGO"</span>
                    <span className="p">:</span>{" "}
                    <span className="s">"617-555-0101"</span>
                    <span className="p">,</span>
                    <span className="s">"BRIAN"</span>
                    <span className="p">:</span>{" "}
                    <span className="s">"617-555-0102"</span>
                    <span className="p">,</span>
                    <span className="s">"DAVID"</span>
                    <span className="p">:</span>{" "}
                    <span className="s">"617-555-0103"</span>
                    <span className="p">{"}"}</span>
                    <span className="k">if</span>{" "}
                    <span className="s">"EMMA"</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">people</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">"Found {"{people['EMMA']}"}"</span>
                    <span className="p">)</span>
                    <span className="n">sys</span>
                    <span className="o">.</span>
                    <span className="nb">exit</span>
                    <span className="p">(</span>
                    <span className="mi">0</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Not found"</span>
                    <span className="p">)</span>
                    <span className="n">sys</span>
                    <span className="o">.</span>
                    <span className="nb">exit</span>
                    <span className="p">(</span>
                    <span className="mi">1</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Notice that we can get the value of of a particular key in a
                dictionary with{" "}
                <code className="highlighter-rouge">people['EMMA']</code>. Here,
                we use single quotes (both single and double quotes are allowed,
                as long they match for a string) to differentiate the inner
                string from the outer string.
              </li>
              <li>
                And we declare dictionaries with curly braces,{" "}
                <code className="highlighter-rouge">{}</code>, and lists with
                brackets <code className="highlighter-rouge">[]</code>.
              </li>
            </ul>
          </li>
          <li>
            In Python, we can compare strings directly with just{" "}
            <code className="highlighter-rouge">==</code>:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_string</span>
                    <span className="n">s</span> <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"s: "</span>
                    <span className="p">)</span>
                    <span className="n">t</span> <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"t: "</span>
                    <span className="p">)</span>
                    <span className="k">if</span> <span className="n">s</span>{" "}
                    <span className="o">==</span> <span className="n">t</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Same"</span>
                    <span className="p">)</span>
                    <span className="k">else</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Different"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            Copying strings, too, works without any extra work from us:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_string</span>
                    <span className="n">s</span> <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"s: "</span>
                    <span className="p">)</span>
                    <span className="n">t</span> <span className="o">=</span>{" "}
                    <span className="n">s</span>
                    <span className="n">t</span> <span className="o">=</span>{" "}
                    <span className="n">t</span>
                    <span className="o">.</span>
                    <span className="n">capitalize</span>
                    <span className="p">()</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">"s: {"{s}"}"</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">"t: {"{t}"}"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            Swapping two variables can also be done by assigning both values at
            the same time:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="n">x</span> <span className="o">=</span>{" "}
                    <span className="mi">1</span>
                    <span className="n">y</span> <span className="o">=</span>{" "}
                    <span className="mi">2</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">
                      "x is {"{x}"}, y is {"{y}"}"
                    </span>
                    <span className="p">)</span>
                    <span className="n">x</span>
                    <span className="p">,</span> <span className="n">y</span>{" "}
                    <span className="o">=</span> <span className="n">y</span>
                    <span className="p">,</span> <span className="n">x</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">
                      "x is {"{x}"}, y is {"{y}"}"
                    </span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
        </ul>

        <h2 id="files">Files</h2>

        <ul>
          <li>
            Let’s open a CSV file:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">csv</span>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_string</span>
                    <span className="nb">file</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">"phonebook.csv"</span>
                    <span className="p">,</span> <span className="s">"a"</span>
                    <span className="p">)</span>
                    <span className="n">name</span> <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"Name: "</span>
                    <span className="p">)</span>
                    <span className="n">number</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"Number: "</span>
                    <span className="p">)</span>
                    <span className="n">writer</span>{" "}
                    <span className="o">=</span> <span className="n">csv</span>
                    <span className="o">.</span>
                    <span className="n">writer</span>
                    <span className="p">(</span>
                    <span className="nb">file</span>
                    <span className="p">)</span>
                    <span className="n">writer</span>
                    <span className="o">.</span>
                    <span className="n">writerow</span>
                    <span className="p">((</span>
                    <span className="n">name</span>
                    <span className="p">,</span>{" "}
                    <span className="n">number</span>
                    <span className="p">))</span>
                    <span className="nb">file</span>
                    <span className="o">.</span>
                    <span className="n">close</span>
                    <span className="p">()</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                It turns out that Python also has a{" "}
                <code className="highlighter-rouge">csv</code> package (library)
                that helps us work with CSV files, so after we open the file for
                appending, we can call{" "}
                <code className="highlighter-rouge">csv.writer</code> to create
                a <code className="highlighter-rouge">writer</code> from the
                file and then{" "}
                <code className="highlighter-rouge">writer.writerow</code> to
                write a row. With the inner parentheses, we’re creating a tuple
                with the values we want to write, so we’re actually passing in a
                single argument that has all the values for our row.
              </li>
            </ul>
          </li>
          <li>
            We can use the <code className="highlighter-rouge">with</code>{" "}
            keyword, which will helpfully close the file for us:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="o">...</span>
                    <span className="k">with</span>{" "}
                    <span className="nb">open</span>
                    <span className="p">(</span>
                    <span className="s">"phonebook.csv"</span>
                    <span className="p">,</span> <span className="s">"a"</span>
                    <span className="p">)</span> <span className="k">as</span>{" "}
                    <span className="nb">file</span>
                    <span className="p">:</span>
                    <span className="n">writer</span>{" "}
                    <span className="o">=</span> <span className="n">csv</span>
                    <span className="o">.</span>
                    <span className="n">writer</span>
                    <span className="p">(</span>
                    <span className="nb">file</span>
                    <span className="p">)</span>
                    <span className="n">writer</span>
                    <span className="o">.</span>
                    <span className="n">writerow</span>
                    <span className="p">((</span>
                    <span className="n">name</span>
                    <span className="p">,</span>{" "}
                    <span className="n">number</span>
                    <span className="p">))</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
        </ul>

        <h2 id="new-features">New features</h2>

        <ul>
          <li>
            A feature of Python that C does not have is{" "}
            <strong>regular expressions</strong>, or patterns against which we
            can match strings. For example, its syntax includes:
            <ul>
              <li>
                <code className="highlighter-rouge">.</code>, for any character
              </li>
              <li>
                <code className="highlighter-rouge">.*</code>, for 0 or more
                characters
              </li>
              <li>
                <code className="highlighter-rouge">.+</code>, for 1 or more
                characters
              </li>
              <li>
                <code className="highlighter-rouge">?</code>, for something
                optional
              </li>
              <li>
                <code className="highlighter-rouge">^</code>, for start of input
              </li>
              <li>
                <code className="highlighter-rouge">$</code>, for end of input
              </li>
            </ul>
          </li>
          <li>
            For example, we can match strings with:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">re</span>
                    <span className="kn">from</span>{" "}
                    <span className="nn">cs50</span>{" "}
                    <span className="kn">import</span>{" "}
                    <span className="n">get_string</span>
                    <span className="n">s</span> <span className="o">=</span>{" "}
                    <span className="n">get_string</span>
                    <span className="p">(</span>
                    <span className="s">"Do you agree?</span>
                    <span className="se">\n</span>
                    <span className="s">"</span>
                    <span className="p">)</span>
                    <span className="k">if</span> <span className="n">re</span>
                    <span className="o">.</span>
                    <span className="n">search</span>
                    <span className="p">(</span>
                    <span className="s">"^y(es)?$"</span>
                    <span className="p">,</span> <span className="n">s</span>
                    <span className="p">,</span> <span className="n">re</span>
                    <span className="o">.</span>
                    <span className="n">IGNORECASE</span>
                    <span className="p">):</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Agreed."</span>
                    <span className="p">)</span>
                    <span className="k">elif</span>{" "}
                    <span className="n">re</span>
                    <span className="o">.</span>
                    <span className="n">search</span>
                    <span className="p">(</span>
                    <span className="s">"^no?$"</span>
                    <span className="p">,</span> <span className="n">s</span>
                    <span className="p">,</span> <span className="n">re</span>
                    <span className="o">.</span>
                    <span className="n">IGNORECASE</span>
                    <span className="p">):</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Not agreed."</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                First, we need the <code className="highlighter-rouge">re</code>{" "}
                package, or library, for regular expressions.
              </li>
              <li>
                Then, for <code className="highlighter-rouge">y</code> or{" "}
                <code className="highlighter-rouge">yes</code>, we have the
                regular expression{" "}
                <code className="highlighter-rouge">^y(es)?$</code>. We want to
                make sure that the string starts with{" "}
                <code className="highlighter-rouge">y</code>, and optionally has{" "}
                <code className="highlighter-rouge">es</code> immediately after
                the <code className="highlighter-rouge">y</code>, and then ends.
              </li>
              <li>
                Similarly, for <code className="highlighter-rouge">n</code> and{" "}
                <code className="highlighter-rouge">no</code>, we want our
                string to start, have the letter{" "}
                <code className="highlighter-rouge">n</code>, and optionally the
                letter <code className="highlighter-rouge">o</code> next, and
                then end. The regular expression for that would be{" "}
                <code className="highlighter-rouge">^no?$</code>.
              </li>
              <li>
                We pass in another argument,{" "}
                <code className="highlighter-rouge">re.IGNORECASE</code>, to
                ignore the casing of the letters in the string.
              </li>
              <li>
                If neither regular expression matches, we wouldn’t print
                anything.
              </li>
            </ul>
          </li>
          <li>
            On our own Mac or PC, we can open a terminal after installing
            Python, and use the microphone to convert our speech to text:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="kn">import</span>{" "}
                    <span className="nn">speech_recognition</span>
                    <span className="n">recognizer</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">speech_recognition</span>
                    <span className="o">.</span>
                    <span className="n">Recognizer</span>
                    <span className="p">()</span>
                    <span className="k">with</span>{" "}
                    <span className="n">speech_recognition</span>
                    <span className="o">.</span>
                    <span className="n">Microphone</span>
                    <span className="p">()</span> <span className="k">as</span>{" "}
                    <span className="n">source</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Say something!"</span>
                    <span className="p">)</span>
                    <span className="n">audio</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">recognizer</span>
                    <span className="o">.</span>
                    <span className="n">listen</span>
                    <span className="p">(</span>
                    <span className="n">source</span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">
                      "Google Speech Recognition thinks you said:"
                    </span>
                    <span className="p">)</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">recognizer</span>
                    <span className="o">.</span>
                    <span className="n">recognize_google</span>
                    <span className="p">(</span>
                    <span className="n">audio</span>
                    <span className="p">))</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                It turns out that there’s another library we can download,
                called{" "}
                <code className="highlighter-rouge">speech_recognition</code>,
                that can listen to audio and convert it to a string.
              </li>
            </ul>
          </li>
          <li>
            And now, we can match on the audio to print something else:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="o">...</span>
                    <span className="n">words</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">recognizer</span>
                    <span className="o">.</span>
                    <span className="n">recognize_google</span>
                    <span className="p">(</span>
                    <span className="n">audio</span>
                    <span className="p">)</span>
                    <span className="c1"># Respond to speech</span>
                    <span className="k">if</span>{" "}
                    <span className="s">"hello"</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">words</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Hello to you too!"</span>
                    <span className="p">)</span>
                    <span className="k">elif</span>{" "}
                    <span className="s">"how are you"</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">words</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"I am well, thanks!"</span>
                    <span className="p">)</span>
                    <span className="k">elif</span>{" "}
                    <span className="s">"goodbye"</span>{" "}
                    <span className="ow">in</span>{" "}
                    <span className="n">words</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Goodbye to you too!"</span>
                    <span className="p">)</span>
                    <span className="k">else</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Huh?"</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li>
            We can even use regular expressions, to match on part of a string:
            <div className="language-python highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="o">...</span>
                    <span className="n">words</span>{" "}
                    <span className="o">=</span>{" "}
                    <span className="n">recognizer</span>
                    <span className="o">.</span>
                    <span className="n">recognize_google</span>
                    <span className="p">(</span>
                    <span className="n">audio</span>
                    <span className="p">)</span>
                    <span className="n">matches</span>{" "}
                    <span className="o">=</span> <span className="n">re</span>
                    <span className="o">.</span>
                    <span className="n">search</span>
                    <span className="p">(</span>
                    <span className="s">"my name is (.*)"</span>
                    <span className="p">,</span>{" "}
                    <span className="n">words</span>
                    <span className="p">)</span>
                    <span className="k">if</span>{" "}
                    <span className="n">matches</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="n">f</span>
                    <span className="s">"Hey, {"{matches[1]}"}."</span>
                    <span className="p">)</span>
                    <span className="k">else</span>
                    <span className="p">:</span>
                    <span className="k">print</span>
                    <span className="p">(</span>
                    <span className="s">"Hey, you."</span>
                    <span className="p">)</span>
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li>
                Here, we can get all the characters after{" "}
                <code className="highlighter-rouge">my name is </code> with{" "}
                <code className="highlighter-rouge">.*</code>, and print it out.
              </li>
            </ul>
          </li>
          <li>
            We run{" "}
            <a href="https://cdn.cs50.net/2019/fall/lectures/6/src6/6/faces/">
              detect.py and faces.py
            </a>
            , which finds each face (or even a specific face) in a photo.
          </li>
          <li>
            <a href="https://cdn.cs50.net/2019/fall/lectures/6/src6/6/qr/">
              qr.py
            </a>{" "}
            will also generate a QR code to a particular URL.
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Note6;
