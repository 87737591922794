import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

function Houses() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md">
        <a data-id="" id="houses"></a>
        <h1>
          <a data-id="" href="#houses">
            Houses
          </a>
        </h1>

        <p>
          Implement a program to import student data into a database, and then
          produce class rosters.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                $ python import.py characters.csv
                <br />
                $ python roster.py Gryffindor
                <br />
                <br />
                <br />
                Lavender Brown, born 1979
                <br />
                Colin Creevey, born 1981
                <br />
                Seamus Finnigan, born 1979
                <br />
                Hermione Jean Granger, born 1979
                <br />
                Neville Longbottom, born 1980
                <br />
                Parvati Patil, born 1979
                <br />
                Harry James Potter, born 1980
                <br />
                Dean Thomas, born 1980
                <br />
                Romilda Vane, born 1981
                <br />
                Ginevra Molly Weasley, born 1981
                <br />
                Ronald Bilius Weasley, born 1980
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="getting-started"></a>
        <h2>
          <a data-id="" href="#getting-started">
            Getting Started
          </a>
        </h2>

        <p>
          Here’s how to download this problem into your own CS50 IDE. Log into{" "}
          <a href="https://ide.cs50.io/">CS50 IDE</a> and then, in a terminal
          window, execute each of the below.
        </p>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Execute <code className="highlighter-rouge">cd</code> to ensure that
            you’re in <code className="highlighter-rouge">~/</code> (i.e., your
            home directory, aka <code className="highlighter-rouge">~</code>).
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            If you haven’t already, execute{" "}
            <code className="highlighter-rouge">mkdir pset7</code> to make
            (i.e., create) a directory called{" "}
            <code className="highlighter-rouge">pset7</code> in your home
            directory.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Execute <code className="highlighter-rouge">cd pset7</code> to
            change into (i.e., open) that directory.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Execute{" "}
            <code className="highlighter-rouge">
              wget https://cdn.cs50.net/2019/fall/psets/7/houses/houses.zip
            </code>{" "}
            to download a (compressed) ZIP file with this problem’s
            distribution.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Execute <code className="highlighter-rouge">unzip houses.zip</code>{" "}
            to uncompress that file.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Execute <code className="highlighter-rouge">rm houses.zip</code>{" "}
            followed by <code className="highlighter-rouge">yes</code> or{" "}
            <code className="highlighter-rouge">y</code> to delete that ZIP
            file.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Execute <code className="highlighter-rouge">ls</code>. You should
            see a directory called{" "}
            <code className="highlighter-rouge">houses</code>, which was inside
            of that ZIP file.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Execute <code className="highlighter-rouge">cd houses</code> to
            change into that directory.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Execute <code className="highlighter-rouge">ls</code>. You should
            see a <code className="highlighter-rouge">characters.csv</code> file
            and a <code className="highlighter-rouge">students.db</code>{" "}
            database.
          </li>
        </ul>

        <a data-id="" id="background"></a>
        <h2>
          <a data-id="" href="#background">
            Background
          </a>
        </h2>

        <p>
          Hogwarts is in need of a student database. For years, the professors
          have been maintaing a CSV file containing all of the students’ names
          and houses and years. But that file didn’t make it particularly easy
          to get access to certain data, such as a roster of all the Ravenclaw
          students, or an alphabetical listing of the students enrolled at the
          school.
        </p>

        <p>
          The challenge ahead of you is to import all of the school’s data into
          a SQLite database, and write a Python program to query that database
          to get house rosters for each of the houses of Hogwarts.
        </p>

        <a data-id="" id="specification"></a>
        <h2>
          <a data-id="" href="#specification">
            Specification
          </a>
        </h2>

        <p>
          In <code className="highlighter-rouge">import.py</code>, write a
          program that imports data from a CSV spreadsheet.
        </p>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Your program should accept the name of a CSV file as a command-line
            argument.
            <ul className="fa-ul">
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                If the incorrect number of command-line arguments are provided,
                your program should print an error and exit.
              </li>
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                You may assume that the CSV file will exist, and will have
                columns <code className="highlighter-rouge">name</code>,{" "}
                <code className="highlighter-rouge">house</code>, and{" "}
                <code className="highlighter-rouge">birth</code>.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            For each student in the CSV file, insert the student into the{" "}
            <code className="highlighter-rouge">students</code> table in the{" "}
            <code className="highlighter-rouge">students.db</code> database.
            <ul className="fa-ul">
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                While the CSV file provided to you has just a{" "}
                <code className="highlighter-rouge">name</code> column, the
                database has separate columns for{" "}
                <code className="highlighter-rouge">first</code>,{" "}
                <code className="highlighter-rouge">middle</code>, and{" "}
                <code className="highlighter-rouge">last</code> names. You’ll
                thus want to first parse each name and separate it into first,
                middle, and last names. You may assume that each person’s name
                field will contain either two space-separated names (a first and
                last name) or three space-separated names (a first, middle, and
                last name). For students without a middle name, you should leave
                their <code className="highlighter-rouge">middle</code> name
                field as <code className="highlighter-rouge">NULL</code> in the
                table.
              </li>
            </ul>
          </li>
        </ul>

        <p>
          In <code className="highlighter-rouge">roster.py</code>, write a
          program that prints a list of students for a given house in
          alphabetical order.
        </p>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Your program should accept the name of a house as a command-line
            argument.
            <ul className="fa-ul">
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                If the incorrect number of command-line arguments are provided,
                your program should print an error and exit.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Your program should query the{" "}
            <code className="highlighter-rouge">students</code> table in the{" "}
            <code className="highlighter-rouge">students.db</code> database for
            all of the students in the specified house.
          </li>
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Your program should then print out each student’s full name and
            birth year (formatted as, e.g.,{" "}
            <code className="highlighter-rouge">
              Harry James Potter, born 1980
            </code>{" "}
            or{" "}
            <code className="highlighter-rouge">Luna Lovegood, born 1981</code>
            ).
            <ul className="fa-ul">
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                Each student should be printed on their own line.
              </li>
              <li data-marker="*">
                <span className="fa-li">
                  <i className="fas fa-circle"></i>
                </span>
                Students should be ordered by last name. For students with the
                same last name, they should be ordered by first name.
              </li>
            </ul>
          </li>
        </ul>

        <a data-id="" id="walkthrough"></a>
        <h2>
          <a data-id="" href="#walkthrough">
            Walkthrough
          </a>
        </h2>

        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
        >
          <iframe
            width="640"
            height="360"
            src={t("pset7_houses")}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <a data-id="" id="usage"></a>
        <h2>
          <a data-id="" href="#usage">
            Usage
          </a>
        </h2>

        <p>Your program should behave per the example below:</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />$ python import.py characters.csv
              </code>
            </pre>
          </div>
        </div>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                $ python roster.py Gryffindor
                <br />
                <br />
                Hermione Jean Granger, born 1979
                <br />
                Harry James Potter, born 1980
                <br />
                Ginevra Molly Weasley, born 1981
                <br />
                Ronald Bilius Weasley, born 1980 ...
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="hints"></a>
        <h2>
          <a data-id="" href="#hints">
            Hints
          </a>
        </h2>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Recall that after you’ve imported{" "}
            <code className="highlighter-rouge">SQL</code> from{" "}
            <code className="highlighter-rouge">cs50</code>, you can set up a
            database connection using syntax like
          </li>
        </ul>

        <div className="language-python highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <span className="n">db</span> <span className="o">=</span>{" "}
                <span className="n">SQL</span>
                <span className="p">(</span>
                <span className="s">"sqlite:///students.db"</span>
                <span className="p">)</span>
              </code>
            </pre>
          </div>
        </div>

        <p>
          Then, you can use{" "}
          <code className="highlighter-rouge">db.execute</code> to execute SQL
          queries from inside of your Python script.
        </p>

        <ul className="fa-ul">
          <li data-marker="*">
            <span className="fa-li">
              <i className="fas fa-circle"></i>
            </span>
            Recall that when you call{" "}
            <code className="highlighter-rouge">db.execute</code> and perform a{" "}
            <code className="highlighter-rouge">SELECT</code> query, the return
            value will be a <code className="highlighter-rouge">list</code> of
            rows that are returned, where each row is represented by a Python{" "}
            <code className="highlighter-rouge">dict</code>.
          </li>
        </ul>

        <a data-id="" id="testing"></a>
        <h2>
          <a data-id="" href="#testing">
            Testing
          </a>
        </h2>

        <p>
          No <code className="highlighter-rouge">check50</code> for this
          problem, but be sure to test your code for each of the following.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <br />
                $ python import.py characters.csv
                <br />
                <br />
                $ python roster.py Gryffindor
                <br />
                <br />
                Lavender Brown, born 1979
                <br />
                Colin Creevey, born 1981
                <br />
                Seamus Finnigan, born 1979
                <br />
                Hermione Jean Granger, born 1979
                <br />
                Neville Longbottom, born 1980
                <br />
                Parvati Patil, born 1979
                <br />
                Harry James Potter, born 1980
                <br />
                Dean Thomas, born 1980
                <br />
                Romilda Vane, born 1981
                <br />
                Ginevra Molly Weasley, born 1981
                <br />
                Ronald Bilius Weasley, born 1980
                <br />
                <br />
                $ python roster.py Hufflepuff
                <br />
                <br />
                Hannah Abbott, born 1980
                <br />
                Susan Bones, born 1979
                <br />
                Cedric Diggory, born 1977
                <br />
                Justin Finch-Fletchley, born 1979
                <br />
                Ernest Macmillan, born 1980
                <br />
                <br />
                $ python roster.py Ravenclaw
                <br />
                <br />
                Terry Boot, born 1980
                <br />
                Mandy Brocklehurst, born 1979
                <br />
                Cho Chang, born 1979
                <br />
                Penelope Clearwater, born 1976
                <br />
                Michael Corner, born 1979
                <br />
                Roger Davies, born 1978
                <br />
                Marietta Edgecombe, born 1978
                <br />
                Anthony Goldstein, born 1980
                <br />
                Robert Hilliard, born 1974
                <br />
                Luna Lovegood, born 1981
                <br />
                Isobel MacDougal, born 1980
                <br />
                Padma Patil, born 1979
                <br />
                Lisa Turpin, born 1979
                <br />
                $ python roster.py Slytherin
                <br />
                <br />
                Millicent Bulstrode, born 1979
                <br />
                Vincent Crabbe, born 1979
                <br />
                Tracey Davis, born 1980
                <br />
                Marcus Flint, born 1975
                <br />
                Gregory Goyle, born 1980
                <br />
                Terence Higgs, born 1979
                <br />
                Draco Lucius Malfoy, born 1980
                <br />
                Adelaide Murton, born 1982
                <br />
                Pansy Parkinson, born 1979
                <br />
                Adrian Pucey, born 1977
                <br />
                Blaise Zabini, born 1979
                <br />
              </code>
            </pre>
          </div>
        </div>

        <a data-id="" id="how-to-submit"></a>
        <h2>
          <a data-id="" href="#how-to-submit">
            How to Submit
          </a>
        </h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2020/x/houses</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Houses;
