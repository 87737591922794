import React, { useRef } from "react";

import n from "./img/n.png";
import p from "./img/p.png";
import pointing from "./img/pointing.png";
import s_array from "./img/s_array.png";
import s_pointer from "./img/s_pointer.png";
import memory_layout from "./img/memory_layout.png";
import stack from "./img/stack.png";
import s_value from "./img/s_value.png";
import s_t from "./img/s_t.png";
import swap_image from "./img/swap.png";

function Note4() {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();
  const ref10 = useRef();
  const ref11 = useRef();
  const ref12 = useRef();
  const ref13 = useRef();

  const handleClick = (e, reference) => {
    e.preventDefault();
    reference.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 className="no_toc" id="lecture-4">
          Lecture 4
        </h1>

        <ul id="markdown-toc">
          <li>
            <a
              href="#hexadecimal"
              id="markdown-toc-hexadecimal"
              onClick={(e) => handleClick(e, ref1)}
            >
              Hexadecimal
            </a>
          </li>
          <li>
            <a
              href="#addresses"
              id="markdown-toc-addresses"
              onClick={(e) => handleClick(e, ref2)}
            >
              Addresses
            </a>
          </li>
          <li>
            <a
              href="#pointers"
              id="markdown-toc-pointers"
              onClick={(e) => handleClick(e, ref3)}
            >
              Pointers
            </a>
          </li>
          <li>
            <a
              href="#strings"
              id="markdown-toc-strings"
              onClick={(e) => handleClick(e, ref4)}
            >
              Strings
            </a>
          </li>
          <li>
            <a
              href="#pointer-arithmetic"
              id="markdown-toc-pointer-arithmetic"
              onClick={(e) => handleClick(e, ref5)}
            >
              Pointer arithmetic
            </a>
          </li>
          <li>
            <a
              href="#compare-and-copy"
              id="markdown-toc-compare-and-copy"
              onClick={(e) => handleClick(e, ref6)}
            >
              Compare and copy
            </a>
          </li>
          <li>
            <a
              href="#valgrind"
              id="markdown-toc-valgrind"
              onClick={(e) => handleClick(e, ref7)}
            >
              valgrind
            </a>
          </li>
          <li>
            <a
              href="#garbage-values"
              id="markdown-toc-garbage-values"
              onClick={(e) => handleClick(e, ref8)}
            >
              Garbage values
            </a>
          </li>
          <li>
            <a
              href="#swap"
              id="markdown-toc-swap"
              onClick={(e) => handleClick(e, ref9)}
            >
              Swap
            </a>
          </li>
          <li>
            <a
              href="#memory-layout"
              id="markdown-toc-memory-layout"
              onClick={(e) => handleClick(e, ref10)}
            >
              Memory layout
            </a>
          </li>
          <li>
            <a
              href="#scanf"
              id="markdown-toc-scanf"
              onClick={(e) => handleClick(e, ref11)}
            >
              scanf
            </a>
          </li>
          <li>
            <a
              href="#files"
              id="markdown-toc-files"
              onClick={(e) => handleClick(e, ref12)}
            >
              Files
            </a>
          </li>
          <li>
            <a
              href="#graphics"
              id="markdown-toc-graphics"
              onClick={(e) => handleClick(e, ref13)}
            >
              Graphics
            </a>
          </li>
        </ul>

        <h2 id="hexadecimal" ref={ref1}>
          Hexadecimal
        </h2>

        <ul>
          <li data-marker="*">
            In week 2, we talked about memory and how each byte has an address,
            or identifier, so we can refer to where our data are actually
            stored.
          </li>
          <li data-marker="*">
            It turns out that, by convention, the addresses for memory use the
            counting system <strong>hexadecimal</strong>, or base-16, where
            there are 16 digits: 0-9, and A-F as equivalents to 10-15.
          </li>
          <li data-marker="*">
            Let’s consider a two-digit hexadecimal number:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`16^1 16^0
   0    A`}</code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Here, the A in the ones place (since 16^0 = 1) has a decimal
                value of 10. We can keep counting until{" "}
                <code className="language-plaintext highlighter-rouge">0F</code>
                , which is equivalent to 15 in decimal.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            After{" "}
            <code className="language-plaintext highlighter-rouge">0F</code>, we
            need to carry the one, as we would go from 09 to 10 in decimal:
            <div className="language-plaintext highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>{`16^1 16^0
   1    0`}</code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Here, the{" "}
                <code className="language-plaintext highlighter-rouge">1</code>{" "}
                has a value of 16^1 * 1 = 16, so{" "}
                <code className="language-plaintext highlighter-rouge">10</code>{" "}
                in hexadecimal is 16 in decimal.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            With two digits, we can have a maximum value of{" "}
            <code className="language-plaintext highlighter-rouge">FF</code>, or
            16^1 * 15 + 16^0 * 15 = 240 + 15 = 255, which is the same maximum
            value with 8 bits of binary. So two digits in hexadecimal can
            conveniently represent the value of a byte in binary. (Each digit in
            hexadecimal, with 16 values, maps to four bits in binary.)
          </li>
          <li data-marker="*">
            In writing, we indicate a value is in hexadecimal by prefixing it
            with{" "}
            <code className="language-plaintext highlighter-rouge">0x</code>, as
            in{" "}
            <code className="language-plaintext highlighter-rouge">0x10</code>,
            where the value is equal to 16 in decimal, as opposed to 10.
          </li>
          <li data-marker="*">
            The RGB color system conventionally uses hexadecimal to describe the
            amount of each color. For example,{" "}
            <code className="language-plaintext highlighter-rouge">000000</code>{" "}
            in hexadecimal represents 0 for each of red, green, and blue, for a
            combined color of black. And{" "}
            <code className="language-plaintext highlighter-rouge">FF0000</code>{" "}
            would be 255, or the highest possible, amount of red.{" "}
            <code className="language-plaintext highlighter-rouge">FFFFFF</code>{" "}
            would indicate the highest value of each color, combining to be the
            brightest white. With different values for each color, we can
            represent millions of different colors.
          </li>
          <li data-marker="*">
            For our computer’s memory, too, we’ll use hexadecimal for each
            address or location.
          </li>
        </ul>

        <h2 id="addresses" ref={ref2}>
          Addresses
        </h2>

        <ul>
          <li data-marker="*">
            We might create a value{" "}
            <code className="language-plaintext highlighter-rouge">n</code>, and
            print it out:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

int main(void)
{
    int n = 50;
    printf("%i\\n", n);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            In our computer’s memory, there are now 4 bytes somewhere that have
            the binary value of 50, labeled{" "}
            <code className="language-plaintext highlighter-rouge">n</code>:
            <br />
            <img
              src={n}
              alt="grid representing bytes, with four boxes together containing 50 with small n underneath"
            />
          </li>
          <li data-marker="*">
            It turns out that, with the billions of bytes in memory, those bytes
            for the variable{" "}
            <code className="language-plaintext highlighter-rouge">n</code>{" "}
            starts at some location, which might look something like{" "}
            <code className="language-plaintext highlighter-rouge">
              0x12345678
            </code>
            .
          </li>
          <li data-marker="*">
            In C, we can actually see the address with the{" "}
            <code className="language-plaintext highlighter-rouge">&amp;</code>{" "}
            operator, which means “get the address of this variable”:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

int main(void)
{
    int n = 50;
    printf("%p\\n", &n);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">%p</code>{" "}
                is the format code for an address.
              </li>
              <li data-marker="*">
                In the CS50 IDE, we see an address like{" "}
                <code className="language-plaintext highlighter-rouge">
                  0x7ffd80792f7c
                </code>
                . The value of the address in itself is not useful, since it’s
                just some location in memory that the variable is stored in;
                instead, the important idea is that we can <em>use</em> this
                address later.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            The <code className="language-plaintext highlighter-rouge">*</code>{" "}
            operator, or the dereference operator, lets us “go to” the location
            that a pointer is pointing to.
          </li>
          <li data-marker="*">
            For example, we can print{" "}
            <code className="language-plaintext highlighter-rouge">
              *&amp;n
            </code>
            , where we “go to” the address of{" "}
            <code className="language-plaintext highlighter-rouge">n</code>, and
            that will print out the value of{" "}
            <code className="language-plaintext highlighter-rouge">n</code>,{" "}
            <code className="language-plaintext highlighter-rouge">50</code>,
            since that’s the value at the address of{" "}
            <code className="language-plaintext highlighter-rouge">n</code>:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

int main(void)
{
    int n = 50;
    printf("%i\\n", *&n);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
        </ul>

        <h2 id="pointers" ref={ref3}>
          Pointers
        </h2>

        <ul>
          <li data-marker="*">
            A variable that stores an address is called a{" "}
            <strong>pointer</strong>, which we can think of as a value that
            “points” to a location in memory. In C, pointers can refer to
            specific types of values.
          </li>
          <li data-marker="*">
            We can use the{" "}
            <code className="language-plaintext highlighter-rouge">*</code>{" "}
            operator (in an unfortunately confusing way) to declare a variable
            that we want to be a pointer:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

int main(void)
{
   int n = 50;
   int *p = &n;
   printf("%p\\n", p);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Here, we use{" "}
                <code className="language-plaintext highlighter-rouge">
                  int *p
                </code>{" "}
                to declare a variable,{" "}
                <code className="language-plaintext highlighter-rouge">p</code>,
                that has the type of{" "}
                <code className="language-plaintext highlighter-rouge">*</code>,
                a pointer, to a value of type{" "}
                <code className="language-plaintext highlighter-rouge">
                  int
                </code>
                , an integer. Then, we can print its value (an address,
                something like{" "}
                <code className="language-plaintext highlighter-rouge">
                  0x12345678
                </code>
                ), or print the <em>value at</em> its location with{" "}
                <code className="language-plaintext highlighter-rouge">
                  printf("%i\n", *p);
                </code>
                .
              </li>
            </ul>
          </li>
          <li data-marker="*">
            In our computer’s memory, the variables will look like this:
            <br />
            <img
              src={p}
              alt="grid representing bytes, with four boxes together containing 50 with small 0x123 underneath, and eight boxes together containing 0x123 with small p underneath"
            />
            <ul>
              <li data-marker="*">
                Since{" "}
                <code className="language-plaintext highlighter-rouge">p</code>{" "}
                is a variable itself, it’s somewhere in memory, and the value
                stored there is the address of{" "}
                <code className="language-plaintext highlighter-rouge">n</code>.
              </li>
              <li data-marker="*">
                Modern computer systems are “64-bit”, meaning that they use 64
                bits to address memory, so a pointer will in reality be 8 bytes,
                twice as big as an integer of 4 bytes.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can abstract away the actual value of the addresses, since
            they’ll be different as we declare variables in our programs and not
            very useful, and simply think of{" "}
            <code className="language-plaintext highlighter-rouge">p</code> as
            “pointing at” some value:
            <br />
            <img
              src={pointing}
              alt="one box containing p pointing at smaller box containing 50"
            />
          </li>
          <li data-marker="*">
            In the real world, we might have a mailbox labeled “p”, among many
            mailboxes with addresses. Inside our mailbox, we can put a value
            like{" "}
            <code className="language-plaintext highlighter-rouge">0x123</code>,
            which is the address of some other mailbox{" "}
            <code className="language-plaintext highlighter-rouge">n</code>,
            with the address{" "}
            <code className="language-plaintext highlighter-rouge">0x123</code>.
          </li>
        </ul>

        <h2 id="strings" ref={ref4}>
          Strings
        </h2>

        <ul>
          <li data-marker="*">
            A variable declared with{" "}
            <code className="language-plaintext highlighter-rouge">
              string s = "HI!";
            </code>{" "}
            will be stored one character at a time in memory. And we can access
            each character with{" "}
            <code className="language-plaintext highlighter-rouge">s[0]</code>,{" "}
            <code className="language-plaintext highlighter-rouge">s[1]</code>,{" "}
            <code className="language-plaintext highlighter-rouge">s[2]</code>,
            and{" "}
            <code className="language-plaintext highlighter-rouge">s[3]</code>:
            <br />
            <img
              src={s_array}
              alt="boxes side by side, containing: H labeled s[0], I labeled s[1], ! labeled s[2], \0 labeled s[3]"
            />
          </li>
          <li data-marker="*">
            But it turns out that each character, since it’s stored in memory,{" "}
            <em>also</em> has some unique address, and{" "}
            <code className="language-plaintext highlighter-rouge">s</code> is
            actually just a pointer with the address of the first character:
            <br />
            <img
              src={s_pointer}
              alt="boxes side by side containing H labeled 0x123, I labeled 0x124, ! labeled 0x125, \0 labeled 0x126"
            />
          </li>
          <li data-marker="*">
            And the variable{" "}
            <code className="language-plaintext highlighter-rouge">s</code>{" "}
            stores the address of the first character of the string. The value{" "}
            <code className="language-plaintext highlighter-rouge">\0</code> is
            the only indicator of the end of the string:
            <br />
            <img
              src={s_value}
              alt="box containing 0x123 labeled s, boxes side by side containing H labeled 0x123, I labeled 0x124, ! labeled 0x125, \0 labeled 0x126"
            />
            <ul>
              <li data-marker="*">
                Since the rest of the characters are in an array, back-to-back,
                we can start at the address in{" "}
                <code className="language-plaintext highlighter-rouge">s</code>{" "}
                and continue reading one character at a time from memory until
                we reach{" "}
                <code className="language-plaintext highlighter-rouge">\0</code>
                .
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Let’s print out a string:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <stdio.h>

int main(void)
{
    string s = "HI!";
    printf("%s\\n", s);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We can see the value stored in{" "}
            <code className="language-plaintext highlighter-rouge">s</code> with{" "}
            <code className="language-plaintext highlighter-rouge">
              printf("%p\n", s);
            </code>
            , and we see something like{" "}
            <code className="language-plaintext highlighter-rouge">
              0x4006a4
            </code>{" "}
            since we’re printing the address in memory of the first character of
            the string.
          </li>
          <li data-marker="*">
            If we add another line,{" "}
            <code className="language-plaintext highlighter-rouge">
              printf("%p\n", &amp;s[1]);
            </code>
            , we indeed see the next address in memory:{" "}
            <code className="language-plaintext highlighter-rouge">
              0x4006a5
            </code>
            .
          </li>
          <li data-marker="*">
            It turns out that{" "}
            <code className="language-plaintext highlighter-rouge">
              string s
            </code>{" "}
            is just a pointer, an address to some character in memory.
          </li>
          <li data-marker="*">
            In fact, the CS50 library defines a type that doesn’t exist in C,{" "}
            <code className="language-plaintext highlighter-rouge">string</code>
            , as{" "}
            <code className="language-plaintext highlighter-rouge">char *</code>
            , with{" "}
            <code className="language-plaintext highlighter-rouge">
              typedef char *string;
            </code>
            . The custom type,{" "}
            <code className="language-plaintext highlighter-rouge">string</code>
            , is defined as just a{" "}
            <code className="language-plaintext highlighter-rouge">char *</code>{" "}
            with{" "}
            <code className="language-plaintext highlighter-rouge">
              typedef
            </code>
            . So{" "}
            <code className="language-plaintext highlighter-rouge">
              string s = "HI!"
            </code>{" "}
            is the same as{" "}
            <code className="language-plaintext highlighter-rouge">
              char *s = "HI!";
            </code>
            . And we can use strings in C in the exact same way without the CS50
            library, by using{" "}
            <code className="language-plaintext highlighter-rouge">char *</code>
            .
          </li>
        </ul>

        <h2 id="pointer-arithmetic" ref={ref5}>
          Pointer arithmetic
        </h2>

        <ul>
          <li data-marker="*">
            <strong>Pointer arithmetic</strong> is mathematical operations on
            addresses with pointers.
          </li>
          <li data-marker="*">
            We can print out each character in a string (using{" "}
            <code className="language-plaintext highlighter-rouge">char *</code>{" "}
            directly):
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

int main(void)
{
    char *s = "HI!";
    printf("%c\\n", s[0]);
    printf("%c\\n", s[1]);
    printf("%c\\n", s[2]);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            But we can go to addresses directly:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

int main(void)
{
    char *s = "HI!";
    printf("%c\\n", *s);
    printf("%c\\n", *(s+1));
    printf("%c\\n", *(s+2));
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">*s</code>{" "}
                goes to the address stored in{" "}
                <code className="language-plaintext highlighter-rouge">s</code>,
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  *(s+1)
                </code>{" "}
                goes to the location in memory with an address one byte higher,
                or the next character.{" "}
                <code className="language-plaintext highlighter-rouge">
                  s[1]
                </code>{" "}
                is syntactic sugar for{" "}
                <code className="language-plaintext highlighter-rouge">
                  *(s+1)
                </code>
                , equivalent in function but more human-friendly to read and
                write.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can even try to go to addresses in memory that we shouldn’t, like
            with{" "}
            <code className="language-plaintext highlighter-rouge">
              *(s+10000)
            </code>
            , and when we run our program, we’ll get a{" "}
            <strong>segmentation fault</strong>, or crash as a result of our
            program touching memory in a segment it shouldn’t have.
          </li>
        </ul>

        <h2 id="compare-and-copy" ref={ref6}>
          Compare and copy
        </h2>

        <ul>
          <li data-marker="*">
            Let’s try to compare two integers from the user:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <stdio.h>

int main(void)
{
    int i = get_int("i: ");
    int j = get_int("j: ");

    if (i == j)
    {
        printf("Same\\n");
    }
    else
    {
        printf("Different\\n");
    }
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We compile and run our program, and it works as we’d expect,
                with the same values of the two integers giving us “Same” and
                different values “Different”.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            When we try to compare two strings, we see that the same inputs are
            causing our program to print “Different”:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <stdio.h>

int main(void)
{
    char *s = get_string("s: ");
    char *t = get_string("t: ");

    if (s == t)
    {
        printf("Same\\n");
    }
    else
    {
        printf("Different\\n");
    }
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Even when our inputs are the same, we see “Different” printed.
              </li>
              <li data-marker="*">
                Each “string” is a pointer,{" "}
                <code className="language-plaintext highlighter-rouge">
                  char *
                </code>
                , to a different location in memory, where the first character
                of each string is stored. So even if the characters in the
                string are the same, this will always print “Different”.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            For example, our first string might be at address 0x123, our second
            might be at 0x456, and{" "}
            <code className="language-plaintext highlighter-rouge">s</code> will
            have the value of{" "}
            <code className="language-plaintext highlighter-rouge">0x123</code>,
            pointing at that location, and{" "}
            <code className="language-plaintext highlighter-rouge">t</code> will
            have the value of{" "}
            <code className="language-plaintext highlighter-rouge">0x456</code>,
            pointing at another location:
            <br />
            <img
              src={s_t}
              alt="box containing 0x123 labeled s, boxes side by side containing H labeled 0x123, I labeled 0x124, ! labeled 0x125, \0 labeled 0x126, box containing 0x456 labeled t, boxes side by side containing H labeled 0x456, I labeled 0x457, ! labeled 0x458, \0 labeled 0x459"
            />
          </li>
          <li data-marker="*">
            And{" "}
            <code className="language-plaintext highlighter-rouge">
              get_string
            </code>
            , this whole time, has been returning just a{" "}
            <code className="language-plaintext highlighter-rouge">char *</code>
            , or a pointer to the first character of a string from the user.
            Since we called{" "}
            <code className="language-plaintext highlighter-rouge">
              get_string
            </code>{" "}
            twice, we got two different pointers back.
          </li>
          <li data-marker="*">
            Let’s try to copy a string:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <ctype.h>
#include <stdio.h>

int main(void)
{
    char *s = get_string("s: ");

    char *t = s;

    t[0] = toupper(t[0]);

    printf("s: %s\\n", s);
    printf("t: %s\\n", t);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We get a string{" "}
                <code className="language-plaintext highlighter-rouge">s</code>,
                and copy the value of{" "}
                <code className="language-plaintext highlighter-rouge">s</code>{" "}
                into{" "}
                <code className="language-plaintext highlighter-rouge">t</code>.
                Then, we capitalize the first letter in{" "}
                <code className="language-plaintext highlighter-rouge">t</code>.
              </li>
              <li data-marker="*">
                But when we run our program, we see that both{" "}
                <code className="language-plaintext highlighter-rouge">s</code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">t</code>{" "}
                are now capitalized.
              </li>
              <li data-marker="*">
                Since we set{" "}
                <code className="language-plaintext highlighter-rouge">s</code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">t</code>{" "}
                to the same value, or the same address, they’re both pointing to
                the same character, and so we capitalized the same character in
                memory!
              </li>
            </ul>
          </li>
          <li data-marker="*">
            To actually make a copy of a string, we have to do a little more
            work, and copy each character in{" "}
            <code className="language-plaintext highlighter-rouge">s</code> to
            somewhere else in memory:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <ctype.h>
#include <stdio.h>
#include <stdlib.h>
#include <string.h>

int main(void)
{
    char *s = get_string("s: ");

    char *t = malloc(strlen(s) + 1);

    for (int i = 0, n = strlen(s); i < n + 1; i++)
    {
        t[i] = s[i];
    }

    t[0] = toupper(t[0]);

    printf("s: %s\\n", s);
    printf("t: %s\\n", t);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We create a new variable,{" "}
                <code className="language-plaintext highlighter-rouge">t</code>,
                of the type{" "}
                <code className="language-plaintext highlighter-rouge">
                  char *
                </code>
                , with{" "}
                <code className="language-plaintext highlighter-rouge">
                  char *t
                </code>
                . Now, we want to point it to a new chunk of memory that’s large
                enough to store the copy of the string. With{" "}
                <strong>
                  <code className="language-plaintext highlighter-rouge">
                    malloc
                  </code>
                </strong>
                , we <em>allocate</em> some number of bytes in memory (that
                aren’t already used to store other values), and we pass in the
                number of bytes we’d like to mark for use. We already know the
                length of{" "}
                <code className="language-plaintext highlighter-rouge">s</code>,
                and we add 1 to that for the terminating null character. So, our
                final line of code is{" "}
                <code className="language-plaintext highlighter-rouge">
                  char *t = malloc(strlen(s) + 1);
                </code>
                .
              </li>
              <li data-marker="*">
                Then, we copy each character, one at a time, with a{" "}
                <code className="language-plaintext highlighter-rouge">
                  for
                </code>{" "}
                loop. We use{" "}
                <code className="language-plaintext highlighter-rouge">
                  i &lt; n + 1
                </code>
                , since we actually want to go <em>up to</em>{" "}
                <code className="language-plaintext highlighter-rouge">n</code>,
                the length of the string, to ensure we copy the terminating
                character in the string. In the loop, we set{" "}
                <code className="language-plaintext highlighter-rouge">
                  t[i] = s[i]
                </code>
                , copying the characters. While we could use{" "}
                <code className="language-plaintext highlighter-rouge">
                  *(t+i) = *(s+i)
                </code>{" "}
                to the same effect, it’s arguably less readable.
              </li>
              <li data-marker="*">
                Now, we can capitalize just the first letter of{" "}
                <code className="language-plaintext highlighter-rouge">t</code>.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can add some error-checking to our program:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <ctype.h>
#include <stdio.h>
#include <stdlib.h>
#include <string.h>

int main(void)
{
    char *s = get_string("s: ");

    char *t = malloc(strlen(s) + 1);
    if (t == NULL)
    {
        return 1;
    }

    for (int i = 0, n = strlen(s); i < n + 1; i++)
    {
        t[i] = s[i];
    }

    if (strlen(t) > 0)
    {
        t[0] = toupper(t[0]);
    }

    printf("s: %s\\n", s);
    printf("t: %s\\n", t);

    free(t);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                If our computer is out of memory,{" "}
                <code className="language-plaintext highlighter-rouge">
                  malloc
                </code>{" "}
                will return{" "}
                <code className="language-plaintext highlighter-rouge">
                  NULL
                </code>
                , the null pointer, or a special value that indicates there
                isn’t an address to point to. So we should check for that case,
                and exit if{" "}
                <code className="language-plaintext highlighter-rouge">t</code>{" "}
                is{" "}
                <code className="language-plaintext highlighter-rouge">
                  NULL
                </code>
                .
              </li>
              <li data-marker="*">
                We could also check that{" "}
                <code className="language-plaintext highlighter-rouge">t</code>{" "}
                has a length, before trying to capitalize the first character.
              </li>
              <li data-marker="*">
                Finally, we should <strong>free</strong> the memory we allocated
                earlier, which marks it as usable again by some other program.
                We call the{" "}
                <code className="language-plaintext highlighter-rouge">
                  free
                </code>{" "}
                function and pass in the pointer{" "}
                <code className="language-plaintext highlighter-rouge">t</code>,
                since we’re done with that chunk of memory. (
                <code className="language-plaintext highlighter-rouge">
                  get_string
                </code>
                , too, calls{" "}
                <code className="language-plaintext highlighter-rouge">
                  malloc
                </code>{" "}
                to allocate memory for strings, and calls{" "}
                <code className="language-plaintext highlighter-rouge">
                  free
                </code>{" "}
                just before the{" "}
                <code className="language-plaintext highlighter-rouge">
                  main
                </code>{" "}
                function returns.)
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can actually also use the{" "}
            <code className="language-plaintext highlighter-rouge">strcpy</code>{" "}
            function, from the C’s string library, with{" "}
            <code className="language-plaintext highlighter-rouge">
              strcpy(t, s);
            </code>{" "}
            instead of our loop, to copy the string{" "}
            <code className="language-plaintext highlighter-rouge">s</code> into{" "}
            <code className="language-plaintext highlighter-rouge">t</code>.
          </li>
        </ul>

        <h2 id="valgrind" ref={ref7}>
          valgrind
        </h2>

        <ul>
          <li data-marker="*">
            <code className="language-plaintext highlighter-rouge">
              valgrind
            </code>{" "}
            is a command-line tool that we can use to run our program and see if
            it has any <strong>memory leaks</strong>, or memory we’ve allocated
            without freeing, which might eventually cause out computer to run
            out of memory.
          </li>
          <li data-marker="*">
            Let’s build a string but allocate less than what we need in{" "}
            <code className="language-plaintext highlighter-rouge">
              memory.c
            </code>
            :
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>
#include <stdlib.h>

int main(void)
{
    char *s = malloc(3);
    s[0] = 'H';
    s[1] = 'I';
    s[2] = '!';
    s[3] = '\\0';
    printf("%s\\n", s);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We also don’t free the memory we’ve allocated.
              </li>
              <li data-marker="*">
                We’ll run{" "}
                <code className="language-plaintext highlighter-rouge">
                  valgrind ./memory
                </code>{" "}
                after compiling, and we’ll see a lot of output, but we can run{" "}
                <code className="language-plaintext highlighter-rouge">
                  help50 valgrind ./memory
                </code>{" "}
                to help explain some of those messages. For this program, we see
                snippets like “Invalid write of size 1”, “Invalid read of size
                1”, and finally “3 bytes in 1 blocks are definitely lost”, with
                line numbers nearby. Indeed, we’re writing to memory,{" "}
                <code className="language-plaintext highlighter-rouge">
                  s[3]
                </code>
                , which is not part of what we originally allocated for{" "}
                <code className="language-plaintext highlighter-rouge">s</code>.
                And when we print out{" "}
                <code className="language-plaintext highlighter-rouge">s</code>,
                we’re reading all the way to{" "}
                <code className="language-plaintext highlighter-rouge">
                  s[3]
                </code>{" "}
                as well. And finally,{" "}
                <code className="language-plaintext highlighter-rouge">s</code>{" "}
                isn’t freed at the end of our program.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can make sure to allocate the right number of bytes, and free
            memory at the end:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>
#include <stdlib.h>

int main(void)
{
    char *s = malloc(4);
    s[0] = 'H';
    s[1] = 'I';
    s[2] = '!';
    s[3] = '\\0';
    printf("%s\\n", s);
    free(s);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                Now,{" "}
                <code className="language-plaintext highlighter-rouge">
                  valgrind
                </code>{" "}
                doesn’t show any warning messages.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="garbage-values" ref={ref8}>
          Garbage values
        </h2>

        <ul>
          <li data-marker="*">
            Let’s take a look at the following:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`int main(void)
{
    int *x;
    int *y;

    x = malloc(sizeof(int));

    *x = 42;
    *y = 13;

    y = x;

    *y = 13;
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We declare two pointers to integers,{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">y</code>,
                but don’t assign them values. We use{" "}
                <code className="language-plaintext highlighter-rouge">
                  malloc
                </code>{" "}
                to allocate enough memory for an integer with{" "}
                <code className="language-plaintext highlighter-rouge">
                  sizeof(int)
                </code>
                , and store it in{" "}
                <code className="language-plaintext highlighter-rouge">x</code>.{" "}
                <code className="language-plaintext highlighter-rouge">
                  *x = 42
                </code>{" "}
                goes to the address{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                points to, and sets that location in memory to the value 42.
              </li>
              <li data-marker="*">
                With{" "}
                <code className="language-plaintext highlighter-rouge">
                  *y = 13
                </code>
                , we’re trying to put the value 13 at the address{" "}
                <code className="language-plaintext highlighter-rouge">y</code>{" "}
                points to. But since we never assigned{" "}
                <code className="language-plaintext highlighter-rouge">y</code>{" "}
                a value, it has a <strong>garbage value</strong>, or whatever
                unknown value that was in memory, from whatever program was
                running in our computer before. So when we try to go to the
                garbage value in{" "}
                <code className="language-plaintext highlighter-rouge">y</code>{" "}
                as an address, we’re going to some unknown address, which is
                likely to cause a segmentation fault, or segfault.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We watch{" "}
            <a href="https://www.youtube.com/watch?v=3uLKjb973HU">
              Pointer Fun with Binky
            </a>
            , an animated video demonstrating the concepts in the code above.
          </li>
          <li data-marker="*">
            We can print out garbage values, by declaring an array but not
            setting any of its values:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

int main(void)
{
    int scores[3];
    for (int i = 0; i < 3; i++)
    {
        printf("%i\\n", scores[i]);
    }
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                When we compile and run this program, we see various values
                printed.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="swap" ref={ref9}>
          Swap
        </h2>

        <ul>
          <li data-marker="*">
            Let’s try to swap the values of two integers.
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

void swap(int a, int b);

int main(void)
{
    int x = 1;
    int y = 2;

    printf("x is %i, y is %i\\n", x, y);
    swap(x, y);
    printf("x is %i, y is %i\\n", x, y);
}

void swap(int a, int b)
{
    int tmp = a;
    a = b;
    b = tmp;
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                In the real world, if we had a red liquid in one glass, and a
                blue liquid in another, and we wanted to swap them, we would
                need a third glass to temporarily hold one of the liquids,
                perhaps the red glass. Then we can pour the blue liquid into the
                first glass, and finally the red liquid from the temporary glass
                into the second one.
              </li>
              <li data-marker="*">
                In our{" "}
                <code className="language-plaintext highlighter-rouge">
                  swap
                </code>{" "}
                function, we have a third variable to use as temporary storage
                space as well. We put{" "}
                <code className="language-plaintext highlighter-rouge">a</code>{" "}
                into{" "}
                <code className="language-plaintext highlighter-rouge">
                  tmp
                </code>
                , and then set{" "}
                <code className="language-plaintext highlighter-rouge">a</code>{" "}
                to the value of{" "}
                <code className="language-plaintext highlighter-rouge">b</code>,
                and finally{" "}
                <code className="language-plaintext highlighter-rouge">b</code>{" "}
                can be changed to the original value of{" "}
                <code className="language-plaintext highlighter-rouge">a</code>,
                now in{" "}
                <code className="language-plaintext highlighter-rouge">
                  tmp
                </code>
                .
              </li>
            </ul>
          </li>
          <li data-marker="*">
            But, if we tried to use that function in a program, we don’t see any
            changes. It turns out that the{" "}
            <code className="language-plaintext highlighter-rouge">swap</code>{" "}
            function gets its own variables,{" "}
            <code className="language-plaintext highlighter-rouge">a</code> and{" "}
            <code className="language-plaintext highlighter-rouge">b</code> when
            they are passed in, that are copies of{" "}
            <code className="language-plaintext highlighter-rouge">x</code> and{" "}
            <code className="language-plaintext highlighter-rouge">y</code>, and
            so changing those values don’t change{" "}
            <code className="language-plaintext highlighter-rouge">x</code> and{" "}
            <code className="language-plaintext highlighter-rouge">y</code> in
            the{" "}
            <code className="language-plaintext highlighter-rouge">main</code>{" "}
            function.
          </li>
        </ul>

        <h2 id="memory-layout" ref={ref10}>
          Memory layout
        </h2>

        <ul>
          <li data-marker="*">
            Within our computer’s memory, the different types of data that need
            to be stored for our program are organized into different sections:
            <br />
            <img
              src={memory_layout}
              alt="Grid with sections, from top to bottom: machine code, globals, heap (with arrow pointing downward), stack (with arrow pointing upward)"
            />
            <ul>
              <li data-marker="*">
                The <strong>machine code</strong> section is our compiled
                program’s binary code. When we run our program, that code is
                loaded into the “top” of memory.
              </li>
              <li data-marker="*">
                Just below, or in the next part of memory, are{" "}
                <strong>global variables</strong> we declare in our program.
              </li>
              <li data-marker="*">
                The <strong>heap</strong> section is an empty area from where{" "}
                <code className="language-plaintext highlighter-rouge">
                  malloc
                </code>{" "}
                can get free memory for our program to use. As we call{" "}
                <code className="language-plaintext highlighter-rouge">
                  malloc
                </code>
                , we start allocating memory from the top down.
              </li>
              <li data-marker="*">
                The <strong>stack</strong> section is used by functions in our
                program as they are called, and grows upwards. For example, our{" "}
                <code className="language-plaintext highlighter-rouge">
                  main
                </code>{" "}
                function is at the very bottom of the stack and has the local
                variables{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">y</code>.
                The{" "}
                <code className="language-plaintext highlighter-rouge">
                  swap
                </code>{" "}
                function, when it’s called, has its own area of memory that’s on
                top of{" "}
                <code className="language-plaintext highlighter-rouge">
                  main
                </code>
                ’s, with the local variables{" "}
                <code className="language-plaintext highlighter-rouge">a</code>,{" "}
                <code className="language-plaintext highlighter-rouge">b</code>,
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  tmp
                </code>
                :
                <br />
                <img
                  src={stack}
                  alt="Stack section with (a, b, tmp) labeled swap, above (x, y) labeled main"
                />
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Once the function{" "}
            <code className="language-plaintext highlighter-rouge">swap</code>{" "}
            returns, the memory it was using is freed for the next function
            call.{" "}
            <code className="language-plaintext highlighter-rouge">x</code> and{" "}
            <code className="language-plaintext highlighter-rouge">y</code> are
            arguments, so they’re copied as{" "}
            <code className="language-plaintext highlighter-rouge">a</code> and{" "}
            <code className="language-plaintext highlighter-rouge">b</code> for{" "}
            <code className="language-plaintext highlighter-rouge">swap</code>,
            so we don’t see our changes back in{" "}
            <code className="language-plaintext highlighter-rouge">main</code>.
          </li>
          <li data-marker="*">
            By passing in the address of{" "}
            <code className="language-plaintext highlighter-rouge">x</code> and{" "}
            <code className="language-plaintext highlighter-rouge">y</code>, our{" "}
            <code className="language-plaintext highlighter-rouge">swap</code>{" "}
            function can actually work:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

void swap(int *a, int *b);

int main(void)
{
    int x = 1;
    int y = 2;

    printf("x is %i, y is %i\\n", x, y);
    swap(&x, &y);
    printf("x is %i, y is %i\\n", x, y);
}

void swap(int *a, int *b)
{
    int tmp = *a;
    *a = *b;
    *b = tmp;
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                The addresses of{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">y</code>{" "}
                are passed in from{" "}
                <code className="language-plaintext highlighter-rouge">
                  main
                </code>{" "}
                to{" "}
                <code className="language-plaintext highlighter-rouge">
                  swap
                </code>{" "}
                with{" "}
                <code className="language-plaintext highlighter-rouge">
                  &amp;x
                </code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  &amp;y
                </code>
                , and we use the{" "}
                <code className="language-plaintext highlighter-rouge">
                  int *a
                </code>{" "}
                syntax to declare that our{" "}
                <code className="language-plaintext highlighter-rouge">
                  swap
                </code>{" "}
                function takes in pointers. We save the value of{" "}
                <code className="language-plaintext highlighter-rouge">x</code>{" "}
                to{" "}
                <code className="language-plaintext highlighter-rouge">
                  tmp
                </code>{" "}
                by following the pointer{" "}
                <code className="language-plaintext highlighter-rouge">a</code>,
                and then take the value of{" "}
                <code className="language-plaintext highlighter-rouge">y</code>{" "}
                by following the pointer{" "}
                <code className="language-plaintext highlighter-rouge">b</code>,
                and store that to the location{" "}
                <code className="language-plaintext highlighter-rouge">a</code>{" "}
                is pointing to (
                <code className="language-plaintext highlighter-rouge">x</code>
                ). Finally, we store the value of{" "}
                <code className="language-plaintext highlighter-rouge">
                  tmp
                </code>{" "}
                to the location pointed to by{" "}
                <code className="language-plaintext highlighter-rouge">b</code>{" "}
                (<code className="language-plaintext highlighter-rouge">y</code>
                ), and we’re done:
                <br />
                <img
                  src={swap_image}
                  alt="Stack section with (tmp, a, b) labeled swap, above (x, y) labeled main, with a and b pointing to x and y"
                />
              </li>
            </ul>
          </li>
          <li data-marker="*">
            If we call{" "}
            <code className="language-plaintext highlighter-rouge">malloc</code>{" "}
            for too much memory, we will have a <strong>heap overflow</strong>,
            since we end up going past our heap. Or, if we call too many
            functions without returning from them, we will have a{" "}
            <strong>stack overflow</strong>, where our stack has too much memory
            allocated as well.
          </li>
          <li data-marker="*">
            Let’s implement drawing Mario’s pyramid, by calling a function:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <stdio.h>

void draw(int h);

int main(void)
{
    int height = get_int("Height: ");
    draw(height);
}

void draw(int h)
{
    for (int i = 1; i <= h; i++)
    {
        for (int j = 1; j <= i; j++)
        {
            printf("#");
        }
        printf("\\n");
    }
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
          </li>
          <li data-marker="*">
            We can change{" "}
            <code className="language-plaintext highlighter-rouge">draw</code>{" "}
            to be recursive:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`void draw(int h)
{
    draw(h - 1);

    for (int i = 0; i < h; i++)
    {
        printf("#");
    }
    printf("\\n");
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                When we try to compile this with{" "}
                <code className="language-plaintext highlighter-rouge">
                  make
                </code>
                , we see a warning that the{" "}
                <code className="language-plaintext highlighter-rouge">
                  draw
                </code>{" "}
                function will call itself recursively without stopping. So we’ll
                use{" "}
                <code className="language-plaintext highlighter-rouge">
                  clang
                </code>{" "}
                without the extra checks, and when we run this program, we get a
                segmentation fault right away.{" "}
                <code className="language-plaintext highlighter-rouge">
                  draw
                </code>{" "}
                is calling itself over and over, and we ran out of memory on the
                stack.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            By adding a base case, the{" "}
            <code className="language-plaintext highlighter-rouge">draw</code>{" "}
            function will stop calling itself at some point:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`void draw(int h)
{
    if (h == 0)
    {
        return;
    }

    draw(h - 1);

    for (int i = 0; i < h; i++)
    {
        printf("#");
    }
    printf("\\n");
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                But if we enter a large enough value for the height, like{" "}
                <code className="language-plaintext highlighter-rouge">
                  2000000000
                </code>
                , we’ll still run out of memory, since we’re calling{" "}
                <code className="language-plaintext highlighter-rouge">
                  draw
                </code>{" "}
                too many times without returning.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            A <strong>buffer overflow</strong> occurs when we go past the end of
            a buffer, some chunk of memory we’ve allocated like an array, and
            access memory we shouldn’t be.
          </li>
        </ul>

        <h2 id="scanf" ref={ref11}>
          scanf
        </h2>

        <ul>
          <li data-marker="*">
            We can implement{" "}
            <code className="language-plaintext highlighter-rouge">
              get_int
            </code>{" "}
            ourselves with a C library function,{" "}
            <code className="language-plaintext highlighter-rouge">scanf</code>:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

int main(void)
{
    int x;
    printf("x: ");
    scanf("%i", &x);
    printf("x: %i\\n", x);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  scanf
                </code>{" "}
                takes a format,{" "}
                <code className="language-plaintext highlighter-rouge">%i</code>
                , so the input is “scanned” for that format. We also pass in the
                address in memory where we want that input to go. But{" "}
                <code className="language-plaintext highlighter-rouge">
                  scanf
                </code>{" "}
                doesn’t have much error checking, so we might not get an
                integer.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can try to get a string the same way:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdio.h>

int main(void)
{
    char *s;
    printf("s: ");
    scanf("%s", s);
    printf("s: %s\\n", s);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                But we haven’t actually allocated any memory for{" "}
                <code className="language-plaintext highlighter-rouge">s</code>,
                so we need to call{" "}
                <code className="language-plaintext highlighter-rouge">
                  malloc
                </code>{" "}
                to allocate memory for characters for our string. We could also
                use{" "}
                <code className="language-plaintext highlighter-rouge">
                  char s[4];
                </code>{" "}
                to declare an array of four characters. Then,{" "}
                <code className="language-plaintext highlighter-rouge">s</code>{" "}
                will be treated as a pointer to the first character in{" "}
                <code className="language-plaintext highlighter-rouge">
                  scanf
                </code>{" "}
                and{" "}
                <code className="language-plaintext highlighter-rouge">
                  printf
                </code>
                .
              </li>
              <li data-marker="*">
                Now, if the user types in a string of length 3 or less, our
                program will work safely. But if the user types in a longer
                string,{" "}
                <code className="language-plaintext highlighter-rouge">
                  scanf
                </code>{" "}
                might be trying to write past the end of our array into unknown
                memory, causing our program to crash.
              </li>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  get_string
                </code>{" "}
                from the CS50 library continuously allocates more memory as{" "}
                <code className="language-plaintext highlighter-rouge">
                  scanf
                </code>{" "}
                reads in more characters, so it doesn’t have that issue.
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="files" ref={ref12}>
          Files
        </h2>

        <ul>
          <li data-marker="*">
            With the ability to use pointers, we can also open files, like a
            digital phone book:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <cs50.h>
#include <stdio.h>
#include <string.h>

int main(void)
{
    FILE *file = fopen("phonebook.csv", "a");
    if (file == NULL)
    {
        return 1;
    }

    char *name = get_string("Name: ");
    char *number = get_string("Number: ");

    fprintf(file, "%s,%s\\n", name, number);

    fclose(file);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                <code className="language-plaintext highlighter-rouge">
                  fopen
                </code>{" "}
                is a new function we can use to open a file. It will return a
                pointer to a new type,{" "}
                <code className="language-plaintext highlighter-rouge">
                  FILE
                </code>
                , that we can read from and write to. The first argument is the
                name of the file, and the second argument is the mode we want to
                open the file in (
                <code className="language-plaintext highlighter-rouge">r</code>{" "}
                for read,{" "}
                <code className="language-plaintext highlighter-rouge">w</code>{" "}
                for write, and{" "}
                <code className="language-plaintext highlighter-rouge">a</code>{" "}
                for append, or adding to).
              </li>
              <li data-marker="*">
                We’ll add a check to exit if we couldn’t open the file for some
                reason.
              </li>
              <li data-marker="*">
                After we get some strings, we can use{" "}
                <code className="language-plaintext highlighter-rouge">
                  fprintf
                </code>{" "}
                to print to a file.
              </li>
              <li data-marker="*">
                Finally, we close the file with{" "}
                <code className="language-plaintext highlighter-rouge">
                  fclose
                </code>
                .
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Now we can create our own CSV files, a file of comma-separated
            values (like a mini-spreadsheet), programmatically.
          </li>
        </ul>

        <h1 id="graphics" ref={ref13}>
          Graphics
        </h1>

        <ul>
          <li data-marker="*">
            We can read in binary and map them to pixels and colors, to display
            images and videos. With a finite number of bits in an image file,
            though, we can only zoom in so far before we start seeing individual
            pixels.
            <ul>
              <li data-marker="*">
                With artificial intelligence and machine learning, however, we
                can use algorithms that can generate additional details that
                weren’t there before, by guessing based on other data.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            Let’s look at a program that opens a file and tells us if it’s a
            JPEG file, an image file in a particular format:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdint.h>
#include <stdio.h>

typedef uint8_t BYTE;

int main(int argc, char *argv[])
{
    // Check usage
    if (argc != 2)
    {
        return 1;
    }

    // Open file
    FILE *file = fopen(argv[1], "r");
    if (!file)
    {
        return 1;
    }

    // Read first three bytes
    BYTE bytes[3];
    fread(bytes, sizeof(BYTE), 3, file);

    // Check first three bytes
    if (bytes[0] == 0xff && bytes[1] == 0xd8 && bytes[2] == 0xff)
    {
        printf("Maybe\\n");
    }
    else
    {
        printf("No\\n");
    }

    // Close file
    fclose(file);
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                First, we define a{" "}
                <code className="language-plaintext highlighter-rouge">
                  BYTE
                </code>{" "}
                as 8 bits, so we can refer to a byte as a type more easily in C.
              </li>
              <li data-marker="*">
                Then, we try to open a file (checking that we indeed get a
                non-NULL file back), and read the first three bytes from the
                file with{" "}
                <code className="language-plaintext highlighter-rouge">
                  fread
                </code>
                , into a buffer called{" "}
                <code className="language-plaintext highlighter-rouge">
                  bytes
                </code>
                .
              </li>
              <li data-marker="*">
                We can compare the first three bytes (in hexadecimal) to the
                three bytes required to begin a JPEG file. If they’re the same,
                then our file is likely to be a JPEG file (though, other types
                of files may still begin with those bytes). But if they’re not
                the same, we know it’s definitely not a JPEG file.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can even copy files ourselves, one byte at a time now:
            <div className="language-c highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    {`#include <stdint.h>
#include <stdio.h>
#include <stdlib.h>

typedef uint8_t BYTE;

int main(int argc, char *argv[])
{
    // Ensure proper usage
    if (argc != 3)
    {
        fprintf(stderr, "Usage: copy SOURCE DESTINATION\\n");
        return 1;
    }

    // open input file
    FILE *source = fopen(argv[1], "r");
    if (source == NULL)
    {
        printf("Could not open %s.\\n", argv[1]);
        return 1;
    }

    // Open output file
    FILE *destination = fopen(argv[2], "w");
    if (destination == NULL)
    {
        fclose(source);
        printf("Could not create %s.\\n", argv[2]);
        return 1;
    }

    // Copy source to destination, one BYTE at a time
    BYTE buffer;
    while (fread(&buffer, sizeof(BYTE), 1, source))
    {
        fwrite(&buffer, sizeof(BYTE), 1, destination);
    }

    // Close files
    fclose(source);
    fclose(destination);
    return 0;
}`}
                  </code>
                </pre>
              </div>{" "}
            </div>
            <ul>
              <li data-marker="*">
                We use{" "}
                <code className="language-plaintext highlighter-rouge">
                  argv
                </code>{" "}
                to get arguments, using them as filenames to open files to read
                from and one to write to.
              </li>
              <li data-marker="*">
                Then, we read one byte from the{" "}
                <code className="language-plaintext highlighter-rouge">
                  source
                </code>{" "}
                file into a buffer, and write that byte to the{" "}
                <code className="language-plaintext highlighter-rouge">
                  destination
                </code>{" "}
                file. We can use a{" "}
                <code className="language-plaintext highlighter-rouge">
                  while
                </code>{" "}
                loop to call{" "}
                <code className="language-plaintext highlighter-rouge">
                  fread
                </code>
                , which will stop once there are no more bytes to read.
              </li>
            </ul>
          </li>
          <li data-marker="*">
            We can use these abilities to read and write files, recovering
            images from a file, and adding filters to images by changing the
            bytes in them, in this week’s problem set!
          </li>
        </ul>
      </main>
    </React.Fragment>
  );
}

export default Note4;
