import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const alert = {
  padding: "10px",
  color: "#856404",
  backgroundColor: "#fff3cd",
  borderColor: "#ffeeba",
};

function Hello() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 id="hello">Hello</h1>

        <div style={alert} data-alert="warning" role="alert">
          <p>
            If you already started to work on Problem Set 1 in CS50 Lab, you may{" "}
            <a href="https://lab.cs50.io/cs50/labs/2020/x/hello/">
              continue working on it
            </a>{" "}
            there. If you’re just now starting to work in this problem, be sure
            to use CS50 IDE instead by following the instructions below!
          </p>
        </div>

        <h2 id="getting-started">Getting Started</h2>

        <p>
          CS50 IDE is a web-based “integrated development environment” that
          allows you to program “in the cloud,” without installing any software
          locally. Indeed, CS50 IDE provides you with your very own “workspace”
          (i.e., storage space) in which you can save your own files and folders
          (aka directories).
        </p>

        <h3 id="logging-in">Logging In</h3>

        <p>
          Head to <a href="https://ide.cs50.io">ide.cs50.io</a> and click “Sign
          in with GitHub” to access your CS50 IDE. Once your IDE loads, you
          should see that (by default) it’s divided into three parts. Toward the
          top of CS50 IDE is your “text editor”, where you’ll write all of your
          programs. Toward the bottom of is a “terminal window” (light blue, by
          default), a command-line interface (CLI) that allows you to explore
          your workspace’s files and directories, compile code, run programs,
          and even install new software. And on the left is your “file browser”,
          which shows you all of the files and folders currently in your IDE.
        </p>

        <p>
          Start by clicking inside your terminal window. You should find that
          its “prompt” resembles the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>~/ $</code>
            </pre>
          </div>
        </div>

        <p>Click inside of that terminal window and then type</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>mkdir ~/pset1/</code>
            </pre>
          </div>
        </div>

        <p>
          followed by Enter in order to make a directory (i.e., folder) called{" "}
          <code className="highlighter-rouge">pset1</code> in your home
          directory. Take care not to overlook the space between{" "}
          <code className="highlighter-rouge">mkdir</code> and{" "}
          <code className="highlighter-rouge">~/pset1</code> or any other
          character for that matter! Keep in mind that{" "}
          <code className="highlighter-rouge">~</code> denotes your home
          directory and <code className="highlighter-rouge">~/pset1</code>{" "}
          denotes a directory called{" "}
          <code className="highlighter-rouge">pset1</code> within{" "}
          <code className="highlighter-rouge">~</code>.
        </p>

        <p>
          Here on out, to execute (i.e., run) a command means to type it into a
          terminal window and then hit Enter. Commands are “case-sensitive,” so
          be sure not to type in uppercase when you mean lowercase or vice
          versa.
        </p>

        <p>Now execute</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>cd ~/pset1/</code>
            </pre>
          </div>
        </div>

        <p>
          to move yourself into (i.e., open) that directory. Your prompt should
          now resemble the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>~/pset1/ $</code>
            </pre>
          </div>
        </div>

        <p>
          If not, retrace your steps and see if you can determine where you went
          wrong.
        </p>

        <p>Now execute</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>mkdir ~/pset1/hello</code>
            </pre>
          </div>
        </div>

        <p>
          to create a new directory called{" "}
          <code className="highlighter-rouge">hello</code> inside of your{" "}
          <code className="highlighter-rouge">pset1</code> directory. Then
          execute
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>cd ~/pset1/hello</code>
            </pre>
          </div>
        </div>

        <p>to move yourself into that directory.</p>

        <p>
          Shall we have you write your first program? From the <em>File</em>{" "}
          menu, click <em>New File</em>, and save it (as via the <em>Save</em>{" "}
          option in the <em>File</em> menu) as{" "}
          <code className="highlighter-rouge">hello.c</code> inside of your{" "}
          <code className="highlighter-rouge">~/pset1/hello</code> directory.
          Proceed to write your first program by typing precisely these lines
          into the file:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                #include &lt;stdio.h&gt; int main(void)
                {`printf("hello, world\n");`}
              </code>
            </pre>
          </div>
        </div>

        <p>
          Notice how CS50 IDE adds “syntax highlighting” (i.e., color) as you
          type, though CS50 IDE’s choice of colors might differ from this
          problem set’s. Those colors aren’t actually saved inside of the file
          itself; they’re just added by CS50 IDE to make certain syntax stand
          out. Had you not saved the file as{" "}
          <code className="highlighter-rouge">hello.c</code> from the start,
          CS50 IDE wouldn’t know (per the filename’s extension) that you’re
          writing C code, in which case those colors would be absent.
        </p>

        <h2 id="listing-files">Listing Files</h2>

        <p>
          Next, in your terminal window, immediately to the right of the prompt
          (<code className="highlighter-rouge">~/pset1/hello/ $</code>), execute
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>ls</code>
            </pre>
          </div>
        </div>

        <p>
          You should see just <code className="highlighter-rouge">hello.c</code>
          ? That’s because you’ve just listed the files in your{" "}
          <code className="highlighter-rouge">hello</code> folder. In
          particular, you <em>executed</em> (i.e., ran) a command called{" "}
          <code className="highlighter-rouge">ls</code>, which is shorthand for
          “list.” (It’s such a frequently used command that its authors called
          it just <code className="highlighter-rouge">ls</code> to save
          keystrokes.) Make sense?
        </p>

        <h2 id="compiling-programs">Compiling Programs</h2>

        <p>
          Now, before we can execute the{" "}
          <code className="highlighter-rouge">hello.c</code> program, recall
          that we must <em>compile</em> it with a <em>compiler</em> (e.g.,{" "}
          <code className="highlighter-rouge">clang</code>), translating it from{" "}
          <em>source code</em> into <em>machine code</em> (i.e., zeroes and
          ones). Execute the command below to do just that:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>clang hello.c</code>
            </pre>
          </div>
        </div>

        <p>And then execute this one again:</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>ls</code>
            </pre>
          </div>
        </div>

        <p>
          This time, you should see not only{" "}
          <code className="highlighter-rouge">hello.c</code> but{" "}
          <code className="highlighter-rouge">a.out</code> listed as well? (You
          can see the same graphically if you click that folder icon again.)
          That’s because <code className="highlighter-rouge">clang</code> has
          translated the source code in{" "}
          <code className="highlighter-rouge">hello.c</code> into machine code
          in <code className="highlighter-rouge">a.out</code>, which happens to
          stand for “assembler output,” but more on that another time.
        </p>

        <p>Now run the program by executing the below.</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>./a.out</code>
            </pre>
          </div>
        </div>

        <p>Hello, world, indeed!</p>

        <h2 id="naming-programs">Naming Programs</h2>

        <p>
          Now, <code className="highlighter-rouge">a.out</code> isn’t the most
          user-friendly name for a program. Let’s compile{" "}
          <code className="highlighter-rouge">hello.c</code> again, this time
          saving the machine code in a file called, more aptly,{" "}
          <code className="highlighter-rouge">hello</code>. Execute the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>clang -o hello hello.c</code>
            </pre>
          </div>
        </div>

        <p>
          Take care not to overlook any of those spaces therein! Then execute
          this one again:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>ls</code>
            </pre>
          </div>
        </div>

        <p>
          You should now see not only{" "}
          <code className="highlighter-rouge">hello.c</code> (and{" "}
          <code className="highlighter-rouge">a.out</code> from before) but also{" "}
          <code className="highlighter-rouge">hello</code> listed as well?
          That’s because <code className="highlighter-rouge">-o</code> is a{" "}
          <em>command-line argument</em>, sometimes known as a <em>flag</em> or
          a <em>switch</em>, that tells{" "}
          <code className="highlighter-rouge">clang</code> to output (hence the{" "}
          <code className="highlighter-rouge">o</code>) a file called{" "}
          <code className="highlighter-rouge">hello</code>. Execute the below to
          try out the newly named program.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>./hello</code>
            </pre>
          </div>
        </div>

        <p>Hello there again!</p>

        <h2 id="making-things-easier">Making Things Easier</h2>

        <p>
          Recall that we can automate the process of executing{" "}
          <code className="highlighter-rouge">clang</code>, letting{" "}
          <code className="highlighter-rouge">make</code> figure out how to do
          so for us, thereby saving us some keystrokes. Execute the below to
          compile this program one last time.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>make hello</code>
            </pre>
          </div>
        </div>

        <p>
          You should see that <code className="highlighter-rouge">make</code>{" "}
          executes <code className="highlighter-rouge">clang</code> with even
          more command-line arguments for you? More on those, too, another time!
        </p>

        <p>
          Now execute the program itself one last time by executing the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>./hello</code>
            </pre>
          </div>
        </div>

        <p>Phew!</p>

        <h2 id="getting-user-input">Getting User Input</h2>

        <p>
          Suffice it to say, no matter how you compile or execute this program,
          it only ever prints{" "}
          <code className="highlighter-rouge">hello, world</code>. Let’s
          personalize it a bit, just as we did in class.
        </p>

        <p>
          Modify this program in such a way that it first prompts the user for
          their name and then prints{" "}
          <code className="highlighter-rouge">hello, so-and-so</code>, where{" "}
          <code className="highlighter-rouge">so-and-so</code> is their actual
          name.
        </p>

        <p>As before, be sure to compile your program with:</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>make hello</code>
            </pre>
          </div>
        </div>

        <p>
          And be sure to execute your program, testing it a few times with
          different inputs, with:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>./hello</code>
            </pre>
          </div>
        </div>

        <h3 id="walkthrough">Walkthrough</h3>

        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
        >
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={t("pset1_hello")}
          ></iframe>
        </div>

        <h3 id="hints">Hints</h3>

        <h4 id="dont-recall-how-to-prompt-the-user-for-their-name">
          Don’t recall how to prompt the user for their name?
        </h4>

        <p>
          Recall that you can use{" "}
          <code className="highlighter-rouge">get_string</code> as follows,
          storing its <em>return value</em> in a variable called{" "}
          <code className="highlighter-rouge">name</code> of type{" "}
          <code className="highlighter-rouge">string</code>.
        </p>

        <div className="language-c highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <span className="n">string</span>{" "}
                <span className="n">name</span> <span className="o">=</span>{" "}
                <span className="n">get_string</span>
                <span className="p">(</span>
                <span className="s">"What is your name?</span>
                <span className="se">\n</span>
                <span className="s">"</span>
                <span className="p">);</span>
              </code>
            </pre>
          </div>
        </div>

        <h4 id="dont-recall-how-to-format-a-string">
          Don’t recall how to format a string?
        </h4>

        <p>
          Don’t recall how to join (i.e., concatenate) the user’s name with a
          greeting? Recall that you can use{" "}
          <code className="highlighter-rouge">printf</code> not only to print
          but to format a string (hence, the{" "}
          <code className="highlighter-rouge">f</code> in{" "}
          <code className="highlighter-rouge">printf</code>), a la the below,
          wherein <code className="highlighter-rouge">name</code> is a{" "}
          <code className="highlighter-rouge">string</code>.
        </p>

        <div className="language-c highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <span className="n">printf</span>
                <span className="p">(</span>
                <span className="s">"hello, %s</span>
                <span className="se">\n</span>
                <span className="s">"</span>
                <span className="p">,</span> <span className="n">name</span>
                <span className="p">);</span>
              </code>
            </pre>
          </div>
        </div>

        <h4 id="use-of-undeclared-identifier">Use of undeclared identifier?</h4>

        <p>Seeing the below, perhaps atop other errors?</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                error: use of undeclared identifier 'string'; did you mean
                'stdin'?
              </code>
            </pre>
          </div>
        </div>

        <p>
          Recall that, to use{" "}
          <code className="highlighter-rouge">get_string</code>, you need to
          include <code className="highlighter-rouge">cs50.h</code> (in which{" "}
          <code className="highlighter-rouge">get_string</code> is{" "}
          <em>declared</em>) atop a file, as with:
        </p>

        <div className="language-c highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <span className="cp">#include &lt;cs50.h&gt;</span>
              </code>
            </pre>
          </div>
        </div>

        <h3 id="how-to-test-your-code">How to Test Your Code</h3>

        <p>
          Execute the below to evaluate the correctness of your code using{" "}
          <code className="highlighter-rouge">check50</code>. But be sure to
          compile and test it yourself as well!
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>check50 cs50/problems/2020/x/hello</code>
            </pre>
          </div>
        </div>

        <p>
          Execute the below to evaluate the style of your code using{" "}
          <code className="highlighter-rouge">style50</code>.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>style50 hello.c</code>
            </pre>
          </div>
        </div>

        <h2 id="how-to-submit">How to Submit</h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2020/x/hello</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Hello;
