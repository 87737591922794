import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import pyramid from "./img/pyramid.png";
import { useTranslation } from "react-i18next";

const alert = {
  padding: "10px",
  color: "#856404",
  backgroundColor: "#fff3cd",
  borderColor: "#ffeeba",
};

function MarioLess() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 id="mario">Mario</h1>

        <div style={alert} data-alert="warning" role="alert">
          <p>
            If you already started to work on Problem Set 1 in CS50 Lab, you may{" "}
            <a href="https://lab.cs50.io/cs50/labs/2020/x/mario/less/">
              continue working on it
            </a>{" "}
            there. If you’re just now starting to work in this problem, be sure
            to use CS50 IDE instead by following the instructions below!
          </p>
        </div>

        <h2 id="world-1-1">World 1-1</h2>

        <p>
          Toward the end of World 1-1 in Nintendo’s Super Mario Brothers, Mario
          must ascend right-aligned pyramid of blocks, a la the below.
        </p>

        <p>
          <img
            src={pyramid}
            alt="screenshot of Mario jumping up a right-aligned pyramid"
          />
        </p>

        <p>
          Let’s recreate that pyramid in C, albeit in text, using hashes (
          <code className="highlighter-rouge">#</code>) for bricks, a la the
          below. Each hash is a bit taller than it is wide, so the pyramid
          itself is also be taller than it is wide.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                # <br />
                ## <br />
                ###
                <br />
                ####
                <br />
                #####
                <br />
                ######
                <br />
                #######
                <br />
                ########
                <br />
              </code>
            </pre>
          </div>
        </div>

        <p>
          The program we’ll write will be called{" "}
          <code className="highlighter-rouge">mario</code>. And let’s allow the
          user to decide just how tall the pyramid should be by first prompting
          them for a positive integer between, say, 1 and 8, inclusive.
        </p>

        <p>
          Here’s how the program might work if the user inputs{" "}
          <code className="highlighter-rouge">8</code> when prompted:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario Height: 8<br />
                #<br />
                ##
                <br />
                ###
                <br />
                ####
                <br />
                #####
                <br />
                ######
                <br />
                #######
                <br />
                ########
              </code>
            </pre>
          </div>
        </div>

        <p>
          Here’s how the program might work if the user inputs{" "}
          <code className="highlighter-rouge">4</code> when prompted:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario Height: 4 #<br />
                ##
                <br />
                ###
                <br />
                ####
                <br />
              </code>
            </pre>
          </div>
        </div>

        <p>
          Here’s how the program might work if the user inputs{" "}
          <code className="highlighter-rouge">2</code> when prompted:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario Height: 2 #<br />
                ##
                <br />
              </code>
            </pre>
          </div>
        </div>

        <p>
          And here’s how the program might work if the user inputs{" "}
          <code className="highlighter-rouge">1</code> when prompted:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario Height: 1 #<br />
              </code>
            </pre>
          </div>
        </div>

        <p>
          If the user doesn’t, in fact, input a positive integer between 1 and
          8, inclusive, when prompted, the program should re-prompt the user
          until they cooperate:
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario Height: -1 Height: 0 Height: 42 Height: 50 Height: 4 #
                ## ### ####
              </code>
            </pre>
          </div>
        </div>

        <p>How to begin? Let’s approach this problem one step at a time.</p>

        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
        >
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={t("pset1_mario_less")}
          ></iframe>
        </div>

        <h2 id="pseudocode">Pseudocode</h2>

        <p>
          First, create a new directory (i.e., folder) called{" "}
          <code className="highlighter-rouge">mario</code> inside of your{" "}
          <code className="highlighter-rouge">pset1</code> directory by
          executing
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>~/ $ mkdir ~/pset1/mario</code>
            </pre>
          </div>
        </div>

        <p>
          Add a new file called{" "}
          <code className="highlighter-rouge">pseudocode.txt</code> inside of
          your <code className="highlighter-rouge">mario</code> directory.
        </p>

        <p>
          Write in <code className="highlighter-rouge">pseudocode.txt</code>{" "}
          some pseudocode that implements this program, even if not (yet!) sure
          how to write it in code. There’s no one right way to write pseudocode,
          but short English sentences suffice. Recall how we wrote pseudocode
          for{" "}
          <a href="https://docs.google.com/presentation/d/17wRd8ksO6QkUq906SUgm17AqcI-Jan42jkY-EmufxnE/edit?usp=sharing">
            finding Mike Smith
          </a>
          . Odds are your pseudocode will use (or imply using!) one or more
          functions, conditions, Boolean expressions, loops, and/or variables.
        </p>

        <details>
          <summary>Spoiler</summary>
          <p>There’s more than one way to do this, so here’s just one!</p>

          <ol>
            <li>Prompt user for height</li>
            <li>
              If height is less than 1 or greater than 8 (or not an integer at
              all), go back one step
            </li>
            <li>
              Iterate from 1 through height:
              <ol>
                <li>
                  On iteration <em>i</em>, print <em>i</em> hashes and then a
                  newline
                </li>
              </ol>
            </li>
          </ol>

          <p>
            It’s okay to edit your own after seeing this pseudocode here, but
            don’t simply copy/paste ours into your own!
          </p>
        </details>

        <h2 id="prompting-for-input">Prompting for Input</h2>

        <p>
          Whatever your pseudocode, let’s first write only the C code that
          prompts (and re-prompts, as needed) the user for input. Create a new
          file called <code className="highlighter-rouge">mario.c</code> inside
          of your <code className="highlighter-rouge">mario</code> directory.
        </p>

        <p>
          Now, modify <code className="highlighter-rouge">mario.c</code> in such
          a way that it prompts the user for the pyramid’s height, storing their
          input in a variable, re-prompting the user again and again as needed
          if their input is not a positive integer between 1 and 8, inclusive.
          Then, simply print the value of that variable, thereby confirming (for
          yourself) that you’ve indeed stored the user’s input successfully, a
          la the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./mario Height: -1 Height: 0 Height: 42 Height: 50 Height: 4
                Stored: 4
              </code>
            </pre>
          </div>
        </div>

        <details>
          <summary>Hints</summary>
          <ul>
            <li>
              Recall that you can compile your program with{" "}
              <code className="highlighter-rouge">make</code>.
            </li>
            <li>
              Recall that you can print an{" "}
              <code className="highlighter-rouge">int</code> with{" "}
              <code className="highlighter-rouge">printf</code> using{" "}
              <code className="highlighter-rouge">%i</code>.
            </li>
            <li>
              Recall that you can get an integer from the user with{" "}
              <code className="highlighter-rouge">get_int</code>.
            </li>
            <li>
              Recall that <code className="highlighter-rouge">get_int</code> is
              declared in <code className="highlighter-rouge">cs50.h</code>.
            </li>
            <li>
              Recall that we prompted the user for a positive integer in class
              via <code className="highlighter-rouge">positive.c</code>.
            </li>
          </ul>
        </details>

        <h2 id="building-the-opposite">Building the Opposite</h2>

        <p>
          Now that your program is (hopefully!) accepting input as prescribed,
          it’s time for another step.
        </p>

        <p>
          It turns out it’s a bit easier to build a left-aligned pyramid than
          right-, a la the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code># ## ### #### ##### ###### ####### ########</code>
            </pre>
          </div>
        </div>

        <p>
          So let’s build a left-aligned pyramid first and then, once that’s
          working, right-align it instead!
        </p>

        <p>
          Modify <code className="highlighter-rouge">mario.c</code> at right
          such that it no longer simply prints the user’s input but instead
          prints a left-aligned pyramid of that height.
        </p>

        <details>
          <summary>Hints</summary>
          <ul>
            <li>
              Keep in mind that a hash is just a character like any other, so
              you can print it with{" "}
              <code className="highlighter-rouge">printf</code>.
            </li>
            <li>
              Just as Scratch has a{" "}
              <a href="https://docs.google.com/presentation/d/17wRd8ksO6QkUq906SUgm17AqcI-Jan42jkY-EmufxnE/edit?usp=sharing">
                Repeat
              </a>{" "}
              block, so does C have a{" "}
              <a href="https://docs.google.com/presentation/d/191XW0DHWlW6WmAhYuFUYnZKUlDx0N4u4Fp81AeW-uNs/edit?usp=sharing">
                <code className="highlighter-rouge">for</code>
              </a>{" "}
              loop, via which you can iterate some number times. Perhaps on each
              iteration, <em>i</em>, you could print that many hashes?
            </li>
            <li>
              <p>
                You can actually “nest” loops, iterating with one variable
                (e.g., <code className="highlighter-rouge">i</code>) in the
                “outer” loop and another (e.g.,{" "}
                <code className="highlighter-rouge">j</code>) in the “inner”
                loop. For instance, here’s how you might print a square of
                height and width <code className="highlighter-rouge">n</code>,
                below. Of course, it’s not a square that you want to print!
              </p>

              <div className="highlighter-rouge">
                <div className="highlight">
                  <pre className="highlight">
                    <code>
                      {" "}
                      for (int i = 0; i &lt; n; i++)
                      {`{`}
                      for (int j = 0; j &lt; n; j++)
                      {`{`}
                      printf("#");
                      {`}`}
                      printf("\n");
                      {`}`}
                    </code>
                  </pre>
                </div>{" "}
              </div>
            </li>
          </ul>
        </details>

        <h2 id="right-aligning-with-dots">Right-Aligning with Dots</h2>

        <p>
          Let’s now right-align that pyramid by pushing its hashes to the right
          by prefixing them with dots (i.e., periods), a la the below.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                .......#
                <br />
                ......## <br />
                .....###
                <br />
                ....####
                <br />
                ...#####
                <br />
                ..######
                <br />
                .#######
                <br />
                ########
              </code>
            </pre>
          </div>
        </div>

        <p>
          Modify <code className="highlighter-rouge">mario.c</code> in such a
          way that it does exactly that!
        </p>

        <details>
          <summary>Hint</summary>
          <p>
            Notice how the number of dots needed on each line is the “opposite”
            of the number of that line’s hashes. For a pyramid of height 8, like
            the above, the first line has but 1 hash and thus 7 dots. The bottom
            line, meanwhile, has 8 hashes and thus 0 dots. Via what formula (or
            arithmetic, really) could you print that many dots?
          </p>
        </details>

        <h3 id="how-to-test-your-code">How to Test Your Code</h3>

        <p>Does your code work as prescribed when you input</p>

        <ul>
          <li>
            <code className="highlighter-rouge">-1</code> (or other negative
            numbers)?
          </li>
          <li>
            <code className="highlighter-rouge">0</code>?
          </li>
          <li>
            <code className="highlighter-rouge">1</code> through{" "}
            <code className="highlighter-rouge">8</code>?
          </li>
          <li>
            <code className="highlighter-rouge">9</code> or other positive
            numbers?
          </li>
          <li>letters or words?</li>
          <li>no input at all, when you only hit Enter?</li>
        </ul>

        <h2 id="removing-the-dots">Removing the Dots</h2>

        <p>
          All that remains now is a finishing flourish! Modify{" "}
          <code className="highlighter-rouge">mario.c</code> in such a way that
          it prints spaces instead of those dots!
        </p>

        <h3 id="how-to-test-your-code-1">How to Test Your Code</h3>

        <p>
          Execute the below to evaluate the correctness of your code using{" "}
          <code className="highlighter-rouge">check50</code>. But be sure to
          compile and test it yourself as well!
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>check50 cs50/problems/2021/x/mario/less</code>
            </pre>
          </div>
        </div>

        <p>
          Execute the below to evaluate the style of your code using{" "}
          <code className="highlighter-rouge">style50</code>.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>style50 mario.c</code>
            </pre>
          </div>
        </div>

        <details>
          <summary>Hint</summary>
          <p>
            A space is just a press of your space bar, just as a period is just
            a press of its key! Just remember that{" "}
            <code className="highlighter-rouge">printf</code> requires that you
            surround both with double quotes!
          </p>
        </details>

        <h2 id="how-to-submit">How to Submit</h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2021/x/mario/less</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default MarioLess;
