import React from "react";
import { useHistory } from "react-router";
import coins from "./img/coins.jpg";
import { useTranslation } from "react-i18next";

const alert = {
  padding: "10px",
  color: "#856404",
  backgroundColor: "#fff3cd",
  borderColor: "#ffeeba",
};

function Cash() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 id="cash">Cash</h1>

        <h2 id="greedy-algorithms">Greedy Algorithms</h2>

        {/* <!-- http://mypieceofthe31415927.blogspot.com/2014/04/whats-wrong-with-these-us-coins.html --> */}
        <p>
          <img src={coins} alt="US coins" />
        </p>

        <p>
          When making change, odds are you want to minimize the number of coins
          you’re dispensing for each customer, lest you run out (or annoy the
          customer!). Fortunately, computer science has given cashiers
          everywhere ways to minimize numbers of coins due: greedy algorithms.
        </p>

        <p>
          According to the National Institute of Standards and Technology
          (NIST), a greedy algorithm is one “that always takes the best
          immediate, or local, solution while finding an answer. Greedy
          algorithms find the overall, or globally, optimal solution for some
          optimization problems, but may find less-than-optimal solutions for
          some instances of other problems.”
        </p>

        <p>
          What’s all that mean? Well, suppose that a cashier owes a customer
          some change and in that cashier’s drawer are quarters (25¢), dimes
          (10¢), nickels (5¢), and pennies (1¢). The problem to be solved is to
          decide which coins and how many of each to hand to the customer. Think
          of a “greedy” cashier as one who wants to take the biggest bite out of
          this problem as possible with each coin they take out of the drawer.
          For instance, if some customer is owed 41¢, the biggest first (i.e.,
          best immediate, or local) bite that can be taken is 25¢. (That bite is
          “best” inasmuch as it gets us closer to 0¢ faster than any other coin
          would.) Note that a bite of this size would whittle what was a 41¢
          problem down to a 16¢ problem, since 41 - 25 = 16. That is, the
          remainder is a similar but smaller problem. Needless to say, another
          25¢ bite would be too big (assuming the cashier prefers not to lose
          money), and so our greedy cashier would move on to a bite of size 10¢,
          leaving him or her with a 6¢ problem. At that point, greed calls for
          one 5¢ bite followed by one 1¢ bite, at which point the problem is
          solved. The customer receives one quarter, one dime, one nickel, and
          one penny: four coins in total.
        </p>

        <p>
          It turns out that this greedy approach (i.e., algorithm) is not only
          locally optimal but also globally so for America’s currency (and also
          the European Union’s). That is, so long as a cashier has enough of
          each coin, this largest-to-smallest approach will yield the fewest
          coins possible. How few? Well, you tell us!
        </p>

        <h2 id="implementation-details">Implementation Details</h2>

        <p>
          Implement, in a file called{" "}
          <code className="highlighter-rouge">cash.c</code> in a{" "}
          <code className="highlighter-rouge">~/pset1/cash</code> directory, a
          program that first asks the user how much change is owed and then
          prints the minimum number of coins with which that change can be made.
        </p>

        <ul>
          <li>
            Use <code className="highlighter-rouge">get_float</code> to get the
            user’s input and <code className="highlighter-rouge">printf</code>{" "}
            to output your answer. Assume that the only coins available are
            quarters (25¢), dimes (10¢), nickels (5¢), and pennies (1¢).
            <ul>
              <li>
                We ask that you use{" "}
                <code className="highlighter-rouge">get_float</code> so that you
                can handle dollars and cents, albeit sans dollar sign. In other
                words, if some customer is owed $9.75 (as in the case where a
                newspaper costs 25¢ but the customer pays with a $10 bill),
                assume that your program’s input will be{" "}
                <code className="highlighter-rouge">9.75</code> and not{" "}
                <code className="highlighter-rouge">$9.75</code> or{" "}
                <code className="highlighter-rouge">975</code>. However, if some
                customer is owed $9 exactly, assume that your program’s input
                will be <code className="highlighter-rouge">9.00</code> or just{" "}
                <code className="highlighter-rouge">9</code> but, again, not{" "}
                <code className="highlighter-rouge">$9</code> or{" "}
                <code className="highlighter-rouge">900</code>. Of course, by
                nature of floating-point values, your program will likely work
                with inputs like <code className="highlighter-rouge">9.0</code>{" "}
                and <code className="highlighter-rouge">9.000</code> as well;
                you need not worry about checking whether the user’s input is
                “formatted” like money should be.
              </li>
            </ul>
          </li>
          <li>
            You need not try to check whether a user’s input is too large to fit
            in a <code className="highlighter-rouge">float</code>. Using{" "}
            <code className="highlighter-rouge">get_float</code> alone will
            ensure that the user’s input is indeed a floating-point (or
            integral) value but not that it is non-negative.
          </li>
          <li>
            If the user fails to provide a non-negative value, your program
            should re-prompt the user for a valid amount again and again until
            the user complies.
          </li>
          <li>
            So that we can automate some tests of your code, be sure that your
            program’s last line of output is only the minimum number of coins
            possible: an integer followed by{" "}
            <code className="highlighter-rouge">\n</code>.
          </li>
          <li>
            Beware the inherent imprecision of floating-point values. Recall{" "}
            <code className="highlighter-rouge">floats.c</code> from class,
            wherein, if <code className="highlighter-rouge">x</code> is{" "}
            <code className="highlighter-rouge">2</code>, and{" "}
            <code className="highlighter-rouge">y</code> is{" "}
            <code className="highlighter-rouge">10</code>,{" "}
            <code className="highlighter-rouge">x / y</code> is not precisely
            two tenths! And so, before making change, you’ll probably want to
            convert the user’s inputted dollars to cents (i.e., from a{" "}
            <code className="highlighter-rouge">float</code> to an{" "}
            <code className="highlighter-rouge">int</code>) to avoid tiny errors
            that might otherwise add up!
          </li>
          <li>
            <p>
              Take care to round your cents to the nearest penny, as with{" "}
              <code className="highlighter-rouge">round</code>, which is
              declared in <code className="highlighter-rouge">math.h</code>. For
              instance, if <code className="highlighter-rouge">dollars</code> is
              a <code className="highlighter-rouge">float</code> with the user’s
              input (e.g., <code className="highlighter-rouge">0.20</code>),
              then code like
            </p>

            <div className="highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>int cents = round(dollars * 100);</code>
                </pre>
              </div>{" "}
            </div>

            <p>
              will safely convert{" "}
              <code className="highlighter-rouge">0.20</code> (or even{" "}
              <code className="highlighter-rouge">
                0.200000002980232238769531250
              </code>
              ) to <code className="highlighter-rouge">20</code>.
            </p>
          </li>
        </ul>

        <p>Your program should behave per the examples below.</p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>$ ./cash Change owed: 0.41 4</code>
            </pre>
          </div>
        </div>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                $ ./cash Change owed: -0.41 Change owed: foo Change owed: 0.41 4
              </code>
            </pre>
          </div>
        </div>

        <h3 id="walkthrough">Walkthrough</h3>

        <div
          className="border embed-responsive embed-responsive-16by9"
          data-video=""
        >
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="embed-responsive-item"
            src={t("pset1_cash")}
          ></iframe>
        </div>

        <h3 id="how-to-test-your-code">How to Test Your Code</h3>

        <p>Does your code work as prescribed when you input</p>

        <ul>
          <li>
            <code className="highlighter-rouge">-1.00</code> (or other negative
            numbers)?
          </li>
          <li>
            <code className="highlighter-rouge">0.00</code>?
          </li>
          <li>
            <code className="highlighter-rouge">0.01</code> (or other positive
            numbers)?
          </li>
          <li>letters or words?</li>
          <li>no input at all, when you only hit Enter?</li>
        </ul>

        <p>
          You can also execute the below to evaluate the correctness of your
          code using <code className="highlighter-rouge">check50</code>. But be
          sure to compile and test it yourself as well!
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>check50 cs50/problems/2021/x/cash</code>
            </pre>
          </div>
        </div>

        <p>
          Execute the below to evaluate the style of your code using{" "}
          <code className="highlighter-rouge">style50</code>.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>style50 cash.c</code>
            </pre>
          </div>
        </div>

        <h2 id="how-to-submit">How to Submit</h2>

        <p>
          Execute the below, logging in with your GitHub username and password
          when prompted. For security, you’ll see asterisks (
          <code className="highlighter-rouge">*</code>) instead of the actual
          characters in your password.
        </p>

        <div className="highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>submit50 cs50/problems/2021/x/cash</code>
            </pre>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Cash;
