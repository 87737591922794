import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import finance from "./img/finance.png";

function Finance() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md">
        <h1 id="c50-finance">C$50 Finance</h1>
        <iframe
          width="70%"
          height="720"
          src="https://www.youtube.com/embed/kAvCTffbH04"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <p>
          Implement a website via which users can “buy” and “sell” stocks, a la
          the below.
        </p>

        <p>
          <img src={finance} width="50%" alt="C$50 Finance" />
        </p>

        <h2 id="background">Background</h2>

        <p>
          If you’re not quite sure what it means to buy and sell stocks (i.e.,
          shares of a company), head{" "}
          <a href="https://www.investopedia.com/articles/basics/06/invest1000.asp">
            here
          </a>{" "}
          for a tutorial.
        </p>

        <p>
          You’re about to implement C$50 Finance, a web app via which you can
          manage portfolios of stocks. Not only will this tool allow you to
          check real stocks’ actual prices and portfolios’ values, it will also
          let you buy (okay, “buy”) and sell (okay, “sell”) stocks by querying{" "}
          <a href="https://iextrading.com/developer/">IEX</a> for stocks’
          prices.
        </p>

        <p>
          Indeed, IEX lets you download stock quotes via their API (application
          programming interface) using URLs like{" "}
          <code className="language-plaintext highlighter-rouge">
            https://cloud-sse.iexapis.com/stable/stock/nflx/quote?token=API_KEY
          </code>
          . Notice how Netflix’s symbol (NFLX) is embedded in this URL; that’s
          how IEX knows whose data to return. That link won’t actually return
          any data because IEX requires you to use an API key (more about that
          in a bit), but if it did, you’d see a response in JSON (JavaScript
          Object Notation) format like this:
        </p>
        <pre>
          {`    
{  
"symbol": "NFLX",
"companyName": "Netflix, Inc.",
"primaryExchange": "NASDAQ",
"calculationPrice": "close",
"open": 317.49,
"openTime": 1564752600327,
"close": 318.83,
"closeTime": 1564776000616,
"high": 319.41,
"low": 311.8,
"latestPrice": 318.83,
"latestSource": "Close",
"latestTime": "August 2, 2019",
"latestUpdate": 1564776000616,
"latestVolume": 6232279,
"iexRealtimePrice": null,
"iexRealtimeSize": null,
"iexLastUpdated": null,
"delayedPrice": 318.83,
"delayedPriceTime": 1564776000616,
"extendedPrice": 319.37,
"extendedChange": 0.54,
"extendedChangePercent": 0.00169,
"extendedPriceTime": 1564876784244,
"previousClose": 319.5,
"previousVolume": 6563156,
"change": -0.67,
"changePercent": -0.0021,
"volume": 6232279,
"iexMarketPercent": null,
"iexVolume": null,
"avgTotalVolume": 7998833,
"iexBidPrice": null,
"iexBidSize": null,
"iexAskPrice": null,
"iexAskSize": null,
"marketCap": 139594933050,
"peRatio": 120.77,
"week52High": 386.79,
"week52Low": 231.23,
"ytdChange": 0.18907500000000002,
"lastTradeTime": 1564776000616
}
`}
        </pre>

        <p>
          Notice how, between the curly braces, there’s a comma-separated list
          of key-value pairs, with a colon separating each key from its value.
        </p>

        <p>Let’s turn our attention now to this problem’s distribution code!</p>

        <h2 id="distribution">Distribution</h2>

        <h3 id="downloading">Downloading</h3>
        <pre>
          {`
$ wget https://cdn.cs50.net/2019/fall/tracks/web/finance/finance.zip
$ unzip finance.zip
$ rm finance.zip
$ cd finance
$ ls
application.py  helpers.py        static/
finance.db      requirements.txt  templates/
`}
        </pre>

        <h3 id="configuring">Configuring</h3>

        <p>
          Before getting started on this assignment, we’ll need to register for
          an API key in order to be able to query IEX’s data. To do so, follow
          these steps:
        </p>

        <ul>
          <li>
            Visit{" "}
            <a href="https://iexcloud.io/cloud-login#/register/">
              iexcloud.io/cloud-login#/register/
            </a>
            .
          </li>
          <li>
            Enter your email address and a password, and click “Create account”.
          </li>
          <li>
            On the next page, scroll down to choose the <strong>Start</strong>{" "}
            (free) plan.
          </li>
          <li>
            Once you’ve confirmed your account via a confirmation email, sign in
            to <a href="https://iexcloud.io/">iexcloud.io</a>.
          </li>
          <li>
            Click <em>API Tokens</em>.
          </li>
          <li>
            Copy the key that appears under the <em>Token</em> column (it should
            begin with{" "}
            <code className="language-plaintext highlighter-rouge">pk_</code>).
          </li>
          <li>In a terminal window within CS50 IDE, execute:</li>
        </ul>

        <pre> {`$ export API_KEY=value`} </pre>

        <p>
          where{" "}
          <code className="language-plaintext highlighter-rouge">value</code> is
          that (pasted) value, without any space immediately before or after the{" "}
          <code className="language-plaintext highlighter-rouge">=</code>. You
          also may wish to paste that value in a text document somewhere, in
          case you need it again later.
        </p>

        <h3 id="running">Running</h3>

        <p>
          . Start Flask’s built-in web server (within{" "}
          <code className="language-plaintext highlighter-rouge">finance/</code>
          ):
        </p>

        <div className="language-plaintext highlighter-rouge">
          <div className="highlight">
            <pre className="highlight">
              <code>
                <pre>{`$ flask run`}</pre>
              </code>
            </pre>
          </div>
        </div>

        <p>
          Visit the URL outputted by{" "}
          <code className="language-plaintext highlighter-rouge">flask</code> to
          see the distribution code in action. You won’t be able to log in or
          register, though, just yet!
        </p>

        <p>
          Via CS50’s file browser, double-click{" "}
          <code className="language-plaintext highlighter-rouge">
            finance.db
          </code>{" "}
          in order to open it with phpLiteAdmin. Notice how{" "}
          <code className="language-plaintext highlighter-rouge">
            finance.db
          </code>{" "}
          comes with a table called{" "}
          <code className="language-plaintext highlighter-rouge">users</code>.
          Take a look at its structure (i.e., schema). Notice how, by default,
          new users will receive $10,000 in cash. But there aren’t (yet!) any
          users (i.e., rows) therein to browse. + Here on out, if you’d prefer a
          command line, you’re welcome to use{" "}
          <code className="language-plaintext highlighter-rouge">sqlite3</code>{" "}
          instead of phpLiteAdmin.
        </p>

        <h3 id="understanding">Understanding</h3>

        <h4 id="applicationpy">
          <code className="language-plaintext highlighter-rouge">
            application.py
          </code>
        </h4>

        <p>
          Open up{" "}
          <code className="language-plaintext highlighter-rouge">
            application.py
          </code>
          . Atop the file are a bunch of imports, among them CS50’s SQL module
          and a few helper functions. More on those soon.
        </p>

        <p>
          After configuring <a href="http://flask.pocoo.org/">Flask</a>, notice
          how this file disables caching of responses (provided you’re in
          debugging mode, which you are by default on CS50 IDE), lest you make a
          change to some file but your browser not notice. Notice next how it
          configures <a href="http://jinja.pocoo.org/">Jinja</a> with a custom
          “filter,”{" "}
          <code className="language-plaintext highlighter-rouge">usd</code>, a
          function (defined in{" "}
          <code className="language-plaintext highlighter-rouge">
            helpers.py
          </code>
          ) that will make it easier to format values as US dollars (USD). It
          then further configures Flask to store{" "}
          <a href="http://flask.pocoo.org/docs/1.0/quickstart/#sessions">
            sessions
          </a>{" "}
          on the local filesystem (i.e., disk) as opposed to storing them inside
          of (digitally signed) cookies, which is Flask’s default. The file then
          configures CS50’s SQL module to use{" "}
          <code className="language-plaintext highlighter-rouge">
            finance.db
          </code>
          , a SQLite database whose contents we’ll soon see!
        </p>

        <p>
          Thereafter are a whole bunch of routes, only two of which are fully
          implemented:{" "}
          <code className="language-plaintext highlighter-rouge">login</code>{" "}
          and{" "}
          <code className="language-plaintext highlighter-rouge">logout</code>.
          Read through the implementation of{" "}
          <code className="language-plaintext highlighter-rouge">login</code>{" "}
          first. Notice how it uses{" "}
          <code className="language-plaintext highlighter-rouge">
            db.execute
          </code>{" "}
          (from CS50’s library) to query{" "}
          <code className="language-plaintext highlighter-rouge">
            finance.db
          </code>
          . And notice how it uses{" "}
          <code className="language-plaintext highlighter-rouge">
            check_password_hash
          </code>{" "}
          to compare hashes of users’ passwords. Finally, notice how{" "}
          <code className="language-plaintext highlighter-rouge">login</code>{" "}
          “remembers” that a user is logged in by storing his or her{" "}
          <code className="language-plaintext highlighter-rouge">user_id</code>,
          an INTEGER, in{" "}
          <code className="language-plaintext highlighter-rouge">session</code>.
          That way, any of this file’s routes can check which user, if any, is
          logged in. Meanwhile, notice how{" "}
          <code className="language-plaintext highlighter-rouge">logout</code>{" "}
          simply clears{" "}
          <code className="language-plaintext highlighter-rouge">session</code>,
          effectively logging a user out.
        </p>

        <p>
          Notice how most routes are “decorated” with{" "}
          <code className="language-plaintext highlighter-rouge">
            @login_required
          </code>{" "}
          (a function defined in{" "}
          <code className="language-plaintext highlighter-rouge">
            helpers.py
          </code>{" "}
          too). That decorator ensures that, if a user tries to visit any of
          those routes, he or she will first be redirected to{" "}
          <code className="language-plaintext highlighter-rouge">login</code> so
          as to log in.
        </p>

        <p>
          Notice too how most routes support GET and POST. Even so, most of them
          (for now!) simply return an “apology,” since they’re not yet
          implemented.
        </p>

        <h4 id="helperspy">
          <code className="language-plaintext highlighter-rouge">
            helpers.py
          </code>
        </h4>

        <p>
          Next take a look at{" "}
          <code className="language-plaintext highlighter-rouge">
            helpers.py
          </code>
          . Ah, there’s the implementation of{" "}
          <code className="language-plaintext highlighter-rouge">apology</code>.
          Notice how it ultimately renders a template,{" "}
          <code className="language-plaintext highlighter-rouge">
            apology.html
          </code>
          . It also happens to define within itself another function,{" "}
          <code className="language-plaintext highlighter-rouge">escape</code>,
          that it simply uses to replace special characters in apologies. By
          defining{" "}
          <code className="language-plaintext highlighter-rouge">escape</code>{" "}
          inside of{" "}
          <code className="language-plaintext highlighter-rouge">apology</code>,
          we’ve scoped the former to the latter alone; no other functions will
          be able (or need) to call it.
        </p>

        <p>
          Next in the file is{" "}
          <code className="language-plaintext highlighter-rouge">
            login_required
          </code>
          . No worries if this one’s a bit cryptic, but if you’ve ever wondered
          how a function can return another function, here’s an example!
        </p>

        <p>
          Thereafter is{" "}
          <code className="language-plaintext highlighter-rouge">lookup</code>,
          a function that, given a{" "}
          <code className="language-plaintext highlighter-rouge">symbol</code>{" "}
          (e.g., NFLX), returns a stock quote for a company in the form of a{" "}
          <code className="language-plaintext highlighter-rouge">dict</code>{" "}
          with three keys:{" "}
          <code className="language-plaintext highlighter-rouge">name</code>,
          whose value is a{" "}
          <code className="language-plaintext highlighter-rouge">str</code>, the
          name of the company;{" "}
          <code className="language-plaintext highlighter-rouge">price</code>,
          whose value is a{" "}
          <code className="language-plaintext highlighter-rouge">float</code>;
          and{" "}
          <code className="language-plaintext highlighter-rouge">symbol</code>,
          whose value is a{" "}
          <code className="language-plaintext highlighter-rouge">str</code>, a
          canonicalized (uppercase) version of a stock’s symbol, irrespective of
          how that symbol was capitalized when passed into{" "}
          <code className="language-plaintext highlighter-rouge">lookup</code>.
        </p>

        <p>
          Last in the file is{" "}
          <code className="language-plaintext highlighter-rouge">usd</code>, a
          short function that simply formats a{" "}
          <code className="language-plaintext highlighter-rouge">float</code> as
          USD (e.g.,{" "}
          <code className="language-plaintext highlighter-rouge">1234.56</code>{" "}
          is formatted as{" "}
          <code className="language-plaintext highlighter-rouge">
            $1,234.56
          </code>
          ).
        </p>

        <h4 id="requirementstxt">
          <code className="language-plaintext highlighter-rouge">
            requirements.txt
          </code>
        </h4>

        <p>
          Next take a quick look at{" "}
          <code className="language-plaintext highlighter-rouge">
            requirements.txt
          </code>
          . That file simply prescribes the packages on which this app will
          depend.
        </p>

        <h4 id="static">
          <code className="language-plaintext highlighter-rouge">static/</code>
        </h4>

        <p>
          Glance too at{" "}
          <code className="language-plaintext highlighter-rouge">static/</code>,
          inside of which is{" "}
          <code className="language-plaintext highlighter-rouge">
            styles.css
          </code>
          . That’s where some initial CSS lives. You’re welcome to alter it as
          you see fit.
        </p>

        <h4 id="templates">
          <code className="language-plaintext highlighter-rouge">
            templates/
          </code>
        </h4>

        <p>
          Now look in{" "}
          <code className="language-plaintext highlighter-rouge">
            templates/
          </code>
          . In{" "}
          <code className="language-plaintext highlighter-rouge">
            login.html
          </code>{" "}
          is, essentially, just an HTML form, stylized with{" "}
          <a href="http://getbootstrap.com/.">Bootstrap</a> In{" "}
          <code className="language-plaintext highlighter-rouge">
            apology.html
          </code>
          , meanwhile, is a template for an apology. Recall that{" "}
          <code className="language-plaintext highlighter-rouge">apology</code>{" "}
          in{" "}
          <code className="language-plaintext highlighter-rouge">
            helpers.py
          </code>{" "}
          took two arguments:{" "}
          <code className="language-plaintext highlighter-rouge">message</code>,
          which was passed to{" "}
          <code className="language-plaintext highlighter-rouge">
            render_template
          </code>{" "}
          as the value of{" "}
          <code className="language-plaintext highlighter-rouge">bottom</code>,
          and, optionally,{" "}
          <code className="language-plaintext highlighter-rouge">code</code>,
          which was passed to{" "}
          <code className="language-plaintext highlighter-rouge">
            render_template
          </code>{" "}
          as the value of{" "}
          <code className="language-plaintext highlighter-rouge">top</code>.
          Notice in{" "}
          <code className="language-plaintext highlighter-rouge">
            apology.html
          </code>{" "}
          how those values are ultimately used! And{" "}
          <a href="https://github.com/jacebrowning/memegen">here’s why</a>. 0:-)
        </p>

        <p>
          Last up is{" "}
          <code className="language-plaintext highlighter-rouge">
            layout.html
          </code>
          . It’s a bit bigger than usual, but that’s mostly because it comes
          with a fancy, mobile-friendly “navbar” (navigation bar), also based on
          Bootstrap. Notice how it defines a block,{" "}
          <code className="language-plaintext highlighter-rouge">main</code>,
          inside of which templates (including{" "}
          <code className="language-plaintext highlighter-rouge">
            apology.html
          </code>{" "}
          and{" "}
          <code className="language-plaintext highlighter-rouge">
            login.html
          </code>
          ) shall go. It also includes support for Flask’s{" "}
          <a href="http://flask.pocoo.org/docs/1.0/patterns/flashing/">
            message flashing
          </a>{" "}
          so that you can relay messages from one route to another for the user
          to see.
        </p>

        <h2 id="specification">Specification</h2>

        <h3 id="register">
          <code className="language-plaintext highlighter-rouge">register</code>
        </h3>

        <p>
          Complete the implementation of{" "}
          <code className="language-plaintext highlighter-rouge">register</code>{" "}
          in such a way that it allows a user to register for an account via a
          form.
        </p>

        <ul>
          <li>
            Require that a user input a username, implemented as a text field
            whose{" "}
            <code className="language-plaintext highlighter-rouge">name</code>{" "}
            is{" "}
            <code className="language-plaintext highlighter-rouge">
              username
            </code>
            . Render an apology if the user’s input is blank or the username
            already exists.
          </li>
          <li>
            Require that a user input a password, implemented as a text field
            whose{" "}
            <code className="language-plaintext highlighter-rouge">name</code>{" "}
            is{" "}
            <code className="language-plaintext highlighter-rouge">
              password
            </code>
            , and then that same password again, implemented as a text field
            whose{" "}
            <code className="language-plaintext highlighter-rouge">name</code>{" "}
            is{" "}
            <code className="language-plaintext highlighter-rouge">
              confirmation
            </code>
            . Render an apology if either input is blank or the passwords do not
            match.
          </li>
          <li>
            Submit the user’s input via{" "}
            <code className="language-plaintext highlighter-rouge">POST</code>{" "}
            to{" "}
            <code className="language-plaintext highlighter-rouge">
              /register
            </code>
            .
          </li>
          <li>
            <code className="language-plaintext highlighter-rouge">INSERT</code>{" "}
            the new user into{" "}
            <code className="language-plaintext highlighter-rouge">users</code>,
            storing a hash of the user’s password, not the password itself. Hash
            the user’s password with{" "}
            <a
              href="http://werkzeug.pocoo.org/docs/0.14/utils/#werkzeug.security.generate_password_hash.
*"
            >
              <code className="language-plaintext highlighter-rouge">
                generate_password_hash
              </code>
            </a>{" "}
            Odds are you’ll want to create a new template (e.g.,{" "}
            <code className="language-plaintext highlighter-rouge">
              register.html
            </code>
            ) that’s quite similar to{" "}
            <code className="language-plaintext highlighter-rouge">
              login.html
            </code>
            .
          </li>
        </ul>

        <p>
          Once you’ve implemented{" "}
          <code className="language-plaintext highlighter-rouge">register</code>{" "}
          correctly, you should be able to register for an account and log in
          (since{" "}
          <code className="language-plaintext highlighter-rouge">login</code>{" "}
          and{" "}
          <code className="language-plaintext highlighter-rouge">logout</code>{" "}
          already work)! And you should be able to see your rows via
          phpLiteAdmin or{" "}
          <code className="language-plaintext highlighter-rouge">sqlite3</code>.
        </p>

        <h3 id="quote">
          <code className="language-plaintext highlighter-rouge">quote</code>
        </h3>

        <p>
          Complete the implementation of{" "}
          <code className="language-plaintext highlighter-rouge">quote</code> in
          such a way that it allows a user to look up a stock’s current price.
        </p>

        <ul>
          <li>
            Require that a user input a stock’s symbol, implemented as a text
            field whose{" "}
            <code className="language-plaintext highlighter-rouge">name</code>{" "}
            is{" "}
            <code className="language-plaintext highlighter-rouge">symbol</code>
            .
          </li>
          <li>
            Submit the user’s input via{" "}
            <code className="language-plaintext highlighter-rouge">POST</code>{" "}
            to{" "}
            <code className="language-plaintext highlighter-rouge">/quote</code>
            .
          </li>
          <li>
            Odds are you’ll want to create two new templates (e.g.,{" "}
            <code className="language-plaintext highlighter-rouge">
              quote.html
            </code>{" "}
            and{" "}
            <code className="language-plaintext highlighter-rouge">
              quoted.html
            </code>
            ). When a user visits{" "}
            <code className="language-plaintext highlighter-rouge">/quote</code>{" "}
            via GET, render one of those templates, inside of which should be an
            HTML form that submits to{" "}
            <code className="language-plaintext highlighter-rouge">/quote</code>{" "}
            via POST. In response to a POST,{" "}
            <code className="language-plaintext highlighter-rouge">quote</code>{" "}
            can render that second template, embedding within it one or more
            values from{" "}
            <code className="language-plaintext highlighter-rouge">lookup</code>
            .
          </li>
        </ul>

        <h3 id="buy">
          <code className="language-plaintext highlighter-rouge">buy</code>
        </h3>

        <p>
          Complete the implementation of{" "}
          <code className="language-plaintext highlighter-rouge">buy</code> in
          such a way that it enables a user to buy stocks.
        </p>

        <ul>
          <li>
            Require that a user input a stock’s symbol, implemented as a text
            field whose{" "}
            <code className="language-plaintext highlighter-rouge">name</code>{" "}
            is{" "}
            <code className="language-plaintext highlighter-rouge">symbol</code>
            . Render an apology if the input is blank or the symbol does not
            exist (as per the return value of{" "}
            <code className="language-plaintext highlighter-rouge">lookup</code>
            ).
          </li>
          <li>
            Require that a user input a number of shares, implemented as a text
            field whose{" "}
            <code className="language-plaintext highlighter-rouge">name</code>{" "}
            is{" "}
            <code className="language-plaintext highlighter-rouge">shares</code>
            . Render an apology if the input is not a positive integer.
          </li>
          <li>
            Submit the user’s input via{" "}
            <code className="language-plaintext highlighter-rouge">POST</code>{" "}
            to{" "}
            <code className="language-plaintext highlighter-rouge">/buy</code>.
          </li>
          <li>
            Odds are you’ll want to call{" "}
            <code className="language-plaintext highlighter-rouge">lookup</code>{" "}
            to look up a stock’s current price.
          </li>
          <li>
            Odds are you’ll want to{" "}
            <code className="language-plaintext highlighter-rouge">SELECT</code>{" "}
            how much cash the user currently has in{" "}
            <code className="language-plaintext highlighter-rouge">users</code>.
          </li>
          <li>
            Add one or more new tables to{" "}
            <code className="language-plaintext highlighter-rouge">
              finance.db
            </code>{" "}
            via which to keep track of the purchase. Store enough information so
            that you know who bought what at what price and when.
            <ul>
              <li>Use appropriate SQLite types.</li>
              <li>
                Define{" "}
                <code className="language-plaintext highlighter-rouge">
                  UNIQUE
                </code>{" "}
                indexes on any fields that should be unique.
              </li>
              <li>
                Define (non-
                <code className="language-plaintext highlighter-rouge">
                  UNIQUE
                </code>
                ) indexes on any fields via which you will search (as via{" "}
                <code className="language-plaintext highlighter-rouge">
                  SELECT
                </code>{" "}
                with{" "}
                <code className="language-plaintext highlighter-rouge">
                  WHERE
                </code>
                ).
              </li>
            </ul>
          </li>
          <li>
            Render an apology, without completing a purchase, if the user cannot
            afford the number of shares at the current price.
          </li>
          <li>
            You don’t need to worry about race conditions (or use transactions).
          </li>
        </ul>

        <p>
          Once you’ve implemented{" "}
          <code className="language-plaintext highlighter-rouge">buy</code>{" "}
          correctly, you should be able to see users’ purchases in your new
          table(s) via phpLiteAdmin or{" "}
          <code className="language-plaintext highlighter-rouge">sqlite3</code>.
        </p>

        <h3 id="index">
          <code className="language-plaintext highlighter-rouge">index</code>
        </h3>

        <p>
          Complete the implementation of{" "}
          <code className="language-plaintext highlighter-rouge">index</code> in
          such a way that it displays an HTML table summarizing, for the user
          currently logged in, which stocks the user owns, the numbers of shares
          owned, the current price of each stock, and the total value of each
          holding (i.e., shares times price). Also display the user’s current
          cash balance along with a grand total (i.e., stocks’ total value plus
          cash).
        </p>

        <ul>
          <li>
            Odds are you’ll want to execute multiple{" "}
            <code className="language-plaintext highlighter-rouge">SELECT</code>
            s. Depending on how you implement your table(s), you might find{" "}
            <a href="https://www.google.com/search?q=SQLite+GROUP+BY,">
              GROUP BY
            </a>{" "}
            <a href="https://www.google.com/search?q=SQLite+HAVING,">HAVING</a>{" "}
            <a href="https://www.google.com/search?q=SQLite+SUM,">SUM</a> and/or{" "}
            <a href="https://www.google.com/search?q=SQLite+WHERE">WHERE</a> of
            interest.
          </li>
          <li>
            Odds are you’ll want to call{" "}
            <code className="language-plaintext highlighter-rouge">lookup</code>{" "}
            for each stock.
          </li>
        </ul>

        <h3 id="sell">
          <code className="language-plaintext highlighter-rouge">sell</code>
        </h3>

        <p>
          Complete the implementation of{" "}
          <code className="language-plaintext highlighter-rouge">sell</code> in
          such a way that it enables a user to sell shares of a stock (that he
          or she owns).
        </p>

        <ul>
          <li>
            Require that a user input a stock’s symbol, implemented as a{" "}
            <code className="language-plaintext highlighter-rouge">select</code>{" "}
            menu whose{" "}
            <code className="language-plaintext highlighter-rouge">name</code>{" "}
            is{" "}
            <code className="language-plaintext highlighter-rouge">symbol</code>
            . Render an apology if the user fails to select a stock or if
            (somehow, once submitted) the user does not own any shares of that
            stock.
          </li>
          <li>
            Require that a user input a number of shares, implemented as a text
            field whose{" "}
            <code className="language-plaintext highlighter-rouge">name</code>{" "}
            is{" "}
            <code className="language-plaintext highlighter-rouge">shares</code>
            . Render an apology if the input is not a positive integer or if the
            user does not own that many shares of the stock.
          </li>
          <li>
            Submit the user’s input via{" "}
            <code className="language-plaintext highlighter-rouge">POST</code>{" "}
            to{" "}
            <code className="language-plaintext highlighter-rouge">/sell</code>.
          </li>
          <li>
            You don’t need to worry about race conditions (or use transactions).
          </li>
        </ul>

        <h3 id="history">
          <code className="language-plaintext highlighter-rouge">history</code>
        </h3>

        <p>
          Complete the implementation of{" "}
          <code className="language-plaintext highlighter-rouge">history</code>{" "}
          in such a way that it displays an HTML table summarizing all of a
          user’s transactions ever, listing row by row each and every buy and
          every sell.
        </p>

        <ul>
          <li>
            For each row, make clear whether a stock was bought or sold and
            include the stock’s symbol, the (purchase or sale) price, the number
            of shares bought or sold, and the date and time at which the
            transaction occurred.
          </li>
          <li>
            You might need to alter the table you created for{" "}
            <code className="language-plaintext highlighter-rouge">buy</code> or
            supplement it with an additional table. Try to minimize
            redundancies.
          </li>
        </ul>

        <h3 id="personal-touch">personal touch</h3>

        <p>Implement at least one personal touch of your choice:</p>

        <ul>
          <li>Allow users to change their passwords.</li>
          <li>Allow users to add additional cash to their account.</li>
          <li>
            Allow users to buy more shares or sell shares of stocks they already
            own via{" "}
            <code className="language-plaintext highlighter-rouge">index</code>{" "}
            itself, without having to type stocks’ symbols manually.
          </li>
          <li>
            Require users’ passwords to have some number of letters, numbers,
            and/or symbols.
          </li>
          <li>Implement some other feature of comparable scope.</li>
        </ul>

        <h2 id="testing">Testing</h2>

        <p>Be sure to test your web app manually too, as by</p>

        <ul>
          <li>
            inputting alpabetical strings into forms when only numbers are
            expected,
          </li>
          <li>
            inputting zero or negative numbers into forms when only positive
            numbers are expected,
          </li>
          <li>
            inputting floating-point values into forms when only integers are
            expected,
          </li>
          <li>trying to spend more cash than a user has,</li>
          <li>trying to sell more shares than a user has,</li>
          <li>inputting an invalid stock symbol, and</li>
          <li>
            including potentially dangerous characters like{" "}
            <code className="language-plaintext highlighter-rouge">'</code> and{" "}
            <code className="language-plaintext highlighter-rouge">;</code> in
            SQL queries.
          </li>
        </ul>

        <h2 id="staffs-solution">Staff’s Solution</h2>

        <p>
          You’re welcome to stylize your own app differently, but here’s what
          the staff’s solution looks like!
        </p>

        <p>https://finance.cs50.net/</p>

        <p>
          Feel free to register for an account and play around. Do{" "}
          <strong>not</strong> use a password that you use on other sites.
        </p>

        <p>
          It is <strong>reasonable</strong> to look at the staff’s HTML and CSS.
        </p>

        <h2 id="hints">Hints</h2>

        <ul>
          <li>
            <p>
              Within{" "}
              <code className="language-plaintext highlighter-rouge">
                cs50.SQL
              </code>{" "}
              is an{" "}
              <code className="language-plaintext highlighter-rouge">
                execute
              </code>{" "}
              method whose first argument should be a{" "}
              <code className="language-plaintext highlighter-rouge">str</code>{" "}
              of SQL. If that{" "}
              <code className="language-plaintext highlighter-rouge">str</code>{" "}
              contains named parameters to which values should be bound, those
              values can be provided as additional named parameters to{" "}
              <code className="language-plaintext highlighter-rouge">
                execute
              </code>
              . See the implementation of{" "}
              <code className="language-plaintext highlighter-rouge">
                login
              </code>{" "}
              for one such example. The return value of{" "}
              <code className="language-plaintext highlighter-rouge">
                execute
              </code>{" "}
              is as follows:
            </p>

            <ul>
              <li>
                If{" "}
                <code className="language-plaintext highlighter-rouge">
                  str
                </code>{" "}
                is a{" "}
                <code className="language-plaintext highlighter-rouge">
                  SELECT
                </code>
                , then{" "}
                <code className="language-plaintext highlighter-rouge">
                  execute
                </code>{" "}
                returns a{" "}
                <code className="language-plaintext highlighter-rouge">
                  list
                </code>{" "}
                of zero or more{" "}
                <code className="language-plaintext highlighter-rouge">
                  dict
                </code>{" "}
                objects, inside of which are keys and values representing a
                table’s fields and cells, respectively.
              </li>
              <li>
                If{" "}
                <code className="language-plaintext highlighter-rouge">
                  str
                </code>{" "}
                is an{" "}
                <code className="language-plaintext highlighter-rouge">
                  INSERT
                </code>
                , and the table into which data was inserted contains an
                autoincrementing{" "}
                <code className="language-plaintext highlighter-rouge">
                  PRIMARY KEY
                </code>
                , then{" "}
                <code className="language-plaintext highlighter-rouge">
                  execute
                </code>{" "}
                returns the value of the newly inserted row’s primary key.
              </li>
              <li>
                If{" "}
                <code className="language-plaintext highlighter-rouge">
                  str
                </code>{" "}
                is a{" "}
                <code className="language-plaintext highlighter-rouge">
                  DELETE
                </code>{" "}
                or an{" "}
                <code className="language-plaintext highlighter-rouge">
                  UPDATE
                </code>
                , then{" "}
                <code className="language-plaintext highlighter-rouge">
                  execute
                </code>{" "}
                returns the number of rows deleted or updated by{" "}
                <code className="language-plaintext highlighter-rouge">
                  str
                </code>
                .
              </li>
            </ul>
          </li>
        </ul>

        <p>
          If an{" "}
          <code className="language-plaintext highlighter-rouge">INSERT</code>{" "}
          or{" "}
          <code className="language-plaintext highlighter-rouge">UPDATE</code>{" "}
          would violate some constraint (e.g., a{" "}
          <code className="language-plaintext highlighter-rouge">UNIQUE</code>{" "}
          index), then{" "}
          <code className="language-plaintext highlighter-rouge">execute</code>{" "}
          returns{" "}
          <code className="language-plaintext highlighter-rouge">None</code>. In
          cases of error,{" "}
          <code className="language-plaintext highlighter-rouge">execute</code>{" "}
          raises a{" "}
          <code className="language-plaintext highlighter-rouge">
            RuntimeError
          </code>
          .
        </p>
        <ul>
          <li>
            Recall that{" "}
            <code className="language-plaintext highlighter-rouge">
              cs50.SQL
            </code>{" "}
            will log to your terminal window any queries that you execute via{" "}
            <code className="language-plaintext highlighter-rouge">
              execute
            </code>{" "}
            (so that you can confirm whether they’re as intended).
          </li>
          <li>
            Be sure to use named bind parameters (i.e., a{" "}
            <a href="https://www.python.org/dev/peps/pep-0249/#paramstyle">
              paramstyle
            </a>{" "}
            of{" "}
            <code className="language-plaintext highlighter-rouge">named</code>)
            when calling CS50’s{" "}
            <code className="language-plaintext highlighter-rouge">
              execute
            </code>{" "}
            method, a la{" "}
            <code className="language-plaintext highlighter-rouge">
              WHERE name=:name
            </code>
            . Do <strong>not</strong> use f-strings,{" "}
            <a href="https://docs.python.org/3.6/library/functions.html#format,">
              <code className="language-plaintext highlighter-rouge">
                format
              </code>
            </a>{" "}
            or <code className="language-plaintext highlighter-rouge">+</code>{" "}
            (i.e., concatenation), lest you risk a SQL injection attack.
          </li>
          <li>
            If (and only if) already comfortable with SQL, you’re welcome to use{" "}
            <a href="http://docs.sqlalchemy.org/en/latest/index.html">
              SQLAlchemy Core
            </a>{" "}
            or <a href="http://flask-sqlalchemy.pocoo.org/">Flask-SQLAlchemy</a>{" "}
            (i.e.,{" "}
            <a href="http://docs.sqlalchemy.org/en/latest/index.html">
              SQLAlchemy ORM
            </a>
            ) instead of{" "}
            <code className="language-plaintext highlighter-rouge">
              cs50.SQL
            </code>
            .
          </li>
          <li>
            You’re welcome to add additional static files to{" "}
            <code className="language-plaintext highlighter-rouge">
              static/
            </code>
            .
          </li>
          <li>
            Odds are you’ll want to consult{" "}
            <a href="http://jinja.pocoo.org/docs/dev/">Jinja’s documentation</a>{" "}
            when implementing your templates.
          </li>
          <li>
            It is <strong>reasonable</strong> to ask others to try out (and try
            to trigger errors in) your site.
          </li>
          <li>
            You’re welcome to alter the aesthetics of the sites, as via
            <ul>
              <li>https://bootswatch.com/,</li>
              <li>https://getbootstrap.com/docs/4.1/content/,</li>
              <li>https://getbootstrap.com/docs/4.1/components/, and/or</li>
              <li>https://memegen.link/.</li>
            </ul>
          </li>
        </ul>

        <h2 id="faqs">FAQs</h2>

        <h3 id="importerror-no-module-named-application">
          ImportError: No module named ‘application’
        </h3>

        <p>
          By default,{" "}
          <code className="language-plaintext highlighter-rouge">flask</code>{" "}
          looks for a file called{" "}
          <code className="language-plaintext highlighter-rouge">
            application.py
          </code>{" "}
          in your current working directory (because we’ve configured the value
          of{" "}
          <code className="language-plaintext highlighter-rouge">
            FLASK_APP
          </code>
          , an environment variable, to be{" "}
          <code className="language-plaintext highlighter-rouge">
            application.py
          </code>
          ). If seeing this error, odds are you’ve run{" "}
          <code className="language-plaintext highlighter-rouge">flask</code> in
          the wrong directory!
        </p>

        <h3 id="oserror-errno-98-address-already-in-use">
          OSError: [Errno 98] Address already in use
        </h3>

        <p>
          If, upon running{" "}
          <code className="language-plaintext highlighter-rouge">flask</code>,
          you see this error, odds are you (still) have{" "}
          <code className="language-plaintext highlighter-rouge">flask</code>{" "}
          running in another tab. Be sure to kill that other process, as with
          ctrl-c, before starting{" "}
          <code className="language-plaintext highlighter-rouge">flask</code>{" "}
          again. If you haven’t any such other tab, execute{" "}
          <code className="language-plaintext highlighter-rouge">
            fuser -k 8080/tcp
          </code>{" "}
          to kill any processes that are (still) listening on TCP port 8080.
        </p>

        <h2 id="how-to-submit">How to Submit</h2>

        <p>
          Execute the below from within your{" "}
          <code className="language-plaintext highlighter-rouge">finance</code>{" "}
          directory, logging in with your GitHub username and password when
          prompted. For security, you’ll see asterisks (
          <code className="language-plaintext highlighter-rouge">*</code>)
          instead of the actual characters in your password.
        </p>

        <pre>{`submit50 cs50/problems/2020/x/tracks/web/finance`}</pre>
      </main>
    </React.Fragment>
  );
}

export default Finance;
