import React from "react";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import { useHistory } from "react-router";

function Scratch() {
  const history = useHistory();
  window.HashChangeEvent = () => history.goBack();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <main className="col-md markdown-body">
        <h1 id="scratch">Scratch</h1>

        {t("Lang") === "عربي" ? (
          <div
            className="border embed-responsive embed-responsive-16by9"
            data-video=""
            style={{ maxWidth: "720px" }}
          >
            <Iframe
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
              className="embed-responsive-item"
              src={t("pset-scratch")}
            ></Iframe>
          </div>
        ) : (
          ""
        )}

        <p>
          It’s time to choose your own adventure! Your assignment, quite simply,
          is to implement in Scratch any project of your choice, be it an
          interactive story, game, animation, or anything else, subject only to
          the following requirements:
        </p>

        <ul>
          <li data-marker="*">
            Your project must have at least two sprites, at least one of which
            must resemble something other than a cat.
          </li>
          <li data-marker="*">
            Your project must have at least three scripts total (i.e., not
            necessarily three per sprite).
          </li>
          <li data-marker="*">Your project must use at least one condition.</li>
          <li data-marker="*">Your project must use at least one loop.</li>
          <li data-marker="*">Your project must use at least one variable.</li>
          <li data-marker="*">Your project must use at least one sound.</li>
          <li data-marker="*">
            Your project should be more complex than most of those demonstrated
            in lecture (many of which, though instructive, were quite short) but
            it can be less complex than <em>Ivy’s Hardest Game</em>. As such,
            your project should probably use a few dozen puzzle pieces overall.
          </li>
        </ul>

        <p>
          If you’d like to try out some Scratch projects from past students,
          here are a few:
        </p>

        <ul>
          <li data-marker="*">
            <a href="https://scratch.mit.edu/projects/37412/">
              It’s Raining Men
            </a>
            , from lecture
          </li>
          <li data-marker="*">
            <a href="https://scratch.mit.edu/projects/326129587/">
              Ivy’s Hardest Game
            </a>
            , a game, Harvard edition
          </li>
          <li data-marker="*">
            <a href="https://scratch.mit.edu/projects/37413/">Soccer</a>, a game
          </li>
          <li data-marker="*">
            <a href="https://scratch.mit.edu/projects/26329196/">
              Cookie Love Story
            </a>
            , an animation
          </li>
          <li data-marker="*">
            <a href="https://scratch.mit.edu/projects/277536784/">
              Gingerbread tales
            </a>
            , an interactive story
          </li>
          <li data-marker="*">
            <a href="https://scratch.mit.edu/projects/75390754/">
              Intersection
            </a>
            , a game
          </li>
          <li data-marker="*">
            <a href="https://scratch.mit.edu/projects/277537196/">Oscartime</a>,
            a game
          </li>
        </ul>

        <p>
          You might find these{" "}
          <a href="https://scratch.mit.edu/projects/editor/?tutorial=all">
            tutorials
          </a>{" "}
          or{" "}
          <a href="https://scratch.mit.edu/starter-projects">
            starter projects
          </a>{" "}
          helpful. And you’re welcome to explore{" "}
          <a href="https://scratch.mit.edu/explore/projects/all">
            scratch.mit.edu
          </a>{" "}
          for inspiration. But try to think of an idea on your own, and then set
          out to implement it. However, don’t try to implement the entirety of
          your project all at once: pluck off one piece at a time. In other
          words, take baby steps: write a bit of code (i.e., drag and drop a few
          puzzle pieces), test, write a bit more, test, and so forth. And select{" "}
          <strong>File &gt; Save now</strong> every few minutes so that you
          don’t lose any work!
        </p>

        <p>
          If, along the way, you find it too difficult to implement some
          feature, try not to fret; alter your design or work around the
          problem. If you set out to implement an idea that you find fun, odds
          are you won’t find it too hard to satisfy the above requirements.
        </p>

        <p>Alright, off you go. Make us proud!</p>

        <p>
          Once finished with your project, select{" "}
          <strong>File &gt; Save now</strong> one last time. Then select{" "}
          <strong>File &gt; Save to your computer</strong> and keep that file so
          that you can submit it. If prompted by your computer to{" "}
          <strong>Open</strong> or <strong>Save</strong> the file, be sure to{" "}
          <strong>Save</strong> it.
        </p>

        <h2 id="hello-world">Hello, World</h2>

        <p>
          Suffice it to say it’s a bit harder to meet classmates when taking a
          course online. But, thanks to technology, everyone can at least say
          hello!
        </p>

        <p>
          If you have a phone (or digital camera) and would like to say hello to
          classmates, record a 1- to 2-minute video of yourself saying hello,
          perhaps stating where in the world you are, why you’re taking CS50x,
          and something interesting about you! Try to begin your video by saying
          “hello, world” and end it with “my name is…, and this is CS50.” But,
          ultimately, it’s totally up to you.
        </p>

        <p>
          If you do record a video, upload it to YouTube (unless blocked in your
          country, in which case you’re welcome to upload it elsewhere) so that
          you can provide us with its URL when you submit!
        </p>

        <h2 id="how-to-submit">How to Submit</h2>

        <div className="alert" data-alert="warning" role="alert">
          <p>
            Be sure to complete <strong>both</strong> steps below, in order!
          </p>
        </div>

        <h3 id="step-1-of-2">Step 1 of 2</h3>

        <p>
          Submit{" "}
          <a href="https://forms.cs50.io/b4e8bd58-1dd6-4a98-91a4-0d95143d63c6">
            this form
          </a>
          .
        </p>

        <h3 id="step-2-of-2">Step 2 of 2</h3>

        <p>
          This step assumes that you’ve downloaded your Scratch project as a
          file whose name ends in{" "}
          <code className="language-plaintext highlighter-rouge">.sb3</code>.
          And this step also assumes that you’ve{" "}
          <a href="https://github.com/join">signed up for a GitHub account</a>,
          per the above form.
        </p>

        <ol>
          <li>
            Visit{" "}
            <a href="https://submit.cs50.io/invites/9770b67479384c4d8c37790779e466d9">
              this link
            </a>
            , log in with your GitHub account, and click{" "}
            <strong>Authorize cs50</strong>.
          </li>
          <li>
            Check the box indicating that you’d like to grant course staff
            access to your submissions, and click <strong>Join course</strong>.
          </li>
          <li>
            Go to{" "}
            <a href="https://submit.cs50.io/upload/cs50/problems/2021/x/scratch">
              https://submit.cs50.io/upload/cs50/problems/2021/x/scratch
            </a>
            .
          </li>
          <li>
            Click “Choose File” and choose your{" "}
            <code className="language-plaintext highlighter-rouge">.sb3</code>{" "}
            file. Click <strong>Submit</strong>.
          </li>
        </ol>

        <p>
          That’s it! Once your submission uploads, you should be redirected to
          your submission page. Click the submission link and then the{" "}
          <strong>check50</strong> link to see which requirements your project
          met. You are welcome to resubmit as many times as you’d like (before
          the deadline)!
        </p>

        <p>
          To view your current progress in the course, visit the course
          gradebook at <a href="https://cs50.me/cs50x">cs50.me/cs50x</a>!
        </p>
      </main>
    </React.Fragment>
  );
}

export default Scratch;
